import React, { useRef, useEffect, useState } from 'react'
import { AdminTableProps } from '../../models'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsAdminSearch,
  setLmsFormMode,
  setLmsItemId,
} from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import LinkButton from './LinkButton'
import { LabTests } from '../../../labTests/models'

const PanelTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const dispatch = useAppDispatch()
  const filtered = getSearchResults(data, searchParam)
  const textLength = 30
  const [more, setMore] = useState(false)
  const [moreIndex, setMoreIndex] = useState(-1)

  filtered?.sort?.((a: any, b: any) => {
    if (a?.department?.department_name < b?.department?.department_name) {
      return -1
    } else if (
      a?.department?.department_name > b?.department?.department_name
    ) {
      return 1
    } else {
      return a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1
    }
  })

  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = tableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const toggleView = (more: boolean, index: number) => {
    setMore(!more)
    setMoreIndex(index === moreIndex ? -1 : index)
  }

  const renderInterpretation = (interpretation: any, index: number) => {
    const text = interpretation.slice(0, textLength)
    if (interpretation.length <= textLength || moreIndex === index) {
      return interpretation
    }

    return <>{text}</>
  }
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())
  const toggleRow = (rowIndex: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev)
      if (newExpandedRows?.has(rowIndex)) {
        newExpandedRows?.delete(rowIndex)
      } else {
        newExpandedRows?.add(rowIndex)
      }
      return newExpandedRows
    })
  }
  const renderLabTests = (labTests: LabTests[], rowIndex: number) => {
    const initialItemCount = 2
    const isExpanded = expandedRows.has(rowIndex)

    return (
      <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
        {labTests
          .slice(0, isExpanded ? labTests.length : initialItemCount)
          .map((item: LabTests) => (
            <li key={item.id} style={{ marginBottom: '2px' }}>
              • {item?.name}
            </li>
          ))}
        {labTests.length > initialItemCount && (
          <li>
            <span
              onClick={() => toggleRow(rowIndex)}
              style={{ color: 'blue', cursor: 'pointer' }}
            >
              {isExpanded ? '...View Less' : '...View More'}
            </span>
          </li>
        )}
      </ul>
    )
  }

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={tableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Id</th>
            <th>Test Name</th>
            <th className='table-w-10'>Department</th>
            <th className='table-w-10'>Sample</th>
            <th> Parameter Name</th>
            <th className='table-w-8'> Parameter Count</th>
            <th className='table-w-10'>Handled At</th>
            <th className='table-w-5'>Price</th>
            <th className='table-w-5'>TAT</th>
            <th style={{ wordWrap: 'break-word' }}>Interpretation</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any, index: any) => {
              const allLabTests =
                d?.lab_tests?.flatMap((panel: any) => panel?.name) || []
              const testCount = d?.lab_tests?.length ?? 0
              return (
                <tr key={d?.id}>
                  <td>{d?.id}</td>
                  <td>
                    <LinkButton id={d?.id} name={d?.name} onClick={onEdit} />
                  </td>
                  <td>{d?.department?.department_name ?? '-'}</td>
                  <td>{d?.sample?.name}</td>
                  <td>{renderLabTests(d?.lab_tests || [], index)}</td>
                  <td>{testCount}</td>
                  <td>{d?.handled_at ?? '-'}</td>
                  <td>{d?.price ?? '-'}</td>
                  <td>{d?.tat ?? '1'}</td>
                  <td style={{ wordWrap: 'break-word' }}>
                    {d?.interpretation ? (
                      <div>
                        {renderInterpretation(d?.interpretation, index)}
                        <span
                          style={{ color: 'blue', cursor: 'pointer' }}
                          onClick={() => toggleView(more, index)}
                        >
                          {index === moreIndex
                            ? '...View Less'
                            : '...View More'}
                        </span>
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={5} className='text-center'>
                No Panels Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PanelTable
