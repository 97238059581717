import KeycloakService from '../../utils/keycloakService'
import { startSxpProxy } from '../../utils/api'
import { OPD_PROJECT_ID, RADIOLOGY_PROJECT_ID } from '../../utils/constants'
import {
  LabResult,
  LmsOrder,
  RadiologyDashboardTab,
  ResultCount,
} from '../lms/models'
import { OrderEventTest, RadiologyLabOrder, Panel, intent } from './constants'
import { matchName } from '../lms/utils'
import { LabOrder } from '../labTests/models'

export const saveOrder = (
  mode: 'visit' | 'patient' | undefined,
  order: RadiologyLabOrder,
  id: string,
  patientId: string,
  lrNumber?: string,
  filteredPanels?: any,
  filteredSamples?: [],
  source?: string,
  packageId?: number,
  price?: number,
  requestedBy?: string,
  origin?: string
) => {
  const intent = 'createRadiologyOrder'
  const state = {
    visitId: mode === 'visit' && id ? parseInt(id) : undefined,
    radiologyLabTests: filteredPanels,
    radiologySamples: filteredSamples,
    lrNumber: lrNumber,
    orderedByName: KeycloakService.getFullname(),
    orderedById: KeycloakService.getSub(),
    patientId: mode === 'patient' ? id : order?.patient_id ?? patientId,
    status: order?.status ?? 'ORDERED',
    source: order?.source ?? source ?? undefined,
    packageId: packageId,
    price: price,
    requestedBy: requestedBy,
    origin: origin,
  }
  return startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)
}
export const getDoctorForVisit = (id: string): Promise<string> => {
  const state = { id: id }
  return startSxpProxy(OPD_PROJECT_ID, intent.getDoctorByVisit, state)
    .then((doctor) => {
      const { name, qualification } = doctor.data
      return `${name}${qualification ? ` [${qualification}]` : ''}`.trim()
    })
    .catch(() => '')
}
export const prepareOrderEventTests = (
  panels: Panel[],
  testStatus: 'ORDERED' | 'ORDER_MODIFIED' = 'ORDERED'
): OrderEventTest[] => {
  const current = new Date().toISOString()
  const user = KeycloakService.getUsername()
  /* eslint-disable camelcase */
  const pTests: OrderEventTest[] = panels.map((p) => ({
    test_id: p.id,
    status: testStatus,
    updatedOn: current,
    updatedBy: user,
  }))

  return [...pTests]
}
export const groupTests = (
  saved: RadiologyLabOrder,
  selectedPanels: Panel[]
) => {
  const filteredSavedPanels =
    saved?.radiology_lab_tests?.filter(
      (sp) => selectedPanels.findIndex((p) => p.id === sp.id) === -1
    ) ?? []
  const fPanels =
    selectedPanels.filter(
      (sp) =>
        !saved?.radiology_lab_tests ||
        saved?.radiology_lab_tests?.findIndex((p) => p.id === sp.id) === -1
    ) ?? []

  return {
    filteredPanels: [...filteredSavedPanels, ...fPanels],
  }
}

export const mapResults = async (
  orderIds: (string | undefined)[]
): Promise<Record<string, ResultCount>> => {
  try {
    const resp = await startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      'getRadiologyResultsByOrderIds',
      {
        orders: orderIds,
      }
    )
    const resultObj: LabResult[] = resp.data.radiology_result
    const reducedObj: Record<number, LabResult[]> = {}
    resultObj.forEach((rs) => {
      if (reducedObj[rs.lab_order_id]) {
        reducedObj[rs.lab_order_id].push(rs)
      } else {
        reducedObj[rs.lab_order_id] = [rs]
      }
    })
    const out: Record<number, ResultCount> = {}
    for (const obj in reducedObj) {
      const res = reducedObj[obj]
      const total =
        res.length - res.filter((r) => r.test_status === 'REJECTED').length
      const referred = res.filter(
        (r) => r.referred_out && r.test_status !== 'REJECTED'
      ).length
      const pending = res.filter(
        (r) => r.test_status === 'COLLECTED' || r.test_status === 'ENTERED'
      ).length
      out[obj] = {
        total,
        referred,
        pending,
      }
    }
    return out
  } catch (er) {
    return {}
  }
}
export const filterOrders = (
  orders: LmsOrder[],
  searchParam: string
): LmsOrder[] => {
  if (!searchParam) return orders
  const splitNames = searchParam.split(' ')
  return orders.filter((item) => {
    return (
      item.patientThopId?.startsWith(searchParam) ||
      item.patientUhid?.toLowerCase().startsWith(searchParam.toLowerCase()) ||
      (item.lr_number && ('' + item.lr_number)?.startsWith?.(searchParam)) ||
      splitNames.some((sn) => matchName(item.patientName, sn)) ||
      item.source?.toLowerCase().startsWith(searchParam.toLowerCase())
    )
  })
}
export const splitOrders = (
  orders: LmsOrder[],
  date: string,
  activeTab: RadiologyDashboardTab
) => {
  let statuses: string[]
  switch (activeTab) {
    case 'testing':
      statuses = ['COLLECTION_APPROVED', 'ORDERED', 'ORDER_MODIFIED']
      break
    case 'validation':
      statuses = ['SUBMITTED']
      break
    default:
      statuses = []
  }
  const current: any = []
  const other: LmsOrder[] = []
  for (const order of orders) {
    const tests = order.radiology_events?.[0]?.tests ?? []
    const filtered = tests
      .filter((t: any) => statuses.includes(t.status))
      .map((t: any) => t.updatedOn.slice(0, 10))
    if (!filtered.length) {
      continue
    }
    if (filtered.some((f: any) => f === date)) {
      current.push(order)
    } else {
      other.push(order)
    }
  }
  return [current, other]
}
export const getLabTestCount = (order: LmsOrder | LabOrder) => {
  const panelCount =
    order.radiology_lab_tests?.reduce(
      (acc: any, cur: { radiology_lab_tests: string | any[] }) =>
        acc + cur.radiology_lab_tests.length,
      0
    ) ?? 0
  return panelCount + (order.radiology_lab_tests?.length ?? 0)
}
