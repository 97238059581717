import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectSelectedLocationId } from '../../location/locationSlice'
import { toast } from 'react-toastify'
import { startSxpProxy } from '../../../utils/api'
import {
  ADMIN_PROJECT_ID,
  CHAT_PROJECT_ID,
  MEMBERSHIP_PROJECT_ID,
} from '../../../utils/constants'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import { useNavigate } from 'react-router-dom'
import { MemberPatient, MembershipType } from '../models'
import { getAgeInYears } from '../../../utils/dateUtils'
import Loading from '../../../form/fields/Loading'
import { odooPharmacyLink } from '../../appointments/models'

type members = {
  members: MemberPatient[]
  onMemberUnSelect: (val: string) => void
}

const ListOfMembers = ({ members, onMemberUnSelect }: members) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string>('')
  const [memberShipTypes, setMembershipTypes] = useState<MembershipType[]>([])
  const [primaryIndex, setPrimaryIndex] = useState(-1)
  const location = useAppSelector(selectSelectedLocationId)

  useEffect(() => {
    startSxpProxy(MEMBERSHIP_PROJECT_ID, 'membershipProducts', { location })
      .then((data: any) => {
        setMembershipTypes(data?.data)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [location])

  const handleCheckboxChange = (id: number) => {
    if (id !== primaryIndex) {
      startSxpProxy(CHAT_PROJECT_ID, 'checkRegAndRestrict', {
        patientIdentifier: members[id].uhid,
      })
        .then(() => {
          setPrimaryIndex(id)
        })
        .catch((err) => {
          console.log(err, 'err')
          toast(
            <ToastMessage
              message={
                err?.response?.data?.error ??
                'change the registration type to full registration'
              }
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
    } else {
      setPrimaryIndex(-1)
    }
  }

  const handleSelect = (e: any) => {
    setSelectedOption(e.target.value)
  }

  const handleUnselect = (id: string, idx: number) => {
    if (idx == primaryIndex) {
      setPrimaryIndex(-1)
    }
    onMemberUnSelect(id)
  }

  const createMember = () => {
    setLoading(true)
    if (members?.length > 0) {
      const primary = members[primaryIndex]
      const associated = members?.filter((d, idx) => idx !== primaryIndex)
      const associatedUhids = associated.map((d) => d.uhid)

      const selectedType = memberShipTypes.find(
        (mt) => mt.id === parseInt(selectedOption)
      )
      const state = {
        location: location,
        membershipId: selectedType?.id,
        partner: primary?.uhid,
        associatedPartner: associatedUhids.join(','),
        price: selectedType?.list_price,
      }
      startSxpProxy(MEMBERSHIP_PROJECT_ID, 'buyMembership', state)
        .then(async (data: any) => {
          const memberRegister = members.filter((e) => {
            return (
              e.registration !== 'membership' &&
              e.registration !== 'membershipPrimary'
            )
          })
          const memberUhids = memberRegister.map((e) => e.uhid) ?? []
          if (memberUhids?.length > 0) {
            for (let i = 0; i < memberUhids.length; i++) {
              const d = memberUhids[i]
              await startSxpProxy(CHAT_PROJECT_ID, 'updatePatientRegType', {
                patientIdentifier: d,
              })
                .then((data) => console.log(data))
                .catch((err) => console.log(err))
            }
          }
          toast(<ToastMessage message={'Membership Details Saved'} />, {
            ...toastOptions,
            type: 'success',
          })

          navigate('/membership')
          setTimeout(() => {
            const id = data?.data?.[0]?.account_invoice_id?.[0]
            startSxpProxy(ADMIN_PROJECT_ID, odooPharmacyLink, {
              invoiceId: id,
              location: location,
            })
              .then((data) => {
                const url = data?.data?.auth_link
                window.open(url, '_blank', 'noopener,noreferrer')
              })
              .catch((err) => console.error(err, 'err'))
          }, 2000)
        })
        .catch((error) => {
          console.log(error, 'eror')
          toast(
            <ToastMessage
              message={
                error?.response?.data?.error ?? 'Failed To Create Membership'
              }
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <div className='patient' style={{ marginBottom: 20 }}>
        {loading ?? <Loading />}
        <p className='admin-header'>Members List:</p>
        <table className='data-table table-fixed admin-table'>
          <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
            <tr>
              <th className='table-w-4' style={{ padding: '6px' }}>
                Sl&nbsp;No
              </th>
              <th className='text-left table-w-15'>UHID</th>
              <th className='text-left table-w-30'>Name</th>
              <th className='text-center table-w-6'>Gender</th>
              <th className='text-center table-w-8'>Age</th>
              <th className='text-center table-w-6'>Action</th>
            </tr>
          </thead>
          <tbody>
            {members.length > 0 ? (
              members.map((d: MemberPatient, idx) => (
                <tr className='memberId' key={d.id}>
                  <td>
                    <input
                      type='checkbox'
                      checked={idx === primaryIndex}
                      onChange={() => handleCheckboxChange(idx)}
                    />
                  </td>
                  <td>{d.uhid || d.thopId}</td>
                  <td>
                    <button className='lms-btn'>{d.name}</button>
                  </td>
                  <td className='text-center'>{d.gender}</td>
                  <td className='text-center'>{getAgeInYears(d.dob) ?? '-'}</td>
                  <td className='text-center'>
                    <button
                      className='typeButton'
                      onClick={() => handleUnselect(d.id, idx)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  No Members found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='flexDivs'>
        <select
          className='doctorSelected'
          value={selectedOption}
          onChange={handleSelect}
        >
          <option value=''>Select an option</option>
          {memberShipTypes.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <button
          className={
            !selectedOption || members.length === 0 || primaryIndex < 0
              ? 'disabled-button'
              : 'createbutton'
          }
          disabled={!selectedOption || members.length === 0 || primaryIndex < 0}
          onClick={createMember}
        >
          Create Membership
        </button>
      </div>
    </>
  )
}

export default ListOfMembers
