import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSequencePanels } from '../useSequencePanels'
import SequenceTable from './SequenceTable'
import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage'
import { toastOptions } from '../../models'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'

const SequencesConfig = () => {
  const [reload, setReload] = useState(1)
  const [selectedPanel, setSelectedPanel] = useState<any>(null)
  const [samples, setSamples] = useState<any[]>([])
  useEffect(() => {
    const fetchSamples = async () => {
      try {
        const data = await startSxpProxy(
          LABS_PROJECT_ID,
          'AllSamplesAndPanels',
          {}
        )
        const sampleData = data?.data?.data?.sample ?? []
        setSamples(sampleData)
      } catch {
        setSamples([])
      }
    }

    fetchSamples()
  }, [])

  const filteredPanels = samples
    ?.flatMap((sample) =>
      sample?.panels?.filter((panel: any) => panel.lab_tests.length > 1)
    )
    ?.map((panel: any) => ({
      name: panel?.name,
      id: panel?.id,
      lab_tests: panel?.lab_tests,
    }))

  const handlePanelChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const selectedPanelId = parseInt(ev.target.value)
    const selectedPanel = filteredPanels?.find(
      (panel) => panel?.id === selectedPanelId
    )
    setSelectedPanel(selectedPanel ?? null)
  }

  const handleReorder = () => {
    toast(<ToastMessage message={'Lab Tests Sequence saved'} />, {
      ...toastOptions,
      type: 'success',
    })
    setReload((r) => r + 1)
  }

  return (
    <>
      <hr />
      <div className='sequences-container'>
        <div className='sequences-menu'>
          {filteredPanels?.length > 0 ? (
            <select
              title={selectedPanel?.name ?? 'Panel'}
              className='admin-select mt10'
              value={selectedPanel?.id ?? -1}
              onChange={handlePanelChange}
            >
              <option value={-1}>Choose Test</option>
              {filteredPanels?.map((panel) => (
                <option key={panel?.id} value={panel?.id}>
                  {panel?.name}
                </option>
              ))}
            </select>
          ) : (
            <p className='admin-header'>No Tests Found</p>
          )}
        </div>
        <div className='sequences-content'>
          <SequenceTable panel={selectedPanel} onReorder={handleReorder} />
        </div>
      </div>
    </>
  )
}

export default SequencesConfig
