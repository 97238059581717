import React from 'react'
import { NavLink } from 'react-router-dom'
import KeycloakService from '../../../../utils/keycloakService'
import {
  DIAGNOSIS_READ,
  DIAGNOSIS_WRITE,
  HISTORY_READ,
  HISTORY_WRITE,
  LAB_ORDERS_READ,
  LAB_ORDERS_WRITE,
  MEDICATIONS_READ,
  MEDICATIONS_WRITE,
  SYSTEMIC_READ,
  SYSTEMIC_WRITE,
  VITALS_READ,
  VITALS_WRITE,
} from '../../../../utils/roles'

const OpdDentalMenu = () => {
  return (
    <div className='opd-menu'>
      <ul>
        {KeycloakService.hasRole([HISTORY_READ, HISTORY_WRITE]) && (
          <li>
            <NavLink to='historyDental'>History and Examination</NavLink>
          </li>
        )}
        {KeycloakService.hasRole([VITALS_READ, VITALS_WRITE]) && (
          <li>
            <NavLink to='vitalsDental'>Vitals</NavLink>
          </li>
        )}
        {KeycloakService.hasRole([SYSTEMIC_READ, SYSTEMIC_WRITE]) && (
          <li>
            <NavLink to='clinicalExamination'>Clinical Examination</NavLink>
          </li>
        )}
        {KeycloakService.hasRole([DIAGNOSIS_READ, DIAGNOSIS_WRITE]) && (
          <li>
            <NavLink to='diagnosisDental'>Diagnosis</NavLink>
          </li>
        )}
        {KeycloakService.hasRole([DIAGNOSIS_READ, DIAGNOSIS_WRITE]) && (
          <li>
            <NavLink to='treatmentPhysio'>Treatment Plan</NavLink>
          </li>
        )}
        {KeycloakService.hasRole([MEDICATIONS_READ, MEDICATIONS_WRITE]) && (
          <li>
            <NavLink to='medications'>Medications</NavLink>
          </li>
        )}
        {KeycloakService.hasRole([LAB_ORDERS_READ, LAB_ORDERS_WRITE]) && (
          <li>
            <NavLink to='lab-orders'>Lab Orders</NavLink>
          </li>
        )}
        {KeycloakService.hasRole([LAB_ORDERS_READ, LAB_ORDERS_WRITE]) && (
          <li>
            <NavLink to='radiology-orders'>Radiology Orders</NavLink>
          </li>
        )}
      </ul>
    </div>
  )
}

export default OpdDentalMenu
