import React from 'react'
import { ScaleLoader } from 'react-spinners'

const Loading = () => {
  return (
    <div className='form-loader'>
      <ScaleLoader color='#004C91' />
    </div>
  )
}

export default Loading
