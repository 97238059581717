import './home.scss'
import { useNavigate } from 'react-router-dom'
import bgimg from '../../assets/images/bg-cover.jpg'
import {
  INNOMAINT_URL,
  RMS_URL,
  REPORT_URL,
  ADMIN_PROJECT_ID,
  COMMANDCENTER_URL,
} from '../../utils/constants'
import {
  ADMINISTRATION_READ,
  ADMINISTRATION_WRITE,
  APPOINTMENTS_READ,
  APPOINTMENTS_WRITE,
  LMS_READ,
  PATIENTS_READ,
  PATIENTS_WRITE,
  RADIOLOGY_READ,
  RADIOLOGY_WRITE,
} from '../../utils/roles'
import RenderOnRole from '../../components/RenderOnRole'
import odoo1 from '../../assets/images/User_icon.png'
import odoo10 from '../../assets/images/imgs/admin.png'
import odoo11 from '../../assets/images/imgs/innoment.png'
import odoo4 from '../../assets/images/imgs/lab.png'
import odoo5 from '../../assets/images/imgs/radiology.png'
import odoo6 from '../../assets/images/imgs/ipd.png'
import odoo9 from '../../assets/images/imgs/report.png'
import odoo12 from '../../assets/images/imgs/rms.png'
import odoo3 from '../../assets/images/imgs/appointment.png'
import odo08 from '../../assets/images/imgs/billing.png'
import odoo7 from '../../assets/images/imgs/Pharmacy.png'
import odoo13 from '../../assets/images/imgs/commandCenter1.png'
import '../Icons/icons.scss'
import { useEffect, useMemo, useState } from 'react'
import { startSxpProxy } from '../../utils/api'
import { useAppSelector } from '../../app/hooks'
import { selectSelectedLocationId } from '../location/locationSlice'

const Menu = () => {
  const navigate = useNavigate()
  const locationId = useAppSelector(selectSelectedLocationId)

  const [billingUrl, setBillingUrl] = useState('')
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'odooLogin', {
      location: locationId,
    })
      .then((data) => setBillingUrl(data?.data?.auth_link))
      .catch((err) => console.error(err, 'err'))
  }, [locationId])

  const [pharmacyUrl, setPharmacyUrl] = useState('')
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'pharmacyLocation', {
      location: locationId,
    })
      .then((data) => setPharmacyUrl(data?.data?.auth_link))
      .catch((err) => console.error(err, 'err'))
  }, [locationId])
  const menuItems = [
    {
      name: 'Registration',
      Img: odoo1,
      url: '/patients',
      roles: [PATIENTS_READ, PATIENTS_WRITE],
    },
    {
      name: 'Appointment',
      Img: odoo3,
      url: '/appointments',
      roles: [APPOINTMENTS_READ, APPOINTMENTS_WRITE],
    },
    {
      name: 'OPD',
      Img: odoo11,
      url: '/opd',
    },
    {
      name: 'Laboratory',
      Img: odoo4,
      url: '/lms/dashboard',
      roles: [LMS_READ],
    },
    {
      name: 'Radiology',
      Img: odoo5,
      url: '/radiology',
      roles: [RADIOLOGY_READ, RADIOLOGY_WRITE],
    },
    {
      name: 'IPD',
      Img: odoo6,
      url: '/bedIpd',
    },
    {
      name: 'Pharmacy',
      Img: odoo7,
      url: pharmacyUrl,
    },
    {
      name: 'Billing',
      Img: odo08,
      url: billingUrl,
    },
    {
      name: 'Reporting',
      Img: odoo9,
      url: REPORT_URL,
    },
    {
      name: 'RMS',
      Img: odoo12,
      url: RMS_URL,
    },
    {
      name: 'Command center',
      Img: odoo13,
      url: COMMANDCENTER_URL,
    },
    {
      name: 'Administration',
      Img: odoo10,
      url: '/administration',
      roles: [ADMINISTRATION_READ, ADMINISTRATION_WRITE],
    },
  ]

  const handleMenuClick = (url: string, name: string) => {
    if (name === 'Pharmacy' && pharmacyUrl) {
      window.open(pharmacyUrl, '_blank')
    } else if (name === 'Billing' && billingUrl) {
      window.open(billingUrl, '_blank')
    } else if (url.includes('http')) {
      window.open(url, '_blank')
    } else {
      navigate(url)
    }
  }

  const content = (
    <div
      className='content-height'
      style={{
        backgroundImage: `url(${bgimg})`,
      }}
    >
      <div className='icons_All' style={{ cursor: 'pointer' }}>
        {menuItems?.map((menuItem, index) => (
          <div
            key={index}
            onClick={() => handleMenuClick(menuItem.url, menuItem.name)}
          >
            <span className='myicons'>
              <img
                src={menuItem.Img}
                alt={`Odoo Icon ${index + 1}`}
                className='odoo-image'
              />
            </span>
            <p className='paragragh_icon'>{menuItem.name}</p>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <RenderOnRole
      roles={[PATIENTS_READ, APPOINTMENTS_READ, LMS_READ]}
      element={content}
    />
  )
}

export default Menu
