import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import appReducer from './appSlice'
import locationReducer from '../features/location/locationSlice'
import patientsReducer from '../features/patients/patientsSlice'
import appointmentsReducer from '../features/appointments/appointmentsSlice'
import slotsReducer from '../features/slots/slotsSlice'
import schedulerReducer from '../features/scheduler/schedulerSlice'
import lmsReducer from '../features/lms/lmsSlice'
import membershipReducer from '../features/membership/membershipSlice'
import radiologyReducer from '../features/Radiology/RadiologySlice'
import IpdVisitReducer from '../features/bedManagement/IpdVisit/IpdVisitSlice'
import appointmentsEncountersReducer from '../features/bedManagement/IpdVisit/appointmentsEncounterSlice'
import IpdPatientRegisterSlice from '../features/ipRegistration/IpdPatientRegisterSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
    location: locationReducer,
    patients: patientsReducer,
    appointments: appointmentsReducer,
    slots: slotsReducer,
    scheduler: schedulerReducer,
    lms: lmsReducer,
    membership: membershipReducer,
    radiology: radiologyReducer,
    IpdVisit: IpdVisitReducer,
    appointmentsEncounters: appointmentsEncountersReducer,
    IpdPatientRegisterDetails: IpdPatientRegisterSlice,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
