import React, { useState } from 'react'
import { IPD_PROJECT_ID } from '../../../../utils/constants'
import SxpForm from '../../../../form/SxpForm'
import { DIAGNOSIS_WRITE } from '../../../../utils/roles'
import KeycloakService from '../../../../utils/keycloakService'

interface props {
  id: string
  intent: string
}

const IpdForm = ({ id, intent }: props) => {
  const [key, setKey] = useState(Date.now())
  const status = true

  return (
    <>
      {id && intent && (
        <SxpForm
          key={key}
          project={IPD_PROJECT_ID}
          intent={intent}
          journeyState={{ encounterId: id }}
          isDynamic
          canSubmit={
            status ? false : KeycloakService.hasRole([DIAGNOSIS_WRITE])
          }
          onJourneyEnd={() => setKey(Date.now())}
        />
      )}
    </>
  )
}

export default IpdForm
