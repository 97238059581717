import React, { useState } from 'react'
import { fetchSlotsAsync, SlotSchedule } from '../slotsSlice'
import {
  readableDateFormat,
  readableSlotFormat,
} from '../../../utils/dateUtils'
import { startSxpProxy } from '../../../utils/api'
import { CHAT_PROJECT_ID } from '../../../utils/constants'
import { useAppDispatch } from '../../../app/hooks'
import KeycloakService from '../../../utils/keycloakService'
import { SLOTS_DELETE } from '../../../utils/roles'
import LoadingButton from '../../../components/LoadingButton'

type Props = {
  schedule: SlotSchedule
  onClose: () => void
}

const ScheduleDialog = ({ schedule, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const [confirm, setConfirm] = useState(false)
  const [apiLoading, setApiLoading] = useState(false)
  const notFree = schedule.slots.some((sl) => sl.status !== 'free')

  const helpText = notFree
    ? 'Appointments are already booked for some slots'
    : 'Deleting the schedule will remove all the slots associated with it. This cannot be undone'

  const handleClose = () => {
    onClose()
  }

  const handleDelete = () => {
    if (!confirm) {
      setConfirm(true)
    } else {
      setApiLoading(true)
      startSxpProxy(CHAT_PROJECT_ID, 'cascadeDeleteSchedule', {
        scheduleId: schedule.id,
      })
        .then(() => {
          dispatch(fetchSlotsAsync())
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setApiLoading(false)
          onClose()
        })
    }
  }

  return (
    <div className='schedule-modal'>
      <p className='modal-heading'>
        Schedule - {readableDateFormat(new Date(schedule.date))}
      </p>
      <div className='modal-slots'>
        {schedule.slots.map((slot) => (
          <div
            key={slot.id}
            className={`modal-slot${slot.status !== 'free' ? ' busy' : ''}`}
          >
            {readableSlotFormat(new Date(slot.start))}
          </div>
        ))}
      </div>
      {(notFree || confirm) && <p className='modal-text'>{helpText}</p>}
      <div className='modal-buttons'>
        {!notFree && KeycloakService.hasRole([SLOTS_DELETE]) && (
          <LoadingButton
            title={confirm ? 'Confirm Delete' : 'Delete'}
            loading={apiLoading}
            onClick={handleDelete}
          />
        )}
        <button autoFocus className='modal-button' onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  )
}

export default ScheduleDialog
