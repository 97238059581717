import React from 'react'
import KeycloakService from '../../../../utils/keycloakService'
import { LMS_ADMIN_WRITE } from '../../../../utils/roles'

type Props = {
  id: string
  name: string
  onClick: (id: string) => void
}

const LinkButton = ({ id, name, onClick }: Props) => {
  return KeycloakService.hasRole([LMS_ADMIN_WRITE]) ? (
    <button onClick={() => onClick(id)} className='lms-btn'>
      {name}
    </button>
  ) : (
    <span>{name}</span>
  )
}

export default LinkButton
