import React from 'react'
import ErrorLogo from '../assets/images/warning.png'

const Error = () => {
  return (
    <div className='center-layout'>
      <img src={ErrorLogo} alt='Unauthorized' />
      <p className='unauthorized'>
        Failed to connect to server. Please contact Support
      </p>
    </div>
  )
}

export default Error
