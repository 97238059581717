import { useEffect, useState, useRef } from 'react'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { downloadModifiedPdf } from '../../utils'
import { trimPDF } from '../../../../utils/constants'
import medunited from '../../../../assets/images/medunited.png'
import email from '../../../../assets/icons/email.png'
import mobile from '../../../../assets/icons/mobile.png'
import location from '../../../../assets/icons/location.png'
import '../../lms.scss'
const TrimPDF = () => {
  const pdfInputRef = useRef<HTMLInputElement>(null)
  const [pdfFormat, setPdfFormat] = useState<string>('')
  const [pdfFile, setPdfFile] = useState<File | null>(null)
  const [pdfFileName, setPdfFileName] = useState<string>('')
  const [modifiedPdfBytes, setModifiedPdfBytes] = useState<Uint8Array | null>()
  const [isDetails, setIsDetails] = useState<string>('')

  async function modifyKimsPdfWithDetails() {
    if (!pdfFile) return

    const existingPdfBytes = pdfFile
      ? await pdfFile?.arrayBuffer()
      : new Uint8Array(0)

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const emailImageBytes = await fetch(email).then((res) => res.arrayBuffer())
    const medUnitedImageBytes = await fetch(medunited).then((res) =>
      res.arrayBuffer()
    )
    const mobileImageBytes = await fetch(mobile).then((res) =>
      res.arrayBuffer()
    )
    const locationImageBytes = await fetch(location).then((res) =>
      res.arrayBuffer()
    )

    const medunitedImage = await pdfDoc.embedPng(medUnitedImageBytes)
    const emailImage = await pdfDoc.embedPng(emailImageBytes)
    const mobileImage = await pdfDoc.embedPng(mobileImageBytes)
    const locationImage = await pdfDoc.embedPng(locationImageBytes)

    const pages = pdfDoc.getPages()
    const { width, height } = pages[0].getSize()

    const topBoxHeight = 7
    const bottomBoxHeight = 20
    const orangeBox = (30 / 100) * width
    const blueBox = (70 / 100) * width

    for (let i = 0; i < pages.length; i++) {
      pages[i].drawRectangle({
        x: 0,
        y: height - topBoxHeight,
        width: orangeBox,
        height: topBoxHeight,
        color: rgb(0.9568627450980393, 0.4549019607843137, 0.12941176470588237),
      })

      pages[i].drawRectangle({
        x: 179,
        y: height - topBoxHeight,
        width: blueBox,
        height: topBoxHeight,
        color: rgb(0, 0.30196078431372547, 0.5686274509803921),
      })

      if (i === 0) {
        pages[i].drawRectangle({
          x: 0,
          y: height - topBoxHeight * 15.5,
          width: width,
          height: 98,
          color: rgb(1, 1, 1),
        })
      } else {
        pages[i].drawRectangle({
          x: 0,
          y: height - topBoxHeight * 9,
          width: width,
          height: 50,
          color: rgb(1, 1, 1),
        })
      }

      pages[i].drawImage(medunitedImage, {
        x: 20,
        y: height - topBoxHeight * 8,
        width: 130,
        height: 30,
      })

      pages[i].drawImage(locationImage, {
        x: 357,
        y: height - topBoxHeight * 5,
      })

      pages[i].drawText(trimPDF.addressText, {
        x: 370,
        y: height - topBoxHeight * 4.5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawImage(mobileImage, {
        x: 400,
        y: height - topBoxHeight * 7,
      })

      pages[i].drawText(trimPDF.mobileText, {
        x: 415,
        y: height - topBoxHeight * 6.5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawImage(emailImage, {
        x: 474,
        y: height - topBoxHeight * 7,
      })

      pages[i].drawText(trimPDF.emailText, {
        x: 488,
        y: height - topBoxHeight * 6.5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawLine({
        start: { x: 0, y: 22 },
        end: { x: width, y: 22 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      })

      pages[i].drawRectangle({
        x: 0,
        y: 0,
        width: width,
        height: bottomBoxHeight,
        color: rgb(1, 1, 1),
      })

      pages[i].drawText(trimPDF.footerTextOne, {
        x: 10,
        y: bottomBoxHeight - 8,
        size: 9,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawText(trimPDF.footerTextTwo, {
        x: 10,
        y: bottomBoxHeight - 18,
        size: 9,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
    }

    const modifiedPdfBytes = await pdfDoc.save()
    setModifiedPdfBytes(modifiedPdfBytes as Uint8Array | null)
  }

  async function modifyKimsPdfWithoutDetails() {
    if (!pdfFile) return

    const existingPdfBytes = pdfFile
      ? await pdfFile?.arrayBuffer()
      : new Uint8Array(0)

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const pages = pdfDoc.getPages()
    const { width, height } = pages[0].getSize()

    for (let i = 0; i < pages.length; i++) {
      pages[i].drawRectangle({
        x: 0,
        y: height - 107,
        width: width,
        height: 107,
        color: rgb(1, 1, 1),
      })
    }
    const modifiedPdfBytes = await pdfDoc.save()
    setModifiedPdfBytes(modifiedPdfBytes as Uint8Array | null)
  }

  async function modifyTenetPdfWithDetails() {
    if (!pdfFile) return

    const existingPdfBytes = pdfFile
      ? await pdfFile?.arrayBuffer()
      : new Uint8Array(0)

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const emailImageBytes = await fetch(email).then((res) => res.arrayBuffer())
    const medUnitedImageBytes = await fetch(medunited).then((res) =>
      res.arrayBuffer()
    )
    const mobileImageBytes = await fetch(mobile).then((res) =>
      res.arrayBuffer()
    )
    const locationImageBytes = await fetch(location).then((res) =>
      res.arrayBuffer()
    )

    const medunitedImage = await pdfDoc.embedPng(medUnitedImageBytes)
    const emailImage = await pdfDoc.embedPng(emailImageBytes)
    const mobileImage = await pdfDoc.embedPng(mobileImageBytes)
    const locationImage = await pdfDoc.embedPng(locationImageBytes)

    const pages = pdfDoc.getPages()
    const { width, height } = pages[0].getSize()

    const topBoxHeight = 7
    const bottomBoxHeight = 30
    const orangeBox = (30 / 100) * width
    const blueBox = (70 / 100) * width

    for (let i = 0; i < pages.length; i++) {
      pages[i].drawRectangle({
        x: 0,
        y: height - topBoxHeight,
        width: orangeBox,
        height: topBoxHeight,
        color: rgb(0.9568627450980393, 0.4549019607843137, 0.12941176470588237),
      })

      pages[i].drawRectangle({
        x: 179,
        y: height - topBoxHeight,
        width: blueBox,
        height: topBoxHeight,
        color: rgb(0, 0.30196078431372547, 0.5686274509803921),
      })

      pages[i].drawRectangle({
        x: 0,
        y: height - topBoxHeight * 15,
        width: width,
        height: 95,
        color: rgb(1, 1, 1),
      })

      pages[i].drawImage(medunitedImage, {
        x: 20,
        y: height - topBoxHeight * 8,
        width: 130,
        height: 30,
      })

      pages[i].drawImage(locationImage, {
        x: 357,
        y: height - topBoxHeight * 5,
      })

      pages[i].drawText(trimPDF.addressText, {
        x: 370,
        y: height - topBoxHeight * 4.5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawImage(mobileImage, {
        x: 400,
        y: height - topBoxHeight * 7,
      })

      pages[i].drawText(trimPDF.mobileText, {
        x: 415,
        y: height - topBoxHeight * 6.5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawImage(emailImage, {
        x: 474,
        y: height - topBoxHeight * 7,
      })

      pages[i].drawText(trimPDF.emailText, {
        x: 488,
        y: height - topBoxHeight * 6.5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawLine({
        start: { x: 0, y: 35 },
        end: { x: width, y: 35 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      })

      pages[i].drawRectangle({
        x: 0,
        y: 0,
        width: width,
        height: bottomBoxHeight,
        color: rgb(1, 1, 1),
      })

      pages[i].drawText(trimPDF.footerTextOne, {
        x: 10,
        y: bottomBoxHeight - 10,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawText(trimPDF.footerTextTwo, {
        x: 10,
        y: bottomBoxHeight - 20,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      const modifiedPdfBytes = await pdfDoc.save()
      setModifiedPdfBytes(modifiedPdfBytes as Uint8Array | null)
    }
  }

  async function modifyTenetPdfWithoutDetails() {
    if (!pdfFile) return

    const existingPdfBytes = pdfFile
      ? await pdfFile?.arrayBuffer()
      : new Uint8Array(0)

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const pages = pdfDoc.getPages()

    const { width, height } = pages[0].getSize()
    const topBoxHeight = 7
    const bottomBoxHeight = 30

    for (let i = 0; i < pages.length; i++) {
      pages[i].drawRectangle({
        x: 0,
        y: height - topBoxHeight * 15,
        width: width,
        height: 95,
        color: rgb(1, 1, 1),
      })

      pages[i].drawRectangle({
        x: 0,
        y: 0,
        width: width,
        height: bottomBoxHeight,
        color: rgb(1, 1, 1),
      })
    }

    const modifiedPdfBytes = await pdfDoc.save()
    setModifiedPdfBytes(modifiedPdfBytes as Uint8Array | null)
  }

  async function modifyGayatriPdfWithDetails() {
    if (!pdfFile) return

    const existingPdfBytes = pdfFile
      ? await pdfFile?.arrayBuffer()
      : new Uint8Array(0)

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const emailImageBytes = await fetch(email).then((res) => res.arrayBuffer())
    const medUnitedImageBytes = await fetch(medunited).then((res) =>
      res.arrayBuffer()
    )
    const mobileImageBytes = await fetch(mobile).then((res) =>
      res.arrayBuffer()
    )
    const locationImageBytes = await fetch(location).then((res) =>
      res.arrayBuffer()
    )

    const medunitedImage = await pdfDoc.embedPng(medUnitedImageBytes)
    const emailImage = await pdfDoc.embedPng(emailImageBytes)
    const mobileImage = await pdfDoc.embedPng(mobileImageBytes)
    const locationImage = await pdfDoc.embedPng(locationImageBytes)

    const pages = pdfDoc.getPages()
    const { width, height } = pages[0].getSize()

    const topBoxHeight = 7
    const bottomBoxHeight = 60
    const orangeBox = (30 / 100) * width
    const blueBox = (70 / 100) * width

    for (let i = 0; i < pages.length; i++) {
      pages[i].drawRectangle({
        x: 0,
        y: height - topBoxHeight,
        width: orangeBox,
        height: topBoxHeight,
        color: rgb(0.9568627450980393, 0.4549019607843137, 0.12941176470588237),
      })

      pages[i].drawRectangle({
        x: 179,
        y: height - topBoxHeight,
        width: blueBox,
        height: topBoxHeight,
        color: rgb(0, 0.30196078431372547, 0.5686274509803921),
      })

      pages[i].drawRectangle({
        x: 0,
        y: height - topBoxHeight * 15,
        width: width,
        height: 95,
        color: rgb(1, 1, 1),
      })

      pages[i].drawImage(medunitedImage, {
        x: 20,
        y: height - topBoxHeight * 8,
        width: 130,
        height: 30,
      })

      pages[i].drawImage(locationImage, {
        x: 357,
        y: height - topBoxHeight * 5,
      })

      pages[i].drawText(trimPDF.addressText, {
        x: 370,
        y: height - topBoxHeight * 4.5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawImage(mobileImage, {
        x: 400,
        y: height - topBoxHeight * 7,
      })

      pages[i].drawText(trimPDF.mobileText, {
        x: 415,
        y: height - topBoxHeight * 6.5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawImage(emailImage, {
        x: 474,
        y: height - topBoxHeight * 7,
      })

      pages[i].drawText(trimPDF.emailText, {
        x: 488,
        y: height - topBoxHeight * 6.5,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawRectangle({
        x: 0,
        y: 0,
        width: width,
        height: bottomBoxHeight,
        color: rgb(1, 1, 1),
      })

      pages[i].drawLine({
        start: { x: 0, y: 35 },
        end: { x: width, y: 35 },
        thickness: 1,
        color: rgb(0, 0, 0),
        opacity: 0.75,
      })

      pages[i].drawText(trimPDF.footerTextOne, {
        x: 10,
        y: bottomBoxHeight - 40,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      pages[i].drawText(trimPDF.footerTextTwo, {
        x: 10,
        y: bottomBoxHeight - 50,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })

      const modifiedPdfBytes = await pdfDoc.save()
      setModifiedPdfBytes(modifiedPdfBytes as Uint8Array | null)
    }
  }

  async function modifyGayatriPdfWithoutDetails() {
    if (!pdfFile) return

    const existingPdfBytes = pdfFile
      ? await pdfFile?.arrayBuffer()
      : new Uint8Array(0)

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const pages = pdfDoc.getPages()

    const { width, height } = pages[0].getSize()
    const topBoxHeight = 7
    const bottomBoxHeight = 60

    for (let i = 0; i < pages.length; i++) {
      pages[i].drawRectangle({
        x: 0,
        y: height - topBoxHeight * 15,
        width: width,
        height: 95,
        color: rgb(1, 1, 1),
      })

      pages[i].drawRectangle({
        x: 0,
        y: 0,
        width: width,
        height: bottomBoxHeight,
        color: rgb(1, 1, 1),
      })
    }

    const modifiedPdfBytes = await pdfDoc.save()
    setModifiedPdfBytes(modifiedPdfBytes as Uint8Array | null)
  }

  useEffect(() => {
    if (pdfFile) {
      if (pdfFormat === 'KIMS' && isDetails === 'with') {
        modifyKimsPdfWithDetails()
      } else if (pdfFormat === 'KIMS' && isDetails === 'without') {
        modifyKimsPdfWithoutDetails()
      } else if (pdfFormat === 'Tenet' && isDetails === 'with') {
        modifyTenetPdfWithDetails()
      } else if (pdfFormat === 'Tenet' && isDetails === 'without') {
        modifyTenetPdfWithoutDetails()
      } else if (pdfFormat === 'Gayatri' && isDetails === 'with') {
        modifyGayatriPdfWithDetails()
      } else if (pdfFormat === 'Gayatri' && isDetails === 'without') {
        modifyGayatriPdfWithoutDetails()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfFormat, isDetails, pdfFile])

  useEffect(() => {
    if (pdfInputRef.current) {
      pdfInputRef.current.value = ''
      setPdfFile(null)
    }
  }, [pdfFormat])

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPdfFile(e.target.files[0])
      setPdfFileName(e.target.files[0]?.name)
    }
  }

  return (
    <div className='trim-container'>
      <div>
        <span
          style={{
            fontSize: '14px',
            fontWeight: 600,
            color: '#363463',
            textAlign: 'center',
          }}
        >
          Trim PDF
        </span>
      </div>
      <table className='data-table table-fixed admin-table relative'>
        <thead>
          <tr>
            <th className='table-w-5'>Sl&nbsp;No</th>
            <th>Select Source</th>
            <th>Header Details</th>
            <th>Input File</th>
            <th>OutPut File</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              <select
                onChange={(e) => setPdfFormat(e.target.value)}
                className='bg-none'
              >
                <option value=''>{trimPDF.selectOption}</option>
                <option value='KIMS'>{trimPDF.kimsText}</option>
                <option value='Tenet'>{trimPDF.tenetText}</option>
                <option value='Gayatri'>{trimPDF.gayatriText}</option>
              </select>
            </td>
            <td>
              {pdfFormat && (
                <select
                  onChange={(e) => setIsDetails(e.target.value)}
                  className='bg-none'
                >
                  <option value=''>{trimPDF.selectOption}</option>
                  <option value='with'>
                    {trimPDF.withHeaderAndFooterText}
                  </option>
                  <option value='without'>
                    {trimPDF.withoutHeaderAndFooterText}
                  </option>
                </select>
              )}
            </td>
            <td>
              {pdfFormat && isDetails && (
                <div>
                  <input
                    ref={pdfInputRef}
                    data-testid='pdf-input'
                    type='file'
                    accept='.pdf'
                    onChange={(e) => handleFileInput(e)}
                  />
                </div>
              )}
            </td>
            <td>
              {pdfFile && isDetails && modifiedPdfBytes && (
                <button
                  data-testid='pdf-download-button'
                  onClick={() =>
                    downloadModifiedPdf(modifiedPdfBytes, pdfFileName)
                  }
                >
                  {trimPDF.trimAndDownloadText}
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default TrimPDF
