import React from 'react'
import DatePicker from 'react-datepicker'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectSlotsEndDate,
  selectSlotsStartDate,
  setSlotsEndDate,
  setSlotsStartDate,
} from '../slotsSlice'
import { isoDateFormat } from '../../../utils/dateUtils'

const SelectDates = () => {
  const start = useAppSelector(selectSlotsStartDate)
  const end = useAppSelector(selectSlotsEndDate)
  const dispatch = useAppDispatch()

  const current = new Date()
  const startDate = new Date(start)
  const endDate = end ? new Date(end) : undefined
  const max = new Date(start)
  max.setUTCDate(max.getUTCDate() + 6)

  const onDatesChange = (dates: [Date | null, Date | null]) => {
    const [startD, endD] = dates
    dispatch(setSlotsStartDate(startD ? isoDateFormat(startD) : ''))
    dispatch(setSlotsEndDate(endD ? isoDateFormat(endD) : ''))
  }

  return (
    <div>
      <p style={{ fontSize: 15, fontWeight: 600 }}>Choose Dates</p>
      <DatePicker
        selected={startDate}
        onChange={onDatesChange}
        startDate={startDate}
        endDate={endDate}
        minDate={current}
        maxDate={max}
        selectsRange
        inline
      />
    </div>
  )
}

export default SelectDates
