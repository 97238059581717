import { useEffect, useState, useCallback } from 'react'
import OPDSearch from './opdSearch'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID } from '../../utils/constants'
import { Patient } from 'fhir/r4'
import { makeName } from '../lms/utils'
import { SearchPatient } from '../membership/models'
import { upComingAppointmentType } from './constant'
import { useNavigate } from 'react-router-dom'

const OPDVisitsDetails = () => {
  const [show, setShow] = useState<boolean>(true)
  const [patients, setPatients] = useState<SearchPatient[]>([])
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    upComingAppointmentType[]
  >([])
  const [appointmentIds, setAppointmentIds] = useState<string[]>([])
  const [encounterDetails, setEncounterDetails] = useState<any>(null)
  const [query, setQuery] = useState<string>('')
  const [param, setParam] = useState<string>('')
  const [result, setResult] = useState('')
  const navigate = useNavigate()
  const [executedOnce, setExecutedOnce] = useState(false)

  const formatPatients = (entry: { resource: Patient }[]): SearchPatient[] => {
    return entry?.map((en) => {
      const gender = en?.resource?.gender
      const pat: SearchPatient = {
        id: en?.resource?.id ?? '',
        name: makeName(en?.resource?.name),
        gender:
          gender === 'male'
            ? 'Male'
            : gender === 'female'
            ? 'Female'
            : 'Others',
        dob: en?.resource?.birthDate ?? '',
        uhid: en?.resource?.identifier?.[2]?.value ?? '',
        thopId: en?.resource?.identifier?.[0]?.value ?? '',
        isSelected: false,
        isNominee: false,
        registration: en?.resource?.identifier?.[3]?.value ?? '',
      }
      return pat
    })
  }

  const handleSearch = useCallback((query: string, param: string) => {
    const state = {
      pageSize: 20,
      query: query,
      value: param || undefined,
    }

    setShow(false)

    startSxpProxy(CHAT_PROJECT_ID, 'getPatientsAPI', state)
      .then((data) => {
        if (data?.data?.entry?.length) {
          const formattedPatients = formatPatients(data?.data?.entry)
          setPatients(formattedPatients)
        } else {
          setPatients([])
        }
      })
      .catch((err) => {
        console.log(err)
        setPatients([])
      })
      .finally(() => {
        setQuery('')
        setParam('')
      })
  }, [])
  const fetchAllAppointments = async (patientId: string) => {
    try {
      const intent = 'allAppointments'
      const state = {
        id: patientId,
      }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)

      if (resp?.data?.length === 0) {
        setResult('No visits found')
        setUpcomingAppointments([])
        setAppointmentIds([])
      } else {
        setUpcomingAppointments(resp?.data)
        const ids = resp?.data?.map(
          (appointment: { appointmentId: string }) => appointment?.appointmentId
        )
        setAppointmentIds(ids)
      }
    } catch (e) {
      console.error('Error fetching upcoming appointments:', e)
    }
  }

  const fetchEncounterForAppointments = useCallback(
    async (appointmentIdsString: string) => {
      try {
        const intent = 'getEncounterForAppointments'
        const state = { appointmentIds: appointmentIdsString }
        const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
        setEncounterDetails(resp?.data)

        if (resp?.data?.length > 0 && !executedOnce) {
          const lastEncounterId = resp?.data[resp?.data?.length - 1].encounterId
          navigate(`/visits/${lastEncounterId}`, {
            state: {
              id: patients[0]?.id,
            },
          })
          setExecutedOnce(true)
          setQuery('')
          setParam('')
        }
      } catch (e) {
        console.error('Error fetching encounter details:', e)
      }
    },
    [navigate, patients, executedOnce]
  )

  useEffect(() => {
    if (patients.length > 0) {
      fetchAllAppointments(patients[0]?.id)
    }
  }, [patients])

  useEffect(() => {
    if (appointmentIds.length > 0) {
      const appointmentIdsString = appointmentIds.join(',')
      fetchEncounterForAppointments(appointmentIdsString)
      setExecutedOnce(true)
    }
  }, [appointmentIds, fetchEncounterForAppointments, executedOnce])
  return (
    <>
      <div className='layoutcontent'>
        <div className='opdpatientdata'>
          <span className='mt4'> OP Visit Details </span>
          <OPDSearch
            onSearch={(query, param) => {
              handleSearch(query, param)
            }}
            onCreateVisit={function (): void {
              throw new Error('Function not implemented.')
            }}
            thopId={''}
          />
        </div>
        {result}
        {!location.pathname.startsWith('/visits/') && show && (
          <div className='appointments-table-container'>
            <table
              className='data-table admin-table'
              style={{ position: 'relative' }}
            >
              <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
                <tr>
                  <th className='table-w-4'>Sl&nbsp;No</th>
                  <th className='text-left table-w-15'>UHID</th>
                  <th className='table-w-8'>Reg.Type</th>
                  <th className='text-left table-w-15'>Name</th>

                  <th className='table-w-8'>Mobile No.</th>
                  <th className='text-center table-w-6'>Gender</th>
                  <th className='text-center table-w-8'>Age</th>
                  <th className='text-center table-w-10'>Operating Unit</th>
                  <th className='text-center table-w-10'>Created On</th>
                  <th className='text-center table-w-10'>Updated On</th>
                  <th className='text-center table-w-10'>
                    Patient Doc&lsquo;s
                  </th>
                  <th className='text-center table-w-10'>View</th>
                </tr>
              </thead>
              <tbody>
                {upcomingAppointments?.length === 0 ? (
                  <tr>
                    <td colSpan={12} style={{ textAlign: 'center' }}>
                      No visits
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={6} style={{ textAlign: 'center' }}>
                      No visits
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}

export default OPDVisitsDetails
