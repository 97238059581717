import { useEffect, useState } from 'react'
import './EditPatientSearch'
import { toast } from 'react-toastify'
import { startSxpProxy } from '../../../utils/api'
import {
  CHAT_PROJECT_ID,
  MEMBERSHIP_PROJECT_ID,
} from '../../../utils/constants'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { selectSelectedLocationId } from '../../location/locationSlice'
import { MemberPatient } from '../models'
import { getAgeInYears } from '../../../utils/dateUtils'
import Loading from '../../../form/fields/Loading'

type member = {
  members: MemberPatient[]
  onMemberUnSelect: (val: string) => void
  previousMembers: MemberPatient[]
}

const EditMemberTable = ({
  members,
  onMemberUnSelect,
  previousMembers,
}: member) => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const [primaryIndex, setPrimaryIndex] = useState<any>(previousMembers)

  const location = useAppSelector(selectSelectedLocationId)
  useEffect(() => {
    setPrimaryIndex(previousMembers?.[0]?.id)
  }, [previousMembers])

  const handleCheckboxChange = (id: string) => {
    if (id !== primaryIndex) {
      startSxpProxy(CHAT_PROJECT_ID, 'checkRegAndRestrict', {
        patientIdentifier: members.find((e) => e.id === id)?.uhid,
      })
        .then(() => {
          setPrimaryIndex(id)
        })
        .catch((err) => {
          console.log(err, 'err')
          toast(
            <ToastMessage
              message={
                err?.response?.data?.error ??
                'change the registration type to full registration'
              }
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
    } else {
      setPrimaryIndex(-1)
    }
  }

  const handleUnselect = (id: string) => {
    if (id == primaryIndex) {
      setPrimaryIndex(-1)
    }
    onMemberUnSelect(id)
  }
  const filteredArray = previousMembers.filter(
    (a) => !members.some((b) => a.uhid === b.uhid)
  )
  const edit = () => {
    setLoading(true)
    const primary = members?.find((e) => e.id === primaryIndex)?.uhid
    const associated = members?.filter((d) => d.id !== primaryIndex)
    const associatedUhids = associated.map((d) => d.uhid)
    const state = {
      location,
      lineId: id,
      partner: primary,
      assoPartnerRefs: associatedUhids.join(','),
    }
    startSxpProxy(MEMBERSHIP_PROJECT_ID, 'editMembership', state)
      .then(async () => {
        if (filteredArray?.length > 0) {
          for (let i = 0; i < filteredArray?.length; i++) {
            await startSxpProxy(CHAT_PROJECT_ID, 'revertMembership', {
              patientIdentifier: filteredArray[i].uhid,
              patientId: filteredArray[i].id,
            })
              .then((data) => console.log(data, 'data'))
              .catch((err) => console.log(err, 'err'))
          }
        }
        const memberRegister = members.filter((e) => {
          return (
            e.registration !== 'membership' &&
            e.registration !== 'membershipPrimary'
          )
        })
        const memberUhids = memberRegister.map((e) => e.uhid) ?? []
        if (memberUhids?.length > 0) {
          for (let i = 0; i < memberUhids.length; i++) {
            const d = memberUhids[i]
            await startSxpProxy(CHAT_PROJECT_ID, 'updatePatientRegType', {
              patientIdentifier: d,
            })
              .then((data) => console.log(data))
              .catch((err) => console.log(err))
          }
        }
        toast(<ToastMessage message={'Edited Members Successfully'} />, {
          ...toastOptions,
          type: 'success',
        })
        setTimeout(() => {
          navigate('/membership')
        }, 2000)
      })
      .catch((err) => {
        console.log(err)
        toast(
          <ToastMessage message={'already added in another membership'} />,
          {
            ...toastOptions,
            type: 'error',
          }
        )
      })
      .finally(() => setLoading(false))
  }

  return (
    <div style={{ marginBottom: 10 }} className='search-table'>
      {loading && <Loading />}
      <p className='admin-header'>Members List:</p>
      <div className='tabFixHead'>
        <table className='data-table table-fixed admin-table'>
          <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
            <tr>
              <th className='table-w-4' style={{ padding: '6px' }}>
                Sl&nbsp;No
              </th>
              <th className='text-left table-w-15'>UHID</th>
              <th className='text-left table-w-25'>Name</th>
              <th className='text-center table-w-6'>Gender</th>
              <th className='text-center table-w-8'>Age</th>
              <th className='text-center table-w-6'>Action</th>
            </tr>
          </thead>
          <tbody>
            {members.length > 0 ? (
              members.map((d: MemberPatient) => (
                <tr className='memberId' key={d.id}>
                  <td>
                    <input
                      type='checkbox'
                      checked={d.id === primaryIndex}
                      onChange={() => handleCheckboxChange(d.id)}
                      disabled={d.id === primaryIndex}
                    />
                  </td>
                  <td>{d.uhid}</td>
                  <td>
                    <button className='lms-btn'>{d.name}</button>
                  </td>
                  <td className='text-center'>{d.gender}</td>
                  <td className='text-center'>{getAgeInYears(d?.dob)}</td>
                  <td className='text-center'>
                    <button
                      className={
                        d?.id === primaryIndex
                          ? 'disabled-button'
                          : 'typeButton'
                      }
                      disabled={d.id === primaryIndex}
                      onClick={() => handleUnselect(d.id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  No Members found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className='flexDivsOne' style={{ marginTop: 15 }}>
          <button className={'btn btn-primaryBtn'} onClick={edit}>
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
export default EditMemberTable
