import React, { useState } from 'react'
import UserImage from '../../assets/images/blank-user.gif'
import Camera from '../../assets/images/camera.png'
import Upload from '../../assets/images/upload.png'
import Delete from '../../assets/images/close.png'
import Dialog from '../../components/Dialog'
import UploadPhoto from './UploadPhoto'
import CaptureImage from './CaptureImage'
import Signature from '../../assets/images/signature.png'
import './profile.scss'
import { modeType } from '../administration/constants'
import {
  captureImage,
  deletePhoto,
  doctorImage,
  doctorSignature,
  profilePicture,
  uploadImage,
} from './constants'

type Props = {
  photo?: string
  onPhotoChange: (photo: string) => void
  item?: string
}

type Mode = '' | 'capture' | 'upload'

const ProfilePicture = ({ photo, onPhotoChange, item }: Props) => {
  const [mode, setMode] = useState<Mode>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleOpen = (val: Mode) => {
    setIsOpen(true)
    setMode(val)
  }

  const handleClose = () => {
    setIsOpen(false)
    setMode('')
  }

  const handleConfirm = (image: string) => {
    setIsOpen(false)
    setMode('')
    onPhotoChange(image)
  }

  const handleDelete = () => {
    onPhotoChange('')
  }
  const handleSignDelete = () => {
    onPhotoChange('')
  }
  return (
    <div className='profile-container'>
      <>
        <div className='overall-container'>
          <div className='photo-container'>
            {photo && item === modeType.admin && (
              <div className='delete-image'>
                <img
                  onClick={handleDelete}
                  className='delete'
                  src={Delete}
                  alt={deletePhoto}
                />
              </div>
            )}
            {photo && item === modeType.signature && (
              <div className='delete-image'>
                <img
                  onClick={handleSignDelete}
                  className='delete'
                  src={Delete}
                  alt={deletePhoto}
                />
              </div>
            )}
          </div>
          {!item && (
            <img
              className='profile'
              src={photo || UserImage}
              alt={profilePicture}
            />
          )}
          {item === modeType.admin && (
            <img
              className='admin'
              src={photo || UserImage}
              alt={profilePicture}
            />
          )}
          {item === modeType.signature && (
            <img
              className='admin'
              src={photo || Signature}
              alt={profilePicture}
            />
          )}
        </div>
        <div className='controls'>
          <img
            onClick={() => handleOpen('capture')}
            className='camera'
            src={Camera}
            alt={captureImage}
          />
          <img
            onClick={() => handleOpen('upload')}
            className={`width-forty ${
              item === 'admin' || item === 'signature'
                ? 'admin-upload'
                : 'upload'
            }`}
            src={Upload}
            alt={uploadImage}
          />
          <Dialog open={isOpen} onClose={handleClose}>
            {mode === 'capture' && (
              <CaptureImage mode={mode} onConfirm={handleConfirm} />
            )}
            {mode === 'upload' && (
              <UploadPhoto mode={mode} onConfirm={handleConfirm} />
            )}
          </Dialog>
        </div>
        <div className='heading'>
          {item === modeType.admin && (
            <span className='heading-action'>{doctorImage}</span>
          )}
          {item === modeType.signature && (
            <span className='heading-action'>{doctorSignature}</span>
          )}
        </div>
      </>
    </div>
  )
}

export default ProfilePicture
