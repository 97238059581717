import { useEffect, useState } from 'react'
import { LmsOrder } from '../../models'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'
import { OrderEvent } from '../../../labTests/models'
import {
  readableDateFormat,
  readableDateTimeFormat,
} from '../../../../utils/dateUtils'
import Close from '../../../../assets/images/close.png'

type SingleEventProps = {
  event: OrderEvent
}

const SingleEvent = ({ event }: SingleEventProps) => {
  let status = ''
  switch (event.type) {
    case 'ORDERED':
    case 'ORDER_MODIFIED':
      status = 'Ordered'
      break
    case 'COLLECTION_IN_PROGRESS':
      status = 'Samples Collected'
      break
    case 'COLLECTION_COMPLETED':
      status = 'Sample Collection Completed'
      break
    case 'COLLECTION_APPROVED':
      status = 'Samples Approved'
      break
    case 'COLLECTION_REJECTED':
      status = 'Samples Rejected'
      break
    case 'RESULTS_ENTERED':
      status = 'Results Entered'
      break
    case 'REFERRED_RESULTS_ENTERED':
      status = 'Referred Results Entered'
      break
    case 'RESULTS_SUBMITTED':
      status = 'Results Submitted'
      break
    case 'RESULTS_APPROVED':
      status = 'Results Approved'
      break
    case 'RESULTS_REJECTED':
      status = 'Results Rejected'
      break
  }

  return (
    <div className='single-event'>
      <div className='event-left'>
        <p className='event-date'>
          {readableDateTimeFormat(new Date(event.created_at))}
        </p>
        <p className='updated-by'>{event.updated_by}</p>
      </div>
      <div>
        <p>{status}</p>
      </div>
    </div>
  )
}

type Props = {
  order: LmsOrder
  onClose: () => void
}

const EventDialog = ({ order, onClose }: Props) => {
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState<OrderEvent[]>([])

  useEffect(() => {
    const orderId = order.id
    startSxpProxy(LABS_PROJECT_ID, 'getOrderEventsByOrder', { orderId })
      .then((data) => {
        setEvents(data.data.order_event)
      })
      .catch((err) => {
        console.log(err)
        setEvents([])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [order])

  return (
    <div className='event-dialog'>
      <span className='event-close' onClick={() => onClose()}>
        <img className='close-img' src={Close} alt='Close Event Dialog' />
      </span>

      <div className='event-header'>
        <div>
          <h2>Order ID: {order.lr_number ?? order.id}</h2>
          <p>
            Ordered: {readableDateFormat(new Date(order.ordered_on ?? ''))}
            {' - '}
            <span>{order.source}</span>
          </p>
        </div>
        <div className='patient-details'>
          <h2>{order.patientName}</h2>
          <p>{order.patientUhid}</p>
        </div>
      </div>
      <div className='events'>
        {loading
          ? 'Loading...'
          : events.map((e) => <SingleEvent event={e} key={e.id} />)}
      </div>
    </div>
  )
}

export default EventDialog
