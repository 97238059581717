import { FormEvent, useState } from 'react'
import Asterisk from '../../../components/Asterisk'
import { startSxpProxy } from '../../../utils/api'
import { useNavigate } from 'react-router-dom'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'

const DepartmentsForm = () => {
  const [text, setText] = useState<string>('')
  const navigate = useNavigate()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const state = {
      specialty: text,
    }
    startSxpProxy(ADMIN_PROJECT_ID, 'createSpecialty', state)
      .then(() => {
        navigate('/administration/departments')
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }

  return (
    <form className='admin-form' onSubmit={handleSubmit}>
      <div className='doc-container'>
        <label htmlFor='name' className='doc-input-label'>
          Speciality
          <Asterisk />
          &nbsp;:
        </label>
        <input
          required
          name='speciality'
          value={text}
          type='text'
          onChange={({ target }) => setText(target.value)}
          placeholder='Enter Speciality Name'
        />
      </div>
      <div className='location-container'>
        <div className='location-input-label'></div>
        <div className='location-input-content'>
          <Box className='box-btn-doctor'>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className='submit-btn-doctor'
            >
              Submit
            </Button>
          </Box>
        </div>
      </div>
    </form>
  )
}

export default DepartmentsForm
