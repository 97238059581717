import { useEffect, useState } from 'react'
import { LabOrder } from '../../../labTests/models'
import { startSxpProxy } from '../../../../utils/api'
import { RADIOLOGY_PROJECT_ID } from '../../../../utils/constants'
import { intent } from '../../constants'

export const useRadiologyOrders = (id: string, source: string) => {
  const [orders, setOrders] = useState<LabOrder[]>([])

  useEffect(() => {
    const state = {
      patientId: id,
      source: source,
    }
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      intent.getRadiologyOrdersByPatientIdAndStatus,
      state
    )
      .then((data: any) => {
        setOrders(data.data.radiology_orders ?? [])
      })
      .catch(() => {
        setOrders([])
      })
  }, [id, source])

  return orders
}
