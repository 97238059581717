import React, { useState } from 'react'
import SxpForm from '../../../../form/SxpForm'
import { OPD_PROJECT_ID } from '../../../../utils/constants'
import { useParams } from 'react-router-dom'
import RenderOnRole from '../../../../components/RenderOnRole'
import KeycloakService from '../../../../utils/keycloakService'
import { HISTORY_READ, HISTORY_WRITE } from '../../../../utils/roles'

const ExaminationPhysio = () => {
  const { id } = useParams()
  const [key, setKey] = useState(Date.now())
  return (
    <RenderOnRole
      roles={[HISTORY_READ, HISTORY_WRITE]}
      element={
        <SxpForm
          key={key}
          project={OPD_PROJECT_ID}
          intent='examinationOne'
          journeyState={{ encounterId: id }}
          isDynamic
          canSubmit={KeycloakService.hasRole([HISTORY_WRITE])}
          onJourneyEnd={() => setKey(Date.now())}
        />
      }
    />
  )
}

export default ExaminationPhysio
