import React, { useRef, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import { AdminTableProps } from '../../models'
import { readableDateFormat, shortDateRange } from '../../../../utils/dateUtils'
import LinkButton from './LinkButton'
import { LabTests } from '../../../labTests/models'
import ProvenanceView from '../../../../components/ProvenanceView'

const PackagesTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = getSearchResults(data, searchParam)
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())

  const packagesTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = packagesTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window?.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window?.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const toggleRow = (rowIndex: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev)
      if (newExpandedRows?.has(rowIndex)) {
        newExpandedRows?.delete(rowIndex)
      } else {
        newExpandedRows?.add(rowIndex)
      }
      return newExpandedRows
    })
  }
  const renderLabTests = (labTests: LabTests[], rowIndex: number) => {
    const initialItemCount = 2
    const isExpanded = expandedRows?.has(rowIndex)

    return (
      <div>
        {labTests
          ?.slice(0, isExpanded ? labTests?.length : initialItemCount)
          ?.map((item: LabTests) => (
            <li key={item?.id}>{item?.name}</li>
          ))}
        {labTests?.length > initialItemCount && (
          <span
            onClick={() => toggleRow(rowIndex)}
            style={{ color: 'blue', cursor: 'pointer' }}
          >
            {isExpanded ? '...View Less' : '...View More'}
          </span>
        )}
      </div>
    )
  }

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={packagesTableContainerRef}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Id</th>
            <th className='table-w-15'>Package Name</th>
            <th className='table-w-25'>Test Name</th>
            <th>Test Count</th>
            <th>Price</th>
            <th className='table-w-20'>Start-End Date</th>
            <th className='table-w-10'>Created</th>
            <th className='table-w-10'>Updated</th>
            <th className='table-w-7'>Status</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any, index: any) => {
              const allLabTests =
                d?.panels?.flatMap((panel: any) => panel?.lab_tests) || []
              const testCount =
                (d?.lab_tests?.length ?? 0) + allLabTests?.length

              return (
                <tr key={d?.id}>
                  <td>{d?.id}</td>
                  <td>
                    <LinkButton
                      id={d?.id}
                      name={d?.name}
                      onClick={() => onEdit(d?.id)}
                    />
                  </td>
                  <td>{renderLabTests(allLabTests, index)}</td>
                  <td>{testCount}</td>
                  <td>{d?.price}</td>
                  <td>
                    {d?.active_from
                      ? new Date(d?.active_from).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        })
                      : 'N/A'}
                    {'  -  '}
                    {d?.active_till
                      ? new Date(d?.active_till).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        })
                      : 'N/A'}
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={d?.created_at}
                      name={d?.created_by}
                      mode={'CREATE'}
                    />
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={d?.updated_at ?? d?.createdOn}
                      name={d?.updated_by ?? d?.createdBy}
                      mode={'UPDATE'}
                    />
                  </td>
                  <td>{d?.status}</td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={8} className='text-center'>
                No Packages Configured
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PackagesTable
