import { useState } from 'react'
import Loading from '../../../../form/fields/Loading'

const UploadLabDocs = (docs: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDocUrl, setSelectedDocUrl] = useState('')
  const [loading, setLoading] = useState(false)

  const handlePreview = (url: string) => {
    setSelectedDocUrl(url)
    setIsModalOpen(true)
  }
  const handleDownload = (url: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = ''
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link.click()
  }

  const DocumentModal = () => {
    const handleCloseModal = () => {
      setIsModalOpen(false)
      setSelectedDocUrl('')
    }

    return (
      <div className={`document-modal ${isModalOpen ? 'open' : ''}`}>
        <div className='document-modal-content'>
          <button className='close-modal-button' onClick={handleCloseModal}>
            Close
          </button>
          {selectedDocUrl ? (
            <iframe
              src={selectedDocUrl}
              width='100%'
              height='100%'
              title='Document Preview'
            />
          ) : (
            'Please complete test at Referred Out Tab'
          )}
        </div>
      </div>
    )
  }

  return (
    <div className='DocContainer'>
      <div className='mainCard'>
        <h3>
          <div className='docViewBody'>
            <div className='docUploadBtn'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between !important',
                }}
              >
                <label htmlFor='docUrl'>
                  <span className='docUrl mr295'>
                    Lab Referred Test Documents{' '}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </h3>
        <div className='docView'>
          <ul className='docListView'>
            {docs?.docs.length > 0 &&
              docs?.docs
                .filter((e: any) => e.document_url !== null)
                .map((data: any, index: number) => {
                  const serialNumber = index + 1
                  return (
                    <li className='docListItem' key={index}>
                      <div className='docItemInfo'>
                        <div className='docItemSerial'>{`${serialNumber} - `}</div>
                        <div className='docItemTitle'>
                          {data?.lab_test?.panel_id}
                        </div>
                        <div className='docItemButtons'>
                          <button
                            className='docItemButton'
                            onClick={() => handlePreview(data?.document_url)}
                          >
                            Preview
                          </button>
                          <button
                            className='docItemButton'
                            onClick={() => handleDownload(data?.document_url)}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </li>
                  )
                })}
          </ul>
        </div>
      </div>
      {isModalOpen && <DocumentModal />}
      {loading && <Loading />}
    </div>
  )
}

export default UploadLabDocs
