import React, { useCallback, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import BlankUser from '../../assets/images/blank-user.gif'
import { Buffer } from 'buffer'
import { uploadImage } from '../../utils/api'
import { FILE_SERVER_URL } from '../../utils/constants'

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
}

type Props = {
  onConfirm: (image: string) => void
  mode: string
}

const CaptureImage = ({ onConfirm }: Props) => {
  const [image, setImage] = useState<string>('')
  const webcamRef = useRef<Webcam>(null)

  const convertBase64ToBlob = (base64Image: string) => {
    const parts = base64Image.split(';base64,')
    const imageType = parts[0].split(':')[1]
    const decodedData = new Buffer(parts[1], 'base64')
    return new Blob([decodedData], { type: imageType })
  }

  const capture = useCallback(() => {
    const data = webcamRef?.current?.getScreenshot()
    setImage(data ?? '')
  }, [webcamRef])

  const confirm = () => {
    const file = convertBase64ToBlob(image)
    const formData = new FormData()
    formData.append('file', file, 'profile.jpeg')
    uploadImage(formData)
      .then((data: any) => {
        const uploaded = data.data[0]
        onConfirm(`${FILE_SERVER_URL}file/${uploaded}`)
      })
      .catch((err) => {
        console.error('Failed to store captured image, Reason:', err)
        onConfirm('')
      })
  }

  return (
    <div className='camera-wrapper'>
      <div className='capture'>
        <Webcam
          audio={false}
          screenshotFormat='image/jpeg'
          ref={webcamRef}
          width={200}
          height={200}
          videoConstraints={videoConstraints}
        />
        <button onClick={capture}>Capture</button>
      </div>
      <div className='confirm'>
        <img src={image || BlankUser} alt='Captured Image from Camera' />
        <button disabled={!image} onClick={confirm}>
          Confirm
        </button>
      </div>
    </div>
  )
}

export default CaptureImage
