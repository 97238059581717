import React from 'react'
import { Doctor, selectSchedulerStatus } from '../schedulerSlice'
import DocImage from '../../../assets/images/doctor.png'
import { useAppSelector } from '../../../app/hooks'

type Props = {
  doctors: Doctor[]
  selectedDoc: number
  handleChange: (doc: Doctor, i: number) => void
}

const SingleDoctor = ({ d }: { d: Doctor }) => {
  return (
    <div>
      <img alt={d.name} src={DocImage} />
      <p>Dr. {d.name}</p>
      <span>{d.specialty}</span>
    </div>
  )
}

const DoctorList = ({ doctors, selectedDoc, handleChange }: Props) => {
  const status = useAppSelector(selectSchedulerStatus)

  return (
    <div className='docBlock'>
      {doctors?.length ? (
        <ul className='docsList'>
          {doctors.map((d: Doctor, i) => (
            <li
              key={d.id}
              className={`${selectedDoc === i ? 'activeTab' : ''}`}
              onClick={() => handleChange(d, i)}
            >
              <SingleDoctor d={d} />
            </li>
          ))}
        </ul>
      ) : (
        <p>{status === 'loading' ? 'Loading Doctors' : 'No Doctors found'}</p>
      )}
    </div>
  )
}

export default DoctorList
