import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import {
  AppLocation,
  getLocationData,
  selectLocations,
} from '../../location/locationSlice'
import LocationForm from './LocationForm'
import { IconButton } from '@mui/material'
import { editlocation } from '../../patients/constants'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const EditLocation = () => {
  const { id } = useParams()
  const locations = useAppSelector(selectLocations)
  const [selected, setSelected] = useState<AppLocation | null>(null)

  useEffect(() => {
    if (id) {
      const loc = locations.find((l) => l.resource.id === id)
      if (loc) {
        const sel = getLocationData(loc)
        setSelected(sel)
      }
    }
  }, [locations, id])

  const navigate = useNavigate()
  const navigateBack = () => {
    navigate(-1)
  }
  return (
    <>
      <div className='package-container'>
        <IconButton
          onClick={navigateBack}
          className='mb25'
          sx={{ fontSize: 20, color: 'blue' }}
        >
          <KeyboardBackspaceIcon className='backspace-icon-search-view' />
        </IconButton>
        <span className='opdpatientdata'>{editlocation}</span>
      </div>
      {selected ? (
        <LocationForm loc={selected} />
      ) : (
        <p>Not a Valid Location Id</p>
      )}
    </>
  )
}

export default EditLocation
