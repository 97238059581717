import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

const IpdVisitSlice = createSlice({
  name: 'IpdVisit',
  initialState: {
    query: '',
    param: '',
    patientId: '',
    id: '',
    updation: false,
    selectedTab: 'vitals',
  },
  reducers: {
    setQueryParam: (state, action) => {
      state.query = action.payload.q ?? state.query
      state.param = action.payload.param ?? state.param
      state.patientId = action.payload.patientId ?? state.patientId
      state.id = action.payload.id ?? state.id
      state.selectedTab = action.payload.selectedTab ?? state.selectedTab
    },
  },
})

export const { setQueryParam } = IpdVisitSlice.actions
export const selectQuery = (state: RootState) => state.IpdVisit.query
export const selectParam = (state: RootState) => state.IpdVisit.param
export const selectPatientId = (state: RootState) => state.IpdVisit.patientId
export const selectId = (state: RootState) => state.IpdVisit.id
export const selectedTabs = (state: RootState) => state.IpdVisit.selectedTab

export default IpdVisitSlice.reducer
