import { useRef, useEffect } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch } from '../../lmsSlice'
import LinkButton from './LinkButton'
import '../../lms.scss'

const TestRangeTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = data?.filter((d: any) =>
    d.lab_test?.name?.toLowerCase()?.includes(searchParam.toLowerCase())
  )
  filtered?.sort?.((a: any, b: any) => {
    const p1 = a.lab_test?.panel
    const p2 = b.lab_test?.panel
    if (p1?.name < p2?.name) {
      return -1
    } else if (p1?.name > p2?.name) {
      return 1
    } else {
      if (a.lab_test?.name?.toLowerCase() < b.lab_test?.name?.toLowerCase()) {
        return -1
      } else if (
        a.lab_test?.name?.toLowerCase() > b.lab_test?.name?.toLowerCase()
      ) {
        return 1
      } else {
        return a.id < b.id ? -1 : 1
      }
    }
  })

  const paramRangeTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = paramRangeTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div
      className='data-table table-fixed admin-table-table-containe'
      ref={paramRangeTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Id</th>
            <th className='table-w-20'>Parameter Name</th>
            <th className='table-w-20'>Test Name</th>
            <th>Gender</th>
            <th>Method</th>
            <th>Unit Type</th>
            <th>Age Range</th>
            <th>Value Range</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d.id}>
                <td>{d.id}</td>
                <td>
                  <LinkButton
                    id={d.id}
                    name={d.lab_test.name}
                    onClick={onEdit}
                  />
                </td>
                <td>{d.lab_test.panel?.name ?? '-'}</td>
                <td>{d.gender ?? '-'}</td>
                <td>{d.test_method?.method_name ?? '-'}</td>
                <td>{d.test_unit_type?.unit_type ?? '-'}</td>
                <td>
                  {d.min_age || d.max_age ? (
                    <span>
                      {d.min_age ?? 0} to{' '}
                      {d.max_age ?? (
                        <span style={{ fontSize: 16 }}>&infin;</span>
                      )}{' '}
                      {d.age_type ?? 'y'}
                    </span>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {d.min_value || d.max_value ? (
                    <span>
                      {d.min_value ?? 0} to {d.max_value}
                    </span>
                  ) : d.abnormal ? (
                    <span>{d.abnormal}</span>
                  ) : d?.ref_range ? (
                    <div className='range-column'>
                      {d?.ref_range
                        ?.split(',')
                        ?.map((rangeValue: any, i: number) => (
                          <span key={i}>{rangeValue.trim()}</span>
                        ))}
                    </div>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8} className='text-center'>
                No Test Ranges Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default TestRangeTable
