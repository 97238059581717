import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const DateCarouselSelector = (props: any) => {
  const { visitDates, dateHandler, activeDate } = props
  const handleDateClick = (d: any, i: number) => {
    dateHandler(d, i)
  }

  const timeFormat = (time: any) => {
    const date = new Date(time)
    if (isNaN(date?.getTime())) {
      return 'Invalid date'
    }
    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'long',
      timeZone: 'Asia/Calcutta',
    }).format(date)
  }

  return (
    <div>
      <Carousel responsive={responsive}>
        {visitDates?.map((d: any, i: any) => {
          const statusHistory = d?.resource?.statusHistory
          const startDate = statusHistory?.[0]?.period?.start
          return (
            <div
              onClick={() => handleDateClick(d, i)}
              key={i}
              className='dateSelections'
            >
              <span className={`${activeDate === i ? 'activeDateTab' : ''}`}>
                {startDate ? timeFormat(startDate) : 'Date not available'}
              </span>
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}

export default DateCarouselSelector
