import React from 'react'
import { useNavigate } from 'react-router-dom'
import DoctorsForm from './DoctorsForm'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const CreateDoctors = () => {
  const navigate = useNavigate()
  return (
    <>
      <IconButton onClick={() => navigate('/administration/doctors')}>
        <KeyboardBackspaceIcon className='back-btn-admin' />
      </IconButton>
      <DoctorsForm doctor={null} isEdit={false} />
    </>
  )
}

export default CreateDoctors
