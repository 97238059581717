import React from 'react'
import './component.scss'
import { NavLink } from 'react-router-dom'
import Location from '../features/location/Location'
import ProfileDropDown from './ProfileDropDown'
import MainMenu from './MainMenu'
import logoImg from './../assets/images/content-view-grid-icon.svg'

const Header = () => {
  return (
    <div className='headerComponent'>
      <div className='headerBodyComponnet'>
        <div className='logo'>
          <NavLink to='/'>
            <img src={logoImg} alt='Logo' style={{ height: '1rem' }} />
          </NavLink>
        </div>
        <div>
          <MainMenu />
        </div>
      </div>
      <div className='location-data'>
        <Location />
        <ProfileDropDown />
      </div>
    </div>
  )
}

export default Header
