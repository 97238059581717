import React from 'react'
import { ElementProps } from '../models'
import FieldLabel from './FieldLabel'

const ConfirmField = ({ component, state, handleChange }: ElementProps) => {
  return (
    <div className='fieldSet'>
      <FieldLabel label={component.label} required={component.required} />
      <div>
        <input
          type='checkbox'
          checked={state[component.reference] === 'yes'}
          value={state[component.reference] === 'yes' ? 'on' : 'off'}
          onChange={(e) =>
            handleChange(e.target.value === 'off' ? 'yes' : 'no', component)
          }
          name={component.reference}
        />
      </div>
    </div>
  )
}

export default ConfirmField
