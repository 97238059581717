import React, { memo, useEffect, useState } from 'react'
import { SequencePanel, SequenceTest } from '../../models'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { orderTestsBySequence } from '../../utils'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'

type SequenceProps = {
  item: SequenceTest
  index: number
}

const Sequence = ({ item, index }: SequenceProps) => {
  return (
    <Draggable draggableId={'' + item.id} index={index}>
      {(provided) => (
        <div
          className='sequence-item'
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {item.name}
        </div>
      )}
    </Draggable>
  )
}

const SequenceList = memo(function SeqList({ list }: { list: SequenceTest[] }) {
  return (
    <>
      {list.map((l, i) => (
        <Sequence key={`${l.id}`} item={l} index={i} />
      ))}
    </>
  )
})

const reorder = (
  list: SequenceTest[],
  dragIndex: number,
  dropIndex: number
) => {
  const result = list.slice()
  const [removed] = result.splice(dragIndex, 1)
  result.splice(dropIndex, 0, removed)

  return result
}

const shouldUpdate = (
  items: SequenceTest[],
  panel?: SequencePanel
): boolean => {
  if (!panel?.lab_test_sequence) {
    return true
  }
  const seq = panel.lab_test_sequence.sequence
  if (seq.length !== items.length) {
    return true
  }
  for (let i = 0; i < items.length; i++) {
    const itemId = items[i].id
    if (itemId !== seq[i]) {
      return true
    }
  }
  return false
}

type Props = {
  panel?: SequencePanel
  onReorder: () => void
}

const SequenceTable = ({ panel, onReorder }: Props) => {
  const [items, setItems] = useState<SequenceTest[]>([])

  useEffect(() => {
    const ordered = orderTestsBySequence(panel)
    setItems(ordered)
  }, [panel])

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return
    }
    const list = reorder(items, result.source.index, result.destination.index)
    setItems(list)
  }

  const handleSave = () => {
    const intent = 'saveLabTestSequence'
    const seqItems = items.map((i) => i.id)
    const sequence: any = { sequence: seqItems }
    const state: any = {}
    if (panel?.lab_test_sequence?.id) {
      state.sequenceId = panel.lab_test_sequence.id
    } else {
      // eslint-disable-next-line camelcase
      sequence.panel_id = panel?.id
    }
    state.sequence = sequence
    startSxpProxy(LABS_PROJECT_ID, intent, state)
      .then(() => {
        onReorder()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return items.length > 0 ? (
    <div>
      <div className='admin-header'>
        {panel?.name ?? 'Test'} [Drag and Drop to Reorder]
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='list'>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <SequenceList list={items} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {shouldUpdate(items, panel) && (
        <button className='mt10' onClick={handleSave}>
          Save
        </button>
      )}
    </div>
  ) : null
}

export default SequenceTable
