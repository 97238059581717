export const unitOptions = [
  { name: 'Capsule(s)', value: 'capsule' },
  { name: 'Tablet(s)', value: 'tablet' },
  { name: 'ml', value: 'ml' },
  { name: 'mg', value: 'mg' },
  { name: 'IU', value: 'iu' },
  { name: 'Drop', value: 'drop' },
  { name: 'Tablespoon', value: 'tablespoon' },
  { name: 'Teaspoon', value: 'teaspoon' },
  { name: 'Unit(s)', value: 'unit' },
  { name: 'Puff(s)', value: 'puff' },
]
export const frequencyOptions = [
  { name: 'Immediately', value: 'Immediately' },
  { name: 'Once a day', value: 'Once a day' },
  { name: 'Twice a day', value: 'Twice a day' },
  { name: 'Thrice a day', value: 'Thrice a day' },
  { name: 'Four times a day', value: 'Four times a day' },
  // { name: 'Five times a day', value: 'Five times a day' },
  // { name: 'Every Hour', value: 'Every Hour' },
  // { name: 'Every 2 hours', value: 'Every 2 hours' },
  // { name: 'Every 3 hours', value: 'Every 3 hours' },
  { name: 'Every 4 hours', value: 'Every 4 hours' },
  { name: 'Every 6 hours', value: 'Every 6 hours' },
  // { name: 'Every 8 hours', value: 'Every 8 hours' },
  // { name: 'Every 12 hours', value: 'Every 12 hours' },
  { name: 'On alternate days', value: 'On alternate days' },
  { name: 'Once a week', value: 'Once a week' },
  { name: 'Twice a week', value: 'Twice a week' },
  { name: 'Thrice a week', value: 'Thrice a week' },
  { name: 'Four days a week', value: 'Four days a week' },
  { name: 'Five days a week', value: 'Five days a week' },
  { name: 'Six days a week', value: 'Six days a week' },
  // { name: 'Every 2 weeks', value: 'Every 2 weeks' },
  // { name: 'Every 3 weeks', value: 'Every 3 weeks' },
  // { name: 'Once a month', value: 'Once a month' },
  // { name: 'Day(s)', value: 'Day(s)' },
  // { name: 'Week(s)', value: 'Week(s)' },
  // { name: 'Month(s)', value: 'Month(s)' },
]
export const routeOptions = [
  { name: 'Intramuscular', value: 'intramuscular' },
  { name: 'Nasal', value: 'nasal' },
  { name: 'Topical', value: 'topical' },
  { name: 'Intraosseous', value: 'intraosseous' },
  { name: 'Intrathecal', value: 'intrathecal' },
  { name: 'Intraperitoneal', value: 'intraperitoneal' },
  { name: 'Intradermal', value: 'intradermal' },
  { name: 'Nasogastric', value: 'nasogastric' },
  { name: 'Sub Lingual', value: 'sublingual' },
  { name: 'Per Rectum', value: 'perrectum' },
  { name: 'Oral', value: 'oral' },
  { name: 'Intravenous', value: 'intravenous' },
  { name: 'Inhalation', value: 'inhalation' },
  { name: 'Subcutaneous', value: 'subcutaneous' },
  { name: 'Per-Vaginal', value: 'per-vaginal' },
  { name: 'Buccal', value: 'buccal' },
  { name: 'Intranasal', value: 'intranasal' },
  { name: 'Transdermal', value: 'transdermal' },
  { name: 'Epidural', value: 'epidural' },
  { name: 'Rectal', value: 'rectal' },
  { name: 'Ophthalmic', value: 'ophthalmic' },
  { name: 'Otic', value: 'otic' },
  { name: 'Intravaginal', value: 'intravaginal' },
  { name: 'Intracardiac', value: 'intracardiac' },
  { name: 'Intra-articular', value: 'intra-articular' },
  { name: 'Intraocular', value: 'intraocular' },
  { name: 'Intralymphatic', value: 'intralymphatic' },
]
export const durationUnits = [
  { name: 'Hour(s)', value: 'hour' },
  { name: 'Day(s)', value: 'day' },
  { name: 'Month(s)', value: 'month' },
  { name: 'Week(s)', value: 'week' },
  // { name: '2 Week(s)', value: '2 week' },
  // { name: '3 Week(s)', value: '3 week' },
]

export const instructions = [
  { name: 'Before meals', value: 'beforemeals' },
  { name: 'Empty stomach', value: 'emptystomach' },
  { name: 'After meals', value: 'aftermeals' },
  { name: 'In the morning', value: 'inmorning' },
  { name: 'In the evening', value: 'inevening' },
  { name: 'At bedtime', value: 'bedtime' },
  { name: 'Immediately', value: 'immediately' },
  { name: 'As directed', value: 'directed' },
]
export const weekDays = [
  { name: 'Sun', value: 'sunday' },
  { name: 'Mon', value: 'monday' },
  { name: 'Tue', value: 'tuesday' },
  { name: 'Wed', value: 'wednesday' },
  { name: 'Thu', value: 'thursday' },
  { name: 'Fri', value: 'friday' },
  { name: 'Sat', value: 'saturday' },
]
export const sixHourDay = [
  { name: '6AM', value: '6AM' },
  { name: '12PM', value: '12PM' },
  { name: '6PM', value: '6PM' },
  { name: '12AM', value: '12AM' },
]
export const eightHourDay = [
  { name: '8AM', value: '8AM' },
  { name: '4PM', value: '4PM' },
  { name: '12AM', value: '12AM' },
]
export const twelveHourDay = [
  { name: '12PM', value: '12PM' },
  { name: '12AM', value: '12AM' },
]
export const twoHourDay = [
  { name: '2AM', value: '2AM' },
  { name: '4AM', value: '4AM' },
  { name: '6AM', value: '6AM' },
  { name: '8AM', value: '8AM' },
  { name: '10AM', value: '10AM' },
  { name: '12PM', value: '12PM' },
  { name: '2PM', value: '2PM' },
  { name: '4PM', value: '4PM' },
  { name: '6PM', value: '6PM' },
  { name: '8PM', value: '8PM' },
  { name: '10PM', value: '10PM' },
  { name: '12AM', value: '12AM' },
]

export const everyHourDay = [
  { name: '1AM', value: '1AM' },
  { name: '2AM', value: '2AM' },
  { name: '3AM', value: '3AM' },
  { name: '4AM', value: '4AM' },
  { name: '5AM', value: '5AM' },
  { name: '6AM', value: '6AM' },
  { name: '7AM', value: '7AM' },
  { name: '8AM', value: '8AM' },
  { name: '9AM', value: '9AM' },
  { name: '10AM', value: '10AM' },
  { name: '11AM', value: '11AM' },
  { name: '12PM', value: '12PM' },
  { name: '1PM', value: '1PM' },
  { name: '2PM', value: '2PM' },
  { name: '3PM', value: '3PM' },
  { name: '4PM', value: '4PM' },
  { name: '5PM', value: '5PM' },
  { name: '6PM', value: '6PM' },
  { name: '7PM', value: '7PM' },
  { name: '8PM', value: '8PM' },
  { name: '9AM', value: '9AM' },
  { name: '10PM', value: '10PM' },
  { name: '11AM', value: '11AM' },
  { name: '12AM', value: '12AM' },
]

export const threeHourDay = [
  { name: '3AM', value: '3AM' },
  { name: '6AM', value: '6AM' },
  { name: '9AM', value: '9AM' },
  { name: '12PM', value: '12PM' },
  { name: '3PM', value: '3PM' },
  { name: '6PM', value: '6PM' },
  { name: '9PM', value: '9PM' },
  { name: '12AM', value: '12AM' },
]
export const fourHourDay = [
  { name: '4AM', value: '4AM' },
  { name: '8AM', value: '8AM' },
  { name: '12PM', value: '12PM' },
  { name: '4PM', value: '4PM' },
  { name: '8PM', value: '8PM' },
  { name: '12AM', value: '12AM' },
]
export const addDays = (date: string | number | Date, days: number) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}
export const formatDate = (date: {
  getFullYear: () => any
  getMonth: () => number
  getDate: () => any
}) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
export type RadioOption = {
  name: string
  value: string
}
export type FormData = {
  drugName: string
  dose: string
  unit: string
  route: string
  frequency: string
  duration: string
  radioOptions: any[]
  startDate: string
  durationUnits: any
  quantities: any
  quantityUnits: string
  sos: boolean
  instructions: string
  additional: string
  endDate: any
}
