import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Button } from '@mui/material'
import { startSxpProxy } from '../../../../utils/api'
import { ipVisitApi } from '../../models'
import { IPD_PROJECT_ID } from '../../../../utils/constants'
import IpdForm from './IpdForm'
import { filterEncountersByDate, convertUTCToLocal } from './Constants'

const IpdTreatmentPlanSummary = (props: any) => {
  const { patientRegisteredId } = props
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [treatmentPlanData, setTreatmentPlanData] = useState<any>([])
  const [treatmentPlanSearchData, setTreatmentPlanSearchData] = useState<any>(
    []
  )
  const [selectedId, setSelectedId] = useState('')

  const fetchAllDiagnosisData = async (patientRegisteredId: string) => {
    const intent = ipVisitApi.getAllDiagnosisIpd
    const state = { patId: patientRegisteredId }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.length > 0) {
          const reversedData = data?.data?.reverse()
          setTreatmentPlanData(reversedData)
          setTreatmentPlanSearchData(reversedData)
        } else {
          setTreatmentPlanData([])
          setTreatmentPlanSearchData([])
        }
      })
      .catch((err) => {
        setTreatmentPlanData([])
        setTreatmentPlanSearchData([])
        console.error(err, 'err')
      })
  }

  const dateOnChange = (date: any) => {
    setSelectedDate(date)
  }

  const handleEncounterId = (id: string) => {
    setSelectedId(id)
  }

  const handleDataFilterWithDate = () => {
    const data = filterEncountersByDate(treatmentPlanSearchData, selectedDate)
    setTreatmentPlanData(data)
  }

  useEffect(() => {
    handleDataFilterWithDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  useEffect(() => {
    fetchAllDiagnosisData(patientRegisteredId)
  }, [patientRegisteredId])

  return (
    <div className='ip-treatmentPlan-visit-summary'>
      <div className='ip-treatmentPlan-filter-section'>
        <div>
          <DatePicker
            className='datePickerUI'
            placeholderText='DD-MM-YYYY'
            selected={selectedDate}
            onChange={dateOnChange}
            dateFormat='dd-MM-yyyy'
          />
        </div>
      </div>
      <div className='ip-treatmentPlan-form-table-container'>
        <div className='ip-treatmentPlan-table'>
          <TableContainer component={Paper} className='table-lab-visit-ipd-mui'>
            <Table aria-label='collapsible table' size='small'>
              <TableHead className='table-head-lab-visit'>
                <TableRow>
                  <TableCell className='table-head-th-lab-visit' align='center'>
                    Sl No
                  </TableCell>
                  <TableCell className='table-head-th-lab-visit'>
                    Date
                  </TableCell>
                  <TableCell className='table-head-th-lab-visit' align='left'>
                    Doctor Name
                  </TableCell>
                  <TableCell className='table-head-th-lab-visit' align='center'>
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {treatmentPlanData?.length > 0 ? (
                  <>
                    {treatmentPlanData?.map((item: any, index: number) => {
                      return (
                        <TableRow key={item?.encounterId}>
                          <TableCell align='left'>{index + 1}</TableCell>
                          <TableCell align='left'>
                            {convertUTCToLocal(item?.date)}
                          </TableCell>
                          <TableCell align='left'>
                            {item?.practitioner_name}
                          </TableCell>
                          <TableCell align='center'>
                            <Button
                              className='ip-visit-view-btn'
                              onClick={() =>
                                handleEncounterId(item?.encounterId)
                              }
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}> No Data</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className='ip-treatmentPlan-form'>
          {selectedId && (
            <div className='ip-treatmentPlan-form-heading'>
              Treatment Plan Details
            </div>
          )}
          <IpdForm id={selectedId} intent='treatmentPlanForIPD' />
        </div>
      </div>
    </div>
  )
}

export default IpdTreatmentPlanSummary
