import React, { useState } from 'react'
import { OPD_PROJECT_ID } from '../../../../utils/constants'
import SxpForm from '../../../../form/SxpForm'
import { useParams } from 'react-router-dom'
import { VITALS_READ, VITALS_WRITE } from '../../../../utils/roles'
import KeycloakService from '../../../../utils/keycloakService'
import RenderOnRole from '../../../../components/RenderOnRole'

const SystematicDental = () => {
  const { id } = useParams()
  const [key, setKey] = useState(Date.now())

  return (
    <RenderOnRole
      roles={[VITALS_READ, VITALS_WRITE]}
      element={
        <SxpForm
          key={key}
          project={OPD_PROJECT_ID}
          intent='systematicDental'
          journeyState={{ encounterId: id }}
          isDynamic
          canSubmit={KeycloakService.hasRole([VITALS_WRITE])}
          onJourneyEnd={() => setKey(Date.now())}
        />
      }
    />
  )
}

export default SystematicDental
