import { useEffect, useRef, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import PatientTable from '../PatientTable'
import AllOrders from './AllOrders'
import SelectableSearch from '../../../../components/SelectableSearch'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsActiveTab } from '../../lmsSlice'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import IconButton from '@mui/material/IconButton'
import { capitalizeFirstLetter } from '../../../patients/utils'
import { Button } from '@mui/material'
const SearchView = () => {
  const [show, setShow] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [param, setParam] = useState<string>('')
  const [patientId, setPatientId] = useState<string>('')
  const [row, setRow] = useState<any>([])
  const navigate = useNavigate()
  const locationRoute = window.location.pathname

  const handlePatientSelect = (id: string) => {
    if (id !== patientId) {
      setPatientId(id)
      navigate('')
    }
  }

  const handleSearch = (q: string, p: string) => {
    setQuery(q)
    setParam(p)
    setPatientId('')
    if (!q || !p) {
      setShow(false)
      setRow([])
    } else {
      setShow(true)
    }
    navigate('')
  }

  const handleView = (url: string) => {
    setPatientId('')
    setShow(false)
    navigate(url)
  }

  const handleCreate = (id: string) => {
    setPatientId('')
    setShow(false)
    navigate(`createorder/${id}`)
  }
  const patientsTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientsTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 50}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const selectedLmsActive = useAppSelector(selectedLmsActiveTab)
  const navigateBack = () => {
    navigate('/lms/Dashboard')
  }

  return (
    <div className='collect-samples'>
      <div
        className='filterandtext'
        style={{ justifyContent: 'space-between' }}
      >
        <div className='membershipText mt5 package-container'>
          {locationRoute.includes('lms/search/createorder') ||
          locationRoute === '/lms/search' ? (
            'Create Lab Orders'
          ) : (
            <div className='package-container'>
              <IconButton onClick={navigateBack} className='mb25'>
                <KeyboardBackspaceIcon className='backspace-icon-search-view' />
              </IconButton>
              <span>{capitalizeFirstLetter(selectedLmsActive)}</span>
            </div>
          )}
        </div>
        <div className='search-view'>
          <SelectableSearch items={['ID']} onSearch={handleSearch} />
          <Button
            size='small'
            className='lab-create-btn'
            variant='contained'
            disabled={row?.length === 0 || !row}
            onClick={() => handleCreate(row?.[0]?.id)}
          >
            Create{' '}
          </Button>
        </div>
      </div>
      <div className='mb10'>
        {show ? (
          <PatientTable
            searchQuery={query}
            searchParam={param}
            onPatientSelect={handlePatientSelect}
            setRow={setRow}
            selectedId={patientId}
          />
        ) : null}
        {patientId && (
          <AllOrders
            id={patientId}
            onView={handleView}
            onCreate={handleCreate}
          />
        )}
        <Outlet />
      </div>
    </div>
  )
}
export default SearchView
