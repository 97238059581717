import {
  CHAT_PROJECT_ID,
  FILE_SERVER_URL,
  SOCKET_PATH,
  SOCKET_URL,
  VERSION,
} from '../utils/constants'
import './chat.scss'
import Logo from '../assets/images/medunited-logo.png'

const SxpChat = () => {
  const mainObj = {
    socketUrl: SOCKET_URL,
    socketPath: SOCKET_PATH,
    SSL: true,
    accessToken: '',
    currentProject: CHAT_PROJECT_ID,
    fileServerUrl: FILE_SERVER_URL,
    mainTitle: '',
    // call : '+919876543210',
    titleLogo: Logo,
    // customLauncher:'https://sxp.v1.techsophy.com/static/media/logo.7ad470b4.png',
    subTitle: 'Service eXperience Platform',
    chatRefresh: true,
    autoLaunch: false,
    lazyAutoLaunch: false,
    editChat: false,
    uploadDoc: false,
    defaultMessage: 'Hi, Welcome to MedUnited',
    languages: [],
    version: VERSION,
    journeyTray: false,
    fieldInputPlaceholder: 'Enter your Message here...',
    closeIcon: {
      showClose: true,
      imgUrl:
        'https://paisaplan.s3.ap-south-1.amazonaws.com/dev/public/emailTemplateIcons/closebutton.png',
    },
  }
  window.embedSXPChat(mainObj)

  return <></>
}

export default SxpChat
