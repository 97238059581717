import React from 'react'
import Pagination from '../../../components/Pagination'
import {
  fetchPaginatedPatientsAsync,
  fetchPatientsAsync,
  selectPatientsCurrentPage,
  selectPatientsNext,
  selectPatientsPrevious,
  selectPatientsStatus,
  selectPatientsTotal,
  setCurrentPage,
} from '../patientsSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'

const PatientNavigation = () => {
  const next = useAppSelector(selectPatientsNext)
  const previous = useAppSelector(selectPatientsPrevious)
  const total = useAppSelector(selectPatientsTotal)
  const current = useAppSelector(selectPatientsCurrentPage)
  const status = useAppSelector(selectPatientsStatus)
  const dispatch = useAppDispatch()

  const goBack = () => {
    dispatch(fetchPaginatedPatientsAsync(previous ?? ''))
    if (current > 1) {
      dispatch(setCurrentPage(current - 1))
    }
  }

  const goNext = () => {
    dispatch(fetchPaginatedPatientsAsync(next ?? ''))
    dispatch(setCurrentPage(current + 1))
  }

  return (
    <div className='text-right'>
      <Pagination
        total={total}
        current={current}
        handleChange={() => dispatch(fetchPatientsAsync())}
      />
      <div className='inline-block' style={{ marginRight: '80px' }}>
        <button
          disabled={status === 'loading'}
          className={`mr10 btn btn-primaryBtn ${previous ? '' : 'disableBtn'}`}
          onClick={() => goBack()}
        >
          Prev
        </button>
        <button
          disabled={status === 'loading'}
          className={`btn btn-primaryBtn ${next ? '' : 'disableBtn'}`}
          onClick={() => goNext()}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default PatientNavigation
