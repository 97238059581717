import React from 'react'

type Props = {
  errorMessage?: string
}

const ErrorMessage = ({ errorMessage }: Props) => {
  return errorMessage ? (
    <span className='sxp-error-message'>{errorMessage}</span>
  ) : null
}

export default ErrorMessage
