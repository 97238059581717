import { SxpComponent, SxpFormData } from './models'
import { createContext, Dispatch, useContext } from 'react'

export const SxpFormContext = createContext<SxpFormData>({} as SxpFormData)
export const SxpFormDispatchContext = createContext<
  Dispatch<Record<string, any>>
>(() => undefined)

export const useSxpFormData = () => useContext(SxpFormContext)
export const useSxpFormDispatch = () => useContext(SxpFormDispatchContext)

export const sxpFormReducer = (
  formData: SxpFormData,
  action: Record<string, any>
): SxpFormData => {
  switch (action.type) {
    case 'store_data': {
      const { project, intent, submitted, devMode } = formData
      return { ...action.data, project, intent, submitted, devMode }
    }
    case 'update_state':
      return {
        ...formData,
        state: { ...formData.state, [action.reference]: action.value },
      }
    case 'set_project':
      return {
        ...formData,
        project: action.value,
      }
    case 'set_intent':
      return {
        ...formData,
        intent: action.value,
      }
    case 'set_submitted':
      return {
        ...formData,
        submitted: action.value,
      }
    case 'set_dev_mode':
      return {
        ...formData,
        devMode: action.value,
      }
    default:
      return formData
  }
}

export const normalizeComponents = (
  data: {
    components: any
    error: any
    state: Record<string, string>
    project: string
    intent: string
    messages: string[]
    apiError: boolean
    closeWindow: boolean
    initialLoad?: boolean | undefined
    devMode?: boolean | undefined
  },
  preState: Record<string, string>
): SxpFormData => {
  const componentArray = data.components.map((comp: SxpComponent) => {
    if (data.error?.reference === comp.reference) {
      comp.errorMessage = data.error?.errorMessage
    }
    return comp
  })
  data.state = { ...preState, ...data.state }
  for (const key in data.state) {
    try {
      const stateValue = data.state[key]
      data.state[key] = JSON.parse(stateValue)
    } catch (_err) {
      // eslint-disable-next-line quotes
      let morphed = data.state[key].replace(/\\'/g, "'")
      morphed = morphed.replace(/\\"/g, '"')
      morphed = morphed.replace(/\\n/g, '\n')
      data.state[key] = morphed
    }
  }
  const componentObject = componentArray.reduce(
    (acc: any, cur: SxpComponent) => ({
      ...acc,
      [cur.reference]: cur,
    }),
    {}
  )
  return {
    ...data,
    componentArray: componentArray,
    components: componentObject,
  }
}
