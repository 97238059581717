import React, { useState } from 'react'
import { readableSlotFormat } from '../../../utils/dateUtils'
import deleteImg from '../../../assets/images/subtract.png'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  clearSlotConfigs,
  createSlotsAsync,
  deleteSlotConfig,
  fetchSlotsAsync,
  selectAvailableSlots,
  selectSlotsConfigs,
  selectSlotsStartDate,
  setConfigError,
} from '../slotsSlice'
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'
import { toast } from 'react-toastify'
import LoadingButton from '../../../components/LoadingButton'

const ConfigList = () => {
  const [apiLoading, setApiLoading] = useState(false)
  const start = useAppSelector(selectSlotsStartDate)
  const configs = useAppSelector(selectSlotsConfigs)
  const availableSlots = useAppSelector(selectAvailableSlots)
  const dispatch = useAppDispatch()

  const startDate = new Date(start)

  const deleteConfig = (id: string) => {
    dispatch(deleteSlotConfig(id))
  }

  const validateAndCreate = () => {
    setApiLoading(true)
    if (availableSlots.length) {
      let rStart, rEnd
      for (const { resource } of availableSlots) {
        rStart = new Date(resource.start)
        rEnd = new Date(resource.end)
        rStart.setFullYear(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )
        rStart.setSeconds(0)
        rStart.setMilliseconds(0)
        rEnd.setFullYear(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )
        rEnd.setSeconds(0)
        rEnd.setMilliseconds(0)
        for (const slot of configs) {
          const slotStart = new Date(slot.start)
          slotStart.setDate(startDate.getDate())
          const slotEnd = new Date(slot.end)
          slotEnd.setDate(startDate.getDate())
          const isOverlapping = areIntervalsOverlapping(
            { start: slotStart, end: slotEnd },
            { start: rStart, end: rEnd }
          )
          if (isOverlapping) {
            dispatch(
              setConfigError(
                'Slots already exist for the specified times. Please check and try again'
              )
            )
            setApiLoading(false)
            return
          }
        }
      }
    }
    dispatch(setConfigError(''))
    dispatch(createSlotsAsync()).then((data) => {
      if (data.type === 'slots/createSlots/rejected') {
        const message =
          data.payload?.data?.message ?? data.payload?.message ?? 'Conflict'
        toast.error(`Failed to create slots - ${message}`)
      } else {
        toast.success('Created Slots Successfully...')
        dispatch(clearSlotConfigs())
        dispatch(fetchSlotsAsync())
      }
      setApiLoading(false)
    })
  }

  return configs.length ? (
    <div className='createSlotBlock'>
      <table className='data-table text-center customGrid'>
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Slot duration</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {configs.map((sl) => (
            <tr key={sl.id}>
              <td>{readableSlotFormat(new Date(sl.start))}</td>
              <td>{readableSlotFormat(new Date(sl.end))}</td>
              <td>{sl.duration} minutes</td>
              <td>
                <button onClick={() => deleteConfig(sl.id)}>
                  <img alt='Delete' src={deleteImg} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='text-right mt10'>
        <LoadingButton
          title='Create Slots'
          loading={apiLoading}
          onClick={validateAndCreate}
        />
      </div>
    </div>
  ) : null
}

export default ConfigList
