import { useNavigate } from 'react-router-dom'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { Box, Button, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Asterisk from '../../../components/Asterisk'
import { emptyString, intent } from '../../Radiology/constants'
import { PractitionerRole, Practitioner } from 'fhir/r4'
import { useState, useEffect } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { forms, formValues } from '../constants'
import { FormDoctor } from '../doctor/DoctorsForm'
import KeycloakService from '../../../utils/keycloakService'
import { toast } from 'react-toastify'
import { setLmsItem, setLmsFormMode } from '../../lms/lmsSlice'
import { dispatch } from '../../lms/models'

const styles = (theme: any) => ({})
const formatDoctors = (
  roles: { resource: PractitionerRole }[],
  id?: string
) => {
  if (!id) {
    return 'NA'
  }
  const resType = roles.find(
    (e) => e?.resource?.practitioner?.reference?.split?.('/')?.[1] === id
  )
  const code =
    resType?.resource?.specialty?.[0]?.coding?.[0]?.code ?? emptyString
  const display =
    resType?.resource?.specialty?.[0]?.coding?.[0]?.display ?? emptyString
  return `${display}::${code}`
}
const PartnerForm = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [doctorsData, setDoctorsData] = useState<FormDoctor[]>([])
  const classes = useCustomStyles(styles, theme)
  const initialFormState = {
    type: '',
    name: '',
    phoneNumber: '',
    email: '',
    status: '',
    fhirId: '',
    id: '',
    location: '',
    address: '',
    govId: '',
    department:'',
    designation:''
  }
  const {
    values,
    setFieldValue,
    setValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    submitForm,
  } = useFormik({
    initialValues: initialFormState,
    validationSchema: Yup.object({}),
    onSubmit: (values, { setSubmitting }) => {
      handleRegistrationSubmit(values, setSubmitting)
    },
    enableReinitialize: true,
  })
  const handleRegistrationSubmit = async (
    data: Record<string, any>,
    setSubmitting: any
  ) => {
    const { type, name, phoneNumber, email, status, fhirId, id } = data
    const formatted = {
      type: type,
      name: name,
      id: id,
      mobile_number: phoneNumber,
      email: email,
      fhir_id: fhirId,
      created_by: KeycloakService.getUsername(),
    }

    const state = {
      organization: formatted,
    }
    startSxpProxy(ADMIN_PROJECT_ID, 'createOrganization', state)
      .then((data) => {
        toast.success(data?.data?.message)
        navigate('/administration/partner')
      })
      .catch((err) => {
        toast.error(data?.data?.message)
      })
  }
  const fetchDoctors = () => {
    startSxpProxy(ADMIN_PROJECT_ID, 'getDoctors')
      .then((res) => {
        const practitioners = res?.data?.entry?.filter(
          (e: { resource: { resourceType: string } }) =>
            e?.resource?.resourceType === forms?.practitioner
        )
        const roles: { resource: PractitionerRole }[] =
          res?.data?.entry?.filter(
            (e: { resource: { resourceType: string } }) =>
              e?.resource?.resourceType === forms?.practitionerRole
          )
        const data: FormDoctor[] = practitioners?.map(
          (e: { resource: Practitioner }) => {
            const role = roles?.find(
              (r) =>
                r?.resource?.practitioner?.reference?.split?.('/')?.[1] ===
                e?.resource?.id
            )
            return {
              id: e?.resource?.id,
              firstName:
                e?.resource?.name?.[0]?.given?.join(emptyString) ?? emptyString,
              middleName: emptyString,
              lastName: e?.resource?.name?.[0]?.family ?? emptyString,
              salutation: e?.resource?.name?.[0]?.prefix?.[0] ?? emptyString,
              registeredOn:
                e?.resource?.identifier?.[1]?.period?.start ?? emptyString,
              dob: e?.resource?.birthDate,
              email:
                e?.resource?.telecom?.find(
                  (contact: any) => contact?.system === formValues.email
                )?.value || formValues.na,
              phone:
                e?.resource?.telecom?.find(
                  (contact: any) => contact?.system === formValues.phone
                )?.value || formValues.na,
              qualification:
                e?.resource?.qualification?.[0]?.code?.text || formValues.na,
              gender: e?.resource?.gender || formValues.na,
              regNumber:
                e?.resource?.qualification?.[0]?.identifier?.[0]?.value,
              speciality:
                formatDoctors(roles, e?.resource?.id) || formValues.na,
              active: e?.resource?.active ?? true,
              healthService:
                role?.resource?.healthcareService?.map?.(
                  (hs) => hs.reference?.split?.('/')?.[1] ?? emptyString
                ) ?? [],
            }
          }
        )
        data.sort((a, b) => (a?.id < b?.id ? -1 : 1))
        setDoctorsData(data)
      })
      .catch((err) => console.error(err))
  }
  useEffect(() => {
    if (values?.type === 'doctor') {
      fetchDoctors()
    } else {
      setDoctorsData([])
      setValues({
        ...values,
        id: '',
        fhirId: '',
        name: '',
        phoneNumber: '',
        email: '',
        status: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type])
  const handleDoctorChange = (e: { target: { value: string } }) => {
    const selectedDoctor = doctorsData?.find((doc) => doc?.id === e?.target?.value)
    if (selectedDoctor) {
      setValues({
        ...values,
        fhirId: selectedDoctor?.id,
        name: `${selectedDoctor?.firstName} ${selectedDoctor?.lastName}`,
        phoneNumber: selectedDoctor?.phone,
        email: selectedDoctor?.email,
        status: selectedDoctor?.active ? 'active' : 'inActive',
      })
    } else {
      setFieldValue('name', e.target.value)
    }
  }
  return (
    <>
      <div>
        <form className='panel-form'>
          <div className='admin-form-flex w-72'>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                Partner Type
                <Asterisk />
                &nbsp;:
              </label>
              <select
                className='panel-input-content'
                required
                name='type'
                value={values?.type}
                onChange={handleChange}
              >
                <option value={emptyString}>Select Option</option>
                <option value='doctor'>Doctor</option>
                <option value='b2bPartner'>B2B Partner</option>
                <option value='employee'>Employee</option>
                <option value='self'>Self</option>
                <option value='rmp'>RMP</option>
                <option value='pmp'>PMP</option>
                <option value='ashaWorker'>Asha Worker</option>
                <option value='mithra'>Mithra</option>
              </select>
            </div>
            {values.type === 'doctor' ? (
              <div className='doc-container'>
                <label htmlFor='doctor-name' className='doc-input-label'>
                  Name
                  <Asterisk />
                  &nbsp;:
                </label>
                <select
                  className='panel-input-content'
                  required
                  name='id'
                  value={values?.fhirId}
                  onChange={handleDoctorChange}
                >
                  <option value={emptyString}>Select Doctor</option>
                  {doctorsData?.map((doctor) => (
                    <option key={doctor?.id} value={doctor?.id}>
                      {doctor?.firstName} {doctor?.lastName}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className='doc-container'>
                <label htmlFor='test-name' className='doc-input-label'>
                  Name
                  <Asterisk />
                  &nbsp;:
                </label>
                <input
                  type='text'
                  className='panel-input-content'
                  required
                  name='name'
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            )}
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                Phone Number
                <Asterisk />
                &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='phoneNumber'
                value={values?.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                Email Id
                <Asterisk />
                &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='email'
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                Department
                &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='department'
                value={values?.department}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                Designation
                &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='designation'
                value={values?.designation}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                Status
                <Asterisk />
                &nbsp;:
              </label>
              <select
                className='panel-input-content'
                required
                name='status'
                value={values?.status}
                onChange={handleChange}
              >
                <option value={emptyString}>Select Option</option>
                <option value='active'>Active</option>
                <option value='inActive'>In Active</option>
              </select>
            </div>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                GST/PAN &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='govId'
                value={values?.location}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                Location &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='location'
                value={values?.location}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='test-name' className='doc-input-label'>
                Address &nbsp;:
              </label>
              <input
                type='text'
                className='panel-input-content'
                required
                name='address'
                value={values?.location}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className='doc-container'>
              <div className='doc-input-label'></div>
              <div className='panel-input-content'>
                <Box className='box-btn-referred'>
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    className='ip-register-btn w10'
                    onClick={submitForm}
                  >
                    Submit
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default PartnerForm
