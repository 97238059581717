/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import Details from './components/Details'
import Radiology from './Radiology'
import { useParams } from 'react-router-dom'
import { LabMode, LabOrder } from './models'
import { startSxpProxy } from '../../utils/api'
import { LABS_PROJECT_ID, OPD_PROJECT_ID } from '../../utils/constants'
import { useAppSelector } from '../../app/hooks'
import {
  selectLocationById,
  selectSelectedLocationId,
} from '../location/locationSlice'
import { readableDateTimeFormat } from '../../utils/dateUtils'

const RadiologyOrders = () => {
  const { id } = useParams()
  const [patientId, setPatientId] = useState('')
  const [order, setOrder] = useState<LabOrder>({})
  const [orders, setOrders] = useState<LabOrder[]>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [mode, setMode] = useState<LabMode>('')
  useEffect(() => {
    if (id) {
      const intent = 'getLabOrdersApi'
      startSxpProxy(LABS_PROJECT_ID, intent, { visitId: id })
        .then((data: any) => {
          const orders: LabOrder[] = data?.data?.lab_orders ?? []
          if (!orders.length) {
            setActiveIndex(-1)
          }
          setOrders(orders)
          const state = { encounterId: id }
          startSxpProxy(OPD_PROJECT_ID, 'getVisitApi', state)
            .then((data) => {
              setPatientId(
                data?.data?.subject?.reference?.split('/')?.[1] ?? ''
              )
            })
            .catch((err) => {
              console.error(err)
            })
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [id])

  useEffect(() => {
    const activeOrder = orders[activeIndex] ?? {}
    setOrder(activeOrder)
    if (activeOrder?.package_id) {
      setMode('package')
    } else {
      setMode('test')
    }
  }, [orders, activeIndex])

  return (
    <div className='orders-container'>
      <div className='order-buttons'>
        {orders.map((ord, idx) => (
          <button
            className={`order-btn${
              activeIndex === idx ? ' active-order-btn' : ''
            }`}
            onClick={() => setActiveIndex(idx)}
            key={ord?.id}
            title={`Order ${ord?.id} - ${readableDateTimeFormat(
              new Date(ord?.ordered_on ?? '')
            )}`}
          >
            Order {ord?.id}
          </button>
        ))}
        <button
          disabled={orders?.some(
            (ord) =>
              ord?.order_events?.[0]?.type === 'ORDERED' ||
              ord?.order_events?.[0]?.type === 'ORDER_MODIFIED'
          )}
          className={`order-btn${
            activeIndex === -1 ? ' active-order-btn' : ''
          }`}
          onClick={() => setActiveIndex(-1)}
        >
          New Order
        </button>
      </div>
      <Details summary='Radiology'>
        <Radiology />
      </Details>
    </div>
  )
}

export default RadiologyOrders
