import { createSlice } from '@reduxjs/toolkit'

const membershipSlice = createSlice({
  name: 'membership',
  initialState: { query: '', param: '' },
  reducers: {
    setQueryParam: (state, action) => {
      state.query = action.payload.q
      state.param = action.payload.param
    },
  },
})
export const { setQueryParam } = membershipSlice.actions
export const selectQuery = (state: { membership: { query: any } }) =>
  state.membership.query
export const selectParam = (state: { membership: { param: any } }) =>
  state.membership.param

export default membershipSlice.reducer
