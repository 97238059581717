import { useEffect, useState } from 'react'
import '../bms.scss'
import { startSxpProxy } from '../../../utils/api'
import { CHAT_PROJECT_ID, IPD_PROJECT_ID } from '../../../utils/constants'
import { makeName } from '../../lms/utils'
import { getTotalAge } from '../../../utils/dateUtils'
import KeycloakService from '../../../utils/keycloakService'
import { useNavigate } from 'react-router-dom'
import Dialog from '../../../components/Dialog'
import AddTransferDialog from './AddTransferDialog'
import DischargeConformationDialog from './DischargeConformationDialog'
import { bedFilter, updateBedType } from '../constants'
import { allocateApi, allocation, tableHeadText } from '../modals'
import { emptyString, toastOptions } from '../../Radiology/constants'
import Close from '../../../assets/images/close.png'
import Edit from '../../../assets/icons/edit_icon.svg'
import EditAdmissionDate from './EditAdmissionDate'
import { IPD_ADMIN } from '../../../utils/roles'
import { toast } from 'react-toastify'
import ToastMessage from '../../lms/components/ToastMessage'
type Props = {
  onConfirm: (image: boolean) => void
  wardId: bedFilter
  setRefresh?: any
  refresh: boolean
  tableRefresh?: any
  mode: string
  onClose: () => void
}
const AddDischargeDialog = ({
  onConfirm,
  wardId,
  setRefresh,
  tableRefresh,
  mode,
  onClose,
}: Props) => {
  const navigate = useNavigate()
  const [dischargeOpen, setDischargeOpen] = useState<boolean>(false)
  const [bed, setBed] = useState<bedFilter>(wardId)
  const [isTransferOpen, setIsTransferOpen] = useState<boolean>(false)
  const [isEditDate, setIsEditDate] = useState(false)
  const [dischargeBedData, setDischargedBedData] = useState<updateBedType>({
    admissionDate: '',
    patientId: '',
    id: 0,
    bedMapId: 0,
    patientMapId: '',
    patientFhirId: '',
  })
  const transferBed = () => {
    setIsTransferOpen(true)
  }
  const dateObject = (dateString: any) => {
    const dateObject = new Date(dateString)
    const formattedDate = dateObject.toISOString().split('T')[0]
    return formattedDate
  }
  useEffect(() => {
    startSxpProxy(CHAT_PROJECT_ID, allocateApi.getPatientDetailsByIdentifier, {
      patientIdentifier: bed.patientId,
    }).then((data) => {
      if (data?.data?.entry?.length > 0) {
        const name = makeName(data?.data?.entry?.[0]?.resource.name)
        const gender = data?.data?.entry?.[0]?.resource.gender
        const age = getTotalAge(
          data?.data?.entry?.[0]?.resource.birthDate ?? ''
        )
        setBed((prevBed: any) => ({
          ...prevBed,
          patientName: name,
          gender: gender,
          age: age,
        }))
      }
    })
  }, [bed.patientId])
  useEffect(() => {
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getBedDetailsById, {
      bedId: parseInt(bed.id),
    }).then((data: any) => {
      const bed = data.data.bed_by_pk
      const status = bed.bed_patient_assignment_maps?.find(
        (status: any) => status?.bed_status === 'ASSIGNED'
      )
      setDischargedBedData({
        admissionDate: bed.admission_date,
        patientId: bed.patient_id,
        patientFhirId: status?.patient_registered_id,
        id: bed.id,
        bedMapId: status.id,
        patientMapId: status.patient_id,
      })
    })
  }, [bed.id])

  const handleTransferClose = (e: boolean) => {
    onConfirm(e)
  }
  const handleDischargeClose = () => {
    setDischargeOpen(false)
  }
  const dischargeConfirm = (e: boolean) => {
    // setDischarge(e)
    setDischargeOpen(false)
    if (e) {
      sendDischargeData()
    }
    onConfirm(false)
  }

  const sendDischargeData = () => {
    const state = {
      id: parseInt(bed.id),
      patientId: emptyString,
      bedMapId: dischargeBedData.bedMapId,
      patientMapId: dischargeBedData?.patientMapId,
      bedStatus: allocation.dischargedText,
      dateOfDischarge: new Date().toISOString(),
      updatedBy: KeycloakService.getUsername(),
      patientName: emptyString,
      admissionDate: emptyString,
      dischargedDate: emptyString,
      status: allocation.available,
      patientFhirId: dischargeBedData?.patientFhirId,
    }
    startSxpProxy(IPD_PROJECT_ID, allocateApi?.updateAndDischargeBed, state)
      .then((data) => {
        const encounterId = data?.data
        const state = {
          status: 'finished',
          encounterId: encounterId,
          reason: 'Discharged',
        }
        startSxpProxy(IPD_PROJECT_ID, allocateApi?.closeEncounter, state)
          .then((data) => {
            if (mode === allocation.list) {
              onConfirm(false)
              setRefresh((prevState: boolean) => !prevState)
            }
            if (mode === allocation.layout) {
              onConfirm(false)
              if (window.location.pathname === '/bedIpd') {
                navigate('/bedIpd/bedOverview')
              } else {
                navigate('/bedIpd')
              }
            }
          })
          .catch((err) => console.error(err, 'err'))
      })
      .catch((err) => {
        console.error(err, 'err')
        err?.response?.data?.errors
          ? err?.response?.data?.errors?.map((e: string) =>
              toast.error(
                <ToastMessage message={e ?? 'Unable to fetch api'} />,
                toastOptions
              )
            )
          : toast.error(
              <ToastMessage message={'Unable to fetch api'} />,
              toastOptions
            )
      })
  }

  const allocateDateOpen = (wardId: bedFilter) => {
    setIsEditDate(true)
  }
  const handleEditDateClose = () => {
    setIsEditDate(false)
    onConfirm(false)
    setRefresh(true)
    tableRefresh()
  }
  const handleEditChange = () => {
    setIsEditDate(false)
    onConfirm(false)
  }
  return (
    <>
      {isEditDate && (
        <Dialog
          open={isEditDate}
          onClose={handleEditDateClose}
          style={{ width: '50%', padding: '1%' }}
        >
          <EditAdmissionDate
            onClose={handleEditChange}
            onConfirm={handleEditDateClose}
            wardId={wardId}
          />
        </Dialog>
      )}
      {dischargeOpen && (
        <Dialog
          open={dischargeOpen}
          onClose={handleDischargeClose}
          style={{ width: '28%', padding: '2%' }}
        >
          <DischargeConformationDialog
            onConfirm={dischargeConfirm}
            onClose={() => setDischargeOpen(false)}
          />
        </Dialog>
      )}

      <div className='assign-container'>
        <div>
          <span className='event-close' onClick={() => onClose()}>
            <img className='close-img' src={Close} alt='Close Event Dialog' />
          </span>
          <div>
            <span className=''>{allocation.patientDetails}</span>
          </div>
          <table className='bed-table relative'>
            <thead>
              <tr>
                <th>{tableHeadText.uhid}</th>
                <th className='text-center'>{tableHeadText.name}</th>
                <th className='text-center'>{tableHeadText.age}</th>
                <th className='text-center'>{tableHeadText.gender}</th>
                <th className='text-left'>{tableHeadText.admit}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>{bed.patientId}</td>
                <td className='text-center'>{bed.patientName}</td>
                <td className='text-center'>{bed.age}</td>
                <td className='text-center'>{bed.gender}</td>
                <td className='flex-center'>
                  {bed.admission ? dateObject(bed.admission) : '-'}
                  {KeycloakService.hasRole([IPD_ADMIN]) && (
                    <div>
                      <button
                        className='bg-none'
                        onClick={() => allocateDateOpen(bed)}
                      >
                        <img
                          alt='Edit Patient'
                          src={Edit}
                          className='height18-width20'
                        />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div>
            <span>{allocation.bedDetails}</span>
          </div>
          <table className='bed-table relative'>
            <thead>
              <tr>
                <th>{tableHeadText.bedNo}</th>
                <th className='text-left'>{tableHeadText.floor}</th>
                <th className='text-center'>{tableHeadText.bedCategory}</th>
                <th className='text-center'>{tableHeadText.roomNo}</th>
                <th className='text-center'>{tableHeadText.status}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>{bed.bedNumber}</td>
                <td className='text-center'>{bed.floorNumber}</td>
                <td className='text-center'>{bed.bedCategory}</td>
                <td className='text-center'>{bed.roomDetail}</td>
                <td className='text-center'>
                  {bed.status === allocation.occupied
                    ? allocation.occupiedCase
                    : allocation.availableCase}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {isTransferOpen && (
          <AddTransferDialog
            bed={bed}
            dischargedBedData={dischargeBedData}
            onClose={handleTransferClose}
            setRefresh={setRefresh}
            tableRefresh={tableRefresh}
            mode={mode}
          />
        )}
        <div className='assign-transfer'>
          <input
            type='button'
            value='Transfer'
            disabled={isTransferOpen === true}
            className={`assign-button ${
              isTransferOpen === true ? 'disableBtn' : ''
            }`}
            onClick={transferBed}
          />
          <input
            type='button'
            value='Discharge'
            className='assign-button'
            onClick={() => {
              setDischargeOpen(true)
            }}
          />
        </div>
      </div>
    </>
  )
}
export default AddDischargeDialog
