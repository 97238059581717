import { useEffect, useState } from 'react'
import { LabOrder } from '../../labTests/models'
import { startSxpProxy } from '../../../utils/api'
import { LABS_PROJECT_ID } from '../../../utils/constants'

export const usePatientOrders = (id: string, source: string) => {
  const [orders, setOrders] = useState<LabOrder[]>([])

  useEffect(() => {
    const state = {
      patientId: id,
      source: source,
    }
    startSxpProxy(LABS_PROJECT_ID, 'getLabOrdersByPatientIdAndStatus', state)
      .then((data) => {
        setOrders(data.data.lab_orders ?? [])
      })
      .catch(() => {
        setOrders([])
      })
  }, [id, source])

  return orders
}
