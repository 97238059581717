import { useNavigate } from 'react-router-dom'
import PathologistForm from './PathologistForm'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import '../admin.scss'

const CreatePathologist = () => {
  const navigate = useNavigate()
  return (
    <>
      <IconButton onClick={() => navigate('/administration/doctors')}>
        <KeyboardBackspaceIcon className='back-btn-admin' />
      </IconButton>
      <PathologistForm pathologist={null} />
    </>
  )
}

export default CreatePathologist
