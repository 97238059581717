type Props = {
  filterPanels: Panel[]
  onPanelSelect: (panel: Panel) => void
  searchText: string
}

import Tick from '../../../assets/images/check.png'
import { Panel } from '../models'

const FilterTests = ({ filterPanels, onPanelSelect, searchText }: Props) => {
  const sample = filterPanels.reduce((acc: any, cur) => {
    const sampleName = cur.sampleName
    if (!acc[sampleName]) {
      acc[sampleName] = [cur]
    } else {
      acc[sampleName].push(cur)
    }
    return acc
  }, [])

  const samplekeys = Object.keys(sample)
  const output = samplekeys.map((department) => (
    <div className='items-container' key={department}>
      <span className='heading'>{department}:</span>
      <div className='items'>
        {sample[department].map((item: Panel) => (
          <div
            className={`item ${item.active ? 'active' : ''}`}
            key={item.id}
            onClick={() => onPanelSelect(item)}
            title={`${item.name.toUpperCase()}${
              item.active ? ' - Selected' : ''
            }`}
          >
            {item.active && <img className='check' src={Tick} alt='Checked' />}
            {item.name}
          </div>
        ))}
      </div>
    </div>
  ))

  return (
    <div>
      {searchText?.length < 3 ? (
        <span>Enter Panel Name</span>
      ) : filterPanels?.length > 0 ? (
        <>{output}</>
      ) : (
        <span className='not-panels-found'>No Panels Found</span>
      )}
    </div>
  )
}
export default FilterTests
