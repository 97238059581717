import React from 'react'

type Props = {
  label: string
  required: boolean
}

const FieldLabel = ({ label, required }: Props) => {
  return (
    <label>
      {label}
      {required && <span className='required_red'>*</span>} :
    </label>
  )
}

export default FieldLabel
