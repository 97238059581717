import React from 'react'
import { LabTest, Panel } from '../models'
import Tick from '../../../assets/images/check.png'

type Props = {
  searchText: string
  panels: Panel[]
  onTestSelect: (test: LabTest) => void
}

const Tests = ({ searchText, panels, onTestSelect }: Props) => {
  let tests: LabTest[] = panels.flatMap((p) => p.lab_tests)

  if (searchText) {
    tests = tests.filter((t) =>
      t.name.toLowerCase().includes(searchText.toLowerCase())
    )
  }

  const handleTestSelect = (test: LabTest) => {
    if (!test.controlled) {
      onTestSelect(test)
    }
  }

  return (
    <div className='items-container lab-tests'>
      <span className='heading'>Test Parameters</span>
      <div className='items'>
        {tests.length > 0 ? (
          tests.map((t) => (
            <div
              className={`item ${
                t.controlled ? 'controlled' : t.active ? 'active' : ''
              }`}
              key={t.id}
              onClick={() => handleTestSelect(t)}
              title={`${t.description.toUpperCase()}${
                t.controlled
                  ? ' - Panel Selected'
                  : t.active
                  ? ' - Selected'
                  : ''
              }`}
            >
              {t.active && <img className='check' src={Tick} alt='Checked' />}
              {t.name}
            </div>
          ))
        ) : (
          <span className='not-found'>No Test Parameters Found</span>
        )}
      </div>
    </div>
  )
}

export default Tests
