import React, { useMemo } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { FhirSlot, selectAvailableSlots, SlotSchedule } from '../slotsSlice'
import ScheduleCard from './ScheduleCard'

const ExistingSlots = () => {
  const availableSlots = useAppSelector(selectAvailableSlots)
  const schedules: SlotSchedule[] = useMemo(() => {
    if (availableSlots.length) {
      const sorted = availableSlots.slice().sort((a: FhirSlot, b: FhirSlot) => {
        if (a.resource.start < b.resource.start) {
          return -1
        }
        return 1
      })
      const reduced = sorted.reduce((acc: any, cur: any) => {
        const scheduleId = cur.resource?.schedule?.reference?.split('/')?.[1]
        if (acc[scheduleId]) {
          acc[scheduleId].push(cur.resource)
        } else {
          acc[scheduleId] = [cur.resource]
        }
        return acc
      }, {})
      const formatted: SlotSchedule[] = []
      for (const key in reduced) {
        const slots = reduced[key]
        const start = slots[0].start
        const end = slots[slots.length - 1].end
        const obj: SlotSchedule = {
          id: key,
          start: start,
          end: end,
          slots: slots,
          date: slots[0].start.slice(0, 10),
        }
        formatted.push(obj)
      }
      formatted.sort((a, b) => {
        if (a.date < b.date) {
          return -1
        }
        return 1
      })
      return formatted
    }
    return []
  }, [availableSlots])

  return schedules?.length ? (
    <section className='existing-slots'>
      <h2 className='slot-heading'>Existing Slots</h2>
      <div className='schedule-list'>
        {schedules.map((sch) => (
          <ScheduleCard key={sch.id} schedule={sch} />
        ))}
      </div>
    </section>
  ) : null
}

export default ExistingSlots
