import { pdf } from '@react-pdf/renderer'
import { startSxpProxy } from '../../utils/api'
import { LABS_PROJECT_ID } from '../../utils/constants'
import { fullDateTimeFormat } from '../../utils/dateUtils'
import KeycloakService from '../../utils/keycloakService'
import { VALIDATION_WRITE } from '../../utils/roles'
import InvoicePdf from '../lms/components/dashboard/InvoicePdf'
import { InvoiceData } from '../lms/models'
import { formatInvoiceData } from '../lms/utils'
import { formatPatient } from '../patients/utils'
import PrintLogo from '../../assets/images/labreportIcon.png'
import { labTestsType } from './constant'
import { Button } from '@mui/material'
import { useRef, useEffect, useState } from 'react'
import { setQueryParam } from '../membership/membershipSlice'
import { useAppDispatch } from '../../app/hooks'

const LabRadiology = (props: any) => {
  const { data, cardType, patientData } = props
  const dispatch = useAppDispatch()
  const [isOrdersVisible, setIsOrdersVisible] = useState(false)
  const OrderTable = () => {
    setIsOrdersVisible(!isOrdersVisible)
  }

  let allLabTests: labTestsType[] = []
  if (data?.length) {
    if (cardType === 'radiology') {
      allLabTests = data
    }
    const filteredData = data?.filter(
      (item: { status: any }) => item?.status === 'COMPLETED'
    )
    allLabTests = filteredData
  }
  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container?.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 200}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  return (
    <>
      <div style={{ fontSize: '15px', cursor: 'pointer' }} onClick={OrderTable}>
        Order History {isOrdersVisible ? '▲' : '▼'}
      </div>
      {isOrdersVisible && (
        <div className='lab-rad-table' ref={VisitTableRef}>
          <table className='data-table table-fixed admin-table'>
            <thead>
              <tr>
                <th className='table-w-4'>Sl&nbsp;No</th>
                <th className='service-wd'>Service</th>
                <th className='table-w-6'>Type</th>
                <th className='table-w-12'>ID</th>
                <th>Test Name</th>
                <th className='table-w-10'>Requested By</th>
                <th className='table-w-8'>Location</th>
                <th className='table-w-10'>Ordered on</th>
                <th className='table-w-10'>Completed on</th>
                <th className='table-w-5'>TAT</th>
                <th className='table-w-6'>View</th>
                <th className='table-w-8'>Status</th>
              </tr>
            </thead>
            <tbody>
              {allLabTests?.length ? (
                allLabTests?.map((d: labTestsType, index: number) => (
                  <SingleCard
                    item={d}
                    key={d.id}
                    patientData={patientData}
                    index={index + 1}
                  />
                ))
              ) : (
                <tr>
                  <td className='text-center' colSpan={8}>
                    No orders found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default LabRadiology

const SingleCard = ({
  item,
  index,
  patientData,
}: {
  item: labTestsType
  patientData: any
  index: number
}) => {
  const patients = formatPatient(patientData)

  const handleInvoicePrint = () => {
    if (!KeycloakService.hasRole([VALIDATION_WRITE])) {
      return
    }
    const intent = 'getLabTestsByIds'
    const panelIds =
      item?.panels?.flatMap((ip: any) =>
        ip?.lab_tests?.map((ipl: any) => ipl?.id)
      ) ?? []
    const testIds = item?.lab_tests?.map((il: any) => il.id) ?? []
    const state = {
      orderId: item?.id,
      ids: [...panelIds, ...testIds],
    }

    startSxpProxy(LABS_PROJECT_ID, intent, state)
      .then(async (data) => {
        const panel = formatInvoiceData(
          data?.data?.lab_test ?? [],
          item?.status ?? ''
        )
        const obj: InvoiceData = {
          id: item.patientUhid ?? item.patientThopId ?? item.patient_id ?? '',
          name: patients?.name,
          age: patients?.age,
          gender: patients?.gender,
          mobile: patients?.mobile ?? '',
          lrNumber: item.lr_number ?? '',
          orderedOn: item.ordered_on ?? '',
          completedOn: item.collection_completed_on ?? '',
          requestedBy: item.requested_by ?? item.ordered_by_name ?? '',
          validatedOn: item.validation_completed_on ?? '',
          panels: panel,
          summary: item.summary ?? '',
          orderType: item?.origin,
        }
        const doc = await pdf(<InvoicePdf data={obj} />).toBlob()
        const fileUrl = URL.createObjectURL(doc)

        const iframe = document.createElement('iframe')
        iframe.src = fileUrl
        iframe.width = '100%'
        iframe.height = '100%'
        iframe.style.border = 'none'

        const newWindow = window.open('', '_blank') as Window
        newWindow.document.body.style.margin = '0'
        newWindow.document.body.appendChild(iframe)
        newWindow.document.title = 'Test Results'
      })
      .catch((err) => {
        console.log('Error:', err)
      })
  }
  function calculateDateDifference(dateA: Date, dateB: Date) {
    const dateAMilliseconds = dateA.getTime()
    const dateBMilliseconds = dateB.getTime()
    const differenceMilliseconds = Math.abs(
      dateAMilliseconds - dateBMilliseconds
    )

    const differenceDays = differenceMilliseconds / (1000 * 3600 * 24)
    const roundedDifference = Math.round(differenceDays)
    return roundedDifference + ' days'
  }

  return (
    <tr key={item.id}>
      <td className='text-left'>{index}</td>
      <td>{item?.origin}</td>
      <td className='text-left'>Lab</td>
      <td className='text-left'>{item?.lr_number}</td>
      <td className='text-left'>
        {(item?.panels?.map((ip) => ip.name) ?? []).join(', ')}
      </td>
      <td className='text-left'>{item?.ordered_by_name}</td>
      <td className='text-left'>{item?.source}</td>
      <td className='text-left'>
        {' '}
        {fullDateTimeFormat(new Date(item?.ordered_on ?? ''))}
      </td>
      <td className='text-left'>
        {fullDateTimeFormat(new Date(item?.collection_completed_on ?? ''))}
      </td>
      <td className='text-left'>
        {calculateDateDifference(
          new Date(item?.collection_completed_on ?? ''),
          new Date(item?.ordered_on ?? '')
        )}
      </td>

      <td className='text-left'>
        <Button
          title='Print Invoice'
          className={
            KeycloakService.hasRole([VALIDATION_WRITE]) ? '' : 'auth-disabled'
          }
          onClick={handleInvoicePrint}
        >
          <img src={PrintLogo} alt='Print Invoice' style={{ height: '20px' }} />
        </Button>
      </td>
      <td className='text-left'>
        {item?.status
          ?.toLowerCase()
          .split(' ')
          .map(
            (word: string) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
          )
          .join(' ')}
      </td>
    </tr>
  )
}
