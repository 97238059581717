import React, { useState } from 'react'

const maskNumber = (str: string) => {
  const len = str?.length - 2
  if (len > 0) {
    return '*'.repeat(len) + str.slice(len)
  }
  return str ?? ''
}

type Props = {
  mobile: string
}

const MaskedNumber = ({ mobile }: Props) => {
  const [hide, setHide] = useState(true)

  return (
    <span className='pointer' onClick={() => setHide((m) => !m)}>
      {hide ? maskNumber(mobile) : mobile}
    </span>
  )
}

export default MaskedNumber
