import React, { useRef, useEffect } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch } from '../../lmsSlice'
import { AdminTableProps } from '../../models'
import LinkButton from './LinkButton'

const UnitTypeTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = data?.filter((d: any) =>
    d.unit_type?.toLowerCase()?.includes(searchParam)
  )
  filtered?.sort?.((a: any, b: any) =>
    a.unit_type?.toLowerCase() < b.unit_type?.toLowerCase() ? -1 : 1
  )

  const unitTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = unitTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div
      className='data-table table-fixed admin-table-table-containe'
      ref={unitTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Id</th>
            <th>Unit</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d.id}>
                <td>{d.id}</td>
                <td>
                  <LinkButton id={d.id} name={d.unit_type} onClick={onEdit} />
                </td>
                <td>{d.unit_type_description}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className='text-center'>
                No Unit Types Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default UnitTypeTable
