import React, { useRef, useEffect } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import { AdminTableProps } from '../../models'
import LinkButton from './LinkButton'

const ResultTypeTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = getSearchResults(data, searchParam)
  filtered?.sort?.((a: any, b: any) =>
    a.result_type?.toLowerCase() < b.result_type?.toLowerCase() ? -1 : 1
  )

  const resultTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = resultTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={resultTableContainerRef}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Id</th>
            <th>Result Type</th>
            <th>Description</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d.id}>
                <td>{d.id}</td>
                <td>
                  <LinkButton id={d.id} name={d.result_type} onClick={onEdit} />
                </td>
                <td>{d.result_description}</td>
                <td>{d.options?.join(', ') ?? '-'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                No Result Types Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ResultTypeTable
