import React, { useState } from 'react'
import { SlotSchedule } from '../slotsSlice'
import {
  readableDateFormat,
  readableSlotFormat,
} from '../../../utils/dateUtils'
import Dialog from '../../../components/Dialog'
import ScheduleDialog from './ScheduleDialog'
import differenceInMinutes from 'date-fns/differenceInMinutes'

type Props = {
  schedule: SlotSchedule
}

const ScheduleCard = ({ schedule }: Props) => {
  const [open, setOpen] = useState(false)
  const { start, end } = schedule.slots[0]
  const duration = differenceInMinutes(new Date(end), new Date(start))

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <ScheduleDialog schedule={schedule} onClose={handleClose} />
      </Dialog>
      <div className='schedule-card' onClick={handleOpen}>
        <div>
          <p className='schedule-heading'>
            {readableDateFormat(new Date(schedule.date))}
          </p>
          <p className='schedule-slots'>
            {schedule.slots.length} Slots [{duration} mins]
          </p>
        </div>
        <div>
          <p className='schedule-time'>
            {readableSlotFormat(new Date(schedule.start))} to{' '}
            {readableSlotFormat(new Date(schedule.end))}
          </p>
        </div>
      </div>
    </>
  )
}

export default ScheduleCard
