import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import ExistingSlots from './ExistingSlots'
import ConfigInputs from './ConfigInputs'
import ConfigList from './ConfigList'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  fetchSlotsAsync,
  selectSlotsEndDate,
  selectSlotsSelectedDoctor,
  selectSlotsSelectedLocation,
  selectSlotsSelectedService,
  selectSlotsStartDate,
} from '../slotsSlice'
import { makeConfigString } from '../../../utils/dateUtils'
import KeycloakService from '../../../utils/keycloakService'
import { SLOTS_CREATE } from '../../../utils/roles'

const Configurations = () => {
  const doctor = useAppSelector(selectSlotsSelectedDoctor)
  const service = useAppSelector(selectSlotsSelectedService)
  const location = useAppSelector(selectSlotsSelectedLocation)
  const start = useAppSelector(selectSlotsStartDate)
  const end = useAppSelector(selectSlotsEndDate)
  const dispatch = useAppDispatch()
  const dateString = makeConfigString(start, end)

  useEffect(() => {
    if (doctor && service && location) {
      dispatch(fetchSlotsAsync())
    }
  }, [doctor, service, location, start, end, dispatch])

  return doctor && service && location ? (
    <div className='slot-config'>
      {KeycloakService.hasRole([SLOTS_CREATE]) && (
        <div>
          <p style={{ fontSize: 15, fontWeight: '600', marginTop: 0 }}>
            Slot Configuration [{dateString}]
          </p>
          <ConfigInputs />
          <ConfigList />
        </div>
      )}
      <ExistingSlots />
    </div>
  ) : null
}

export default Configurations
