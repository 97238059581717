import React, { ChangeEvent } from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getLocationData,
  selectLocationById,
  selectLocations,
  selectSelectedLocationId,
  setSelectedLocation,
} from './locationSlice'
import './location.scss'
import { updateSxpState } from '../../utils/api'
import { CHAT_PROJECT_ID } from '../../utils/constants'
import { LOCATION_SWITCH } from '../../utils/roles'
import { toast } from 'react-toastify'
import KeycloakService from '../../utils/keycloakService'

const Location = () => {
  const locations = useAppSelector(selectLocations)
  const selectedId = useAppSelector(selectSelectedLocationId)
  const selectedLocation = useAppSelector((state) =>
    selectLocationById(selectedId, state)
  )
  const dispatch = useAppDispatch()

  const handleLocationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value
    const location = locations.find((loc) => loc.resource.id === id)
    if (location) {
      dispatch(setSelectedLocation(id))
      const locData = getLocationData(location)
      const state = {
        selectedLocationId: id,
        locationValue: locData.value,
        locationPrefix: locData.prefix,
        selectedLocationName: locData.name,
      }
      updateSxpState(CHAT_PROJECT_ID, state).then(() => {
        toast(`Switched to location ${locData.name}`, {
          position: 'bottom-center',
          hideProgressBar: true,
          closeButton: false,
          theme: 'dark',
          autoClose: 3000,
        })
      })
    }
  }

  return KeycloakService.hasRole([LOCATION_SWITCH]) && locations?.length ? (
    <select
      onChange={handleLocationChange}
      value={selectedId || ''}
      className='locationDataSize'
    >
      {locations
        .filter((l) => l.resource.status === 'active')
        .map((d) => (
          <option key={d.resource.id} value={d.resource.id}>
            {d.resource.name}
          </option>
        ))}
    </select>
  ) : (
    <span>{selectedLocation?.resource.name ?? ''}</span>
  )
}

export default Location
