import {
  DMS_CLIENT_ID,
  DMS_CLIENT_SECRET,
  DMS_USERNAME,
  DMS_PASSWORD,
  AUTH_SERVER_URL,
  REALM,
} from './constants'

let intervalId: any

export const startTokenRefresh = () => {
  const callFunction = async () => {
    await getDMSToken()
  }

  intervalId = setInterval(callFunction, 14 * 60 * 1000)

  callFunction()
}

export const stopTokenRefresh = () => {
  if (intervalId) {
    clearInterval(intervalId)
  }
}

const getDMSToken = async () => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

  const urlencoded = new URLSearchParams()
  urlencoded.append('client_id', DMS_CLIENT_ID)
  urlencoded.append('client_secret', DMS_CLIENT_SECRET)
  urlencoded.append('grant_type', 'password')
  urlencoded.append('username', DMS_USERNAME)
  urlencoded.append('password', DMS_PASSWORD)

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  }

  try {
    const response = await fetch(
      `${AUTH_SERVER_URL}/realms/${REALM}/protocol/openid-connect/token`,
      requestOptions
    )
    const result = await response.text()
    const accessToken = JSON.parse(result).access_token
    sessionStorage.setItem('dms-token', accessToken)
  } catch (error) {
    console.error('Unable to generate dms token', error)
  }
}
