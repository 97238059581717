import React, { ReactNode } from 'react'
import LoadingOverlay from 'react-loading-overlay-ts'

interface OverlayLoaderProps {
  isActive: boolean
  message: string
  children: ReactNode
}

const OverlayLoader: React.FC<OverlayLoaderProps> = ({
  isActive,
  message,
  children,
}) => {
  return (
    <LoadingOverlay active={isActive} spinner text={message}>
      {children}
    </LoadingOverlay>
  )
}

export default OverlayLoader
