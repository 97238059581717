import React from 'react'
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from '@mui/material'
import './treatment.scss'
interface TreatmentPlanFormProps {
  treatmentPlan: string
  followUpAdvice: string
  followUpDate: string
  homeAdvice: string
  referralRequired?: string | undefined
  doctorName?: string
  department?: string
  reasonReferral?: string
}

const IpdTreatmentPlanForm: React.FC<TreatmentPlanFormProps> = ({
  treatmentPlan,
  followUpAdvice,
  followUpDate,
  homeAdvice,
  referralRequired,
  doctorName,
  department,
  reasonReferral,
}) => {
  return (
    <div className='DocContainer'>
      <div className='mainCard'>
        <h3>
          <div className='docViewBody'>
            <div className='docUploadBtn justify-content'>
              <div className='flex-content'>
                <label htmlFor='docUrl'>
                  <span className='f12-m200'>Treatment Plan</span>
                </label>
              </div>
            </div>
          </div>
        </h3>
        <div className='ml10'>
          <Grid container spacing={3} style={{ marginTop: '0.5px' }}>
            <Grid item xs={11}>
              <TextField
                fullWidth
                multiline
                rows={3}
                id='treatmentPlan'
                label='Treatment Plan'
                value={treatmentPlan}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                multiline
                rows={3}
                id='followUpAdvice'
                label='Follow Up Advice'
                value={followUpAdvice}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type='date'
                id='followUpDate'
                label='Follow Up Date'
                value={followUpDate}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                multiline
                rows={4}
                id='homeAdvice'
                label='Home Advice'
                value={homeAdvice}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl component='fieldset' className='font-12'>
                <FormLabel component='legend' className='f-05rem'>
                  Referral Required
                </FormLabel>
                <RadioGroup
                  row
                  aria-label='referralRequired'
                  name='referralRequired'
                  value={referralRequired}
                >
                  <FormControlLabel
                    value='yes'
                    control={<Radio size='small' className='f-05rem' />}
                    label='yes'
                    disabled
                  />
                  <FormControlLabel
                    value='no'
                    control={<Radio size='small' />}
                    label='No'
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {referralRequired == 'yes' && (
            <Grid
              container
              spacing={3}
              style={{ marginTop: '0.5px', paddingBottom: '10px' }}
            >
              <Grid item xs={11}>
                <TextField
                  disabled
                  fullWidth
                  // multiline
                  rows={4}
                  id='Doctor name'
                  label='Doctor Name'
                  value={doctorName?.split('::')?.[1]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  disabled
                  fullWidth
                  // multiline
                  rows={4}
                  id='Department'
                  label='Department'
                  value={department}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <TextField
                  disabled
                  fullWidth
                  // multiline
                  rows={4}
                  id='Reason for Referral'
                  label='Reason for Referal'
                  value={reasonReferral}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </div>
  )
}

export default IpdTreatmentPlanForm
