import React from 'react'
import { AppointmentData, setSelectedPatient } from '../appointmentsSlice'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../app/hooks'
import KeycloakService from '../../../utils/keycloakService'
import { CREATE_VISIT, HISTORY_READ } from '../../../utils/roles'
import { startSxpProxy } from '../../../utils/api'
import { OPD_PROJECT_ID } from '../../../utils/constants'
import { dental, general, getEncounter, physio, visitType } from '../models'

type Props = {
  appointment: AppointmentData
}

const VisitLink = ({ appointment }: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleVisitClick = () => {
    dispatch(setSelectedPatient(appointment.patientId))
    if (appointment.encounter) {
      startSxpProxy(OPD_PROJECT_ID, getEncounter, {
        encounterId: appointment.encounter,
      }).then((data) => {
        const appointmentType = data?.data?.data?.type?.[0]?.coding?.find(
          (e: any) => e.code === visitType
        )?.display
        if (appointmentType === general || !appointmentType) {
          if (KeycloakService.hasRole([HISTORY_READ])) {
            navigate(`/visits/${appointment.encounter}/history`)
          }
        }
        if (appointmentType === physio) {
          if (KeycloakService.hasRole([HISTORY_READ])) {
            navigate(`/visits/${appointment.encounter}/physio/historyPhysio`)
          }
        }
        if (appointmentType === dental) {
          if (KeycloakService.hasRole([HISTORY_READ])) {
            navigate(`/visits/${appointment.encounter}/dental/historyDental`)
          }
        }
      })
    } else {
      if (KeycloakService.hasRole([CREATE_VISIT])) {
        navigate(`${appointment.id}/create-visit`)
      }
    }
  }

  const hasAccess =
    (appointment.encounter && KeycloakService.hasRole([HISTORY_READ])) ||
    (!appointment.encounter && KeycloakService.hasRole([CREATE_VISIT]))

  return (
    <button
      title={
        appointment.encounter ? 'Click to View Visit' : 'Click to Create Visit'
      }
      className={`visitLink ${hasAccess ? '' : 'auth-disabled'}`}
      onClick={() => handleVisitClick()}
    >
      Visit
    </button>
  )
}

export default VisitLink
