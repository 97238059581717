import { LabOrder } from '../../../labTests/models'
import { readableDateFormat } from '../../../../utils/dateUtils'
import { getLabTestCount } from '../../utils'

type Props = {
  mode: 'ordered' | 'collected' | 'completed'
  orders: LabOrder[]
  onOrderView: (id: string) => void
  onOrderCreate?: () => void
}

const SearchTable = ({ mode, orders, onOrderView }: Props) => {
  return (
    <div className='mt20'>
      <p className='collect-header'>
        {mode === 'ordered'
          ? 'Orders to Collect'
          : mode === 'collected'
          ? 'Orders to Test/Dispatch'
          : 'Completed Orders'}
      </p>
      <table className='admin-table'>
        <thead>
          <tr>
            <th>Source</th>
            <th>Ordered By</th>
            <th>Ordered On</th>
            <th>Tests</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {orders.length > 0 ? (
            orders.map((o) => (
              <tr key={o.id}>
                <td>{o.source}</td>
                <td>{o.ordered_by_name}</td>
                <td>
                  {o.ordered_on
                    ? readableDateFormat(new Date(o.ordered_on))
                    : '-'}
                </td>
                <td>{getLabTestCount(o)}</td>
                <td>
                  <button
                    onClick={() => onOrderView(o.id ?? '')}
                    className='lms-btn'
                  >
                    View
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className='text-center' colSpan={5}>
                No Orders found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default SearchTable
