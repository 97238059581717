import React, { useState } from 'react'

type Props = {
  handleCancel: (r: string) => void
  handleClose: () => void
}

const CancelModal = ({ handleCancel, handleClose }: Props) => {
  const [reason, setReason] = useState<string>('')
  const [error, setError] = useState<string>('')
  const handleClick = () => {
    if (!reason) {
      setError('Reason is required')
      return
    }
    handleCancel(reason)
  }

  return (
    <>
      <div className='overlay'></div>
      <div className='modalPopup'>
        <h4>Cancel Appointment</h4>
        <div className='innerModal'>
          <textarea
            style={{ marginTop: 20, width: 260, height: 125 }}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder='Enter the reason for cancelling'
          ></textarea>
          {error && (
            <div style={{ fontSize: 12, fontStyle: 'italic', color: 'red' }}>
              {error}
            </div>
          )}
          <div className='text-center'>
            <button className='btn okBtn' onClick={handleClick}>
              Cancel
            </button>
            <button className='btn close' onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CancelModal
