import KeycloakService from '../../../utils/keycloakService'
import PrintLogo from '../../../assets/images/print.png'
import { VALIDATION_WRITE } from '../../../utils/roles'
import { startSxpProxy } from '../../../utils/api'
import { LABS_PROJECT_ID } from '../../../utils/constants'
import { formatInvoiceData } from '../../lms/utils'
import { InvoiceData } from '../../lms/models'
import { pdf } from '@react-pdf/renderer'
import InvoicePdf from '../../lms/components/dashboard/InvoicePdf'
import { fullDateTimeFormat } from '../../../utils/dateUtils'
import { formatPatient } from '../utils'
type labTests = {
  description: string
  id: number
  name: string
  panelId: number
  panelName: string
  sampleName: string
  sampleId: number
}
type Panels = {
  description: string
  id: number
  lab_tests: labTests[]
  name: string
  sampleId: number
  sampleName: string
}

type labTestsType = {
  origin: string
  lab_tests: any
  patientUhid: any
  patientThopId: any
  patient_id: any
  requested_by: any
  ordered_by_name: any
  summary: string
  ordered_on: string
  lr_number: string
  status: string
  validation_completed_on: string
  collection_completed_on: string
  id: number
  source: string
  panels: Panels[]
  patientId: string
}

const DashboardCard = (props: any) => {
  const { data, cardType, patientData } = props

  let allLabTests: labTestsType[] = []
  if (data?.length) {
    if (cardType === 'radiology') {
      allLabTests = JSON.parse(data?.[0].test_names)
    }
    if (cardType === 'labOrders') {
      const filteredData = data?.filter((e: any) =>
        e?.order_events?.[0]?.tests.some(
          (test: any) => test.status === 'APPROVED'
        )
      )
      allLabTests = filteredData
    }
  }

  return (
    <div className='mainCard'>
      <h3>{props.title}</h3>
      <div className='cardContent'>
        {allLabTests?.length ? (
          <table className='data-table'>
            <thead>
              <tr>
                <th>Id</th>
                <th className='text-left'>Tests</th>
                <th>source</th>
                <th>completed on</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {allLabTests.length ? (
                allLabTests.map((d: labTestsType) => (
                  <SingleCard item={d} key={d.id} patientData={patientData} />
                ))
              ) : (
                <tr>
                  <td className='text-center' colSpan={5}>
                    No orders found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <p style={{ padding: '6px', fontSize: '14px', textAlign: 'center' }}>
            ~No {props.title} found~
          </p>
        )}
      </div>
    </div>
  )
}

export default DashboardCard

const SingleCard = ({
  item,
  patientData,
}: {
  item: labTestsType
  patientData: any
}) => {
  const patients = formatPatient(patientData)

  const handleInvoicePrint = () => {
    if (!KeycloakService.hasRole([VALIDATION_WRITE])) {
      return
    }
    const intent = 'getLabTestsByIds'
    const panelIds =
      item.panels?.flatMap((ip: any) =>
        ip.lab_tests.map((ipl: any) => ipl.id)
      ) ?? []
    const testIds = item.lab_tests?.map((il: any) => il.id) ?? []
    const state = {
      orderId: item.id,
      ids: [...panelIds, ...testIds],
    }
    startSxpProxy(LABS_PROJECT_ID, intent, state)
      .then(async (data) => {
        const panel = formatInvoiceData(
          data.data.lab_test ?? [],
          item.status ?? ''
        )
        const obj: InvoiceData = {
          id: item.patientUhid ?? item.patientThopId ?? item.patient_id ?? '',
          name: patients?.name,
          age: patients?.age,
          gender: patients?.gender,
          mobile: patients?.mobile ?? '',
          lrNumber: item.lr_number ?? '',
          orderedOn: item.ordered_on ?? '',
          completedOn: item.collection_completed_on ?? '',
          requestedBy: item.requested_by ?? item.ordered_by_name ?? '',
          validatedOn: item.validation_completed_on ?? '',
          panels: panel,
          summary: item.summary ?? '',
          orderType: item?.origin,
        }
        const doc = await pdf(<InvoicePdf data={obj} />).toBlob()
        const fileUrl = URL.createObjectURL(doc)

        const iframe = document.createElement('iframe')
        iframe.src = fileUrl
        iframe.width = '100%'
        iframe.height = '100%'
        iframe.style.border = 'none'

        const newWindow = window.open('', '_blank') as Window
        newWindow.document.body.style.margin = '0'
        newWindow.document.body.appendChild(iframe)
        newWindow.document.title = 'Test Results'
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <tr key={item.id}>
      <td className='text-center'>{item?.id}</td>
      <td className='text-left'>
        {(item?.panels?.map((ip) => ip.name) ?? []).join(', ')}
      </td>
      <td className='text-center'>{item?.source}</td>
      <td className='text-center'>
        {fullDateTimeFormat(new Date(item?.collection_completed_on ?? ''))}
      </td>
      <td className='text-center'>
        <button
          title='Print Invoice'
          className={
            KeycloakService.hasRole([VALIDATION_WRITE]) ? '' : 'auth-disabled'
          }
          onClick={handleInvoicePrint}
        >
          <img src={PrintLogo} alt='Print Invoice' />
        </button>
      </td>
    </tr>
  )
}
