import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsFormMode,
  selectedLmsItem,
  setLmsFormMode,
} from '../../lmsSlice'
import AdminConfig from './AdminConfig'
import AdminForm from './AdminForm'
import AdminTable from './AdminTable'
import Packages from './Packages'
import {
  LABS_PROJECT_ID,
  RADIOLOGY_PROJECT_ID,
  IPD_PROJECT_ID,
} from '../../../../utils/constants'

const LmsAdmin = () => {
  const currentPath = window.location.pathname
  const splitRadiology = currentPath.split('/')[2]
  const item = useAppSelector(selectedLmsItem)
  const mode = useAppSelector(selectedLmsFormMode)
  const dispatch = useAppDispatch()

  const handleJourneyEnd = () => {
    dispatch(setLmsFormMode('none'))
  }
  const projectId =
    splitRadiology === 'radiology'
      ? RADIOLOGY_PROJECT_ID
      : splitRadiology === 'bedManagementAdmin'
      ? IPD_PROJECT_ID
      : LABS_PROJECT_ID
  return (
    <section>
      <AdminConfig splitRadiology={splitRadiology} />
      {mode === 'none' ? (
        <AdminTable projectId={projectId} />
      ) : item === 'packages' ? (
        <Packages />
      ) : (
        <AdminForm projectId={projectId} onJourneyEnd={handleJourneyEnd} />
      )}
    </section>
  )
}

export default LmsAdmin
