import { ChangeEvent } from 'react'

type Props = {
  origin: any
  onChange: (ev: ChangeEvent<HTMLSelectElement>) => void
}
const Origin = ({ origin, onChange }: Props) => {
  return (
    <select className='requested-by' value={origin} onChange={onChange}>
      <option value=''>Select an origin option</option>
      <option value='OPD'>OPD</option>
      <option value='IPD'>IPD</option>
    </select>
  )
}
export default Origin
