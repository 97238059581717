import React from 'react'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID, CHAT_PROJECT_ID } from '../../../utils/constants'
import { useAppSelector } from '../../../app/hooks'
import { selectSelectedLocationId } from '../../location/locationSlice'
import { odooPharmacyLink } from '../models'

type Props = {
  status: string
  thopId: string
}

const BillingLink = ({ status, thopId }: Props) => {
  const locationId = useAppSelector(selectSelectedLocationId)
  const handleClick = () => {
    startSxpProxy(CHAT_PROJECT_ID, 'unpaidAppointments', {
      id: thopId,
      selectedLocationId: locationId,
    })
      .then((data) => {
        const id = data.data?.[0]?.id
        if (!id) {
          alert('Invoice not available. Please contact Admin')
          return
        }
        startSxpProxy(ADMIN_PROJECT_ID, odooPharmacyLink, {
          invoiceId: id,
          location: locationId,
        })
          .then((data) => {
            const url = data?.data?.auth_link
            window.open(url, '_blank', 'noopener,noreferrer')
          })
          .catch((err) => console.error(err, 'err'))
      })
      .catch((err) => console.error(err))
  }

  return (
    <button
      style={{
        fontSize: 11,
        padding: '3px 6px',
        marginLeft: 4,
        border: 'none',
        borderRadius: 4,
        backgroundColor: status === 'proposed' ? 'red' : '#c3d7cb',
        color: status === 'proposed' ? 'white' : 'black',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      {status === 'proposed' ? 'Unpaid' : 'Paid'}
    </button>
  )
}

export default BillingLink
