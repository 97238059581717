import React from 'react'
import { LabOrder } from '../../../labTests/models'
import { readableDateFormat } from '../../../../utils/dateUtils'
import {
  emptyString,
  modes,
  noData,
  noOrdersFound,
  tableHeadText,
  view,
} from '../../constants'

type Props = {
  mode: 'ordered' | 'collected' | 'completed'
  orders: LabOrder[]
  onOrderView: (id: string) => void
  onOrderCreate?: () => void
}

const RadiologySearchTable = ({ mode, orders, onOrderView }: Props) => {
  return (
    <div className='mt20'>
      <p className='collect-header'>
        {mode === modes.ordered
          ? modes.ordersToCollect
          : mode === modes.collected
          ? modes.ordersToDispatch
          : modes.completedOrders}
      </p>
      <table className='admin-table'>
        <thead>
          <tr>
            <th>{tableHeadText.source}</th>
            <th>{tableHeadText.orderedBy}</th>
            <th>{tableHeadText.orderedOn}</th>
            <th>{tableHeadText.tests}</th>
            <th>{tableHeadText.category}</th>
            <th>{tableHeadText.actions}</th>
          </tr>
        </thead>
        <tbody>
          {orders.length > 0 ? (
            orders.map((o) => (
              <tr key={o.id}>
                <td>{o.source}</td>
                <td>{o.ordered_by_name}</td>
                <td>
                  {o.ordered_on
                    ? readableDateFormat(new Date(o.ordered_on))
                    : noData}
                </td>
                <td>{o.radiology_lab_tests?.length}</td>
                <td>{o.radiology_lab_tests?.[0]?.sampleName}</td>
                <td>
                  <button
                    onClick={() => onOrderView(o.id ?? emptyString)}
                    className='lms-btn'
                  >
                    {view}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className='text-center' colSpan={5}>
                {noOrdersFound}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default RadiologySearchTable
