import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Location } from 'fhir/r4'
import { RootState } from '../../app/store'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID } from '../../utils/constants'

export type LocationData = {
  resource: Location
}

export type AppLocation = {
  cluster: string | number | readonly string[] | undefined
  type: string | number | readonly string[] | undefined
  gst: any
  dlNumber: string | number | readonly string[] | undefined
  id: string
  name: string
  value: string
  prefix: string
  ipPrefix: string
  address: string
  email: string
  phone: string
  status: string
  ipd: string | number | readonly string[] | undefined
  pin: string
  StreetNo: any
  street: any
  city: string
  state: string
  code: any
  hasInventory: string | number | readonly string[] | undefined
}

export const fetchLocationsAsync = createAsyncThunk(
  'locations/fetchLocations',
  async () => {
    const intent = 'getLocationsAPI'
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent)
    return response.data
  }
)

export const getLocationData = (data: LocationData): AppLocation => {
  const id = data?.resource?.id ?? ''
  const locationName = data?.resource?.name ?? ''
  const locationValue =
    data?.resource?.identifier?.find((dri) => dri.system === 'thop-identifier')
      ?.value ?? ''
  const locationPrefix =
    data?.resource?.identifier?.find((dri) => dri.system === 'prefix')?.value ??
    ''
  const locationIpPrefix =
    data?.resource?.identifier?.find((dri) => dri.system === 'ipPrefix')
      ?.value ?? ''
  const address = data?.resource?.address?.line?.join(', ') ?? ''
  const email =
    data?.resource?.telecom?.find((drt) => drt.system === 'email')?.value ?? ''
  const phone =
    data?.resource?.telecom?.find((drt) => drt.system === 'phone')?.value ?? ''
  const status = data?.resource?.status ?? 'active'
  const dlNumber = data?.resource?.type?.[0].coding?.[0]?.display ?? ''
  const gst = data?.resource?.type?.[0].coding?.[1]?.display ?? ''
  const cluster = data?.resource?.type?.[0].coding?.[5]?.display ?? ''
  const type = data?.resource?.type?.[0].coding?.[2]?.display ?? ''
  const code = data?.resource?.type?.[0].coding?.[4]?.display ?? ''
  const hasInventory = data?.resource?.type?.[0].coding?.[3]?.display ?? ''
  const ipd = ''
  const StreetNo =
    data?.resource?.address?.line?.join(', ')?.split(',')[0] ?? ''
  const street = data?.resource?.address?.line?.join(', ')?.split(',')[1] ?? ''
  const city = data?.resource?.address?.line?.join(', ')?.split(',')[2] ?? ''
  const pin = data?.resource?.address?.line?.join(', ')?.split(',')[4] ?? ''
  const state = data?.resource?.address?.line?.join(', ')?.split(',')[3] ?? ''

  return {
    id: id,
    name: locationName,
    value: locationValue,
    prefix: locationPrefix,
    ipPrefix: locationIpPrefix,
    address,
    email,
    phone,
    status,
    gst,
    dlNumber,
    cluster,
    type,
    hasInventory,
    pin,
    StreetNo,
    street,
    city,
    state,
    ipd,
    code,
  }
}

export type LocationSliceData = {
  status: 'loading' | 'success' | 'error'
  data: LocationData[]
  teleServiceId: string
  selectedLocationId: string
}

const initialState: LocationSliceData = {
  status: 'loading',
  data: [],
  teleServiceId: '',
  selectedLocationId: '',
}

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocations: (state, action: PayloadAction<LocationData[]>) => {
      state.data = action?.payload
    },
    setTeleServiceId: (state, action: PayloadAction<string>) => {
      state.teleServiceId = action?.payload
    },
    setSelectedLocation: (state, action: PayloadAction<string>) => {
      state.selectedLocationId = action?.payload
    },
  },
  extraReducers: (builder) => {
    builder?.addCase(fetchLocationsAsync?.fulfilled, (state, action) => {
      const locs: LocationData[] = action?.payload?.entry ?? []
      locs.sort((a, b) => {
        if (!a?.resource?.id) {
          return -1
        }
        if (!b?.resource?.id) {
          return 1
        }
        return parseInt(a?.resource?.id) < parseInt(b?.resource?.id) ? -1 : 1
      })
      state.data = locs
    })
  },
})

export const { setLocations, setTeleServiceId, setSelectedLocation } =
  locationSlice.actions

export const selectLocations = (state: RootState) => state?.location?.data
export const selectSelectedLocationId = (state: RootState) =>
  state?.location?.selectedLocationId
export const selectLocationById = (
  id: string,
  state: RootState
): LocationData | undefined =>
  state?.location?.data?.find((sld) => sld?.resource?.id === id)

export default locationSlice.reducer
