import React from 'react'
import UnKnownLogo from '../assets/images/search.png'
import { useNavigate } from 'react-router-dom'

const UnKnown = () => {
  const navigate = useNavigate()

  return (
    <div className='center-layout'>
      <img src={UnKnownLogo} alt='404 Not Found' />
      <p className='unauthorized'>
        Sorry, The Page you are looking for does not exist
      </p>
      <button className='main-btn' onClick={() => navigate('/home')}>
        Go Home
      </button>
    </div>
  )
}

export default UnKnown
