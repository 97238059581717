import React from 'react'
import { allocation, tableHeadText } from '../bedManagement/modals'
import { Button, Table, TableHead, TableRow } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setPatientDetails } from './IpdPatientRegisterSlice'
import { useAppSelector } from '../../app/hooks'
import { selectSelectedLocationId } from '../location/locationSlice'
import { KIMS_LOCATION_ID } from '../../utils/constants'

const PatientTable = ({ data }: any) => {
  const dispatch = useDispatch()
  const locationId = useAppSelector(selectSelectedLocationId)

  const handleRecordClick = async (record: any) => {
    await dispatch(setPatientDetails({ patientDetails: record }))
  }

  return (
    <>
      <div className='sub-container'>
        <div className='bedTableHead'>
          <Table className='data-table table-fixed admin-table relative w100'>
            <TableHead className='sticky'>
              <TableRow>
                <th className='text-center w12'>
                  {locationId === KIMS_LOCATION_ID
                    ? 'MR Number'
                    : tableHeadText.uhid}
                </th>
                <th className='text-left w15'>{tableHeadText.name}</th>
                <th className='text-center w10'>{tableHeadText.mobileNo}</th>
                <th className='text-center w10'>{tableHeadText.gender}</th>
                <th className='text-center w10'>{tableHeadText.age}</th>
                <th className='text-center w10'>Actions</th>
              </TableRow>
            </TableHead>
            <tbody>
              {data?.length > 0 ? (
                data?.map((d: any, i: number) => {
                  return (
                    <TableRow key={i}>
                      <td className='text-center'>
                        {locationId === KIMS_LOCATION_ID
                          ? d?.mrNumber || allocation?.hyphen
                          : d?.muhId || allocation?.hyphen}
                      </td>
                      <td className='text-left'>{d?.name}</td>
                      <td className='text-center'>
                        {d?.phoneNumber ?? allocation?.hyphen}
                      </td>
                      <td className='text-center'>
                        {d?.gender ?? allocation?.hyphen}
                      </td>
                      <td className='text-center'>
                        {d?.age ?? allocation?.hyphen}
                      </td>
                      <td className='text-center'>
                        <Button
                          variant='contained'
                          className='ip-register-btn'
                          size='small'
                          onClick={() => handleRecordClick(d)}
                        >
                          Select
                        </Button>
                      </td>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <td className='text-center' colSpan={6}>
                    No Patient Data
                  </td>
                </TableRow>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  )
}
export default PatientTable
