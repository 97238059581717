/* eslint-disable camelcase */
import { ChangeEvent, useEffect, useState } from 'react'
import PatientInfo from '../../../lms/components/PatientInfo'
import { Patient } from 'fhir/r4'
import { useNavigate, useParams } from 'react-router-dom'
import BackButton from '../../../../assets/images/chevron_left.png'
import RequestedBy from '../../../labTests/components/RequestedBy'
import Origin from '../../../labTests/components/Origin'
import {
  OrderEvent,
  RadiologyLabOrder,
  LabMode,
  intent,
  emptyString,
  radiologyMessage,
  goBackString,
  backToSamples,
  modes,
  selectPackage,
  selectTests,
  category,
  changeMode,
  externalRequest,
  noPatientsFound,
} from '../../constants'
import { startSxpProxy } from '../../../../utils/api'
import { saveOrder } from '../../utils'
import { useAppSelector } from '../../../../app/hooks'
import {
  OPD_PROJECT_ID,
  RADIOLOGY_PROJECT_ID,
} from '../../../../utils/constants'
import KeycloakService from '../../../../utils/keycloakService'
import {
  selectSelectedLocationId,
  selectLocationById,
} from '../../../location/locationSlice'
import PackageTests from '../../../labTests/PackageTests'
import { toast } from 'react-toastify'
import ToastMessage from '../../../lms/components/ToastMessage'
import { toastOptions } from '../../../lms/models'
import { prepareOrderEventTests } from '../../../lms/utils'
import { useDispatch } from 'react-redux'
import RadiologyTests from '../../RadiologyTests'
import {
  setRadiologyDateFilter,
  setRadiologyActiveTab,
} from '../../RadiologySlice'

const CreateRadiologyOrder = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [requestedBy, setRequestedBy] = useState('')
  const [mode, setMode] = useState<LabMode>('')
  const [order, setOrder] = useState<RadiologyLabOrder>({})
  const [origin, setCategoryType] = useState('')
  const [patient, setPatient] = useState<Patient | null>(null)
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  useEffect(() => {
    startSxpProxy(RADIOLOGY_PROJECT_ID, intent.getRadiologyOrdersByPatientId, {
      patientId: id,
    })
      .then((data) => {
        const details = data.data?.radiology_orders?.[0] ?? {}
        setOrder(details)
        setRequestedBy(details.requested_by)
        if (details?.package_id) {
          setMode('package')
        } else {
          setMode('test')
        }
      })
      .catch((err) => {
        console.log(err)
      })
    startSxpProxy(OPD_PROJECT_ID, intent.getPatientApi, { id: id })
      .then((data) => {
        setPatient(data.data ?? null)
      })
      .catch((err) => {
        console.log(err)
        setPatient(null)
      })
  }, [id])

  const goBack = () => {
    navigate('/radiology/search')
  }
  const handleRequestedByChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    setRequestedBy(ev.target.value)
  }
  const handleCategoryByChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    setCategoryType(ev.target.value)
  }
  const handleModeChange = (m: LabMode) => {
    if (order.id) {
      const switchMode = confirm(changeMode)
      if (switchMode) {
        saveOrder(
          'patient',
          order,
          id ?? emptyString,
          id ?? emptyString,
          '',
          [],
          [],
          location?.resource.name ?? '',
          0,
          0
        ).then((data: any) => {
          const stateObj: Partial<OrderEvent> = {
            updated_by: KeycloakService.getUsername(),
            order_id: order.id,
            type: 'ORDER_MODIFIED',
            tests: [],
          }
          const updated = data.data?.update_radiology_orders?.returning?.[0]
          setOrder(updated)
          startSxpProxy(RADIOLOGY_PROJECT_ID, intent.createRadiologyEvent, {
            event: stateObj,
          }).then(() => {
            setMode(m)
          })
        })
      }
    } else {
      setMode(m)
    }
  }
  const handlePackageSave = (
    packageId: number,
    price: number,
    panels: any,
    labTests: any
  ) => {
    if (!requestedBy) {
      alert(externalRequest)
      return
    }
    saveOrder(
      'patient',
      order,
      id ?? emptyString,
      id ?? emptyString,
      '',
      panels,
      labTests,
      location?.resource.name ?? emptyString,
      packageId,
      price,
      requestedBy,
      origin
    ).then(() => {
      const stateObj: Partial<OrderEvent> = {
        updated_by: KeycloakService.getUsername(),
        order_id: order.id,
        type: 'ORDER_MODIFIED',
        tests: prepareOrderEventTests(panels, labTests, 'ORDER_MODIFIED'),
      }
      startSxpProxy(RADIOLOGY_PROJECT_ID, intent.createRadiologyEvent, {
        event: stateObj,
      }).then(() => {
        toast(<ToastMessage message={radiologyMessage} />, {
          ...toastOptions,
          type: 'success',
        })
        setTimeout(() => {
          dispatch(
            setRadiologyDateFilter(new Date().toISOString().slice(0, 10))
          )
          dispatch(setRadiologyActiveTab('testing'))
          navigate('/radiology/dashboard')
        }, 1500)
      })
    })
  }
  const handleSave = () => {
    toast(<ToastMessage message={radiologyMessage} />, {
      ...toastOptions,
      type: 'success',
    })
    setTimeout(() => {
      dispatch(setRadiologyDateFilter(new Date().toISOString().slice(0, 10)))
      dispatch(setRadiologyActiveTab('testing'))
      navigate('/radiology/dashboard')
    }, 1500)
  }

  return (
    <div>
      {patient ? <PatientInfo patient={patient} /> : null}
      <button onClick={goBack} className='back-btn'>
        <img src={BackButton} alt={goBackString} />
        {backToSamples}
      </button>

      {patient ? (
        <div>
          {category}
          <div className='samples'>
            <div className='radio-container'>
              <input
                type='radio'
                value='package'
                checked={mode === modes.package}
                onChange={() => handleModeChange('package')}
                name={modes.mode}
                id='modePackage'
              />
              <label className='text-small' htmlFor='modePackage'>
                {selectPackage}
              </label>
              <input
                type='radio'
                value='test'
                checked={mode === modes.test}
                onChange={() => handleModeChange('test')}
                name={modes.mode}
                id='modeTest'
              />
              <label className='text-small' htmlFor='modeTest'>
                {selectTests}
              </label>
            </div>
            <RequestedBy
              requestedBy={requestedBy}
              onChange={handleRequestedByChange}
            />
            <Origin origin={origin} onChange={handleCategoryByChange} />
          </div>

          {mode === modes.package && (
            <PackageTests
              id={order.package_id ?? 0}
              status={order.status ?? emptyString}
              onSave={handlePackageSave}
            />
          )}
          {mode === modes.test && (
            <div className='lab-container'>
              <RadiologyTests
                id={id ?? emptyString}
                patientId={id ?? emptyString}
                order={order}
                mode='patient'
                onSave={handleSave}
                requestedBy={requestedBy}
                origin={origin}
              />
            </div>
          )}
        </div>
      ) : (
        <p>{noPatientsFound}</p>
      )}
    </div>
  )
}

export default CreateRadiologyOrder
