import React, { ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectSlotsSelectedService,
  selectSlotsServices,
  setSelectedSlotsService,
} from '../slotsSlice'

const SelectService = () => {
  const services = useAppSelector(selectSlotsServices)
  const selectedService = useAppSelector(selectSlotsSelectedService)
  const dispatch = useAppDispatch()

  const onServiceChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedSlotsService(e.target.value))
  }

  return (
    <select
      className='doctorSelect'
      value={selectedService}
      onChange={onServiceChange}
    >
      <option value=''>Select a Service</option>
      {services.map(({ resource }) => (
        <option key={resource.id} value={resource.id}>
          {resource.name}
        </option>
      ))}
    </select>
  )
}

export default SelectService
