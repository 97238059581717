import React from 'react'

type Props = {
  title: string
  onClick: () => void
}

const CreateButton = ({ title, onClick }: Props) => {
  return (
    <button className='base-button create-button' onClick={onClick}>
      {title}
    </button>
  )
}

export default CreateButton
