import { useEffect, useRef } from 'react'
import { SXP_CHAT } from '../../utils/roles'
import KeycloakService from '../../utils/keycloakService'
import { CHAT_PROJECT_ID } from '../../utils/constants'
import { sendSxpMessage } from '../../utils/api'
import Appointments from './Appointments'

const CreateAppointment = () => {
  const isInitialRender = useRef(true)
  const fetchData = async () => {
    if (KeycloakService.hasRole([SXP_CHAT])) {
      await sendSxpMessage(CHAT_PROJECT_ID, '::restart')
      window.chatSendMsg('book', KeycloakService.getUsername())
    }
  }
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    fetchData()
  }, [])
  return (
    <>
      <Appointments />
    </>
  )
}
export default CreateAppointment
