import React, { useState } from 'react'

const HistoryExaminationCard = (props: any) => {
  return (
    <div className='mainCard'>
      <h3>{props.title}</h3>
      <div className='cardContent'>
        {props.data.length ? (
          props.data.map((d: any) => <SingleCard key={d.id} d={d} />)
        ) : (
          <p style={{ padding: '6px', fontSize: '14px', textAlign: 'center' }}>
            ~No {props.title} found~
          </p>
        )}
      </div>
    </div>
  )
}

export default HistoryExaminationCard

const SingleCard = ({ d }: { d: any }) => {
  const [show, setShow] = useState<boolean>(true)
  return (
    <div className={`singleCard ${show ? 'activeExpanded' : ''}`}>
      <div className='singleCardLabel' onClick={() => setShow((sh) => !sh)}>
        {/* <h6><span><img src={CarotIcon} /></span> {d.expandTitle}</h6> */}
        <p className='status_by_on'>{d.updateon}</p>
      </div>
      <div className='expandingCard'>
        <div className='expandedContent'>
          <div className='tableContent'>
            <p>{d.code.coding[0].display}</p>
            <p>{d.valueString ? d.valueString : '-'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
