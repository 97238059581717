export const getRemarks = (e: any) => {
  if (e?.length === 0) {
    return ''
  } else {
    return e?.[0]?.remarks ?? ''
  }
}
export const getDoctorName = (e: any) => {
  if (e?.length === 0) {
    return ''
  } else {
    return e?.[0]?.assigned_doctor ?? ''
  }
}
