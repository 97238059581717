import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import {
  CHAT_PROJECT_ID,
  LABS_PROJECT_ID,
  OPD_PROJECT_ID,
} from '../../../utils/constants'
import DashboardCard from './card'
import './card.scss'
import DateCarouselSelector from './dateCarouselSelector'
import HistoryExaminationCard from './historyExaminationCard'
import PatientProfileCard from './patientProfileCard'
import AppointmentCard from './upcomingAppointments'
import UploadDocs from './UploadDocs'

type appointmentType = {
  id: number
  date: string
  desc: string
}

export type upComingAppointmentType = {
  data: string
  docName: string
  service: string
  time: string
}

const PatientDashboard = () => {
  const { id } = useParams()
  const [selectedDate, setSelectedDate] = useState(-1)
  const [visitsList, setVisitList] = useState<appointmentType[]>([])
  const [visitsDateList, setVisitsDateList] = useState<appointmentType[]>([])
  const [drInfo, setDrInfo] = useState({})
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    upComingAppointmentType[]
  >([])
  const [patientData, setPatientData] = useState({})
  const [historyList, setHistoryList] = useState<any>([])
  const [examinationList, setExaminationList] = useState<any>([])
  const [vitalsList, setVitalsList] = useState<any>([])
  const [diagnosisList, setDiagnosisList] = useState<any>([])
  const [labOrderList, setLabOrderList] = useState<any>([])
  const [radiologyList, setRadiologyList] = useState<any>([])
  const [isOpenDocsPage, setIsOpenDocsPage] = useState(false)
  const [medications, setMedications] = useState<any>([])
  useEffect(() => {
    const intent = 'getPatientVisits'
    const patientIntent = 'getPatientApi'
    const state = {
      id: id,
    }

    const fetchUpComingAppointments = async () => {
      try {
        const intent = 'upcomingAppointments'
        const state = {
          id: id,
        }
        const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
        setUpcomingAppointments(resp.data)
      } catch (e) {
        console.log(e)
      }
    }

    startSxpProxy(OPD_PROJECT_ID, intent, state)
      .then((res) => {
        if (res.data.entry) {
          setVisitList(res.data.entry)
          getVisitsListDates(res.data.entry)
          setSelectedDate(0)
          fetchExaminations(res.data.entry[0].resource.id)
          fetchMedication(res.data.entry[0].resource.id)
          fetchHistory(res.data.entry[0].resource.id)
          fetchVitals(res.data.entry[0].resource.id)
          fetchDiagnosis(res.data.entry[0].resource.id)
          fetchRadiologyOrders(res?.data?.entry?.[0]?.resource?.id)
          getDrDetails(
            res.data.entry[0].resource.appointment?.[0]?.reference,
            res.data.entry
          )
        } else {
          setVisitList([])
        }
      })
      .catch((e) => console.log(e))

    startSxpProxy(OPD_PROJECT_ID, patientIntent, state)
      .then((res1) => {
        setPatientData(res1.data)
        fetchUpComingAppointments()
      })
      .catch((f) => console.log(f))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const getVisitsListDates = async (visitedData: any) => {
    if (visitedData) {
      const visitedDates = await visitedData?.filter(
        (elm: any) => elm?.resource?.resourceType === 'Encounter'
      )
      setVisitsDateList(visitedDates)
    }
  }

  const getDrDetails = (appointmentId: string, dataList: any = visitsList) => {
    const drInfoData = {
      drName: [],
      department: '',
      date: '',
      drRegistrationNo: '',
      designation: '',
      signature: '',
    }

    const appointment = dataList.find((item: any) =>
      item.fullUrl.includes(appointmentId)
    )
    if (appointment) {
      const slotId = appointment.resource.slot?.[0].reference
      const slot = dataList.find((item: any) => item.fullUrl.includes(slotId))
      if (slot) {
        const scheduleId = slot.resource.schedule.reference
        const schedule = dataList.find((item: any) =>
          item.fullUrl.includes(scheduleId)
        )
        if (schedule) {
          drInfoData.date = slot.resource.start
          const drId = schedule.resource.actor.find((actor: any) =>
            actor.reference.includes('Practitioner')
          )
          if (drId) {
            const dr = dataList.find((item: any) =>
              item.fullUrl.includes(drId.reference)
            )
            if (dr) {
              drInfoData.drName = dr.resource.name ?? ''
              drInfoData.designation =
                dr.resource?.qualification?.[0]?.code?.text ?? ''
              drInfoData.drRegistrationNo =
                dr.resource?.qualification?.[0]?.identifier?.[0]?.value ?? ''
              drInfoData.signature = dr.resource?.photo?.[1]?.url ?? ''
            }
          }
        }
      }
      drInfoData.department =
        appointment.resource.specialty[0].coding[0].display
    }
    if (drInfoData) {
      setDrInfo(drInfoData)
    }
  }

  const fetchExaminations = async (resourceId: any) => {
    try {
      const intent = 'getOPDObservations'
      const state = {
        encounterId: resourceId,
        type: 'examination',
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.entry) {
        setExaminationList(resp.data.entry[0].resource.component)
      } else {
        setExaminationList([])
      }
    } catch (e) {
      console.error(e)
    }
  }
  const fetchHistory = async (resourceId: any) => {
    try {
      const intent = 'getOPDObservations'
      const state = {
        encounterId: resourceId,
        type: 'history',
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.entry) {
        setHistoryList(resp.data.entry[0].resource.component)
      } else {
        setHistoryList([])
      }
    } catch (e) {
      console.log(e)
    }
  }
  const fetchVitals = async (resourceId: any) => {
    try {
      const intent = 'getOPDObservations'
      const state = {
        encounterId: resourceId,
        type: 'vitals',
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.entry) {
        setVitalsList(resp.data.entry[0].resource.component)
      } else {
        setVitalsList([])
      }
    } catch (e) {
      console.log(e)
    }
  }
  const fetchDiagnosis = async (resourceId: any) => {
    try {
      const intent = 'getOPDObservations'
      const state = {
        encounterId: resourceId,
        type: 'diagnosis',
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.entry) {
        setDiagnosisList(resp.data.entry[0].resource.component)
      } else {
        setDiagnosisList([])
      }
    } catch (e) {
      console.log(e)
    }
  }
  const fetchLabOrders = async (id: any) => {
    try {
      const intent = 'getLabOrdersByPatientIdAndStatus'
      const state = {
        patientId: id,
        source: 'Amalapuram',
      }
      const resp = await startSxpProxy(LABS_PROJECT_ID, intent, state)
      if (resp.data.lab_orders) {
        const laborders = resp.data.lab_orders
        setLabOrderList(laborders)
      } else {
        setLabOrderList([])
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    fetchLabOrders(id)
  }, [id])
  const fetchRadiologyOrders = async (resourceId: any) => {
    try {
      const intent = 'getRadiologyOrders'
      const state = {
        visitId: resourceId,
      }
      const resp = await startSxpProxy(LABS_PROJECT_ID, intent, state)
      if (resp.data) {
        setRadiologyList(resp.data.radiology_lab_orders)
      } else {
        setRadiologyList([])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchMedication = async (resourceId: any) => {
    try {
      const intent = 'getMedicationsVisitIdApi'
      const state = {
        visitId: resourceId,
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp.data.medications) {
        const drugs = resp.data.medications.map((e: any) => ({
          drugName: e.drug_name ?? '',
          frequency: e.frequency ?? '',
          duration: e.duration ?? '',
          dose: e.dose ?? '',
          doseUnit: e.dose_units ?? '',
          durationUnit: e.duration_units ?? '',
          notes: e.additional_instructions ?? '',
          instruction: e.instructions ?? '',
          nextVisitDate: e.next_visit_date ?? '',
        }))
        setMedications(drugs)
      } else {
        setMedications([])
      }
    } catch (e) {
      console.error(e)
    }
  }

  const visitDateHandler = (data: any, i: any) => {
    setSelectedDate(i)
    fetchExaminations(data.resource.id)
    fetchHistory(data.resource.id)
    fetchVitals(data.resource.id)
    fetchDiagnosis(data.resource.id)
    fetchMedication(data.resource.id)
    getDrDetails(data.resource?.appointment?.[0]?.reference, visitsList)
  }

  const handleDocClick = () => {
    setIsOpenDocsPage((prevState) => !prevState)
  }

  return (
    <div className='layoutcontent'>
      <div className='dashboard-headContent'>
        {patientData && (
          <PatientProfileCard
            details={patientData}
            historyList={historyList}
            vitalsList={vitalsList}
            diagnosisList={diagnosisList}
            radiologyList={radiologyList}
            examinationList={examinationList}
            labOrderList={labOrderList}
            medications={medications}
            visitsList={drInfo}
            upcomingAppointments={upcomingAppointments}
          />
        )}
        <div className={`uploadDocBtn ${isOpenDocsPage ? 'active' : ''}`}>
          <button onClick={handleDocClick}>Patient Document&apos;s</button>
        </div>
      </div>
      {isOpenDocsPage && id && <UploadDocs patientId={id} />}
      {!isOpenDocsPage && (
        <>
          <DateCarouselSelector
            dateHandler={visitDateHandler}
            activeDate={selectedDate}
            visitDates={visitsDateList}
          />
          <div className='flex sx-screen-noFlex'>
            <div className='flexgrow1 wleft widthP50'>
              <AppointmentCard
                title='Upcoming Appointments'
                data={upcomingAppointments}
              />
              <DashboardCard
                title='Lab Orders '
                cardType='labOrders'
                data={labOrderList}
                patientData={patientData}
              />
              <DashboardCard
                title='Radiology Orders'
                cardType='radiology'
                data={radiologyList}
              />
            </div>
            <div className='flexgrow1 wright widthP50'>
              <HistoryExaminationCard title='History' data={historyList} />
              <HistoryExaminationCard
                title='Examinations'
                data={examinationList}
              />
              <HistoryExaminationCard title='Vitals' data={vitalsList} />
              <HistoryExaminationCard title='Diagnosis' data={diagnosisList} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PatientDashboard
