import axios, { AxiosRequestConfig } from 'axios'
import KeycloakService from './keycloakService'
import { FILE_SERVER_URL, SOCKET_URL } from './constants'

const _axios = axios.create({
  baseURL: SOCKET_URL,
})

const fsClient = axios.create({
  baseURL: FILE_SERVER_URL,
})

const configure = () => {
  _axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (KeycloakService.isLoggedIn()) {
      const cb = () => {
        const token = KeycloakService.getToken()
        KeycloakService.storeToken(token)
        if (config && config.headers) {
          config.headers.Authorization = `Bearer ${token}`
          return Promise.resolve(config)
        }
      }
      return KeycloakService.updateToken(cb)
    }
  })
}

const getAxiosClient = () => _axios
const getFileServerClient = () => fsClient

const HttpService = {
  configure,
  getAxiosClient,
  getFileServerClient,
}

export default HttpService
