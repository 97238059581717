import React, { ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectSlotsDoctors,
  selectSlotsSelectedDoctor,
  selectSlotsSelectedService,
  setSelectedSlotsDoctor,
} from '../slotsSlice'

const SelectDoctor = () => {
  const doctors = useAppSelector(selectSlotsDoctors)
  const selectedDoctor = useAppSelector(selectSlotsSelectedDoctor)
  const selectedService = useAppSelector(selectSlotsSelectedService)
  const dispatch = useAppDispatch()

  const onDoctorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedSlotsDoctor(e.target.value))
  }

  const filtered = selectedService
    ? doctors.filter((d) => d.serviceId.includes(selectedService) && d?.active)
    : doctors.filter((d) => d.active)

  return (
    <select
      className='doctorSelect'
      value={selectedDoctor}
      onChange={onDoctorChange}
    >
      <option value=''>Select a Doctor</option>
      {filtered.map(({ id, name }) => (
        <option key={id} value={id}>
          Dr. {name}
        </option>
      ))}
    </select>
  )
}

export default SelectDoctor
