import React from 'react'
import { RadiologyTest } from '../models'
import Tick from '../../../assets/images/check.png'

type Props = {
  tests: RadiologyTest[]
  onTestSelect: (test: RadiologyTest) => void
}

const RadiologyTests = ({ tests, onTestSelect }: Props) => {
  return (
    <div className='items-container'>
      <span className='heading'>Radiology Tests</span>
      <div className='items'>
        {tests.length > 0 ? (
          tests.map((t) => (
            <div
              className={`item ${t.active ? 'active' : ''}`}
              key={t.id}
              onClick={() => onTestSelect(t)}
              title={`${t.description.toUpperCase()}${
                t.active ? ' - Selected' : ''
              }`}
            >
              {t.active && <img className='check' src={Tick} alt='Checked' />}
              {t.name}
            </div>
          ))
        ) : (
          <span className='not-found'>No Lab Tests Found</span>
        )}
      </div>
    </div>
  )
}

export default RadiologyTests
