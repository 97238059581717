import KeycloakService from '../utils/keycloakService'
import UnAuthorized from './UnAuthorized'

type Props = {
  roles: string[]
  element: JSX.Element
}

const RenderOnRole = ({ roles, element }: Props) =>
  KeycloakService.hasRole(roles) ? element : <UnAuthorized />

export default RenderOnRole
