import React from 'react'
import { Calendar, dateFnsLocalizer, Event } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import enUS from 'date-fns/locale/en-US'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'

const locales = {
  'en-US': enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

type Props = {
  events: Event[]
  onRangeChange: (range: Date[] | { start: Date; end: Date }) => void
}

const SlotCalendar = ({ events, onRangeChange }: Props) => {
  return (
    <div className='calenderBlock'>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        views={{ week: true, day: true, month: true }}
        defaultView='week'
        min={new Date(0, 0, 0, 6, 0)}
        max={new Date(0, 0, 0, 23, 0)}
        step={15}
        style={{ height: 550 }}
        onSelectEvent={(ev) => console.log(ev)}
        onRangeChange={onRangeChange}
      />
    </div>
  )
}

export default SlotCalendar
