const RemarksText = ({ text, expanded }: any) => {
  return (
    <span className='span-remarks'>
      {text && text.length > 10 ? (
        <span>{expanded ? text : text.slice(0, 10) + '...'}</span>
      ) : (
        text
      )}
    </span>
  )
}

export default RemarksText
