import React from 'react'
import { NavLink } from 'react-router-dom'

const AdminMenu = () => {
  return (
    <div className='admin-menu'>
      <ul>
        <li>
          <NavLink to='locations'>Location</NavLink>
        </li>
        <li>
          <NavLink to='doctors'>Doctor</NavLink>
        </li>
        <li>
          <NavLink to='departments'>Departments</NavLink>
        </li>
        <li>
          <NavLink to='labs'>Laboratory</NavLink>
        </li>
        <li>
          <NavLink to='radiology'>Radiology</NavLink>
        </li>
        <li>
          <NavLink to='bedManagementAdmin'>IPD-BedMgmt</NavLink>
        </li>
        <li>
          <NavLink to='slot-management'>Slot Management</NavLink>
        </li>
      </ul>
    </div>
  )
}

export default AdminMenu
