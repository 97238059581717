import React, { useEffect } from 'react'
import { ScaleLoader } from 'react-spinners'
import 'react-toastify/dist/ReactToastify.css'

import './slot.scss'
import Configurations from './components/Configurations'
import SlotsInputs from './components/SlotsInputs'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  fetchSlotsDoctorsAsync,
  fetchSlotsLocationsAsync,
  fetchSlotsServicesAsync,
  resetSlotsStatus,
  selectSlotsStatus,
} from './slotsSlice'

const Slots = () => {
  const status = useAppSelector(selectSlotsStatus)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchSlotsDoctorsAsync())
    dispatch(fetchSlotsLocationsAsync())
    dispatch(fetchSlotsServicesAsync())
    return () => {
      dispatch(resetSlotsStatus())
    }
  }, [dispatch])

  return status === 'loading' ? (
    <div className='slots-loader'>
      <ScaleLoader color='#004C91' />
    </div>
  ) : (
    <div className='layoutcontent'>
      <div className='slot-container'>
        <SlotsInputs />
        <Configurations />
      </div>
    </div>
  )
}

export default Slots
