import { useEffect, useState } from 'react'
import { LabOrder } from '../../labTests/models'
import { Patient } from 'fhir/r4'
import { startSxpProxy } from '../../../utils/api'
import { LABS_PROJECT_ID, OPD_PROJECT_ID } from '../../../utils/constants'

export const useSingleLabOrder = (id: string, reload: number) => {
  const [order, setOrder] = useState<LabOrder | null>(null)
  const [patient, setPatient] = useState<Patient | null>(null)

  useEffect(() => {
    if (id) {
      const intent = 'getLabOrdersByIdApi'
      const state = {
        labOrderId: id,
      }
      startSxpProxy(LABS_PROJECT_ID, intent, state)
        .then((data) => {
          const ord: LabOrder = data.data.lab_orders_by_pk ?? {}
          if (ord) {
            setOrder(ord)
            const patId = ord.patient_id
            if (patId) {
              startSxpProxy(OPD_PROJECT_ID, 'getPatientApi', { id: patId })
                .then((data) => {
                  setPatient(data.data ?? {})
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [id, reload])

  return { order, patient }
}
