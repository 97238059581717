import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'

import Layout from './components/Layout'
import AppRoutes from './components/AppRoutes'
import SxpChat from './components/SXPChat'
import KeycloakService from './utils/keycloakService'
import { SXP_CHAT } from './utils/roles'

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <AppRoutes />
      </Layout>
      {KeycloakService.hasRole([SXP_CHAT]) && <SxpChat />}
    </BrowserRouter>
  )
}

export default App
