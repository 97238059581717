import React, { ChangeEvent } from 'react'
import './component.scss'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectPageSize, setPageSize } from '../app/appSlice'

type Props = {
  total: number
  current: number
  handleChange: () => void
}

const Pagination = ({ total, current, handleChange }: Props) => {
  const pageSize = useAppSelector(selectPageSize)
  const dispatch = useAppDispatch()
  const offset = (current - 1) * pageSize
  const start = offset + 1
  const end = Math.min(offset + pageSize, total)

  const handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setPageSize(Number(e.target.value)))
    handleChange()
  }

  return (
    <div className='inline-block pagination text-right'>
      <span>
        <span className='mr10'>
          Showing <b>{start}</b> to <b>{end}</b> of <b>{total}</b>{' '}
        </span>
        <select
          className='rowsperpage'
          defaultValue={pageSize}
          onChange={handleSizeChange}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size}>{size}</option>
          ))}
        </select>
      </span>
    </div>
  )
}

export default Pagination
