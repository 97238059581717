import React from 'react'
import SelectDoctor from './SelectDoctor'
import SelectLocation from './SelectLocation'
import SelectService from './SelectService'
import SelectDates from './SelectDates'

const SlotsInputs = () => {
  return (
    <div style={{ flexDirection: 'column', marginRight: 20 }}>
      <SelectService />
      <SelectLocation />
      <SelectDoctor />
      <SelectDates />
    </div>
  )
}

export default SlotsInputs
