import { allocation } from '../modals'
import Close from '../../../assets/images/close.png'

type Props = {
  onConfirm: (e: boolean) => void
  onClose: () => void
}
const DischargeConformationDialog = ({ onConfirm, onClose }: Props) => {
  const handleYes = () => {
    onConfirm(true)
  }
  const handleNo = () => {
    onConfirm(false)
  }
  return (
    <>
      <div className='conform-container'>
        <span className='event-close' onClick={() => onClose()}>
          <img className='close-img' src={Close} alt='Close Event Dialog' />
        </span>
        <span className='conform-content'>{allocation.dischargeModal}</span>
        <div className='conform-button'>
          <input
            type='button'
            value='yes'
            className='conform'
            onClick={handleYes}
          />
          <input
            type='button'
            value='No'
            className='conform'
            onClick={handleNo}
          />
        </div>
      </div>
    </>
  )
}
export default DischargeConformationDialog
