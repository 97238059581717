import React, { useRef, useEffect } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch } from '../../lmsSlice'
import { AdminTableProps } from '../../models'
import LinkButton from './LinkButton'

const DepartmentTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = data?.filter((d: any) =>
    d.department_name?.toLowerCase()?.includes(searchParam)
  )

  filtered?.sort?.(
    (a: { department_name: string }, b: { department_name: string }) =>
      a.department_name?.toLowerCase() < b.department_name?.toLowerCase()
        ? -1
        : 1
  )

  const depTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = depTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={depTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Id</th>
            <th>Department Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d.id}>
                <td>{d.id}</td>
                <td>
                  <LinkButton
                    id={d.id}
                    name={d.department_name}
                    onClick={onEdit}
                  />
                </td>
                <td>{d.department_description ?? '-'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className='text-center'>
                No Departments Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default DepartmentTable
