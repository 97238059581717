import React, { useState } from 'react'
import './index.scss'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID, KIMS_LOCATION_ID } from '../../utils/constants'
import { allocateApi } from '../bedManagement/modals'
import SelectableSearch from '../../components/SelectableSearch'
import { makeName } from '../lms/utils'
import PatientTable from './PatientTable'
import dayjs from 'dayjs'
import { useAppSelector } from '../../app/hooks'
import { selectSelectedLocationId } from '../location/locationSlice'

const IpdRegistrationTable = () => {
  const locationId = useAppSelector(selectSelectedLocationId)
  const [patients, setPatients] = useState([{}])
  const [id, setId] = useState('')
  const EMPTY = ''

  const calculateAge = (dob: string) => {
    const birthDate = dayjs(dob)
    const today = dayjs()
    return today.diff(birthDate, 'year')
  }

  const getPatientWithId = async (id: string) => {
    await startSxpProxy(
      CHAT_PROJECT_ID,
      allocateApi.getPatientDetailsByIdentifier,
      { patientIdentifier: id }
    ).then((data) => {
      if (data?.data?.entry?.length > 0) {
        const getIdentifierValue = (
          index: number,
          field: any,
          rowIndex: number
        ) =>
          data?.data?.entry?.[rowIndex]?.resource?.identifier?.[index]?.[
            field
          ] || EMPTY
        const getTelecomValue = (use: any, rowIndex: number, field = 'value') =>
          data?.data?.entry?.[rowIndex]?.resource?.telecom?.find?.(
            (ddt: any) => ddt?.use === use
          )?.[field] || EMPTY

        const filteredPatients = data?.data?.entry?.map(
          (item: any, index: number) => {
            return {
              name: makeName(item?.resource?.name),
              fhrId: item?.resource?.id,
              lastName: item.resource?.name?.[0]?.family || EMPTY,
              firstName: item?.resource?.name?.[0]?.given?.[0] || EMPTY,
              middleName: item?.resource?.name?.[0]?.given?.[1] || EMPTY,
              salutation: item?.resource?.name?.[0]?.prefix?.[0] || EMPTY,
              gender: item?.resource?.gender,
              dob: item?.resource?.birthDate || EMPTY,
              age: calculateAge(item?.resource?.birthDate || EMPTY),
              patientSource: item?.resource?.telecom?.[4]?.value || EMPTY,
              phoneNumber: getTelecomValue('mobile', index),
              alternateNumber: getTelecomValue('home', index),
              emailId: getTelecomValue('work', index),
              prefferedCommunication:
                item?.resource?.telecom?.find?.(
                  (ddt: any) => ddt?.system === 'sms'
                )?.value === 'no'
                  ? 'whatsApp'
                  : 'sms',
              maritalStatus:
                item?.resource?.maritalStatus?.coding?.[0]?.code || EMPTY,
              socioEconomicStatus: item?.resource?.telecom?.[5]?.value || EMPTY,
              governmentId: getIdentifierValue(1, 'system', index),
              idNumber: getIdentifierValue(1, 'value', index),
              aadharId: getIdentifierValue(4, 'value', index),
              religion: item?.resource?.telecom?.[6]?.value || EMPTY,
              occupation: item?.resource?.telecom?.[7]?.value || EMPTY,
              houseNo: item?.resource?.address?.[0]?.line?.[0] || EMPTY,
              area: item?.resource?.address?.[0]?.line?.[1] || EMPTY,
              town: item?.resource?.address?.[0]?.city || EMPTY,
              state: item?.resource?.address?.[0]?.state || EMPTY,
              district: item?.resource?.address?.[0]?.district || EMPTY,
              postalCode: item?.resource?.address?.[0]?.postalCode || EMPTY,
              relationWithPatient:
                item?.resource?.contact?.[0]?.relationship?.[0]?.coding?.[0]
                  ?.display || EMPTY,
              emergencyAddress:
                item?.resource?.contact?.[0]?.address?.city || EMPTY,
              emergencyPhoneNumber:
                item?.resource?.contact?.[0]?.telecom?.[0]?.value || EMPTY,
              emergencyContactName:
                item?.resource?.contact?.[0]?.name?.family || EMPTY,
              muhId: getIdentifierValue(2, 'value', index),
              mrNumber: getIdentifierValue(6, 'value', index),
            }
          }
        )
        setPatients(filteredPatients)
      } else {
        setPatients([])
      }
    })
  }

  const handleSearch = (query: string, param: string) => {
    if (param) {
      getPatientWithId(param)
      setId(param)
    }
  }

  return (
    <div className='feature-container'>
      <div className='ip-registration-container'>
        <div className='ip-heading-section'>
          <div className='ip-heading'>Patient Table</div>
          <div className='ip-search-container'>
            <SelectableSearch
              items={['ID']}
              onSearch={handleSearch}
              placeHolder={
                locationId === KIMS_LOCATION_ID
                  ? 'Search by Patient MR Number'
                  : ''
              }
            />
            {/* <button className='ip-create-btn'>Create New</button> */}
          </div>
        </div>
        <div>
          <PatientTable data={patients} />
        </div>
      </div>
    </div>
  )
}

export default IpdRegistrationTable
