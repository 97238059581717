import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Patient } from 'fhir/r4'

import { selectPageSize } from '../../app/appSlice'
import { RootState } from '../../app/store'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID } from '../../utils/constants'

export type ProvenanceData = {
  createdOn: string
  createdBy: string
  updatedOn: string
  updatedBy: string
}

export type PatientData = {
  resource: Patient
}

export type PatientSliceData = {
  status: 'loading' | 'success' | 'error'
  data: PatientData[]
  searchQuery: string
  searchValue: string
  prev?: string
  next?: string
  total: number
  currentPage: number
  patientId: string
  statusActive: boolean
  redirectTo: 'patients' | 'membership'
}

const initialState: PatientSliceData = {
  status: 'loading',
  data: [],
  searchQuery: '',
  searchValue: '',
  total: 0,
  statusActive: true,
  currentPage: 1,
  patientId: '',
  redirectTo: 'patients',
}

export const fetchPatientsAsync = createAsyncThunk(
  'patients/fetchPatients',
  async (_arg, { getState }) => {
    const intent = 'getPatientsAPI'
    const state = {
      pageSize: selectPageSize(getState() as RootState),
      query: selectPatientSearchQuery(getState() as RootState),
      value: selectPatientSearchValue(getState() as RootState),
      filter: selectPatientsActive(getState() as RootState),
    }
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
    return response.data
  }
)

export const fetchPaginatedPatientsAsync = createAsyncThunk(
  'patients/fetchPaginatedPatients',
  async (url: string) => {
    const intent = 'getPatientsPaginatedAPI'
    const state = {
      url: url,
    }
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
    return response.data
  }
)

const storePatientData = (
  state: PatientSliceData,
  action: PayloadAction<any>,
  isPaginated = true
) => {
  const { entry, link, total } = action.payload
  state.data = entry ?? []
  state.prev = link?.find?.((l: any) => l.relation === 'previous')?.url
  state.next = link?.find?.((l: any) => l.relation === 'next')?.url
  state.status = 'success'
  state.total = total
  if (!isPaginated) {
    state.currentPage = 1
  }
}

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    resetPatientStatus: (state) => {
      state.status = 'loading'
      state.searchQuery = ''
      state.searchValue = ''
      state.statusActive = true
    },
    setPatientSearchQueryValue: (
      state,
      action: PayloadAction<{ query: string; value: string }>
    ) => {
      state.searchQuery = action.payload.query
      state.searchValue = action.payload.value
    },
    setPatientStatusActive: (state, action: PayloadAction<boolean>) => {
      state.statusActive = action.payload
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setSelectedPatientId: (state, action: PayloadAction<string>) => {
      state.patientId = action.payload
    },
    setRedirectTo: (
      state,
      action: PayloadAction<'patients' | 'membership'>
    ) => {
      state.redirectTo = action.payload
    },
    setPatientData: (state, action: PayloadAction<PatientData[]>) => {
      state.data = action.payload
      state.status = 'success'
      state.total = action.payload.length
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchPatientsAsync.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(fetchPatientsAsync.fulfilled, (state, action) =>
        storePatientData(state, action, false)
      )
      .addCase(fetchPaginatedPatientsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchPaginatedPatientsAsync.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(fetchPaginatedPatientsAsync.fulfilled, (state, action) =>
        storePatientData(state, action)
      )
  },
})

export const {
  resetPatientStatus,
  setPatientSearchQueryValue,
  setCurrentPage,
  setRedirectTo,
  setPatientStatusActive,
  setPatientData,
} = patientsSlice.actions

export const selectPatientsStatus = (state: RootState) => state.patients.status
export const selectPatientsData = (state: RootState) => state.patients.data
export const selectPatientSearchQuery = (state: RootState) =>
  state.patients.searchQuery
export const selectPatientSearchValue = (state: RootState) =>
  state.patients.searchValue
export const selectPatientsNext = (state: RootState) => state.patients.next
export const selectPatientsPrevious = (state: RootState) => state.patients.prev
export const selectPatientsTotal = (state: RootState) => state.patients.total
export const selectPatientsCurrentPage = (state: RootState) =>
  state.patients.currentPage
export const selectPatientsRedirectTo = (state: RootState) =>
  state.patients.redirectTo
export const selectPatientsActive = (state: RootState) =>
  state.patients.statusActive

export default patientsSlice.reducer
