import React from 'react'
import { Panel } from '../models'
import Tick from '../../../assets/images/check.png'

type Props = {
  searchText: string
  panels: Panel[]
  onPanelSelect: (panel: Panel) => void
}

const LabPanels = ({ panels, onPanelSelect }: Props) => {
  return (
    <div className='items-container'>
      <span className='heading'>Tests</span>
      <div className='items'>
        {panels.length > 0 ? (
          panels.map((panel) => (
            <div
              className={`item ${panel.active ? 'active' : ''}`}
              key={panel.id}
              onClick={() => onPanelSelect(panel)}
              title={`${panel.name.toUpperCase()}${
                panel.active ? ' - Selected' : ''
              }`}
            >
              {panel.active && (
                <img className='check' src={Tick} alt='Checked' />
              )}
              {panel.name}
            </div>
          ))
        ) : (
          <span className='not-found'>No Tests Found</span>
        )}
      </div>
    </div>
  )
}

export default LabPanels
