export const calculateFreq = (
  frequency: string,
  dose: number,
  durations: any,
  interval?: any
) => {
  const duration = parseInt(durations)
  let quantities
  let unitsOne

  if (frequency === 'Immediately') {
    quantities = dose * 1 * duration
    unitsOne = 'day'
  } else if (frequency === 'Every Hour') {
    quantities = dose * duration
    unitsOne = 'hour'
  } else if (frequency === 'Every 2 hours') {
    quantities = Math.round(dose * (duration / 2))
    unitsOne = 'hour'
  } else if (frequency === 'Every 3 hours') {
    quantities = Math.round(dose * (duration / 3))
    unitsOne = 'hour'
  } else if (frequency === 'Every 4 hours') {
    quantities = dose * 6 * duration
    unitsOne = 'day'
  } else if (frequency === 'Every 6 hours') {
    quantities = dose * 4 * duration
    unitsOne = 'day'
  } else if (frequency === 'Every 8 hours') {
    quantities = Math.round(dose * (duration / 8))
    unitsOne = 'hour'
  } else if (frequency === 'Every 12 hours') {
    quantities = Math.round(dose * (duration / 12))
    unitsOne = 'twelveHour'
  } else if (frequency === 'Every 2 weeks') {
    quantities = Math.round(dose * (duration / 2))
    unitsOne = '2 week'
  } else if (frequency === 'Every 3 weeks') {
    quantities = Math.round(dose * (duration / 3))
    unitsOne = '3 week'
  } else if (frequency === 'Once a day') {
    quantities = dose * 1 * duration
    unitsOne = 'day'
  } else if (frequency === 'Twice a day') {
    quantities = dose * 2 * duration
    unitsOne = 'day'
  } else if (frequency === 'Thrice a day') {
    quantities = dose * 3 * duration
    unitsOne = 'day'
  } else if (frequency === 'Four times a day') {
    quantities = dose * 4 * duration
    unitsOne = 'day'
  } else if (frequency === 'On alternate days') {
    quantities = Math.round(dose * interval)
    unitsOne = 'day'
  } else if (frequency === 'Once a week') {
    quantities = dose * duration
    unitsOne = 'week'
  } else if (frequency === 'Six times a day') {
    quantities = dose * duration * 6
    unitsOne = 'day'
  } else if (frequency === 'Five times a day') {
    quantities = dose * duration * 5
    unitsOne = 'day'
  } else if (frequency === 'twoDayWeek') {
    quantities = Math.ceil((2 / 7) * dose * duration)
    unitsOne = 'day'
  } else if (frequency === 'Twice a week') {
    quantities = 2 * dose * duration
    unitsOne = 'week'
  } else if (frequency === 'Thrice a week') {
    quantities = 3 * dose * duration
    unitsOne = 'week'
  } else if (frequency === 'Four days a week') {
    quantities = 4 * dose * duration
    unitsOne = 'week'
  } else if (frequency === 'Five days a week') {
    quantities = 5 * dose * duration
    unitsOne = 'week'
  } else if (frequency === 'Six days a week') {
    quantities = 6 * dose * duration
    unitsOne = 'week'
  } else if (frequency === 'Day(s)') {
    quantities = dose * duration
    unitsOne = 'day'
  } else if (frequency === 'Week(s)') {
    quantities = dose * duration * 7
    unitsOne = 'week'
  } else if (frequency === 'Month(s)') {
    quantities = dose * duration * 30
    unitsOne = 'month'
  }
  return { quantities, unitsOne }
}
