import { useState } from 'react'
import BedManagement from './BedManagement'
import BedAllocation from '../allocation/BedAllocation'
import '../bms.scss'
import { allocation } from '../modals'
import { useBeds } from './useBeds'

const BedOverview = () => {
  const [route, setRoute] = useState('bedManagement')
  const [locationName, setLocationName] = useState('Amalapuram')
  const handleSelectChange = (event: any) => {
    setRoute(event.target.value)
  }
  const locations = useBeds()

  return (
    <>
      <div>
        <div className='flex-container'>
          <div className='membershipText'>{allocation?.bedview}</div>
          <div>
            <select
              className={`w105 ${
                route === 'bedManagement' ? 'bedallocate' : 'bedallocate'
              }`}
              name='location'
              value={route}
              onChange={handleSelectChange}
            >
              <option value='bedManagement'>List View</option>
              <option value='bedLayout'>Layout View</option>
            </select>
          </div>
          {route === allocation.bedManagementRoute ? (
            <div className='lms-filters'>
              <div>
                <select
                  className='category-filter'
                  name='location'
                  value={locationName}
                  onChange={(e) => setLocationName(e.target.value)}
                >
                  {locations?.map((op: any, i: number) => (
                    <option key={i} value={op}>
                      {op}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}
        </div>
        <div>
          {route === allocation.bedManagementRoute ? (
            <BedManagement locationName={locationName} />
          ) : (
            <BedAllocation />
          )}
        </div>
      </div>
    </>
  )
}

export default BedOverview
