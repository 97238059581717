import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import format from 'date-fns/format'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  addSlotConfig,
  selectSlotsConfigError,
  selectSlotsConfigs,
  selectSlotsStartDate,
  setConfigError,
  SlotConfig,
  SlotDuration,
} from '../slotsSlice'
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'

const minTime = new Date()
minTime.setHours(6)
minTime.setMinutes(0)
minTime.setSeconds(0)
minTime.setMilliseconds(0)
const maxTime = new Date()
maxTime.setHours(23)
maxTime.setMinutes(30)
maxTime.setSeconds(0)
maxTime.setMilliseconds(0)

const ConfigInputs = () => {
  const start = useAppSelector(selectSlotsStartDate)
  const configs = useAppSelector(selectSlotsConfigs)
  const errMsg = useAppSelector(selectSlotsConfigError)
  const dispatch = useAppDispatch()

  const isCurrent = start.slice(0, 10) === format(minTime, 'yyyy-MM-dd')
  if (isCurrent) {
    const current = new Date()
    if (current.getMinutes() < 30) {
      minTime.setHours(current.getHours())
      minTime.setMinutes(30)
    } else {
      minTime.setHours(current.getHours() + 1)
    }
  } else {
    minTime.setHours(6)
  }
  const [duration, setDuration] = useState<SlotDuration>(5)
  const [startDate, setStartDate] = useState(new Date(minTime))
  const [endDate, setEndDate] = useState(new Date(maxTime))

  useEffect(() => {
    const temp = new Date(startDate)
    if (temp.getHours() < 22) {
      temp.setHours(temp.getHours() + 2)
      temp.setMinutes(temp.getMinutes())
    } else {
      temp.setHours(23)
      temp.setMinutes(30)
    }
    setEndDate(temp)
  }, [startDate])

  const addConfig = () => {
    if (isBefore(endDate, startDate) || isEqual(startDate, endDate)) {
      dispatch(
        setConfigError(
          'To Time cannot be before or equal to From Time. Please check and add'
        )
      )
      return
    }
    if (isCurrent && isBefore(startDate, minTime)) {
      dispatch(
        setConfigError(
          'From Time cannot be before current Time for the selected dates. Please check and add'
        )
      )
      return
    }
    for (const sl of configs) {
      const isOverlapping = areIntervalsOverlapping(
        { start: startDate, end: endDate },
        { start: new Date(sl.start), end: new Date(sl.end) }
      )
      if (isOverlapping) {
        dispatch(
          setConfigError(
            'Some slot durations are overlapping with existing times. Please check and adjust times'
          )
        )
        return
      }
    }
    dispatch(setConfigError(''))
    const slot: SlotConfig = {
      id: '' + Date.now(),
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      duration: duration,
    }
    dispatch(addSlotConfig(slot))
    setStartDate(minTime)
    setEndDate(maxTime)
    setDuration(5)
  }

  return (
    <>
      <div className='timeRangeBlock'>
        <div>
          <DatePicker
            title='From Time'
            selected={startDate}
            onChange={(date) => setStartDate(date ?? new Date())}
            showTimeSelect
            showTimeSelectOnly
            minTime={minTime}
            maxTime={maxTime}
            timeIntervals={30}
            timeCaption='Time'
            dateFormat='h:mm aa'
          />
        </div>
        <div>
          <DatePicker
            title='To Time'
            selected={endDate}
            onChange={(date) => setEndDate(date ?? new Date())}
            showTimeSelect
            showTimeSelectOnly
            minTime={startDate}
            maxTime={maxTime}
            timeIntervals={30}
            timeCaption='Time'
            dateFormat='h:mm aa'
          />
        </div>
        <select
          title='Duration'
          value={duration}
          className='selectDuration'
          onChange={(e) => {
            setDuration(parseInt(e.target.value) as SlotDuration)
          }}
        >
          {[1, 2, 5, 10, 15, 20, 30, 60].map((val) => (
            <option key={val} value={val}>
              {val} mins
            </option>
          ))}
        </select>
        <button className='btn btn-primaryBtn' onClick={addConfig}>
          Add
        </button>
      </div>
      {errMsg ? (
        <p style={{ fontSize: 12, color: 'red', fontStyle: 'italic' }}>
          {errMsg}
        </p>
      ) : null}
    </>
  )
}

export default ConfigInputs
