import { forwardRef } from 'react'
import { useAppSelector } from '../../app/hooks'
import { readableDateTime } from '../../utils/dateUtils'
import { selectLocations } from '../location/locationSlice'
import { LocationByPrefix, getLocationAddress } from '../patients/utils'
import { Patient } from 'fhir/r4'
import { crhealthcare } from '../patientRegstration/constants'
import logo from '../../assets/icons/logo.svg'
import locationIcon from '../../assets/icons/location.svg'
import emailIcon from '../../assets/icons/Email.svg'
import mobileIcon from '../../assets/icons/mobile.svg'
import Barcode from 'react-barcode'

type OPDComponentProps = {
  patient: Patient
}

export const getRegistrationOPDNo = (patient: Patient | undefined) => {
  const identifier = patient?.identifier
  if (identifier) {
    const regNo = identifier.find((data: any) =>
      data?.system?.includes('patient-identifier') ? data : ''
    )
    const thopId = identifier.find((data: any) =>
      data?.system?.includes('thop-identifier') ? data : ''
    )
    return { regNo, thopId }
  }
  return ''
}

const OPDComponentPrintCard = ({ patient }: OPDComponentProps) => {
  const { photo, gender } = patient
  const stringData = JSON.stringify(patient)
  const praseData = JSON.parse(stringData)
  const registeredOn = praseData?.RegisteredOn
    ? readableDateTime(praseData?.RegisteredOn)
    : ''
  const patientName = praseData.name
  const patientProfImg = photo?.[0]?.url ?? ''
  const age = praseData?.Age ?? ''
  const mobileNo = praseData?.Mobile ?? ''
  const registrationNo = praseData.id
  const barCodeData = registrationNo ?? ''
  const location = registrationNo ? LocationByPrefix(registrationNo) : ''
  const locations = useAppSelector(selectLocations)
  const locationAddress = getLocationAddress(registrationNo, locations)

  return (
    <div className='regCardBody'>
      <div>
        <div className='headerRibbon'>
          <div className='orangeline' />
          <div className='blueline' />
        </div>
        <div className='RegCardMain'>
          <div className='regCardHeader'>
            <div className='  ' style={{ flex: 1 }}>
              <img src={logo ?? ''} alt='Medunited Logo' />
            </div>
            <div className='medUniAdd'>
              <span>
                <img src={locationIcon ?? ''} alt='location icon' />
                <p> {locationAddress?.address?.line?.[0]}</p>
                <br />
                <span>
                  <img src={mobileIcon ?? ''} alt='mobile icon' />
                  <p> {locationAddress?.telecom?.[0]?.value}</p>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span>
                  <img src={emailIcon ?? ''} alt='email icon' />
                  <p
                    style={{
                      marginLeft: '5px',
                      lineHeight: '11px',
                    }}
                  >
                    {locationAddress?.telecom?.[1]?.value}
                  </p>
                </span>
              </span>
            </div>
          </div>
          <hr />
          <div className='title-body'>
            <h2 className='title-main'> Registration Card</h2>
            <div>
              <div className='barcode-style'>
                {barCodeData && (
                  <Barcode
                    value={'' + barCodeData}
                    height={30}
                    width={1.3}
                    margin={0}
                    fontSize={12}
                  />
                )}
              </div>
            </div>
          </div>
          <div className='patientDetail'>
            <div className='tableBorderLeft'>
              <div className='patient-main'>
                <p>
                  <b>Registration ID</b>
                </p>
                <p> :</p>
                <span>{registrationNo}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b>Patient Name</b>
                </p>
                <p> :</p>
                <span>{patientName}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b> Age</b>
                </p>
                <p> :</p>
                <span>{age ?? ''}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b>Gender</b>
                </p>
                <p> :</p>
                <span>{gender}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b> Phone Number</b>
                </p>
                <p> :</p>
                <span>{mobileNo}</span>
              </div>
              <div className='patient-main'>
                <p>
                  {' '}
                  <b>Registered On</b>
                </p>
                <p> :</p>
                <span>{registeredOn ?? ''}</span>
              </div>
              <div className='patient-main'>
                <p>
                  <b>Registered At</b>
                </p>
                <p> :</p>
                <span>{location ?? '---'}</span>
              </div>
            </div>
            <div className='tableBorderRight'>
              {patientProfImg && (
                <img src={patientProfImg} alt='Patient Profile Picture' />
              )}
            </div>
          </div>
        </div>
        <div className='footer-style'>
          <footer>
            <p>{crhealthcare}</p>
          </footer>
        </div>
      </div>
    </div>
  )
}

const AnotherComponentPrint = forwardRef<HTMLDivElement, OPDComponentProps>(
  function Label(props, ref) {
    return (
      <div ref={ref}>
        <OPDComponentPrintCard patient={props.patient} />
      </div>
    )
  }
)

export default AnotherComponentPrint
