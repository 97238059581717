import React, { useRef } from 'react'
import UserImg from './../../../assets/images/user.png'
import { readableDateFormat, getTotalAge } from '../../../utils/dateUtils'
import PatientRegCardPrint from './PatientRegCardPrint'

import PrintLogo from '../../../assets/images/print.png'
import OpCaseLogo from '../../../assets/icons/opcasesheet.png'
import medicalPrescription from '../../../assets/icons/medical-prescription.png'
import { useReactToPrint } from 'react-to-print'
import OpCaseSheetPrint from './OpCaseSheetPrint'
import { makeName } from '../../lms/utils'
import EPrescription from './EPrescription'

const PatientProfileCard = (props: any) => {
  const {
    details,
    historyList,
    examinationList,
    vitalsList,
    diagnosisList,
    labOrderList,
    radiologyList,
    visitsList,
    medications,
    upcomingAppointments,
  } = props
  const registrationRef = useRef(null)
  const OpCaseSheetRef = useRef(null)
  const eprescriptionRef = useRef(null)

  const registrationPageStyle = `
  @page {
    size: 148mm 210mm;
  }
  @media print {
    @page {
        size: landscape;
        margin: 0 !important;
    }
}`

  const handleRegistrationPrint = useReactToPrint({
    content: () => registrationRef.current,
    pageStyle: registrationPageStyle,
  })

  const handleOpCaseSheetPrint = useReactToPrint({
    content: () => OpCaseSheetRef.current,
    // pageStyle: opCaseSheetStyle,
  })

  const handleEPrescriptionPrint = useReactToPrint({
    content: () => eprescriptionRef.current,
    // pageStyle: opCaseSheetStyle,
  })

  return (
    <div className='patientProfileCard'>
      <img src={UserImg} alt='User Profile Image' />
      <div>
        <p>
          <b>
            {details?.name?.[0]?.prefix?.[0]} {makeName(details.name)}&nbsp;
            {details?.identifier?.[2]?.value &&
              `( ${details?.identifier?.[2]?.value} ) `}
          </b>
          <span>{details?.gender}</span>
        </p>
        <p>
          Date of Birth
          {details.birthDate ? (
            <span>
              <b> {readableDateFormat(new Date(details.birthDate))}</b> (
              {getTotalAge(new Date(details.birthDate))})
            </span>
          ) : (
            '            -'
          )}
        </p>
      </div>

      <div className='printButton'>
        <div style={{ display: 'none' }}>
          <PatientRegCardPrint ref={registrationRef} patient={details} />
        </div>
        <button onClick={() => handleRegistrationPrint()}>
          <img
            title='Registration Card'
            src={PrintLogo}
            alt='Print Registration Card'
          />
        </button>
      </div>

      {Object.keys(visitsList).length > 0 && (
        <>
          <div className='e-prescriptionBtn'>
            <div style={{ display: 'none' }}>
              <EPrescription
                ref={eprescriptionRef}
                patient={details}
                visitsList={visitsList}
                diagnosisList={diagnosisList}
                labOrderList={labOrderList}
                medications={medications}
              />
            </div>
            <button onClick={() => handleEPrescriptionPrint()}>
              <img
                title='e-prescription'
                src={medicalPrescription}
                alt='Print e-prescription'
              />
            </button>
          </div>
          <div className='caseSheetButton'>
            <div style={{ display: 'none' }}>
              <OpCaseSheetPrint
                ref={OpCaseSheetRef}
                visitsList={visitsList}
                patient={details}
                historyList={historyList}
                examinationList={examinationList}
                vitalsList={vitalsList}
                diagnosisList={diagnosisList}
                labOrderList={labOrderList}
                radiologyList={radiologyList}
                upcomingAppointments={upcomingAppointments}
              />
            </div>
            <button onClick={() => handleOpCaseSheetPrint()}>
              <img
                title='OP Case Sheet'
                src={OpCaseLogo}
                alt='Print Op Case Sheet'
              />
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default PatientProfileCard
