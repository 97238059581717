import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './component.scss'
import {
  ADMIN_PROJECT_ID,
  COMMANDCENTER_URL,
  INNOMAINT_URL,
  INNOMAINT_URL_QR_CODE,
  REPORT_URL,
  RMS_URL,
} from '../utils/constants'
import {
  APPOINTMENTS_READ,
  APPOINTMENTS_WRITE,
  PATIENTS_READ,
  PATIENTS_WRITE,
  LMS_READ,
  RADIOLOGY_READ,
  IPD_READ,
  ADMINISTRATION_READ,
} from '../utils/roles'
import KeycloakService from '../utils/keycloakService'
import { startSxpProxy } from '../utils/api'
import { selectSelectedLocationId } from '../features/location/locationSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import {
  bedManagementOptions,
  labOptions,
  radiologyOptions,
} from '../features/lms/components/admin/AdminConfig'
import {
  setLmsItem,
  setLmsAdminSearch,
  setLmsFormMode,
  setSelectedValue
} from '../features/lms/lmsSlice'
import { AdminOption } from '../features/lms/models'
interface MenuItem {
  name: string
  label: string
  link: any
  roles?: string[]
  dropdownItems?: DropdownItem[]
}

interface DropdownItem {
  name: string
  label: string
  link: any
  onClick?: () => void
  subMenuItems?: DropdownItem[]
}
interface SubdropdownRefs {
  [key: string]: HTMLDivElement | null
}
export const devicesOptions: AdminOption[] = [
  { label: 'Lab Devices', value: 'labDevices' },
  { label: 'Radiology Devices', value: 'radiologyDevices' },
]

const MainMenu = () => {
  const dispatch = useAppDispatch()
  const [hoveredItem, setHoveredItem] = useState<string | null>(null)
  const [hoveredDropdownItem, setHoveredDropdownItem] = useState<string | null>(
    null
  )
  const [selectedSubmenu, setSelectedSubmenu] = useState<string | null>(null)
  const [selectedValue1, setSelectedValue1] = useState<string | null>(null)
  const [BillingUrl, setBillingurl] = useState('')
  const [pharmacyUrl, setPharmacyUrl] = useState('')
  const locationId = useAppSelector(selectSelectedLocationId)
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number
    left: number
  }>({ top: 0, left: 0 })
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'odooLogin', {
      location: locationId,
    })
      .then((data) => setBillingurl(data?.data?.auth_link))
      .catch((err) => console?.error(err, 'err'))
  }, [locationId])
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'pharmacyLocation', {
      location: locationId,
    })
      .then((data) => setPharmacyUrl(data?.data?.auth_link))
      .catch((err) => console.error(err, 'err'))
  }, [locationId])

  const handleMouseEnter = (itemName: string, top: number, left: number) => {
    setHoveredItem(itemName)
    setDropdownPosition({ top, left })
  }

  const handleMouseLeave = () => {
    setHoveredItem(null)
    setHoveredDropdownItem(null)
  }

  const subdropdownRef = useRef<SubdropdownRefs>({})
  const handleDropdownItemEnter = (
    itemName: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setHoveredDropdownItem(itemName)

    const ref = subdropdownRef.current[itemName]
    if (ref) {
      const dropdownItemRect = event?.currentTarget?.getBoundingClientRect()
      const dropdownRect = event?.currentTarget
        ?.closest('.location-dropdown')
        ?.getBoundingClientRect()

      if (dropdownRect) {
        const viewportWidth = window?.innerWidth
        const subDropdownWidth = ref?.getBoundingClientRect()?.width
        const dropdownItemRectRight = dropdownItemRect?.right
        const dropdownItemRectTop = dropdownItemRect?.top
        ref.style.position = 'absolute'
        ref.style.top = `${dropdownItemRectTop - dropdownRect.top}px`
        if (dropdownItemRectRight + subDropdownWidth > viewportWidth) {
          ref.style.left = `-${subDropdownWidth}px`
        } else {
          ref.style.left = `${dropdownItemRect.width}px`
        }
      }
    }
  }

  const handleDropdownItemLeave = () => {
    setHoveredDropdownItem(null)
  }
  const hasRole = (roles: string[]) => {
    return roles.every(() => KeycloakService.hasRole(roles))
  }
  const splitRadiology = hoveredDropdownItem
  const options = useMemo(() => {
    let opts: { value: string; label: string }[] = []

    if (splitRadiology === 'radiology') {
      opts = [
        ...radiologyOptions,
        { value: 'radiologist', label: 'Radiologist' },
      ]
    } else if (splitRadiology === 'bedManagementAdmin') {
      opts = bedManagementOptions
    } else if (splitRadiology == 'devices') {
      opts = devicesOptions
    } else {
      opts = [...labOptions, { value: 'pathologist', label: 'Pathologist' }]
    }

    return opts.sort((a, b) => (a.label < b.label ? -1 : 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    splitRadiology,
    radiologyOptions,
    bedManagementOptions,
    labOptions,
    devicesOptions,
  ])

  options?.sort?.((a, b) => (a?.label < b?.label ? -1 : 1))

  useEffect(() => {
    dispatch(setLmsAdminSearch(''))
  }, [dispatch, selectedValue1, options])

  useEffect(() => {
    if (selectedValue1) {
      dispatch(setLmsItem(selectedValue1))
    }
  }, [dispatch, selectedValue1])

  const handleChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const value = ev.target.value
    dispatch(setSelectedValue(value))
    setSelectedValue1(value)
    setSelectedSubmenu(hoveredDropdownItem)
    dispatch(setLmsFormMode('none'))
    if (value) {
      dispatch(setLmsItem(value))
    }
  }
  const generateSubMenuItems = (
    options: { value: string; label: string }[]
  ) => {
    return options?.map((option) => ({
      name: option?.value,
      label: option?.label,
      onclick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ev.preventDefault()
        if (option?.value === 'radiologist') {
          window.location.href = '/radilogist'
        } else if (option?.value === 'pathologist') {
          window.location.href = '/pathologist'
        } else {
          handleChange(ev as unknown as ChangeEvent<HTMLSelectElement>)
        }
      },
      link:
        option.value === 'radiologist'
          ? '/radilogist'
          : option.value === 'pathologist'
          ? 'administration/pathologist'
          : option.value === 'labDevices'
          ? '/labDevices'
          : option.value === 'radiologyDevices'
          ? '/radiologyDevices'
          : `/administration/${hoveredDropdownItem} `,
    }))
  }

  const menuItems: MenuItem[] = [
    {
      name: 'patients',
      label: 'Registration',
      link: '/patients',
      dropdownItems: [
        {
          name: 'membership',
          label: 'Membership',
          link: '/membership',
        },
        {
          name: 'package',
          label: 'Package',
          link: '/package',
        },
      ],
      roles: hasRole([PATIENTS_READ, PATIENTS_WRITE])
        ? [PATIENTS_READ, PATIENTS_WRITE]
        : undefined,
    },
    {
      name: 'OPD',
      label: 'OPD',
      link: '/opd',
      dropdownItems: [
        {
          name: 'OP Visit Details',
          label: 'OP Visit Details',
          link: '/visits',
        },
      ],
      roles: hasRole([PATIENTS_READ, PATIENTS_WRITE])
        ? [PATIENTS_READ, PATIENTS_WRITE]
        : undefined,
    },
    {
      name: 'appointments',
      label: 'Appointments',
      link: '/appointments',
      dropdownItems: [
        {
          name: 'calendar',
          label: 'Calendar',
          link: 'appointments/calendar',
        },
        {
          name: 'slotManagement',
          label: 'Slot Management',
          link: 'appointments/slot-management',
        },
      ],
      roles: hasRole([APPOINTMENTS_READ, APPOINTMENTS_WRITE])
        ? [APPOINTMENTS_READ, APPOINTMENTS_WRITE]
        : undefined,
    },
    {
      name: 'laboratory',
      label: 'Laboratory',
      link: '/lms',
      dropdownItems: [
        {
          name: 'Create LabOrders',
          label: 'Create LabOrder',
          link: '/lms/search/createorder/{id}',
        },
        {
          name: 'referredOut',
          label: 'Referred Out',
          link: '/lms/referred',
        },
        {
          name: 'Dashboard',
          label: 'Dashboard',
          link: '/lms/lmsView',
        },
        {
          name: 'Lab Census',
          label: 'Lab Census',
          link: '/lms/lmsCensus',
        },
        {
          name: 'Trim PDF',
          label: 'Trim PDF',
          link: '/lms/trimPdf',
        },
        {
          name: 'Lab Test Reference',
          label: 'Lab Test Reference',
          link: '/labReference',
        },
      ],
      roles: hasRole([LMS_READ]) ? [LMS_READ] : undefined,
    },
    {
      name: 'radiology',
      label: 'Radiology',
      link: '/radiology',
      dropdownItems: [
        {
          name: 'Create-radiologyOrder',
          label: 'Create RadiologyOrder',
          link: '/radiology/Create',
        },
        {
          name: 'dashboard',
          label: 'Dashboard',
          link: '/radiology/dashboard',
        },
        {
          name: 'referredOut',
          label: 'Referred Out',
          link: '/radiology/referred',
        },
      ],
      roles: hasRole([RADIOLOGY_READ]) ? [RADIOLOGY_READ] : undefined,
    },
    {
      name: 'ipd',
      label: 'IPD',
      link: '/bedIpd/bedAssignmentList',
      dropdownItems: [
        {
          name: 'IPRegistartion',
          label: 'IP Registration',
          link: '/ipdRegistration',
        },
        {
          name: 'Bed Allocation',
          label: 'Bed Allocation',
          link: '/bedIpd/bedOverview',
        },
        {
          name: 'bedManagement',
          label: 'Bed Dashboard',
          link: '/bedIpd/bedManagementDashboard',
        },
      ],
      roles: hasRole([IPD_READ]) ? [IPD_READ] : undefined,
    },
    {
      name: 'billing',
      label: 'Billing',
      link: BillingUrl,
      dropdownItems: [
        {
          name: 'pharmacy',
          label: 'Pharmacy',
          link: pharmacyUrl,
        },
      ],
      roles: hasRole([PATIENTS_READ, PATIENTS_WRITE])
        ? [PATIENTS_READ, PATIENTS_WRITE]
        : undefined,
    },
    {
      name: 'Apps',
      label: 'Apps',
      link: '/apps',
      dropdownItems: [
        {
          name: 'Command Center',
          label: 'Command Center',
          link: COMMANDCENTER_URL,
        },
        {
          name: 'Innoment',
          label: 'Innoment-Asset Mgmt',
          link: INNOMAINT_URL,
        },
        {
          name: 'Innomaint - QR Code Generator',
          label: 'Innomaint - QR Code Generator',
          link: INNOMAINT_URL_QR_CODE,
        },
        {
          name: 'RMS',
          label: 'RMS',
          link: RMS_URL,
        },
        {
          name: 'Reporting',
          label: 'Reporting',
          link: REPORT_URL,
        },
      ],
      roles: hasRole([PATIENTS_READ, PATIENTS_WRITE])
        ? [PATIENTS_READ, PATIENTS_WRITE]
        : undefined,
    },
    {
      name: 'administration',
      label: 'Administration',
      link: '/administration',
      dropdownItems: [
        {
          name: 'doctor',
          label: 'Doctor',
          link: '/administration/doctors',
        },
        {
          name: 'departments',
          label: 'Departments',
          link: '/administration/departments',
        },
        {
          name: 'bedManagementAdmin',
          label: 'Bed Management',
          link: '/administration/bedManagementAdmin',
          subMenuItems: generateSubMenuItems(options),
        },
        {
          name: 'location',
          label: 'Location',
          link: '/administration/locations',
        },
        {
          name: 'laboratory',
          label: 'Laboratory',
          link: '/administration/laboratory',
          subMenuItems: generateSubMenuItems(options),
        },
        {
          name: 'radiology',
          label: 'Radiology',
          link: '/administration/radiology',
          subMenuItems: generateSubMenuItems(options),
        },
        {
          name: 'User Management',
          label: 'User Management',
          link: '/administration/usersData',
        },
        {
          name: 'proRithm',
          label: 'ProRithm',
          link: '/proRithmDashboard',
        },
        {
          name: 'devices',
          label: 'Devices',
          link: '/devices',
          subMenuItems: generateSubMenuItems(options),
        },
      ],
      roles: hasRole([ADMINISTRATION_READ]) ? [ADMINISTRATION_READ] : undefined,
    },
  ]

  return (
    <div className='sideBar'>
      <ul style={{ display: 'flex', position: 'relative' }}>
        {menuItems?.map(
          (item) =>
            KeycloakService?.hasRole(item.roles || []) && (
              <li
                key={item.name}
                onMouseEnter={(e) =>
                  handleMouseEnter(
                    item.name,
                    e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
                    e.currentTarget.offsetLeft
                  )
                }
                onMouseLeave={handleMouseLeave}
              >
                {item.name === 'billing' ? (
                  <a href={item.link} target='_blank' rel='noopener noreferrer'>
                    {item.label}
                  </a>
                ) : (
                  <NavLink to={item.link}>{item.label}</NavLink>
                )}
                {hoveredItem === item?.name && item?.dropdownItems && (
                  <div
                    className='location-dropdown'
                    style={{
                      position: 'absolute',
                      top: dropdownPosition.top,
                      left: dropdownPosition.left,
                      zIndex: 999,
                      backgroundColor: 'black',
                    }}
                  >
                    <ul
                      className='location-dropdown'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0,
                        boxShadow: '0 1rem 2.1rem rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      {item?.dropdownItems?.map((dropdownItem) => (
                        <li
                          key={dropdownItem.name}
                          style={{ position: 'relative' }}
                          onMouseEnter={(e) =>
                            handleDropdownItemEnter(dropdownItem.name, e)
                          }
                          onMouseLeave={handleDropdownItemLeave}
                        >
                          <NavLink
                            to={dropdownItem.link}
                            style={{
                              display: 'block',
                              width: '150px',
                              padding: '10px',
                              backgroundColor:
                                hoveredDropdownItem === dropdownItem.name
                                  ? '#d4d7d9'
                                  : 'white',
                              color: 'black',
                              textDecoration: 'none',
                            }}
                          >
                            {dropdownItem?.label}
                            {dropdownItem?.subMenuItems && (
                              <span className='arrow'>&#x25B6;</span>
                            )}
                          </NavLink>
                          {dropdownItem?.subMenuItems &&
                            hoveredDropdownItem === dropdownItem?.name && (
                              <div
                                ref={(el) =>
                                  (subdropdownRef.current[dropdownItem.name] =
                                    el)
                                }
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: '100%',
                                  backgroundColor: 'white',
                                  boxShadow: '0 1rem 2.1rem rgba(0, 0, 0, 0.5)',
                                  zIndex: 1000,
                                }}
                              >
                                <ul
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    listStyle: 'none',
                                  }}
                                >
                                  {dropdownItem?.subMenuItems?.map(
                                    (subItem) => (
                                      <li key={subItem.name}>
                                        <NavLink
                                          to={subItem.link}
                                          style={{
                                            display: 'block',
                                            width: '150px',
                                            padding: '10px',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            textDecoration: 'none',
                                          }}
                                          onClick={() =>
                                            handleChange({
                                              target: { value: subItem.name },
                                            } as ChangeEvent<HTMLSelectElement>)
                                          }
                                        >
                                          {subItem?.label}
                                        </NavLink>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            )
        )}
      </ul>
    </div>
  )
}

export default MainMenu
