import React, { useEffect, useState } from 'react'
import { useRadiologyOrders } from './UseRadiologyOrders'
import { useAppSelector } from '../../../../app/hooks'
import {
  selectLocationById,
  selectSelectedLocationId,
} from '../../../location/locationSlice'
import { startSxpProxy } from '../../../../utils/api'
import { OPD_PROJECT_ID } from '../../../../utils/constants'
import { DomainResource } from 'fhir/r4'
import { formatPatientVisits } from '../../../lms/utils'
import { PatientVisit } from '../../../lms/models'
import PatientVisitsTable from '../../../lms/components/search/PatientVisitsTable'
import RadiologySearchTable from './RadiologySearchTable'
import KeycloakService from '../../../../utils/keycloakService'
import { RADIOLOGY_WRITE } from '../../../../utils/roles'
import {
  create,
  createOrderText,
  emptyString,
  intent,
  statuses,
} from '../../constants'

type Props = {
  id: string
  onView: (url: string) => void
  onCreate: (id: string) => void
}

const AllRadiologyOrders = ({ id, onView, onCreate }: Props) => {
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const orders = useRadiologyOrders(id, location?.resource?.name ?? emptyString)
  const [visits, setVisits] = useState<PatientVisit[]>([])

  useEffect(() => {
    const state = { id: id }
    startSxpProxy(OPD_PROJECT_ID, intent.getPatientVisits, state)
      .then((data) => {
        const visitResources: { resource: DomainResource }[] =
          data.data.entry ?? []
        const patientVisits = formatPatientVisits(
          visitResources.map((vr) => vr.resource)
        )
        setVisits(patientVisits)
      })
      .catch((err) => console.log(err))
  }, [id])

  const ordered = orders.filter((o) => o.status === statuses.ordered)
  const completed = orders.filter((o) => o.status === statuses.completed)

  const handleViewOrdered = (id: string) => {
    onView(`collect/${id}`)
  }

  const handleViewCompleted = (id: string) => {
    onView(`results/${id}`)
  }
  const createOrder = (id: string) => {
    onCreate?.(id)
  }

  return (
    <>
      <div className='createOrder'>
        <table className='admin-table'>
          <thead>
            <tr>
              <th className='text-center' colSpan={5}>
                {createOrderText}
              </th>
            </tr>
          </thead>
          <tbody>
            <td className='text-center' colSpan={5}>
              {KeycloakService.hasRole([RADIOLOGY_WRITE]) && (
                <button className='ml5' onClick={() => createOrder(id)}>
                  {create}
                </button>
              )}
            </td>
          </tbody>
        </table>
      </div>
      <RadiologySearchTable
        mode='ordered'
        orders={ordered}
        onOrderView={handleViewOrdered}
        onOrderCreate={() => onCreate(id)}
      />
      <RadiologySearchTable
        mode='completed'
        orders={completed}
        onOrderView={handleViewCompleted}
      />
      <PatientVisitsTable visits={visits} />
    </>
  )
}

export default AllRadiologyOrders
