import { useNavigate } from 'react-router-dom'
import DepartmentsForm from './DepartmentsForm'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const CreateDepartments = () => {
  const navigate = useNavigate()
  return (
    <>
      <IconButton onClick={() => navigate('/administration/departments')}>
        <KeyboardBackspaceIcon className='back-btn-admin' />
      </IconButton>
      <DepartmentsForm />
    </>
  )
}

export default CreateDepartments
