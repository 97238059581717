import React from 'react'
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from '@mui/material'

interface TreatmentPlanFormProps {
  treatmentPlan: string
  followUpAdvice: string
  followUpDate: string
  homeAdvice: string
  referralRequired: 'Yes' | 'No'
  nameOfDoctor: string
  department: string
  reasonReferral: string
}

const TreatmentPlanForm: React.FC<TreatmentPlanFormProps> = ({
  treatmentPlan,
  followUpAdvice,
  followUpDate,
  homeAdvice,
  referralRequired,
  nameOfDoctor,
  department,
  reasonReferral,
}) => {
  return (
    <Grid container spacing={3} style={{ marginTop: '0.5px' }}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={3}
          id='treatmentPlan'
          label='Treatment Plan'
          value={treatmentPlan}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={3}
          id='followUpAdvice'
          label='Follow Up Advice'
          value={followUpAdvice}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type='date'
          id='followUpDate'
          label='Follow Up Date'
          value={followUpDate}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          multiline
          rows={4}
          id='homeAdvice'
          label='Home Advice'
          value={homeAdvice}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Referral Required</FormLabel>
          <RadioGroup
            row
            aria-label='referralRequired'
            name='referralRequired'
            value={referralRequired}
          >
            <FormControlLabel
              value='Yes'
              control={<Radio />}
              label='Yes'
              disabled
            />
            <FormControlLabel
              value='No'
              control={<Radio />}
              label='No'
              disabled
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id='nameOfDoctor'
          label='Name of Doctor'
          value={nameOfDoctor}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id='department'
          label='Department'
          value={department}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id='reasonReferral'
          label='Reason Referral'
          value={reasonReferral}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  )
}
export default TreatmentPlanForm
