/* eslint-disable camelcase */
import { useEffect, useState, KeyboardEvent, ChangeEvent } from 'react'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID, RADIOLOGY_PROJECT_ID } from '../../utils/constants'
import { LabTest, OrderEvent, Panel, LabOrder } from '../labTests/models'
import { groupTests, prepareOrderEventTests } from '../lms/utils'
import { getDoctorForVisit, saveOrder } from './utils'
import { RADIOLOGY_WRITE } from '../../utils/roles'
import { useAppSelector } from '../../app/hooks'
import { useCategories } from './components/RadiologyModel/useCategories'
import {
  selectLocationById,
  selectSelectedLocationId,
} from '../location/locationSlice'
import {
  externalRequest,
  intent,
  modes,
  opd,
  originRequest,
  testSearchHolder,
} from './constants'
import RadiologyCategories from './components/categories/RadiologyCategories'
import RadiologyTestsName from './components/categories/RadiologyTestsName'
import KeycloakService from '../../utils/keycloakService'
import SelectedLabTests from '../labTests/components/SelectedLabTests'
import '../labTests/labs.scss'

type Props = {
  id?: string
  patientId: string
  mode?: 'visit' | 'patient' | 'package'
  order: LabOrder
  onSave?: (panels?: Panel[], labTests?: LabTest[]) => void
  requestedBy?: string
  origin?: string
}
const RadiologyTests = ({
  id,
  patientId,
  mode,
  order,
  onSave,
  requestedBy,
  origin,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const categories = useCategories()
  const [lrNumber, setLrNumber] = useState<string>('')
  const [panels, setPanels] = useState<any>([])
  const [saved, setSaved] = useState<LabOrder>({})
  const [selectedPanels, setSelectedPanels] = useState<Panel[]>([])
  const [selectedLabTests, setSelectedLabTests] = useState<LabTest[]>([])
  const [searchText, setSearchText] = useState('')
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const [apiCall, setApiCall] = useState<boolean>(false)
  useEffect(() => {
    startSxpProxy(CHAT_PROJECT_ID, intent.generateGlobalId, {}).then((data) => {
      setLrNumber(data.data.id)
    })
  }, [location])

  const canUpdate =
    !saved.order_events ||
    saved?.order_events?.[0]?.type === modes.ordered ||
    saved?.order_events?.[0]?.type === modes.modifiedOrder

  useEffect(() => {
    setSaved(order)
  }, [order])

  useEffect(() => {
    const sample = categories[activeIndex]
    if (sample) {
      startSxpProxy(RADIOLOGY_PROJECT_ID, intent.getRadiologyLabTestById, {
        id: sample.id,
      })
        .then((data: any) => {
          const panelData =
            data.data?.radiology_sample?.[0]?.radiology_lab_tests
          const morphed = panelData?.map((p: any) => {
            return {
              ...p,
              lab_tests: [p],
              sampleId: sample.id,
              sampleName: sample.name,
            }
          })
          setPanels(morphed)
        })
        .catch(() => {
          setPanels([])
        })
    }
  }, [activeIndex, categories])
  useEffect(() => {
    setSelectedPanels([])
  }, [activeIndex])

  const handleSampleSelect = (id: number) => {
    setActiveIndex(id)
    setApiCall(false)
    setSearchText('')
  }

  const handlePanelSelect = (panel: Panel) => {
    if (!canUpdate) {
      return
    }
    const foundIndex = selectedPanels.findIndex((s) => s.id === panel.id)
    if (foundIndex >= 0) {
      const first = selectedPanels.slice(0, foundIndex)
      const second = selectedPanels.slice(foundIndex + 1)
      return setSelectedPanels([...first, ...second])
    }
    setSelectedPanels((sp) => [...sp, panel])
    setSelectedLabTests((slt) =>
      slt.filter(
        (lt) => panel.lab_tests.findIndex((plt) => plt.id === lt.id) === -1
      )
    )
  }

  const handleUpdate = (sampleId?: number) => {
    if (!canUpdate) {
      return
    }
    const filteredPanels = saved.panels?.filter(
      (sp) => sp.sampleId !== sampleId
    )
    const filteredLabTests = saved.lab_tests?.filter(
      (slt) => slt.sampleId !== sampleId
    )
    setSaved((svd) => ({
      ...svd,
      panels: filteredPanels,
      lab_tests: filteredLabTests,
    }))
    setSelectedPanels((sp) => sp.filter((p) => p.sampleId !== sampleId))
    setSelectedLabTests((slt) => slt.filter((lt) => lt.sampleId !== sampleId))
  }

  const handleSave = async () => {
    if (!KeycloakService.hasRole([RADIOLOGY_WRITE])) {
      return
    }

    const { filteredPanels, filteredLabTests } = groupTests(
      saved,
      selectedPanels,
      selectedLabTests
    )
    if (mode === 'package') {
      onSave?.(filteredPanels, filteredLabTests)
      return
    }
    if (mode === 'patient' && !requestedBy) {
      alert(externalRequest)
      return
    }
    if (mode === 'patient' && !origin) {
      alert(originRequest)
      return
    }
    let requester = requestedBy
    if (mode === 'visit') {
      requester = await getDoctorForVisit(id ?? '')
    }
    const originType = origin
    if (mode === 'visit') {
      origin = opd
    }

    saveOrder(
      mode,
      saved,
      id ?? '',
      patientId,
      lrNumber,
      filteredPanels,
      [],
      location?.resource.name ?? '',
      0,
      0,
      requester,
      originType
    )
      .then((data: any) => {
        const stateObj: Partial<OrderEvent> = {
          updated_by: KeycloakService.getUsername(),
        }
        const order: LabOrder = data.data?.insert_radiology_orders_one
        stateObj.order_id = order.id
        stateObj.type = 'ORDERED'
        stateObj.tests = prepareOrderEventTests(
          filteredPanels,
          filteredLabTests,
          'ORDERED'
        )

        startSxpProxy(RADIOLOGY_PROJECT_ID, intent.createRadiologyEvent, {
          event: stateObj,
        }).then(() => {
          setSaved(order ?? {})
          setSelectedPanels([])
          setSelectedLabTests([])
          onSave?.(filteredPanels, filteredLabTests)
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const { filteredPanels, filteredLabTests } = groupTests(
    saved,
    selectedPanels,
    selectedLabTests
  )
  const selectedPanelObj: Record<number, Panel> = filteredPanels.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: cur }),
    {}
  )
  const selectedLabTestObj: Record<number, LabTest> = filteredLabTests.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: cur }),
    {}
  )
  const transformed: Panel[] = panels.map((p: any) => {
    const foundPanel = selectedPanelObj[p.id]
    if (foundPanel) {
      const tests = p.lab_tests.map((lt: any) => ({
        ...lt,
        active: true,
        controlled: true,
      }))
      return { ...p, lab_tests: tests, active: true }
    }
    const pTests = p.lab_tests.map((lt: any) => {
      const foundLabTest = selectedLabTestObj[lt.id]
      if (foundLabTest) {
        return { ...lt, active: true, controlled: false }
      }
      return lt
    })
    return { ...p, lab_tests: pTests }
  })

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSearchText(ev.target.value)
  }

  const handleSearch = (param: string) => {
    if (param.length < 3) {
      return
    } else {
      setActiveIndex(-1)
      setApiCall(true)
    }
  }

  const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      handleSearch(searchText)
    }
  }

  return (
    <div className='lab-container'>
      <div className='lab-sidebar'>
        <RadiologyCategories
          samples={categories}
          active={activeIndex}
          onSampleSelect={handleSampleSelect}
        />
        <SelectedLabTests
          samples={categories}
          selectedPanels={selectedPanels}
          selectedLabTests={selectedLabTests}
          saved={saved}
          onUpdate={handleUpdate}
          onSave={handleSave}
        />
      </div>
      <div className='lab-content'>
        <div className='lab-search'>
          <input
            className='search-lab-input'
            placeholder={testSearchHolder}
            type='text'
            value={searchText}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <button
            disabled={searchText?.length < 3}
            onClick={() => handleSearch(searchText)}
            className={`btn btn-primaryBtn ${
              searchText?.length >= 3 ? '' : 'disableBtn'
            }`}
          >
            Search
          </button>
        </div>
        {!apiCall && (
          <RadiologyTestsName
            searchText={searchText}
            panels={transformed}
            onPanelSelect={handlePanelSelect}
          />
        )}
      </div>
    </div>
  )
}

export default RadiologyTests
