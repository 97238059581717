import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { allocateApi } from '../modals'

export const useBeds = () => {
  const [bedsLocation, setBedsLocation] = useState<string[]>([])

  useEffect(() => {
    const intent = allocateApi.getAllBeds
    startSxpProxy(IPD_PROJECT_ID, intent, {})
      .then((data: any) => {
        const sampleData = data.data.bed.map((e: any) => e?.location_id)
        const uniqueArray = sampleData.filter(
          (value: any, index: number, self: any) => {
            return self.indexOf(value) === index
          }
        )
        setBedsLocation(uniqueArray ?? [])
      })
      .catch(() => {
        setBedsLocation([])
      })
  }, [])

  return bedsLocation
}
