import React from 'react'
import { readableDateFormat, readableDateTimeFormat } from '../utils/dateUtils'

type Props = {
  date?: string
  name?: string
  mode: 'CREATE' | 'UPDATE'
}

const ProvenanceView = ({ date, name, mode }: Props) => {
  return (
    <div
      className='provenance-view'
      title={
        date
          ? `${
              mode === 'CREATE' ? 'Created' : 'Last Updated'
            } on ${readableDateTimeFormat(new Date(date))}`
          : ''
      }
    >
      {name ? (
        <>
          <span className='title'>{name || '-'}</span>
          <span className='date'>
            {date ? readableDateFormat(new Date(date)) : '-'}
          </span>
        </>
      ) : (
        <span className='time'>
          {date ? readableDateTimeFormat(new Date(date)) : 'NA'}
        </span>
      )}
    </div>
  )
}

export default ProvenanceView
