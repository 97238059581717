import { useNavigate } from 'react-router-dom'
import LocationForm from './LocationForm'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import '../admin.scss'
import { locationcreation } from '../../patients/constants'

const CreateLocation = () => {
  const navigate = useNavigate()
  const navigateBack = () => {
    navigate(-1)
  }
  return (
    <>
      <div className='package-container'>
        <IconButton
          onClick={navigateBack}
          className='mb25'
          sx={{ fontSize: 20, color: 'blue' }}
        >
          <KeyboardBackspaceIcon className='backspace-icon-search-view' />
        </IconButton>
        <span className='opdpatientdata'>{locationcreation}</span>
      </div>
      <LocationForm loc={null} />
    </>
  )
}

export default CreateLocation
