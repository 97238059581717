import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Practitioner } from 'fhir/r4'

import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID } from '../../utils/constants'
import { isoDateFormat } from '../../utils/dateUtils'
import { RootState } from '../../app/store'

export type Doctor = {
  active: boolean
  id: string
  name: string
  specialty: string
  serviceId: string[]
}

export type CalendarEvent = {
  title: string
  start: string
  end: string
  resource: any
}

export type SchedulerSliceData = {
  status: 'loading' | 'success' | 'error'
  doctors: Doctor[]
  events: CalendarEvent[]
  selectedDoctor: number
}

const initialState: SchedulerSliceData = {
  status: 'loading',
  doctors: [],
  events: [],
  selectedDoctor: 0,
}

export const getDoctorName = (d: Practitioner): string => {
  const names = d?.name
  const name = names?.[0]
  const given = name?.given?.join(' ') ?? ''
  const family = name?.family ?? ''
  return (given + ' ' + family).trim()
}

export const formatDoctors = (entries: any[]): Doctor[] => {
  const docs = entries.filter(
    (entry) => entry.resource.resourceType === 'Practitioner'
  )
  const roleObj = entries
    .filter((entry) => entry.resource.resourceType === 'PractitionerRole')
    .reduce((acc, cur) => {
      const docId = cur.resource.practitioner.reference.split('/')[1]
      return { ...acc, [docId]: cur }
    }, {})
  return docs.map((doc) => {
    const role = roleObj[doc.resource.id]
    return {
      active: doc?.resource?.active,
      id: doc.resource.id,
      name: getDoctorName(doc.resource),
      specialty: role?.resource?.specialty?.[0]?.coding?.[0]?.display ?? '-',
      serviceId:
        role?.resource?.healthcareService?.map?.(
          (hs: { reference: string }) => hs.reference.split('/')[1]
        ) ?? [],
    }
  })
}

export const fetchDoctorsAsync = createAsyncThunk(
  'scheduler/fetchDoctors',
  async (arg: { start: Date; end: Date }, { getState, dispatch }) => {
    const intent = 'getDoctorsAPI'
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent)
    const data = response.data
    if (data.entry?.length) {
      const index = selectSelectedDoctor(getState() as RootState)
      const doc = data.entry[index].resource
      dispatch(
        fetchCalendarSlotsAsync({
          docId: doc.id,
          start: arg.start,
          end: arg.end,
        })
      )
    }
    return data
  }
)

const formatSlots = (slots: any[]): CalendarEvent[] => {
  const filtered = slots.filter((slot) => slot.resource.resourceType === 'Slot')
  const memo = {}
  return filtered.map((slot: any) => {
    const slt = slot.resource
    const start = slt.start
    const end = slt.end
    const schedule = slot.resource?.schedule?.reference?.split('/')?.[1]
    const title = getTitleString(slots, schedule, memo)
    return {
      title: slt.status === 'busy' ? `${title}Booked` : `${title}Available`,
      start: start,
      end: end,
      resource: {
        id: slt.id,
        status: slt.status,
      },
    }
  })
}

const getTitleString = (entries: any[], str: string, memo: any): string => {
  if (!str) {
    return ''
  }
  if (memo[str]) {
    return memo[str]
  }
  const title: string[] = []
  const schedule = entries.find((entry) => entry.resource.id === str)
  if (schedule) {
    const actors = schedule.resource.actor
    const location = actors.find((actor: any) =>
      actor.reference.startsWith('Location')
    )
    const loc = location?.reference?.split?.('/')?.[1]
    if (loc) {
      const found = entries.find((entry) => entry.resource.id === loc)
      title.push(found?.resource?.name ?? '')
    }
    const service = actors.find((actor: any) =>
      actor.reference.startsWith('HealthcareService')
    )
    const ser = service?.reference?.split?.('/')?.[1]
    if (ser) {
      const found = entries.find((entry) => entry.resource.id === ser)
      title.push(found?.resource?.name ?? '')
    }
  }
  const filtered = title.filter((t) => t)
  const out = filtered.length ? filtered.join(', ') + ' - ' : ''
  memo[str] = out
  return out
}

export const fetchCalendarSlotsAsync = createAsyncThunk(
  'scheduler/fetchCalendarSlots',
  async (data: { docId: string; start: Date; end: Date }) => {
    const intent = 'getCalendarSlotsAPI'
    const state = {
      doctorId: data.docId,
      startDate: isoDateFormat(data.start),
      endDate: isoDateFormat(data.end),
    }
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
    return response.data
  }
)

const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState: initialState,
  reducers: {
    resetCalendarStatus: (state) => {
      state.status = 'loading'
    },
    setSelectedDoctor: (state, action: PayloadAction<number>) => {
      state.selectedDoctor = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchDoctorsAsync.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(fetchDoctorsAsync.fulfilled, (state, action) => {
        state.doctors = action.payload.entry
          ? formatDoctors(action.payload.entry)
          : []
        state.status = 'success'
      })
      .addCase(fetchCalendarSlotsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchCalendarSlotsAsync.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(fetchCalendarSlotsAsync.fulfilled, (state, action) => {
        state.events = action.payload.entry
          ? formatSlots(action.payload.entry)
          : []
        state.status = 'success'
      })
  },
})

export const { resetCalendarStatus, setSelectedDoctor } = schedulerSlice.actions

export const selectSchedulerStatus = (state: RootState) =>
  state.scheduler.status
export const selectSchedulerDoctors = (state: RootState) =>
  state.scheduler.doctors
export const selectSchedulerEvents = (state: RootState) =>
  state.scheduler.events
export const selectSelectedDoctor = (state: RootState) =>
  state.scheduler.selectedDoctor

export default schedulerSlice.reducer
