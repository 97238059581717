import { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import UploadLabDocs from './UploadLabDocs'
import uploadIcon from '../../../../assets/images/download_uplaod.png'

const UploadLabs = ({ docs }: any) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm'>
        <IconButton
          onClick={() => setOpen(false)}
          className='content-end f13 ml-auto'
        >
          x
        </IconButton>
        <DialogContent style={{ marginTop: '-2rem', padding: '20px' }}>
          <UploadLabDocs docs={docs} />
        </DialogContent>
      </Dialog>
      <div
        className='uploadbtn'
        style={{ textAlign: 'center' }}
        onClick={() => setOpen(true)}
      >
        <img title='Upload Document`s' src={uploadIcon} alt='upload button' />
      </div>
    </>
  )
}

export default UploadLabs
