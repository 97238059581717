import { ChangeEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsAdminSearch,
  selectedLmsFormMode,
  selectedLmsItem,
  selectedLmsLocationSearch,
  selectedLmsSelectedValue,
  setLmsAdminSearch,
  setLmsFormMode,
  setLmsItem,
  setLmsItemId,
  setLmsLocationSearch,
  setSelectedValue,
} from '../../lmsSlice'
import { AdminOption } from '../../models'
import KeycloakService from '../../../../utils/keycloakService'
import { LMS_ADMIN_WRITE } from '../../../../utils/roles'
import { GetWards } from '../../../bedManagement/layout/GetWards'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import '../../lms.scss'
import { IconButton } from '@mui/material'
import { capitalizeFirstLetter } from '../../../patients/utils'

type Props = {
  splitRadiology: string
}
export const labOptions: AdminOption[] = [
  { label: 'Sample', value: 'sample' },
  { label: 'Test', value: 'panel' },
  { label: 'Parameter', value: 'labTest' },
  { label: 'Department', value: 'department' },
  { label: 'Unit Type', value: 'unitType' },
  { label: 'Result Type', value: 'resultType' },
  { label: 'Method Type', value: 'method' },
  { label: 'Parameter Range', value: 'testRange' },
  { label: 'Packages', value: 'packages' },
  { label: 'Parameter Sequence', value: 'sequence' },
]
export const radiologyOptions: AdminOption[] = [
  { label: 'Category', value: 'radiologyCategory' },
  { label: 'Test', value: 'radiologyTest' },
  { label: 'TestEquipment', value: 'radiologyTestEquipment' },
]
export const bedManagementOptions: AdminOption[] = [
  { label: 'Ward', value: 'ward' },
  { label: 'Room', value: 'rooms' },
  { label: 'Bed', value: 'beds' },
]

const AdminConfig = ({ splitRadiology }: Props) => {
  const item = useAppSelector(selectedLmsItem)
  const locations = GetWards()
  const mode = useAppSelector(selectedLmsFormMode)
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const locationSearch = useAppSelector(selectedLmsLocationSearch)
  const selectedValue = useAppSelector(selectedLmsSelectedValue)
  const [selectedOption, setSelectedOption] = useState('')
  const dispatch = useAppDispatch()
  const options =
    splitRadiology === 'radiology'
      ? radiologyOptions
      : splitRadiology === 'bedManagementAdmin'
      ? bedManagementOptions
      : labOptions
  options?.sort?.((a, b) => (a?.label < b?.label ? -1 : 1))
  useEffect(() => {
    dispatch(setLmsItem(''))
    dispatch(setLmsAdminSearch(''))
  }, [dispatch, splitRadiology, options])
  useEffect(() => {
    if (selectedValue) {
      const option = options?.find((op) => op?.value === selectedValue)
      setSelectedOption(option ? option?.label : '')
    }
  }, [selectedValue, options])

  const handleChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const value = ev.target.value
    dispatch(setSelectedValue(value))
    dispatch(setLmsFormMode('none'))
    if (value) {
      dispatch(setLmsItem(value))
    }
  }

  const handleClick = () => {
    if (KeycloakService.hasRole([LMS_ADMIN_WRITE])) {
      if (mode === 'none') {
        dispatch(setLmsItemId(''))
        dispatch(setLmsFormMode('create'))
      } else {
        dispatch(setLmsFormMode('none'))
      }
    }
  }

  return (
    <>
      <div className='mb10'>
        <div className='mtminus6 package-container'>
          <div>
            {mode !== 'none' && (
              <>
                <IconButton
                  onClick={handleClick}
                  disabled={
                    !item ||
                    item === 'sequence' ||
                    !KeycloakService?.hasRole([LMS_ADMIN_WRITE])
                  }
                >
                  <KeyboardBackspaceIcon className='back-btn-lab' />
                </IconButton>
              </>
            )}
          </div>
          <span className='admin-header-text'>
            {splitRadiology === 'bedManagementAdmin'
              ? 'BedManagement'
              : splitRadiology === 'radiology'
              ? 'Radiology'
              : 'Laboratory'}
            {selectedOption && (
              <span> - {capitalizeFirstLetter(selectedOption)}</span>
            )}
          </span>
        </div>
        <div className='admin-config mtminus29'>
          <div className='bed-admin'>
            {splitRadiology === 'bedManagementAdmin' && (
              <select
                className='admin-select'
                name='location'
                value={locationSearch}
                onChange={({ target: { value } }) =>
                  dispatch(setLmsLocationSearch(value))
                }
              >
                <option value=''>Select Location</option>
                {locations.map((op: any, i: number) => (
                  <option key={i} value={op}>
                    {op}
                  </option>
                ))}
              </select>
            )}
          </div>
          {mode === 'none' && (
            <div>
              <input
                className='admin-lab-searchbox'
                type='search'
                value={searchParam}
                onChange={({ target: { value } }) =>
                  dispatch(setLmsAdminSearch(value))
                }
                placeholder='Search'
              />
            </div>
          )}
          {mode === 'none' && (
            <button
              disabled={
                !item ||
                item === 'sequence' ||
                !KeycloakService.hasRole([LMS_ADMIN_WRITE])
              }
              className='admin-btn'
              onClick={handleClick}
            >
              Create
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default AdminConfig
