import { useEffect, useRef, useState, ChangeEvent } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { Practitioner, PractitionerRole, Provenance } from 'fhir/r4'
import { FormDoctor } from './DoctorsForm'
import { toast } from 'react-toastify'
import { ProvenanceData } from '../../patients/patientsSlice'
import { getProvenanceData } from '../../patients/utils'
import { emptyString } from '../../Radiology/constants'
import { doctors } from '../../patientRegstration/constants'
import {
  formValues,
  forms,
  intent,
  messages,
  placeHolder,
  tableText,
} from '../constants'
import ProvenanceView from '../../../components/ProvenanceView'
import CreateButton from '../../../components/CreateButton'
import EditButton from '../../../components/EditButton'
import '../admin.scss'

export const Doctors = () => {
  const [doctorsData, setDoctorsData] = useState<FormDoctor[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState('active')
  const [checkedItems, setCheckedItems] = useState<boolean[]>([])
  const [reload, setReload] = useState(0)
  const navigate = useNavigate()
  const ids = doctorsData.map((p) => p.id).join(',')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  useEffect(() => {
    if (ids) {
      startSxpProxy(ADMIN_PROJECT_ID, intent.fetchProvByDoctors, {
        pracIds: ids,
      }).then((data) => {
        const entries =
          data.data?.entry?.map(
            (dde: { resource: Provenance | Practitioner }) => dde.resource
          ) ?? []
        const obj = getProvenanceData(entries)
        setProvenances(obj)
      })
    }
  }, [ids])
  const formatDoctors = (
    roles: { resource: PractitionerRole }[],
    id?: string
  ) => {
    if (!id) {
      return 'NA'
    }

    const resType = roles.find(
      (e) => e.resource?.practitioner?.reference?.split?.('/')?.[1] === id
    )
    const code =
      resType?.resource?.specialty?.[0]?.coding?.[0]?.code ?? emptyString
    const display =
      resType?.resource?.specialty?.[0]?.coding?.[0]?.display ?? emptyString
    return `${display}::${code}`
  }
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, intent.getDoctorsAshaWorkers)
      .then((res) => {
        const practitioners = res?.data?.entry?.filter(
          (e: { resource: { resourceType: string } }) =>
            e.resource?.resourceType === forms.practitioner
        )
        const roles: { resource: PractitionerRole }[] =
          res?.data?.entry?.filter(
            (e: { resource: { resourceType: string } }) =>
              e.resource?.resourceType === forms.practitionerRole
          )
        const data: FormDoctor[] = practitioners?.map(
          (e: { resource: Practitioner }) => {
            const role = roles.find(
              (r) =>
                r.resource?.practitioner?.reference?.split?.('/')?.[1] ===
                e.resource.id
            )
            return {
              id: e.resource?.id,
              firstName:
                e.resource?.name?.[0]?.given?.join(emptyString) ?? emptyString,
              middleName: emptyString,
              lastName: e.resource?.name?.[0]?.family ?? emptyString,
              salutation: e.resource?.name?.[0]?.prefix?.[0] ?? emptyString,
              registeredOn:
                e.resource?.identifier?.[1]?.period?.start ?? emptyString,
              dob: e.resource?.birthDate,
              email:
                e?.resource?.telecom?.find(
                  (contact: any) => contact?.system === formValues.email
                )?.value || formValues.na,
              phone:
                e?.resource?.telecom?.find(
                  (contact: any) => contact?.system === formValues.phone
                )?.value || formValues.na,
              qualification:
                e?.resource?.qualification?.[0]?.code?.text || formValues.na,
              gender: e?.resource?.gender || formValues.na,
              regNumber:
                e?.resource?.qualification?.[0]?.identifier?.[0]?.value,
              speciality:
                formatDoctors(roles, e?.resource?.id) || formValues.na,
              active: e?.resource?.active ?? true,
              operatingUnit: e?.resource?.address?.map((d: any) => {
                return d?.text
              })?.[0],
              healthService:
                role?.resource?.healthcareService?.map?.(
                  (hs) => hs.reference?.split?.('/')?.[1] ?? emptyString
                ) ?? [],
            }
          }
        )
        data.sort((a, b) => (a.id < b.id ? -1 : 1))
        setDoctorsData(data)
      })
      .catch((err) => console.log(err))
  }, [reload])

  const filteredDoctors = doctorsData
    .filter((doctor) => {
      const isNameSearch =
        doctor.firstName.toLowerCase().startsWith(searchText.toLowerCase()) ||
        doctor.lastName.toLowerCase().startsWith(searchText.toLowerCase())

      const isStatusMatched =
        statusFilter === formValues.all ||
        (statusFilter === formValues.active && doctor.active) ||
        (statusFilter === formValues.inActive && !doctor.active)

      return isNameSearch && isStatusMatched
    })
    .filter((doctor) => {
      return (
        doctor.firstName.trim() !== emptyString ||
        doctor.lastName.trim() !== emptyString
      )
    })
  filteredDoctors?.sort?.(
    (a: { firstName: string }, b: { firstName: string }) =>
      a.firstName?.toLowerCase() < b.firstName?.toLowerCase() ? -1 : 1
  )

  const handleCreate = () => {
    navigate('create')
  }

  const handleDoctorStatusChange = (l: FormDoctor) => {
    if (l.id) {
      const spec = l.speciality?.split?.('::') ?? []
      const state = {
        id: l.id,
        lastName: l.lastName,
        firstName: l.firstName,
        middleName: '',
        salutation: l.salutation,
        email: l.email,
        mobileNumber: l.phone,
        regNumber: l.regNumber,
        qualificationValue: l.qualification,
        gender: l.gender,
        dob: l.dob,
        specialtyId: spec[1],
        specialtyName: spec[0],
        healthCareServiceId: l.healthService.join(','),
        active: !l.active,
      }
      startSxpProxy(ADMIN_PROJECT_ID, intent.doctorActive, state)
        .then(() => {
          setCheckedItems([])
          setReload((r) => r + 1)
        })
        .catch(() => {
          toast.error(messages.errorDoctorMessage)
        })
    }
  }

  const handleEdit = (id?: string) => {
    if (id) {
      navigate(`edit/${id}`)
    }
  }

  const handleCheckboxChange = (index: number) => {
    const newCheckedItems = [...checkedItems]
    newCheckedItems[index] = !newCheckedItems[index]
    setCheckedItems(newCheckedItems)
  }

  const handleStatusFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(event.target.value)
    setCheckedItems([])
  }

  return (
    <>
      <div className='button-enclosure flexible mb6 mtminus8'>
        <div className='filterandtext'>
          <div className='membershipText'>{doctors}</div>
        </div>
        <div className='status-flex'>
          <select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            className='status-filter doctor-status'
          >
            <option value={formValues.active}>{forms.active}</option>
            <option value={formValues.inActive}>{forms.inactive}</option>
          </select>
          <input
            type='search'
            className='admin-search mr-none'
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            placeholder={placeHolder.doctorName}
          />
          <CreateButton title={'Create'} onClick={handleCreate} />
        </div>
      </div>
      <div
        ref={patientTableContainerRef}
        className='data-table table-fixed admin-table-table-container'
        style={{ height: '440px', overflowY: 'auto' }}
      >
        <table className='data-table table-fixed admin-table relative'>
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              <th className='table-w-10'>Sl&nbsp;No</th>
              <th className='table-w-8'></th>
              <th className='text-left table-w-25'>{tableText.name}</th>
              <th className='text-left table-w-20'>{tableText.speciality}</th>
              <th className='text-left table-w-20'>
                {tableText.qualification}
              </th>
              <th className='text-left table-w-20'>{tableText.regNo}</th>
              <th className='table-w-20'>{tableText.email}</th>
              <th className='text-left table-w-20'>{tableText.phone}</th>
              <th className='text-left table-w-15'>{tableText.gender}</th>
              <th className='text-left table-w-27'>
                {tableText.operatingUnit}
              </th>
              <th className='text-left table-w-20'>{tableText.status}</th>
              <th className='text-left table-w-20'>{tableText.createdBy}</th>
              <th className='text-left table-w-20'>{tableText.updatedBy}</th>
              <th className='text-left table-w-15'>{tableText.action}</th>
            </tr>
          </thead>
          <tbody>
            {filteredDoctors.length > 0 ? (
              filteredDoctors.map((user, index) => (
                <tr key={index}>
                  <td className='table-w-4'>{index + 1}</td>
                  <td>
                    <input
                      type='checkbox'
                      checked={checkedItems[index] || false}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </td>
                  <td className='text-left table-w-15'>{`${user.salutation} ${user.firstName} ${user.lastName}`}</td>
                  <td className='text-left table-w-20'>
                    {user.speciality.split('::')[0]}
                  </td>
                  <td className='text-center table-w-20'>
                    {user.qualification}
                  </td>
                  <td className='text-left table-w-10 wordBreak'>
                    {user.regNumber}
                  </td>
                  <td className='table-w-40 wordBreak normal'>{user.email}</td>
                  <td className='text-left table-w-15'>{user.phone}</td>
                  <td className='text-left'>{user.gender}</td>
                  <td>{user?.operatingUnit}</td>
                  <td>
                    {!checkedItems[index] ? (
                      <div>
                        {user.active ? formValues.active : formValues.inActive}
                      </div>
                    ) : (
                      <div>
                        <select
                          value={
                            user.active
                              ? formValues.active
                              : formValues.inActive
                          }
                          onChange={() => handleDoctorStatusChange(user)}
                        >
                          <option value={formValues.active}>
                            {formValues.active}
                          </option>
                          <option value={formValues.inActive}>inactive</option>
                        </select>
                      </div>
                    )}
                  </td>

                  <td className='text-center'>
                    <ProvenanceView
                      date={user.registeredOn}
                      name={provenances[user.id]?.createdBy}
                      mode={'CREATE'}
                    />
                  </td>
                  <td>
                    <ProvenanceView
                      date={user.registeredOn}
                      name={
                        provenances[user.id]?.updatedBy ??
                        provenances[user.id]?.createdBy
                      }
                      mode={'UPDATE'}
                    />
                  </td>
                  <td className='edit-relation'>
                    <EditButton
                      title='Edit Doctor'
                      onEdit={() => handleEdit(user?.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11} className='text-center'>
                  {tableText.noDoctorsFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Doctors
