import React from 'react'
import '../lms/lms.scss'
import { Outlet } from 'react-router-dom'
import RenderOnRole from '../../components/RenderOnRole'
import { RADIOLOGY_READ } from '../../utils/roles'

const Lms = () => {
  return (
    <RenderOnRole
      roles={[RADIOLOGY_READ]}
      element={
        <div className='lms-container-div'>
          <div className='lms-content-div'>
            <Outlet />
          </div>
        </div>
      }
    />
  )
}

export default Lms
