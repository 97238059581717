export const SOCKET_URL =
  window._env_.REACT_APP_SOCKET_URL ||
  'https://sxp.channel-adapter.k8s.techsophy.com/'
export const SOCKET_PATH = window._env_.REACT_APP_SOCKET_PATH || '/socket.io/'
export const FILE_SERVER_URL =
  window._env_.REACT_APP_CHAT_FILE_SERVER_URL ||
  'https://fileserver.techsophy.com/'
export const ODOO_URL =
  window._env_.REACT_APP_ODOO_URL ||
  'https://dev.medunited.odoo-acc.techsophy.com/'
export const REPORT_URL =
  window._env_.REACT_APP_REPORT_URL || 'https://omnikare.reports.medunited.in'
export const RMS_URL =
  window._env_.REACT_APP_RMS_URL ||
  'https://dev.medunited-rms-ui.techsophy.com/'
export const INNOMAINT_URL =
  window._env_.REACT_APP_INNOMAINT_URL ||
  'https://innomaint-qr-generator.medunited.in/'
export const INNOMAINT_URL_QR_CODE =
  window._env_.REACT_APP_INNOMAINT_URL_QR_CODE || 'https://app.innomaint.com/'
export const CHAT_PROJECT_ID =
  window._env_.REACT_APP_CHAT_PROJECT_ID || '636fb065979a5a8ea4956f7e'
export const REGISTRATION_PROJECT_ID =
  window._env_.REACT_APP_REGISTRATION_PROJECT_ID || '63a2b3b0765c6b905409c76a'
export const OPD_PROJECT_ID =
  window._env_.REACT_APP_OPD_PROJECT_ID || '63a5bda6765c6b450a09db6a'
export const LABS_PROJECT_ID =
  window._env_.REACT_APP_LABS_PROJECT_ID || '63ad345fb78763adb36b72fe'
export const MEMBERSHIP_PROJECT_ID =
  window._env_.REACT_APP_MEMBERSHIP_PROJECT_ID || '64edccfcc086230bb97f874b'
export const PACKAGE_PROJECT_ID =
  window._env_.REACT_APP_PACKAGE_PROJECT_ID || '65defb6c450980f74b52d59d'
export const ADMIN_PROJECT_ID =
  window._env_.REACT_APP_ADMIN_PROJECT_ID || '65095e75c0862383507fa7c9'
export const RADIOLOGY_PROJECT_ID =
  window._env_.REACT_APP_RADIOLOGY_PROJECT_ID || '655c784d0d90b05960c921f8'
export const IPD_PROJECT_ID =
  window._env_.REACT_APP_IPD_PROJECT_ID || '63f6eb15483c16fd92bd0979'
export const AUTH_SERVER_URL =
  window._env_.REACT_APP_AUTH_SERVER_URL ||
  'https://sxp.keycloak-dev.techsophy.com/auth'
export const REALM = window._env_.REACT_APP_REALM || 'medunited'
export const CLIENT_ID = window._env_.REACT_APP_CLIENT_ID || 'medunited'
export const DMS_CLIENT_ID = window._env_.REACT_APP_DMS_CLIENT_ID || ''
export const DMS_CLIENT_SECRET = window._env_.REACT_APP_DMS_CLIENT_SECRET || ''
export const DMS_USERNAME = window._env_.REACT_APP_DMS_USERNAME || ''
export const DMS_PASSWORD = window._env_.REACT_APP_DMS_PASSWORD || ''
export const NEW_LINE_CHARACTER = '\n'
export const NEW_LINE_REPLACE = '*#*'
export const VERSION = 'WORKING'
export const upload = 'Upload'
export const uploadImageText = 'Please upload image to get url'
export const linkCopyText = 'Please click on the link to copy it.'
export const COMMANDCENTER_URL =
  window._env_.REACT_APP_COMMANDCENTER_URL ||
  'https://dev.commandcenter.techsophy.com/'
export const enterMobileNumber = 'Enter Mobile No. To Send Report On Whatsapp:'
export const sendText = 'Send'
export const notificationUrl = `${window._env_.REACT_APP_MEDUNITED_AWGMENT_GATEWAY}/notification/v1/graphql`
export const downloadUrl = `${window._env_.REACT_APP_MEDUNITED_AWGMENT_GATEWAY}/dms/v1/documents/download/public/${window._env_.REACT_APP_REALM}?id=`
export const trimPDF = {
  addressText: 'Reg. Address: 4-2-50/2, College Road, Amalapuram - 533201',
  mobileText: '919851247366',
  emailText: 'care@medunitedhospitals.in',
  footerTextOne: '1. This Is An Electronically Authenticated Report.',
  footerTextTwo:
    '2. Please Correlate With Clinical Findings, Consult a Doctor For Discussion & Further Medication',
  uploadPdfText: 'Upload PDF',
  selectSourceText: 'Select Source:',
  kimsText: 'KIMS',
  tenetText: 'Tenet',
  gayatriText: 'Gayatri Labs',
  withHeaderAndFooterText: 'With Header & Footer',
  withoutHeaderAndFooterText: 'Without Header & Footer',
  trimAndDownloadText: 'Trim & Download',
  selectOption: 'Select An Option',
}
export const KIMS_LOCATION_ID =
  window._env_.REACT_APP_KIMS_LOCATION_ID || '182755'
export const CRITICAL_BED_ID = window._env_.REACT_APP_CRITICAL_BED_ID || 3
export const NON_CRITICAL_BED_ID =
  window._env_.REACT_APP_NON_CRITICAL_BED_ID || 4

export const MEDUNITED_AWGMENT_GATEWAY =
  window._env_.REACT_APP_MEDUNITED_AWGMENT_GATEWAY ||
  'https://api-dev-medunited.techsophy.com/api'
