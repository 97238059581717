import React from 'react'
import { AppointmentData } from '../appointmentsSlice'

type Props = {
  appointment: AppointmentData
}

const MeetingLink = ({ appointment }: Props) => {
  const joinMeeting = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <button
      className='meetingLink'
      onClick={() => joinMeeting(appointment.meetingLink)}
    >
      Join Meeting
    </button>
  )
}

export default MeetingLink
