/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import LabTests from '../../../labTests/LabTests'
import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage'
import { Package, toastOptions } from '../../models'
import { LabOrder, LabTest, Panel } from '../../../labTests/models'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'
import KeycloakService from '../../../../utils/keycloakService'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectedLmsItemId, setLmsFormMode } from '../../lmsSlice'
import { PACKAGES_WRITE } from '../../../../utils/roles'
import PackageOrder from './packageOrder'

export type PackageItem = {
  name?: string
  price?: string
  from?: string
  till?: string
  odooPackageId?: any
}

const Packages = () => {
  return (
    <>
      <PackageOrder />
    </>
  )
}

export default Packages
