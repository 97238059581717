import { useState } from 'react'
import Dialog from '../../../../components/Dialog'
import EventDialog from './EventDialog'
import { LmsOrder } from '../../models'

type Props = {
  order: LmsOrder
  name: string
}

const PatientId = ({ order, name }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <EventDialog order={order} onClose={() => setOpen(false)} />
      </Dialog>
      <span className='patient-id' onClick={() => setOpen(true)}>
        {name}
      </span>
    </>
  )
}

export default PatientId
