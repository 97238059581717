import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { isoDateFormat } from '../../utils/dateUtils'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID } from '../../utils/constants'
import { getDoctorName } from '../scheduler/schedulerSlice'
import { makeName } from '../lms/utils'

export type AppointmentData = {
  id: string
  thopId: string
  patientName: string
  patientNumber: string
  patientUhid: string
  patientThopId: string
  patientId: string
  slotTiming: string
  practitioner: string
  speciality: string
  status: string
  meetingLink: string
  encounter?: string
  invoiceId?: string
  locationId?: string
  consultationType: string
  reasonCode: string
}

export type AppointmentSliceData = {
  status: 'loading' | 'success' | 'error'
  data: AppointmentData[]
  selectedId: string
  selectedPatient: string
  selectedDate: string
  searchValue: string
  provenanceKey: number
}

const initialState: AppointmentSliceData = {
  status: 'loading',
  data: [],
  selectedId: '',
  selectedPatient: '',
  selectedDate: new Date().toISOString(),
  searchValue: '',
  provenanceKey: 0,
}

export const fetchAppointmentsAsync = createAsyncThunk(
  'appointments/fetchAppointments',
  async (data: { date: Date; locationId: string }) => {
    const intent = 'getAppointmentsBySlotAndDate'
    const state = {
      startDate: isoDateFormat(data.date),
      locationId: data.locationId,
    }
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
    return response.data
  }
)

export const updateAppointmentAsync = createAsyncThunk(
  'appointments/updateAppointment',
  async (data: { appId: string; status: string; cancelReason: string }) => {
    const intent = 'updateAppointmentAPI'
    const state = {
      appointmentId: data.appId,
      appointmentStatus: data.status,
      cancelReason: data.cancelReason,
    }
    const response = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
    return response.data
  }
)

const formatData = (entries: any[]): AppointmentData[] => {
  const appointments = entries.filter(
    (en: any) => en.resource.resourceType === 'Appointment'
  )
  const encounters = entries.filter(
    (en: any) => en.resource.resourceType === 'Encounter'
  )
  const entryObj = entries.reduce((acc, cur) => {
    const id = cur.resource.id
    return { ...acc, [id]: cur }
  }, {})
  const roleObject = entries
    .filter((en: any) => en.resource.resourceType === 'PractitionerRole')
    .reduce((acc, cur) => {
      const id = cur.resource?.practitioner?.reference?.split('/')?.[1] ?? ''
      return {
        ...acc,
        [id]: cur.resource?.specialty?.[0]?.coding?.[0]?.display || '',
      }
    }, {})
  const mapped = appointments.map((app) => {
    let encounterId
    if (encounters.length) {
      const enc = encounters.find((e: any) => {
        const refs = e.resource.appointment
        if (refs?.length) {
          for (const ref of refs) {
            if (ref.reference === `Appointment/${app.resource.id}`) {
              return true
            }
          }
        }
        return false
      })
      encounterId = enc?.resource?.id
    }
    const participants = app.resource.participant
    let patient = participants.find((p: any) =>
      p.actor.reference.startsWith('Patient')
    )
    if (!patient) return null
    const patientId = patient.actor.reference.split('/')[1]
    patient = entryObj[patientId]
    const telecom = patient?.resource?.telecom?.find(
      (prt: any) => prt.use === 'mobile'
    )
    const telecom2 = patient?.resource?.telecom?.find(
      (prt: any) => prt.use === 'home'
    )
    const slotReference = app.resource.slot?.[0]?.reference
    const slotId = slotReference?.split('/')[1]
    const slot = entryObj[slotId]
    const scheduleReference = slot?.resource?.schedule?.reference
    const scheduleId = scheduleReference?.split('/')?.[1]
    const schedule = entryObj[scheduleId]
    const doctorRef = schedule?.resource?.actor?.[0]?.reference
    const locationRef = schedule?.resource?.actor?.find((sra: any) =>
      sra.reference.startsWith('Location')
    )
    const doctorId = doctorRef?.split('/')?.[1]
    const doctor = entryObj[doctorId]
    const specialty = roleObject[doctorId]
    const meetingLink =
      app.resource.comment && app.resource.comment !== 'Appointment Demo'
        ? app.resource.comment
        : ''
    const invoiceId =
      app.resource?.identifier?.find(
        (ari: any) => ari.system === 'paymentStatus'
      )?.value ?? ''
    const thopId = app.resource?.identifier?.[0]?.value ?? ''
    const reasonCode = app.resource?.reasonCode?.[0]?.text ?? ''
    return {
      id: app.resource.id,
      thopId: thopId,
      patientId: patient?.resource?.id ?? '',
      patientName: makeName(patient?.resource?.name),
      patientNumber: telecom?.value ?? telecom2?.value ?? '',
      patientUhid: patient?.resource?.identifier?.[2]?.value ?? '',
      patientThopId: patient?.resource?.identifier?.[0]?.value ?? '',
      slotTiming: slot?.resource?.start ?? app.resource?.start,
      practitioner: getDoctorName(doctor?.resource),
      speciality:
        specialty || app.resource.specialty?.[0]?.coding?.[0]?.display || '',
      status: app.resource.status,
      meetingLink: meetingLink,
      encounter: encounterId,
      invoiceId: invoiceId,
      locationId: locationRef?.reference?.split?.('/')[1],
      consultationType:
        reasonCode && reasonCode !== 'Paid Consultation' ? 'Free' : 'Paid',
      reasonCode: reasonCode,
    }
  })
  return mapped.flatMap((x) => (x ? [x] : []))
}

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    resetAppointmentsStatus: (state) => {
      state.status = 'loading'
      state.data = []
    },
    setSelectedAppointment: (state, action: PayloadAction<string>) => {
      state.selectedId = action.payload
    },
    setSelectedPatient: (state, action: PayloadAction<string>) => {
      state.selectedPatient = action.payload
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppointmentsAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAppointmentsAsync.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(fetchAppointmentsAsync.fulfilled, (state, action) => {
        state.data = formatData(action.payload.entry ?? [])
        state.status = 'success'
      })
      .addCase(updateAppointmentAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateAppointmentAsync.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(updateAppointmentAsync.fulfilled, (state, action) => {
        state.data = state.data.map((dt) => {
          if (dt.id === action.payload.id) {
            dt.status = action.payload.status
          }
          return dt
        })
        state.status = 'success'
        state.provenanceKey += 1
      })
  },
})

export const {
  resetAppointmentsStatus,
  setSelectedAppointment,
  setSelectedPatient,
  setSelectedDate,
  setSearchValue,
} = appointmentsSlice.actions

export const selectAppointmentsStatus = (state: RootState) =>
  state.appointments.status
export const selectAppointments = (state: RootState) => state.appointments.data
export const selectSelectedAppointment = (state: RootState) =>
  state.appointments.selectedId
export const selectSelectedPatient = (state: RootState) =>
  state.appointments.selectedPatient
export const selectSelectedAppointmentDate = (state: RootState) =>
  state.appointments.selectedDate
export const selectSearchValue = (state: RootState) =>
  state.appointments.searchValue
export const selectProvenanceKey = (state: RootState) =>
  state.appointments.provenanceKey

export default appointmentsSlice.reducer
