import { IconButton } from '@mui/material'
import DepartmentsForm from '../department/DepartmentsForm'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useNavigate } from 'react-router-dom'
import PartnerForm from './PartnerForm'

const CreateOrganizationForm=()=>{
    const navigate=useNavigate()
    return(
        <>
         <IconButton  onClick={() => navigate('/administration/partner')}>
        <KeyboardBackspaceIcon className='back-btn-admin' />
      </IconButton>
      <PartnerForm />
       </>
    )
}
export default CreateOrganizationForm