import React, { ChangeEvent, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Doctor } from '../../scheduler/schedulerSlice'
import {
  selectSlotsDoctors,
  fetchSlotsDoctorsAsync,
} from '../../slots/slotsSlice'
import { useOrganizations } from '../../lms/components/useOrganizations'

type Props = {
  requestedBy: string
  onChange: (ev: ChangeEvent<HTMLSelectElement>) => void
}

const RequestedBy = ({ requestedBy, onChange }: Props) => {
  const organizations = useOrganizations()
  const doctors = useAppSelector(selectSlotsDoctors)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchSlotsDoctorsAsync())
  }, [dispatch])

  return (
    <select className='requested-by' value={requestedBy} onChange={onChange}>
      <option value=''>Requested By</option>
      <option value='Self'>Self</option>
      {organizations.map((org) => (
        <option key={org.id} value={org.name}>
          {org.name}
        </option>
      ))}
      {doctors?.length
        ? doctors.map((d: Doctor) => (
            <option
              key={d.name}
              value={`${'Dr. ' + d.name}${
                d.specialty ? ` [${d.specialty}]` : ''
              }`.trim()}
            >
              {'Dr. ' + d.name}
            </option>
          ))
        : null}
    </select>
  )
}

export default RequestedBy
