import React, { useRef, useEffect } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import LinkButton from './LinkButton'

const LabTestTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = getSearchResults(data, searchParam)

  filtered?.sort?.((a: any, b: any) => {
    if (a.department?.department_name < b.department?.department_name) {
      return -1
    } else if (a.department?.department_name > b.department?.department_name) {
      return 1
    } else {
      if (a.panel?.name < b.panel?.name) {
        return -1
      } else if (a.panel?.name > b.panel?.name) {
        return 1
      } else {
        return a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1
      }
    }
  })

  const paramTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = paramTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={paramTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Id</th>
            <th className='table-w-20'>Parameter Name</th>
            <th>Department</th>
            <th className='table-w-15'>Sample</th>
            <th>Test</th>
            <th className='table-w-10'>Result Type</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d.id}>
                <td>{d.id}</td>
                <td>
                  <LinkButton id={d.id} name={d.name} onClick={onEdit} />
                </td>
                <td>{d.department?.department_name ?? '-'}</td>
                <td>{d.panel?.sample?.name ?? '-'}</td>
                <td>{d.panel?.name ?? '-'}</td>
                <td>{d.test_result_type?.result_type ?? '-'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className='text-center'>
                No Test Parameters Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default LabTestTable
