import { useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { allocateApi, allocation } from '../modals'
import { bedFilter } from '../constants'

type props = {
  onConfirm: (e: boolean) => void
  wardId: bedFilter
  onClose: () => void
}
const convertISOStringToDate = (isoString: any) => {
  const date = new Date(isoString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
const EditAdmissionDate = ({ onConfirm, wardId }: props) => {
  const [date, setDate] = useState(wardId.admission)
  const [error, setError] = useState('')

  const handleDateChange = (event: any) => {
    const selectedDateValue = event.target.value
    const selectedDate = new Date(selectedDateValue)
    const currentDate = new Date()
    if (selectedDate > currentDate) {
      setError('Selected date cannot be greater than the current date.')
    } else {
      setError('')
      setDate(selectedDateValue)
    }
  }
  const handleDate = (e: boolean) => {
    const state = {
      id: wardId?.patientAssignmentMap?.[0]?.id,
      bedId: wardId?.id,
      patientId: wardId?.patientId,
      dateStarted: date,
    }
    startSxpProxy(
      IPD_PROJECT_ID,
      allocateApi.updateByAdmissionDate,
      state
    ).then((data) => {
      onConfirm(e)
    })
  }
  return (
    <>
      <div className='conform-container'>
        <span className='conform-content'>{allocation.dateModal}</span>
        <div className='conform-button'>
          <input
            type='date'
            value={convertISOStringToDate(date)}
            onChange={handleDateChange}
          />
          <input
            type='button'
            value='Modify'
            className={`${
              date === wardId.admission ? 'disabled-btn' : 'conform'
            }`}
            disabled={date === wardId.admission}
            onClick={() => handleDate(false)}
          />
        </div>
        {error && (
          <div className='uid-search'>
            {allocation.error}
            {error}
          </div>
        )}
      </div>
    </>
  )
}
export default EditAdmissionDate
