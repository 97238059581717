export const allocateApi = {
  getWardsByLocation: 'getWardsByLocation',
  getBedDetailsByLocationAndWards: 'getBedDetailsByLocationAndWards',
  getPatientDetailsByIdentifier: 'getPatientDetailsByIdentifier',
  getAllBeds: 'getAllBeds',
  getAllBedsByLocation: 'getAllBedsByLocation',
  createBedPatient: 'createBedPatient',
  getBedDetailsById: 'getBedDetailsById',
  dischargeBedPatient: 'dischargeBedPatient',
  updateDischargedDate: 'updateDischargedDate',
  transferBedDetails: 'transferBedDetails',
  getWardDetails: 'getWardDetails',
  updateBedId: 'updateBedId',
  getAllWardsAndBeds: 'getAllWardsAndBeds',
  getBedPatientAssignment: 'getBedPatientAssignment',
  editBedPatientAssignment: 'editBedPatientAssignment',
  getAllSubEncounters: 'getAllSubEncounters',
  updateByAdmissionDate: 'updateByAdmissionDate',
  createAndUpdateBed: 'createAndUpdateBed',
  updateAndDischargeBed: 'updateAndDischargeBed',
  closeEncounter: 'closeEncounter',
}
export const allocation = {
  assigned: 'ASSIGNED',
  admittedList: 'Admitted',
  all: 'All',
  dischargedList: 'Discharged',
  assignmentList: 'IPD Patients',
  critical: 'critical',
  nonCritical: 'non-critical',
  criticalNumber: 3,
  nonCriticalNumber: 4,
  zeroNumber: 0,
  occupied: 'OCCUPIED',
  available: 'AVAILABLE',
  dischargedText: 'DISCHARGED',
  transferred: 'TRANSFERRED',
  location: 'Location:',
  bedManagementRoute: 'bedManagement',
  locationOne: 'Location',
  ward: 'Ward/Room',
  availableCase: 'Available',
  occupiedCase: 'Occupied',
  availabeNo: 'Available No:',
  occupiedNo: 'Occupied No:',
  noBeds: 'No Beds Available',
  patientHolder: 'Search By Patient UHID',
  hyphen: '-',
  layout: 'layout',
  list: 'list',
  lists: 'List View',
  layouts: 'Layout View',
  selectWard: 'Select Ward',
  selectLocation: 'Select Location',
  selectBed: 'Select Bed',
  selectStatus: 'Select Status',
  selectCategory: 'Select Category',
  waitList: 'WaitList:',
  discharged: 'Discharged',
  availableHyphen: 'Available:',
  occupiedHyphen: 'Occupied:',
  noWards: 'No Wards Available',
  bedDetails: 'Bed Details:',
  patientDetails: 'Patient Details:',
  uidSearch: 'UID Search:',
  error: 'Error:',
  dischargeModal: 'Do you want to Discharge Patient',
  dateModal: 'Please Select Date',
  proposed: 'Proposed Bed Details:',
  reasonTransfer: 'Reason For Transfer',
  conformation: 'conformation',
  noAvailableBeds: 'No Available beds',
  bedManagement: 'Bed ManageMent',
  bedLayout: 'Bed Layout',
  bedOverview: 'Bed Allocation',
  bedDashboard: 'BedMgmt Dashboard',
  selectAnOption: 'Select An Option',
  viewMore: 'View More',
  viewLess: 'View Less',
  addRemarks: 'Add Remarks',
  ipVisitDashboard: 'IP Visit Dashboard',
  medications: 'Medications',
  noMedications: 'No Medications found',
  allDates: 'All Dates',
  visitHistory: 'Visit History',
  ipVisitDetails: 'IP Visit Details',
  save: 'Save',
  edit: 'Edit',
  ipVisitSummary: 'Summary',
  bedview: 'Bed Overview',
}
export const errorMessages = {
  digitError: 'enter atleast 10 digits',
  uhidError: 'patient with uhid does not exist',
  patientError: 'already  patient exists in bed',
}
export const tableHeadText = {
  id: 'Id',
  slNo: 'Sl.No',
  bedId: 'BedId',
  doctorName: 'Dr.Name',
  remarks: 'Remarks',
  additionalInfo: 'Additional Info',
  bedNo: 'Bed No',
  noOfDays: 'Daysof.Adm',
  operatingUnit: 'Operating Unit',
  ward: 'Ward/Room',
  bedType: 'BedType',
  status: 'Status',
  uhid: 'UHID',
  name: 'Name',
  total: 'Total',
  admission: 'Admission.Dt',
  discharge: 'Discharge.Dt',
  floor: 'Floor',
  bedCategory: 'Bed Category',
  roomNo: 'Room No',
  age: 'Age',
  gender: 'Gender',
  mobileNo: 'Mobile No.',
  admit: 'Admit Dt',
  view: 'View',
  patientdocuments: 'Patient Docs',
  selectDate: 'Select Date',
}
