import React, { useEffect, useRef, useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import '../admin.scss'
import CreateButton from '../../../components/CreateButton'
import EditButton from '../../../components/EditButton'
import { useNavigate } from 'react-router-dom'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { intent, tableText } from '../constants'

export type LabDepartment = {
  id: string | undefined
  code: string
  display: string
}

export type SpecialityData = {
  status: string
  specialities: LabDepartment[]
}

const LabDepartments = () => {
  const [specialityData, setSpecialityData] = useState<SpecialityData[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, intent.getLabDepartments)
      .then((res) => {
        const data = res?.data?.entry?.map((entry: any) => {
          const status = entry?.resource?.status || 'NA'
          const specialities =
            entry?.resource?.compose?.include[0]?.concept || []

          return {
            status,
            specialities: specialities.map((concept: any) => ({
              code: concept?.code || 'NA',
              display: concept?.display || 'NA',
            })),
          }
        })

        setSpecialityData(data)
      })
      .catch((err) => console.error(err))
  }, [])

  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const handleCreate = () => {
    navigate('create')
  }

  const handleEdit = (id?: string) => {
    if (id) {
      navigate(`edit/${id}`)
    }
  }

  return (
    <div>
      <div className='button-enclosure border-none'>
        <div className='flex-center'>
          <input
            type='search'
            className='admin-search'
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            placeholder='Search by Name'
          />
          <CreateButton title={'Create'} onClick={handleCreate} />
        </div>
      </div>
      <div
        ref={patientTableContainerRef}
        className='data-table table-fixed admin-table-table-container overflow-y'
      >
        <table className='data-table table-fixed admin-table relative'>
          <thead className='position-top'>
            <tr>
              <th className='table-w-5'>{tableText.slNo}</th>
              <th className='table-w-50'>{tableText.speciality}</th>
              <th>{tableText.status}</th>
              <th className='table-w-10'>{tableText.action}</th>
            </tr>
          </thead>
          <tbody className='admin-table-body'>
            {specialityData.map((entry, index) => {
              const filteredSpecialities = entry.specialities.filter(
                (speciality) =>
                  speciality.display
                    .toLowerCase()
                    .startsWith(searchText.toLowerCase())
              )

              if (filteredSpecialities.length === 0) {
                return null
              }
              filteredSpecialities?.sort?.((a, b) =>
                a?.display?.toLowerCase() < b?.display?.toLowerCase() ? -1 : 1
              )

              return (
                <React.Fragment key={index}>
                  {filteredSpecialities.map((speciality, Index) => (
                    <tr key={Index}>
                      <td>{Index + 1}</td>
                      <td>{speciality.display}</td>
                      <td>{entry.status}</td>
                      <td>
                        <EditButton
                          title='Edit Lab Departments'
                          onEdit={() => handleEdit(speciality?.code)}
                        />
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default LabDepartments
