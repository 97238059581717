import SelectableSearch from '../../components/SelectableSearch'
import { useDispatch } from 'react-redux'
import { setQueryParam } from './membershipSlice'
import '../../App.scss'
import { create } from '../Radiology/constants'

type Props = {
  onCreate: () => void
}

const MembershipSearch = ({ onCreate }: Props) => {
  const dispatch = useDispatch()
  const handleSearch = (query: string, param: string) => {
    let q = ''
    switch (query) {
      case 'ID':
        q = 'partner'
        break
      case 'Phone':
        q = 'phone'
        break
      case 'Name':
        q = 'name'
        break
    }

    dispatch(setQueryParam({ q, param }))
  }

  return (
    <div className='patients-filters'>
      <SelectableSearch
        items={['ID', 'Phone', 'Name']}
        onSearch={handleSearch}
      />

      <button className='btn btn-primaryBtn' onClick={() => onCreate()}>
        {create}
      </button>
    </div>
  )
}

export default MembershipSearch
