import React, { ChangeEvent, useEffect, useState } from 'react'
import BlankUser from '../../assets/images/blank-user.gif'
import { uploadImage } from '../../utils/api'
import { FILE_SERVER_URL } from '../../utils/constants'

type Props = {
  onConfirm: (image: string) => void
  mode: string
}

const UploadPhoto = ({ onConfirm }: Props) => {
  const [key, setKey] = useState(Date.now())
  const [error, setError] = useState('')
  const [files, setFiles] = useState<FileList | null>(null)
  const [image, setImage] = useState<string>('')

  useEffect(() => {
    if (!files?.length) {
      setImage('')
      return
    }
    const url = URL.createObjectURL(files[0])
    setImage(url)

    return () => URL.revokeObjectURL(url)
  }, [files])

  const confirm = () => {
    if (files?.length) {
      const formData = new FormData()
      formData.append('file', files[0], 'profile.jpeg')
      uploadImage(formData)
        .then((data: any) => {
          const uploaded = data.data[0]
          onConfirm(`${FILE_SERVER_URL}file/${uploaded}`)
        })
        .catch((err) => {
          console.error('Failed to store captured image, Reason:', err)
          onConfirm('')
        })
    }
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    setKey(Date.now())
    if (files) {
      for (const file of files) {
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
          setError('Invalid File Format. Please upload an image')
          setFiles(null)
          return
        }
      }
    }
    setError('')
    setFiles(files)
  }

  return (
    <div className='upload-wrapper'>
      <input
        type='file'
        key={key}
        accept='.png, .jpg, .jpeg'
        onChange={handleFileChange}
      />
      <img src={image || BlankUser} alt='Profile Picture' />
      {error && <span className='error-message'>{error}</span>}
      <button disabled={!image} onClick={confirm}>
        Confirm
      </button>
    </div>
  )
}

export default UploadPhoto
