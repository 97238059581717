import { Patient } from 'fhir/r4'
import { useState, useEffect, useRef } from 'react'
import { startSxpProxy } from '../../../utils/api'
import {
  CHAT_PROJECT_ID,
  MEMBERSHIP_PROJECT_ID,
} from '../../../utils/constants'
import { makeName } from '../../lms/utils'
import '../membership.scss'
import { useAppSelector } from '../../../app/hooks'
import { selectSelectedLocationId } from '../../location/locationSlice'
import { MemberPatient, MembershipModel, SearchPatient } from '../models'
import SelectableSearch from '../../../components/SelectableSearch'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import EditMemberTable from './EditMemberTable'
import SearchPatients from './SearchPatients'

const EditPatientSearch = () => {
  const location = useAppSelector(selectSelectedLocationId)

  const patientsTableContainerRef = useRef<HTMLDivElement | null>(null)
  const [patients, setPatients] = useState<SearchPatient[]>([])
  const [previousMembers, setPreviousMembers] = useState<MemberPatient[]>([])
  const [members, setMembers] = useState<MemberPatient[]>([])
  const { id } = useParams()

  const navigate = useNavigate()
  const [show, setShow] = useState<boolean>(false)

  const handleSearch = (query: string, param: string) => {
    if (!query) {
      setPatients([])
      setShow(false)
      return
    }
    setShow(true)
    const intent = 'getPatientsAPI'
    let q = ''
    switch (query) {
      case 'ID':
        q = 'identifier'
        break
      case 'Phone':
        q = 'phone'
        break
      case 'Name':
        q = 'name'
        break
    }
    const state = {
      pageSize: 20,
      query: q,
      value: param ? param : undefined,
    }
    startSxpProxy(CHAT_PROJECT_ID, intent, state)
      .then((data) => {
        if (data.data.entry?.length) {
          setPatients(formatPatients(data.data.entry))
        } else {
          setPatients([])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    startSxpProxy(MEMBERSHIP_PROJECT_ID, 'membershipLine', {
      location,
      lineId: id,
    })
      .then((data: any) => {
        const uhid = data?.data?.[0]?.partner?.ref
        const associatedUhid = data?.data?.[0]?.partner_ids?.map(
          (e: { ref: any }) => e.ref
        )
        const memberUhids = []
        memberUhids.push(uhid)
        memberUhids.push(associatedUhid)
        const memberUhidsArray = [memberUhids[0], ...memberUhids[1]]
        const membersOne = memberUhidsArray.join(',')
        const state = { patientIdentifier: membersOne }
        startSxpProxy(MEMBERSHIP_PROJECT_ID, 'getPatientById', state)
          .then((data) => {
            const memberDetails = data?.data?.data?.entry?.map(
              (e: { resource: any }) => e.resource
            )
            const uhidObj = memberDetails.reduce(
              (acc: any, cur: { identifier: { value: any }[] }) => {
                const uhid = cur.identifier?.[2]?.value
                return { ...acc, [uhid]: cur }
              },
              {}
            )
            const memberDetailArray: MemberPatient[] = memberUhidsArray.map(
              (d, i) => {
                const role = uhidObj[d]

                return {
                  id: role?.id ?? '',
                  uhid: role?.identifier?.[2]?.value,
                  name: makeName(role?.name),
                  registration: role?.identifier?.[3]?.value,
                  gender:
                    role?.gender === 'male'
                      ? 'Male'
                      : role?.gender === 'female'
                      ? 'Female'
                      : 'Others',
                  dob: role?.birthDate ?? '',
                  thopId: role?.identifier?.[0]?.value ?? '',
                  isSelected: true,
                  isNominee: i === 0 ? true : false,
                }
              }
            )

            setMembers(memberDetailArray)
            setPreviousMembers(memberDetailArray)
          })

          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id, location])

  const formatPatients = (entry: { resource: Patient }[]): SearchPatient[] => {
    return (
      entry.map((en) => {
        const gender = en.resource.gender
        const pat: SearchPatient = {
          id: en.resource.id ?? '',
          name: makeName(en.resource.name),
          gender:
            gender === 'male'
              ? 'Male'
              : gender === 'female'
              ? 'Female'
              : 'Others',
          dob: en.resource.birthDate ?? '',
          uhid: en.resource?.identifier?.[2]?.value ?? '',
          thopId: en.resource?.identifier?.[0]?.value ?? '',
          registration: en.resource?.identifier?.[3]?.value ?? 'ad',
          isSelected: false,
          isNominee: false,
        }
        return pat
      }) ?? []
    )
  }
  const handleMemberSelect = (id: string) => {
    const selectedMember = patients.find((member) => member.id === id)
    if (selectedMember) {
      startSxpProxy(MEMBERSHIP_PROJECT_ID, 'membershipLines', {
        location,
        partner: selectedMember?.uhid,
      })
        .then(async (data) => {
          const members: MembershipModel[] = data?.data?.data ?? []
          const member = !members.every(
            (item) => item.state !== 'waiting' && item.state !== 'invoiced'
          )
          if (members.length === 0 || !member) {
            if (
              selectedMember?.registration === 'membership' ||
              selectedMember?.registration === 'membershipPrimary'
            ) {
              setMembers((prev) => [...prev, selectedMember])
            } else {
              await startSxpProxy(CHAT_PROJECT_ID, 'updatePatientRegType', {
                patientIdentifier: selectedMember?.uhid,
              })
                .then(() => {
                  setMembers((prev) => [...prev, selectedMember])
                })
                .catch(() => setMembers((prev) => [...prev, selectedMember]))
            }
          } else {
            if (member) {
              toast(
                <ToastMessage
                  message={
                    'already available in membership.But is in pending state'
                  }
                />,
                {
                  ...toastOptions,
                  type: 'error',
                }
              )
            } else {
              toast(
                <ToastMessage message={'already available in membership'} />,
                {
                  ...toastOptions,
                  type: 'error',
                }
              )
            }
          }
        })
        .catch((err) => {
          console.log(err)
          toast(
            <ToastMessage
              message={err?.response?.data?.error ?? 'eror occured'}
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
    }
  }
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientsTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 25}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const handleMemberUnSelect = (id: string) => {
    const member = members.filter((d) => d.id !== id)
    setMembers(member)
  }
  const handleMembership = () => {
    navigate('/membership')
  }

  return (
    <>
      <div className='layoutcontent'>
        <div className='patients-filters'>
          <SelectableSearch
            items={['ID', 'Phone', 'Name']}
            onSearch={handleSearch}
          />
          <button className={'btn btn-primaryBtn'} onClick={handleMembership}>
            Back To Membership
          </button>
        </div>
        <EditMemberTable
          members={members}
          onMemberUnSelect={handleMemberUnSelect}
          previousMembers={previousMembers}
        />
        {show && (
          <SearchPatients
            patients={patients}
            onMemberSelect={handleMemberSelect}
            members={members}
          />
        )}
      </div>
    </>
  )
}
export default EditPatientSearch
