import React from 'react'
import { Panel } from '../../../labTests/models'
import Tick from '../../../../assets/images/check.png'
import { checked, noTestsFound, selected, tests } from '../../constants'

type Props = {
  searchText: string
  panels: Panel[]
  onPanelSelect: (panel: Panel) => void
}

const RadiologyTestsName = ({ panels, onPanelSelect }: Props) => {
  return (
    <div className='items-container'>
      <span className='heading'>{tests}</span>
      <div className='items'>
        {panels?.length > 0 ? (
          panels?.map((panel) => (
            <div
              className={`item ${panel?.active ? 'active' : ''}`}
              key={panel.id}
              onClick={() => onPanelSelect(panel)}
              title={`${panel?.name.toUpperCase()}${
                panel.active ? selected : ''
              }`}
            >
              {panel?.active && (
                <img className='check' src={Tick} alt={checked} />
              )}
              {panel?.name}
            </div>
          ))
        ) : (
          <span className='not-found'>{noTestsFound}</span>
        )}
      </div>
    </div>
  )
}

export default RadiologyTestsName
