import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TextFieldProps,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import {
  medicationAdministrationId,
  medicationAdministrationPost,
  medicationAdministrationintent,
} from './Constants'
import { IPD_PROJECT_ID } from '../../../../utils/constants'
import { startSxpProxy } from '../../../../utils/api'
import CloseIcon from '@mui/icons-material/Close'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import InfoIcon from '@mui/icons-material/Info'
import moment from 'moment'
import { toast } from 'react-toastify'
import { capitalizeFirstLetter } from '../../../patients/utils'

interface Medication {
  dose: number
  dose_units: string
  drug_name: string
  next_visit_date: string
  id: number
  visit_id: number
  frequency: string
  start_date: string
  route: string
  duration: number
  duration_units: string
  status: string
}

interface AdministeredData {
  administeredDate: string
  administeredTime: string
  comments: string
  quantityAdministered: number
  medication_id: number
  medication: Medication
}

interface Step {
  time: string
  comments: string
  completed?: boolean
}

interface MedicationAdministrationTimes {
  [date: string]: Step[]
}

const Timeline = ({ steps }: { steps: Step[] }) => {
  return (
    <Box className='timeline-container-administration'>
      {steps?.map((step, index) => (
        <Box
          key={index}
          className={`timeline-step-administration ${
            step?.completed ? 'completed' : ''
          }`}
        >
          {/* {step?.completed && (
            <CheckCircleIcon style={{ marginRight: '8px' }} />
          )} */}
          <Typography
            fontSize={'12px'}
            minWidth={100}
            maxWidth={360}
            textAlign={'center'}
          >
            <span className='administration-timeline-time'>{step?.time}</span>{' '}
            {step?.comments && <span>| {step?.comments} </span>}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

const IpdMedicationAdministration = ({
  patientRegisteredId,
}: {
  patientRegisteredId: string
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [selectedRows, setSelectedRows] = useState<Medication[]>([])
  const [filterDate, setFilterDate] = React.useState<Dayjs | null>(dayjs())
  const [tableData, setTableData] = useState<Medication[]>([])
  const [time, setTime] = React.useState<Dayjs | null>(null)
  const [comments, setComments] = React.useState('')
  const [openAdministrationDialog, setOpenAdministrationDialog] =
    useState(false)
  const [medicationAdministerSlots, setMedicationAdministerSlots] =
    useState<MedicationAdministrationTimes>({})
  const dates = Object.keys(medicationAdministerSlots)

  const [selectedMedicine, setSelectedMedicine] = useState('')
  const [openAdministerView, setOpenAdministerView] = React.useState(false)

  const handleClickAdministerView = (row: Medication) => {
    handleGetMedicationAdministrationWithId(row?.id)
    setSelectedMedicine(row?.drug_name)
    setOpenAdministerView(true)
  }

  const handleCloseAdministerView = () => {
    setOpenAdministerView(false)
    setMedicationAdministerSlots({})
  }

  const handleCloseAdministrationDialog = () => {
    setOpenAdministrationDialog(false)
    setTime(null)
    setComments('')
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedIds = tableData
        ?.filter((row) => row?.status !== 'INACTIVE')
        .map((row) => row.id)

      const newSelectedRows = tableData?.filter(
        (row) => row?.status !== 'INACTIVE'
      )
      setSelectedIds(newSelectedIds)
      setSelectedRows(newSelectedRows)
      return
    }
    setSelectedIds([])
    setSelectedRows([])
  }

  const handleClick = (id: number) => {
    const selectedIndex = selectedIds.indexOf(id)
    let newSelectedIds: number[] = []
    let newSelectedRows: Medication[] = []

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(selectedIds, id)
      newSelectedRows = newSelectedRows.concat(
        selectedRows,
        tableData.find((row) => row.id === id)!
      )
    } else if (selectedIndex === 0) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(1))
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1))
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(0, -1))
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelectedIds = newSelectedIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      )
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      )
    }

    setSelectedIds(newSelectedIds)
    setSelectedRows(newSelectedRows)
  }

  const isSelected = (id: number) => selectedIds.indexOf(id) !== -1

  const handleSubmitMedicationAdministration = async (
    selectedRows: Medication[]
  ) => {
    if (time === null) {
      toast.error('Please select time')
    }
    const formattedData = selectedRows?.map((item: Medication, index) => {
      return {
        administeredDate: dayjs(filterDate).format('YYYY-MM-DD'),
        administeredTime: dayjs(time).format('HH:mm:ssZ'),
        comments: comments,
        quantityAdministered: item?.dose,
        medication_id: item?.id,
      }
    })

    const intent = medicationAdministrationPost
    const state = {
      medicineAdminister: formattedData,
    }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        handleCloseAdministrationDialog()
        if (data?.data?.length > 0) {
          //   setTableData(data?.data)
        }
      })
      .catch((err) => {
        console.error(err, 'err')
        toast.error(err?.response?.data?.message)
      })
  }

  const handleGetMedicationAdministrationWithId = async (id: number) => {
    const intent = medicationAdministrationId
    const state = {
      medication_id: id,
    }

    try {
      const data = await startSxpProxy(IPD_PROJECT_ID, intent, state)

      if (data?.data?.medicine_administration?.length > 0) {
        const result: { [key: string]: any[] } = {}

        data?.data?.medicine_administration?.forEach(
          (item: AdministeredData) => {
            const localTime = item?.administeredTime
            const date = item?.administeredDate

            if (!result[date]) {
              result[date] = []
            }

            result[date].push({
              time: moment
                .parseZone(localTime, 'HH:mm:ssZ')
                .local()
                .format('hh:mm A'),
              completed: true,
              comments: item?.comments,
            })
          }
        )
        setMedicationAdministerSlots(result)
      }
    } catch (err) {
      console.error(err, 'err')
    }
  }

  const handleGetMedicationAdministration = async () => {
    const intent = medicationAdministrationintent
    const state = {
      patId: patientRegisteredId,
      given_date: filterDate ? dayjs(filterDate).format('YYYY-MM-DD') : '',
      end_date: filterDate ? dayjs(filterDate).format('YYYY-MM-DD') : '',
    }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.length > 0) {
          setTableData(data?.data)
        } else {
          setTableData(data?.data)
        }
      })
      .catch((err) => {
        console.error(err, 'err')
      })
  }

  useEffect(() => {
    handleGetMedicationAdministration()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientRegisteredId, filterDate])

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={openAdministerView}
        onClose={handleCloseAdministerView}
        disableScrollLock
      >
        <DialogTitle className='administration-visit-dialog-title'>
          {selectedMedicine}
        </DialogTitle>
        <IconButton
          size='small'
          aria-label='close'
          onClick={handleCloseAdministerView}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
        <DialogContent>
          <div>
            {dates.map((date: string) => (
              <div key={date}>
                <div className='administration-timeline-date'>{date}</div>
                <Timeline steps={medicationAdministerSlots[date]} />
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAdministrationDialog}
        onClose={handleCloseAdministrationDialog}
        disableScrollLock
      >
        <DialogTitle className='administration-visit-dialog-title'>
          Details
        </DialogTitle>
        <IconButton
          size='small'
          aria-label='close'
          onClick={handleCloseAdministrationDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
        <DialogContent className='administration-visit-dialog-content'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label='Time'
              value={time}
              onChange={(newValue) => setTime(newValue)}
              className='custom-date-picker-administration'
            />
            <TextField
              label='Comments'
              name='comments'
              multiline
              rows={3}
              onChange={(e) => setComments(e.target.value)}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions className='administration-visit-dialog-actions'>
          <Button
            variant='contained'
            size='small'
            className='administration-visit-dialog-actions-btn'
            onClick={() => handleSubmitMedicationAdministration(selectedRows)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div className='medication-administration-main'>
        <div className='medication-administration-filter'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              key={'filter-picker'}
              label='Date'
              value={filterDate}
              format='DD/MM/YYYY'
              onChange={(newValue) => setFilterDate(newValue)}
              className='custom-date-picker-administration'
              slots={{
                textField: (params: TextFieldProps) => (
                  <TextField {...params} size='small' />
                ),
              }}
            />
          </LocalizationProvider>
          <div>
            {selectedIds?.length > 0 && (
              <Button
                size='small'
                variant='contained'
                onClick={() => setOpenAdministrationDialog(true)}
                className='administration-visit-dialog-actions-btn'
                startIcon={<VaccinesIcon />}
              >
                Administer
              </Button>
            )}
          </div>
        </div>
        <div className='data-table-administration'>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead className='table-header'>
                <TableRow>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      size='small'
                      indeterminate={
                        selectedIds.length > 0 &&
                        selectedIds.length < tableData.length
                      }
                      checked={
                        tableData.length > 0 &&
                        selectedIds.length === tableData.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>Medicine</TableCell>
                  <TableCell>Dosage</TableCell>
                  <TableCell>Duration(units)</TableCell>
                  <TableCell>Route</TableCell>
                  <TableCell>Frequency</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((row) => {
                  const isItemSelected = isSelected(row.id)
                  return (
                    <TableRow
                      key={row.id}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          disabled={row?.status === 'INACTIVE'}
                          checked={isItemSelected}
                          size='small'
                          onClick={() => handleClick(row.id)}
                        />
                      </TableCell>
                      <TableCell>{row?.drug_name}</TableCell>
                      <TableCell>
                        {row?.dose}({row?.dose_units})
                      </TableCell>
                      <TableCell>
                        {row?.duration}({row?.duration_units})
                      </TableCell>
                      <TableCell>{capitalizeFirstLetter(row?.route)}</TableCell>
                      <TableCell>
                        <div className='table-cell-frequency'>
                          {row.frequency}{' '}
                          <IconButton
                            onClick={() => handleClickAdministerView(row)}
                            className='administration-iconBtn'
                          >
                            <InfoIcon className='administration-visit-info-icon' />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
                {tableData?.length <= 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>No medicines for the day</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  )
}

export default IpdMedicationAdministration
