import React, { useEffect, useState, ChangeEvent } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import {
  Autocomplete,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import {
  BED_TYPE_OPTIONS,
  INTENT_CREATE_UPDATE_BED,
  INTENT_WARD_DETAILS,
} from './Constants'
import { startSxpProxy } from '../../utils/api'
import {
  CRITICAL_BED_ID,
  IPD_PROJECT_ID,
  KIMS_LOCATION_ID,
} from '../../utils/constants'
import {
  selectLocationById,
  selectLocations,
  selectSelectedLocationId,
} from '../location/locationSlice'
import {
  clearPatientDetails,
  getFhrId,
  getMuhId,
  getPatientDetails,
  setFhrId,
  setLoading,
  setMuhId,
} from './IpdPatientRegisterSlice'
import KeycloakService from '../../utils/keycloakService'
import { fetchSlotsDoctorsAsync, selectSlotsDoctors } from '../slots/slotsSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from '../patients/utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface Ward {
  id: number
  floor_number: string
  ward_name: string
}

interface Room {
  id: number
  room_name: string
  room_number: string | null
  ward_name: string
  ward_id: number
}

interface Bed {
  id: number
  bed_number: string
  status: string
  patient_id: number | null
  room_name: string
  room_id: number
}

interface WardData {
  wards: Ward[]
  rooms: Room[]
  beds: Bed[]
}

interface BedDetails {
  bedType: number | null
  ward: number | null
  room: number | null
  bed: number | null
  admissionDate: string
  additionalInfo: string
}

const styles = () => ({
  ipdBedAllocationRegisterMain: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 30,
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    width: '100%',
  },
  ipdBedAllocationRegisterHeading: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '17px',
    fontWeight: '600',
    color: '#090968',
    padding: '10px 0 0 10px',
  },
  ipdBedAllocationRegisterBedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: 20,
    width: '90%',
  },
  ipdBedAllocationFormField: {
    flex: '0 0 30%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
  },
  ipdBedAllocationTableCellHead: {
    border: '1px solid #e6e6e6',
    color: '#fff !important',
  },
  ipdBedAllocationTableCellBody: {
    border: '1px solid #e6e6e6',
  },
  ipdBedAllocationTableHead: {
    backgroundColor: '#4682b4',
  },
  ipdBedAllocationBtnDisable: {
    backgroundColor: '#D3D3D3 !important',
  },
})

const IpdBedAllocation: React.FC = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const locationId = useAppSelector(selectSelectedLocationId)
  const locationNames = useAppSelector(selectLocations)
  const locationName =
    locationNames.find((location: any) => location?.resource?.id === locationId)
      ?.resource?.name || ''
  const patientDetails = useAppSelector(getPatientDetails)
  const muhId = useAppSelector(getMuhId)
  const doctors = useAppSelector(selectSlotsDoctors)
  const fhrId = useAppSelector(getFhrId)

  const [wardData, setWardData] = useState<WardData>({
    wards: [],
    rooms: [],
    beds: [],
  })

  const [bedDetails, setBedDetails] = useState<BedDetails>({
    bedType: CRITICAL_BED_ID,
    ward: null,
    room: null,
    bed: null,
    admissionDate: '',
    additionalInfo: '',
  })

  const [doctorName, setDoctorName] = useState('')

  const handleGetWardData = async () => {
    const intent = INTENT_WARD_DETAILS
    const state = {
      status: 'AVAILABLE',
      locationName: locationName,
      bedTypeId: bedDetails.bedType,
    }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.success) {
          setWardData(data?.data?.data)
          data?.data?.data?.wards?.length <= 0 &&
            toast.warning('No Wards Found')
          data?.data?.data?.rooms?.length <= 0 &&
            toast.warning('No Rooms Found')
          data?.data?.data?.beds?.length <= 0 && toast.warning('No Beds Found')
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setBedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      ...(name === 'ward' && { room: null, bed: null }),
      ...(name === 'room' && { bed: null }),
    }))
  }

  const getFilteredRooms = () => {
    return wardData?.rooms?.filter((room) => room.ward_id === bedDetails.ward)
  }

  const getFilteredBeds = () => {
    return wardData?.beds?.filter((bed) => bed.room_id === bedDetails.room)
  }

  const handleSubmitBedAllocation = async () => {
    dispatch(
      setLoading({
        active: true,
        message: 'Please Wait Allocatting Bed',
      })
    )
    const intent = INTENT_CREATE_UPDATE_BED
    const state = {
      id: bedDetails?.bed,
      status: 'OCCUPIED',
      patientId: muhId,
      createdBy: KeycloakService.getUsername(),
      admissionDate: dayjs(bedDetails?.admissionDate).utc().format(),
      dateCreated: new Date().toISOString(),
      patientName: `${patientDetails?.firstName} ${patientDetails?.lastName}`,
      assignedDoctor: doctorName,
      patientRegisteredId: patientDetails?.fhrId || fhrId,
      locationId: locationId,
      mlcForm: patientDetails?.mlcForm,
      additionalInfo: bedDetails?.additionalInfo,
    }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.success) {
          dispatch(
            setLoading({
              active: false,
              message: '',
            })
          )
          toast.success(data?.data?.message)
          dispatch(clearPatientDetails())
          dispatch(setMuhId({ muhId: '' }))
          dispatch(setFhrId({ fhrId: '' }))
          navigate('/bedIpd/bedAssignmentList')
        } else {
          dispatch(
            setLoading({
              active: false,
              message: '',
            })
          )
          toast.error(data?.data?.message)
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(
          setLoading({
            active: false,
            message: '',
          })
        )
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    if (!fhrId || !muhId) {
      navigate('/ipdRegistration')
    }
    handleGetWardData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bedDetails.bedType])

  useEffect(() => {
    dispatch(fetchSlotsDoctorsAsync())
  }, [dispatch])

  return (
    <div>
      <div className={classes?.ipdBedAllocationRegisterMain}>
        <div className={classes?.ipdBedAllocationRegisterHeading}>
          IPD Bed Allocation
        </div>
        <div>
          <Table className={classes?.ipdBedAllocationTableBorder} size='small'>
            <TableHead className={classes?.ipdBedAllocationTableHead}>
              <TableRow>
                <TableCell
                  className={classes?.ipdBedAllocationTableCellHead}
                  align='left'
                >
                  {locationId === KIMS_LOCATION_ID ? 'MR Number' : 'UHID'}
                </TableCell>
                <TableCell
                  className={classes?.ipdBedAllocationTableCellHead}
                  align='left'
                >
                  Name
                </TableCell>
                <TableCell
                  className={classes?.ipdBedAllocationTableCellHead}
                  align='left'
                >
                  Mobile No
                </TableCell>
                <TableCell
                  className={classes?.ipdBedAllocationTableCellHead}
                  align='left'
                >
                  Gender
                </TableCell>
                <TableCell
                  className={classes?.ipdBedAllocationTableCellHead}
                  align='center'
                >
                  Age
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes?.ipdBedAllocationTableCellBody}
                  align='left'
                >
                  {locationId === KIMS_LOCATION_ID
                    ? patientDetails?.mrNumber
                    : muhId || '-'}
                </TableCell>
                <TableCell
                  align='left'
                  className={classes?.ipdBedAllocationTableCellBody}
                >
                  {(patientDetails?.firstName ?? '') +
                    ' ' +
                    (patientDetails?.lastName ?? '')}
                </TableCell>
                <TableCell
                  align='left'
                  className={classes?.ipdBedAllocationTableCellBody}
                >
                  {patientDetails?.phoneNumber || '-'}
                </TableCell>
                <TableCell
                  align='left'
                  className={classes?.ipdBedAllocationTableCellBody}
                >
                  {capitalizeFirstLetter(patientDetails?.gender)}
                </TableCell>
                <TableCell
                  align='center'
                  className={classes?.ipdBedAllocationTableCellBody}
                >
                  {patientDetails?.age || '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={classes?.ipdBedAllocationRegisterBedContainer}>
          <div className={classes?.ipdBedAllocationFormField}>
            <label>Bed Type</label>
            <TextField
              size='small'
              select
              name='bedType'
              value={bedDetails.bedType}
              onChange={handleChange}
            >
              {BED_TYPE_OPTIONS?.map((item, index) => (
                <MenuItem key={index} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes?.ipdBedAllocationFormField}>
            <label>Ward</label>
            <TextField
              size='small'
              select
              name='ward'
              value={bedDetails.ward}
              onChange={handleChange}
              disabled={!bedDetails?.bedType}
            >
              {wardData.wards.map((ward) => (
                <MenuItem key={ward?.id} value={ward?.id}>
                  {ward?.ward_name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes?.ipdBedAllocationFormField}>
            <label>Room</label>
            <TextField
              size='small'
              select
              name='room'
              value={bedDetails.room}
              onChange={handleChange}
              disabled={!bedDetails?.ward}
            >
              {getFilteredRooms().map((room) => (
                <MenuItem key={room?.id} value={room?.id}>
                  {room?.room_name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes?.ipdBedAllocationFormField}>
            <label>Bed</label>
            <TextField
              size='small'
              select
              name='bed'
              value={bedDetails?.bed}
              onChange={handleChange}
              disabled={!bedDetails?.room}
            >
              {getFilteredBeds().map((bed) => (
                <MenuItem key={bed?.id} value={bed?.id}>
                  {bed?.bed_number}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes?.ipdBedAllocationFormField}>
            <label>Doctor Name</label>
            <Autocomplete
              disabled={!bedDetails?.bed}
              options={doctors}
              getOptionLabel={(option) => option?.name}
              onChange={(event, value: any) =>
                setDoctorName(value?.name || null)
              }
              renderInput={(params) => (
                <TextField {...params} size='small' name='doctorName' />
              )}
            />
          </div>

          <div className={classes?.ipdBedAllocationFormField}>
            <label>Admission Date</label>
            <TextField
              disabled={!doctorName}
              size='small'
              type='datetime-local'
              name='admissionDate'
              placeholder='Admission Date'
              InputLabelProps={{ shrink: true }}
              value={bedDetails?.admissionDate}
              onChange={handleChange}
              inputProps={{ max: dayjs().format('YYYY-MM-DDTHH:mm') }}
            />
          </div>

          <div className={classes?.ipdBedAllocationFormField}>
            <label>Additional Info</label>
            <TextField
              size='small'
              multiline
              rows={2}
              name='additionalInfo'
              placeholder='Additional Info'
              InputLabelProps={{ shrink: true }}
              value={bedDetails?.additionalInfo}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button
          variant='contained'
          className={`ip-register-btn ${
            !bedDetails?.bed || !doctorName || !bedDetails?.admissionDate
              ? classes?.ipdBedAllocationBtnDisable
              : ''
          }`}
          onClick={handleSubmitBedAllocation}
          disabled={
            !bedDetails?.bed || !doctorName || !bedDetails?.admissionDate
          }
        >
          Allocate Bed
        </Button>
      </div>
    </div>
  )
}

export default IpdBedAllocation
