import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import Asterisk from '../../../components/Asterisk'
import { AppDepartment } from './Departments'

const EditDepartments = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [department, setDepartment] = useState<AppDepartment | null>(null)

  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'getSpecilities').then((data) => {
      const speciality = data?.data?.entry[0]?.resource?.compose?.include[0]
      const dep = speciality.concept.find((e: AppDepartment) => e.code === id)
      if (dep) {
        setDepartment(dep)
      }
    })
  }, [id])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (id) {
      startSxpProxy(ADMIN_PROJECT_ID, 'editSpecialty', {
        code: department?.code,
        display: department?.display,
      })
        .then(() => {
          navigate('/administration/departments')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <>
      <button
        className='base-button back-button'
        onClick={() => navigate('/administration/departments')}
      >
        Back to Departments
      </button>
      {department ? (
        <form className='admin-form' onSubmit={handleSubmit}>
          <div>
            <label htmlFor='name'>
              Speciality
              <Asterisk />
            </label>
            <input
              required
              name='display'
              value={department.display}
              type='text'
              onChange={({ target }) =>
                setDepartment({ ...department, display: target.value })
              }
              placeholder='Enter Speciality Name'
            />
          </div>
          <button className='base-button' type='submit'>
            Submit
          </button>
        </form>
      ) : (
        <p>Department Id is not valid</p>
      )}
    </>
  )
}

export default EditDepartments
