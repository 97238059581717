import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

interface PatientDetails {
  [key: string]: any
}

interface IpdPatientRegisterState {
  ipdId: string
  patientDetails: PatientDetails
  muhId: string
  encounterId: string
  fhrId: string
  loading: {
    active: boolean
    message: string
  }
}

const initialState: IpdPatientRegisterState = {
  ipdId: '',
  patientDetails: {},
  muhId: '',
  encounterId: '',
  fhrId: '',
  loading: {
    active: false,
    message: 'Loading',
  },
}

const IpdPatientRegisterSlice = createSlice({
  name: 'IpdPatientRegisterDetails',
  initialState,
  reducers: {
    setIpdId: (state, action: PayloadAction<{ ipdId: string }>) => {
      state.ipdId = action.payload.ipdId
    },
    setPatientDetails: (
      state,
      action: PayloadAction<{ patientDetails: Partial<PatientDetails> }>
    ) => {
      state.patientDetails = {
        ...state.patientDetails,
        ...action.payload.patientDetails,
      }
    },
    setMuhId: (state, action: PayloadAction<{ muhId: string }>) => {
      state.muhId = action.payload.muhId
    },
    setEncounterId: (state, action: PayloadAction<{ encounterId: string }>) => {
      state.encounterId = action.payload.encounterId
    },
    setFhrId: (state, action: PayloadAction<{ fhrId: string }>) => {
      state.fhrId = action.payload.fhrId
    },
    setLoading: (
      state,
      action: PayloadAction<{
        active: boolean
        message: string
      }>
    ) => {
      state.loading.active = action.payload.active
      state.loading.message = action.payload.message
    },
    clearPatientDetails: (state) => {
      state.patientDetails = {}
    },
  },
})

export const {
  setIpdId,
  setPatientDetails,
  setMuhId,
  setEncounterId,
  setFhrId,
  setLoading,
  clearPatientDetails,
} = IpdPatientRegisterSlice.actions
export const getIpdId = (state: RootState) =>
  state.IpdPatientRegisterDetails.ipdId
export const getPatientDetails = (state: RootState) =>
  state.IpdPatientRegisterDetails.patientDetails
export const getMuhId = (state: RootState) =>
  state.IpdPatientRegisterDetails.muhId
export const getEncounterId = (state: RootState) =>
  state.IpdPatientRegisterDetails.encounterId
export const getFhrId = (state: RootState) =>
  state.IpdPatientRegisterDetails.fhrId
export const getIsLoading = (state: RootState) =>
  state.IpdPatientRegisterDetails.loading

export default IpdPatientRegisterSlice.reducer
