import { useCallback, useState } from 'react'
import { useOutsideClick } from './useOutsideClick'
import KeycloakService from '../utils/keycloakService'
import Avatar from '@mui/material/Avatar'

const ProfileDropDown = () => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const handleOutsideClick = useCallback(() => {
    setOpen(false)
  }, [])

  const ref = useOutsideClick(handleOutsideClick)
  const capitalizedUsername = KeycloakService?.getUsername()?.toUpperCase()

  return (
    <div className='profile-dropdown'>
      <button ref={ref} className='profile-btn' onClick={handleClick}>
        <div className='profile-dropdown-logo'>
          <Avatar
            alt={capitalizedUsername}
            className='logo-avatar '
            src='/static/images/avatar/2.jpg'
            title={capitalizedUsername}
          />{' '}
        </div>
      </button>
      {open && (
        <div className='dropdown-NameLogout'>
          <button onClick={() => KeycloakService.doLogout()}>Logout</button>
        </div>
      )}
    </div>
  )
}

export default ProfileDropDown
