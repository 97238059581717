import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { LmsObservation } from '../../models'
import { v4 as uuidv4 } from 'uuid'
import '../../lms.scss'

type Props = {
  existing?: LmsObservation
  onSave: (data: LmsObservation) => void
  onUpdate: (data: LmsObservation) => void
}

const Observation = ({ existing, onSave, onUpdate }: Props) => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [titleError, setTitleError] = useState(false)
  const [contentError, setContentError] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }
  useEffect(() => {
    adjustHeight()
    const textarea = textareaRef.current
    if (textarea) {
      textarea.addEventListener('input', adjustHeight)
      return () => {
        textarea.removeEventListener('input', adjustHeight)
      }
    }
  }, [])

  useEffect(() => {
    if (existing) {
      setTitle(existing.title)
      setContent(existing.content)
    } else {
      setTitle('')
      setContent('')
    }
  }, [existing])

  const handleClick = () => {
    if (!title) {
      setTitleError(true)
      return
    }
    if (!content) {
      setContentError(true)
      return
    }
    if (existing?.id) {
      const obs: LmsObservation = {
        id: existing.id,
        title: title,
        content: content,
      }
      onUpdate(obs)
    } else {
      const obs: LmsObservation = {
        id: uuidv4(),
        title: title,
        content: content,
      }
      onSave(obs)
    }
    setTitle('')
    setContent('')
  }

  const handleTitleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setTitle(target.value)
    setTitleError(false)
    setContentError(false)
  }

  const handleContentChange = ({
    target,
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(target.value)
    setTitleError(false)
    setContentError(false)
  }

  return (
    <div className='observation-flex'>
      <p className='collect-header'>Observations</p>
      <input
        placeholder='Title'
        type='text'
        className='h30px'
        value={title}
        onChange={handleTitleChange}
      />
      {titleError ? <p className='error-text'>Title is required</p> : null}
      <textarea
        placeholder='Content'
        ref={textareaRef}
        className={contentError ? 'obs-area input-error' : 'obs-area'}
        style={{ height: 'auto', overflow: 'hidden' }}
        cols={40}
        rows={2}
        value={content}
        onChange={handleContentChange}
      ></textarea>
      {contentError ? <p className='error-text'>Content is required</p> : null}
      <button className='h29px w60px' onClick={handleClick}>
        {existing?.id ? 'Update' : 'Add'}
      </button>
    </div>
  )
}

export default Observation
