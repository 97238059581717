import React, { ChangeEvent, KeyboardEvent, useState } from 'react'

type Props = {
  items: string[]
  onSearch: (query: string, param: string) => void
  placeHolder?: string
}

const SelectableSearch = ({ items, onSearch, placeHolder }: Props) => {
  const [value, setValue] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const handleOnInput = () => {
    setIsEditing(true)
  }

  const handleOnBlur = () => {
    setIsEditing(false)
  }

  const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value)
    if (!ev.target.value) {
      onSearch('', '')
    }
  }

  const handleSelect = (param: number) => {
    setActiveIndex(0)
    onSearch(items[param], value)
  }

  const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    const size = items.length
    switch (ev.key) {
      case 'Tab':
        setIsEditing(false)
        setActiveIndex(0)
        onSearch(items[activeIndex], value)
        ev.preventDefault()
        break
      case 'Enter':
        setIsEditing(false)
        setActiveIndex(0)
        onSearch(items[activeIndex], value)
        break
      case 'ArrowUp':
        setActiveIndex((ai) => (ai + size - 1) % size)
        ev.preventDefault()
        break
      case 'ArrowDown':
        setActiveIndex((ai) => (ai + 1) % size)
        break
    }
  }

  return (
    <div className='selectable-search-wrapper'>
      <input
        placeholder={placeHolder ? placeHolder : 'Search by Patient UHID'}
        className='search-input'
        type='search'
        value={value}
        onBlur={handleOnBlur}
        onInput={handleOnInput}
        onClick={handleOnInput}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
      />
      {isEditing && value && (
        <div className='options-wrapper'>
          {items.map((i, idx) => (
            <p
              key={i}
              onMouseEnter={() => setActiveIndex(idx)}
              onMouseDown={() => handleSelect(idx)}
              className={`select-option${idx === activeIndex ? ' active' : ''}`}
            >
              Search {i} for <span className='select-value'>{value}</span>
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

export default SelectableSearch
