import React, { PropsWithChildren, useState } from 'react'

type Props = {
  summary: string
  initialOpen?: boolean
}

const Details = ({
  summary,
  children,
  initialOpen = false,
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(initialOpen)

  return (
    <details {...(open ? { open: true } : {})}>
      <summary
        onClick={(e) => {
          e.preventDefault()
          setOpen((open) => !open)
        }}
      >
        <span>{summary}</span>
      </summary>
      {open && children}
    </details>
  )
}

export default Details
