import { FormEvent, useState } from 'react'
import Asterisk from '../../../components/Asterisk'
import { startSxpProxy } from '../../../utils/api'
import { useNavigate } from 'react-router-dom'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { headers, intent } from '../constants'

const LabDepartmentsForm = () => {
  const [text, setText] = useState<string>('')
  const navigate = useNavigate()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const state = {
      specialty: text,
    }
    startSxpProxy(ADMIN_PROJECT_ID, intent.createLabDepartments, state)
      .then(() => {
        navigate('/administration/labDepartments')
      })
      .catch((error) => {
        console.error(error, 'error')
      })
  }

  return (
    <form className='admin-form' onSubmit={handleSubmit}>
      <div>
        <label htmlFor='name'>
          Department
          <Asterisk />
        </label>
        <input
          required
          name='speciality'
          value={text}
          type='text'
          onChange={({ target }) => setText(target.value)}
          placeholder='Enter Department Name'
        />
      </div>
      <button className='base-button' type='submit'>
        {headers.submit}
      </button>
    </form>
  )
}

export default LabDepartmentsForm
