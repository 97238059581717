import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Patient } from 'fhir/r4'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID, OPD_PROJECT_ID } from '../../../../utils/constants'
import PatientInfo from '../PatientInfo'
import OpdOrder from '../../../labTests/OPDOrders'

const CreateLabOrder = () => {
  const { id } = useParams<{ id?: string }>()
  const [patient, setPatient] = useState<Patient | null>(null)

  useEffect(() => {
    if (!id) {
      console.error('Patient ID is undefined')
      return
    }

    const fetchData = async () => {
      try {
        const labOrderData = await startSxpProxy(
          LABS_PROJECT_ID,
          'getLabOrdersByPatientId',
          { patientId: id }
        )
        const patientData = await startSxpProxy(
          OPD_PROJECT_ID,
          'getPatientApi',
          { id }
        )
        setPatient(patientData.data ?? null)
      } catch (err) {
        console.error(err)
      }
    }
    fetchData()
  }, [id])

  return (
    <div className='create-order-container'>
      {patient ? (
        <>
          <PatientInfo patient={patient} />
          <br />
          <OpdOrder />
        </>
      ) : (
        <div className='appointments-table-container'>
          <table className='data-table admin-table'>
            <thead>
              <tr>
                <th>UHID</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Age</th>
                <th>Mobile No.</th>
                <th>Operating Unit</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan={7}
                  className='no-patient-message'
                  style={{ textAlign: 'center' }}
                >
                  No Patient Details Found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default CreateLabOrder
