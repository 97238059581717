import { useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { allocateApi, allocation } from '../modals'
import { bedFilter } from '../constants'

type props = {
  onConfirm: (e: boolean) => void
  wardId: bedFilter
  onClose: () => void
}
const EditDischargeDate = ({ onConfirm, wardId }: props) => {
  const [date, setDate] = useState(wardId.dischargedDate)
  const [error, setError] = useState('')

  const handleDateChange = (event: any) => {
    const selectedDateValue = event.target.value
    const currentDate = new Date().toISOString().split('T')[0]
    if (selectedDateValue < currentDate) {
      setError('Selected date must be equal or greater than the current date.')
    } else {
      setError('')
      setDate(selectedDateValue)
    }
  }
  const handleDate = (e: boolean) => {
    const state = {
      id: parseInt(wardId.id),
      dischargedDate: date,
    }
    startSxpProxy(IPD_PROJECT_ID, allocateApi.updateDischargedDate, state).then(
      () => {
        onConfirm(e)
      }
    )
  }
  return (
    <>
      <div className='conform-container'>
        <span className='conform-content'>{allocation.dateModal}</span>
        <div className='conform-button'>
          <input
            type='date'
            value={date}
            className=''
            onChange={handleDateChange}
          />
          <input
            type='button'
            value='Modify'
            className='conform'
            onClick={() => handleDate(false)}
          />
        </div>
        {error && (
          <div className='uid-search'>
            {allocation.error}
            {error}
          </div>
        )}
      </div>
    </>
  )
}
export default EditDischargeDate
