import React from 'react'
import { Sample } from '../models'

type Props = {
  samples: Sample[]
  active: number
  onSampleSelect: (id: number) => void
}

const LabSamples = ({ samples, active, onSampleSelect }: Props) => {
  return (
    <div className='samples mtminus25'>
      <span className='heading'>Samples</span>
      {samples.length > 0 ? (
        <ul>
          {samples.map((sample, index) => (
            <li
              className={active === index ? 'active' : ''}
              key={sample.id}
              onClick={() => onSampleSelect(index)}
              title={sample.description}
            >
              {sample.name}
            </li>
          ))}
        </ul>
      ) : (
        <span>No Samples Found</span>
      )}
    </div>
  )
}

export default LabSamples
