import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import PatientTable from '../../../lms/components/PatientTable'
import SelectableSearch from '../../../../components/SelectableSearch'
import AllRadiologyOrders from './AllRadiologyOrders'

const RadiologySearchView = () => {
  const [show, setShow] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [param, setParam] = useState<string>('')
  const [patientId, setPatientId] = useState<string>('')
  const navigate = useNavigate()

  const handlePatientSelect = (id: string) => {
    if (id !== patientId) {
      setPatientId(id)
      navigate('')
    }
  }

  const handleSearch = (q: string, p: string) => {
    setQuery(q)
    setParam(p)
    setPatientId('')
    if (!q || !p) {
      setShow(false)
    } else {
      setShow(true)
    }
    navigate('')
  }

  const handleView = (url: string) => {
    setPatientId('')
    setShow(false)
    navigate(url)
  }

  const handleCreate = (id: string) => {
    setPatientId('')
    setShow(false)
    navigate(`create/${id}`)
  }

  return (
    <div className='collect-samples'>
      <SelectableSearch
        items={['ID', 'Phone', 'Name']}
        onSearch={handleSearch}
      />
      <div className='mb10'></div>
      {show && (
        <PatientTable
          searchQuery={query}
          searchParam={param}
          onPatientSelect={handlePatientSelect}
          selectedId={patientId}
        />
      )}
      {patientId ? (
        <AllRadiologyOrders
          id={patientId}
          onView={handleView}
          onCreate={handleCreate}
        />
      ) : null}
      <Outlet />
    </div>
  )
}

export default RadiologySearchView
