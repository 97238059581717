import React, { useCallback, useEffect, useRef, useState } from 'react'
import './opd.scss'
import OpdMenu from './components/OpdMenu'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import RenderOnRole from '../../components/RenderOnRole'
import {
  DIAGNOSIS_READ,
  HISTORY_READ,
  LAB_ORDERS_READ,
  MEDICATIONS_READ,
  SYSTEMIC_READ,
  VITALS_READ,
} from '../../utils/roles'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID, OPD_PROJECT_ID } from '../../utils/constants'
import { appointmentType, upComingAppointmentType } from '../OPDScreen/constant'
import DateofAppointments from './DateofAppointment'
import OPDPatientTable from '../OPDScreen/OpdScreen'
import { FormattedPatient } from '../OPDScreen/opdPatientdata'
import { getTotalAge } from '../../utils/dateUtils'
import { makeName } from '../lms/utils'
import { capitalizeFirstLetter } from '../patients/utils'
import OPDVistsDeatils from '../OPDScreen/visitDetails'
import { Box } from '@mui/material'
interface Appointment {
  appointmentId: string
}
const Opd = () => {
  const { id } = useParams()
  const [selectedDate, setSelectedDate] = useState<number | null>(null)
  const [visitsList, setVisitList] = useState<appointmentType[]>([])
  const [visitsDateList, setVisitsDateList] = useState<appointmentType[]>([])
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    upComingAppointmentType[]
  >([])
  const [patientData, setPatientData] = useState<FormattedPatient[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [encounterDetails, setEncounterDetails] = useState<any>(null)
  const [appointmentIds, setAppointmentIds] = useState<string[]>([])
  const [useStatus, setUseStatus] = useState(false)
  const [param, setParam] = useState<string>('')
  const [selectedDateStatus, setSelectedDateStatus] = useState(false)
  const locationDate = useLocation()
  const { status } = locationDate.state || {}
  useEffect(() => {
    if (status && !param) {
      setUseStatus(true)
    } else {
      setUseStatus(false)
    }
  }, [status, param])

  const fetchUpComingAppointments = async () => {
    try {
      const intent = 'upcomingAppointments'
      const state = {
        id: id,
      }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setUpcomingAppointments(resp.data)
    } catch (e) {
      console.log(e)
    }
  }
  const location = useLocation()
  const storepatentid = location?.state?.id
  useEffect(() => {
    const intent = 'getPatientVisits'
    const patientIntent = 'getPatientApi'
    const state = {
      id: storepatentid,
    }

    startSxpProxy(OPD_PROJECT_ID, intent, state)
      .then((res) => {
        if (res.data.entry) {
          setVisitList(res.data.entry)
          setVisitsDateList(res.data.entry)
          setSelectedDate(0)
        } else {
          setVisitList([])
        }
      })
      .catch((e) => console.log(e))

    startSxpProxy(OPD_PROJECT_ID, patientIntent, state)
      .then((res1) => {
        const data = Array.isArray(res1.data) ? res1.data : [res1.data]
        const formattedData = transformPatientData(data[0])
        setPatientData([formattedData])
        setShow(true)

        fetchUpComingAppointments()
      })
      .catch((f) => console.log(f))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const visitDateHandler = (date: any, status: boolean) => {
    setSelectedDate(date)
    setSelectedDateStatus(status)
  }
  const transformPatientData = (p: any): FormattedPatient => {
    const thop = p.identifier?.[0]
    const bahmni = p.identifier?.[2]
    const created = p.identifier?.[3]
    const regType = p.identifier?.[3]
    const invoice = p.identifier?.[5]
    const name = makeName(p.name)
    const age = getTotalAge(p.birthDate)
    const gender = capitalizeFirstLetter(p.gender)
    const tel1 = p.telecom?.find((prt: { use: string }) => prt.use === 'mobile')
    const tel2 = p.telecom?.find((prt: { use: string }) => prt.use === 'home')

    return {
      id: bahmni?.value ?? thop?.value ?? p.id ?? '',
      name: name.trim() ?? '',
      gender: gender ?? '',
      age: age ?? '',
      fhirId: p.id ?? '',
      thopId: thop?.value ?? '',
      invoiceId: invoice?.value ?? '',
      registeredOn: created?.period?.start ?? '',
      registrationType: regType?.value ?? '',
      mobile: tel1?.value ?? tel2?.value ?? '',
    }
  }
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 10}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const fetchAllAppointments = useCallback(async () => {
    try {
      const intent = 'allAppointments'
      const state = { id: parseInt(patientData?.[0]?.fhirId, 10) }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setUpcomingAppointments(resp.data)
      const ids = resp.data.map(
        (appointment: Appointment) => appointment.appointmentId
      )
      setAppointmentIds(ids)
    } catch (e) {
      console.log(e)
    }
  }, [patientData])

  useEffect(() => {
    if (patientData?.[0]?.fhirId) {
      fetchAllAppointments()
    }
  }, [patientData, fetchAllAppointments])

  const fetchEncounterForAppointments = async (appointmentIds: string) => {
    try {
      const intent = 'getEncounterForAppointments'
      const state = { appointmentIds }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setEncounterDetails(resp?.data)
    } catch (e) {
      console.error('Error fetching encounter details:', e)
    }
  }

  useEffect(() => {
    if (appointmentIds?.length > 0) {
      const appointmentIdsString = appointmentIds?.join(',')
      fetchEncounterForAppointments(appointmentIdsString)
        .then((resp) => console.log('Encounter details response:', resp))
        .catch((error) =>
          console.error('Error fetching encounter details:', error)
        )
    }
  }, [appointmentIds])
  useEffect(() => {
    if (encounterDetails?.length > 0) {
      const findIndex = encounterDetails.findIndex(
        (e: any) => e?.resource?.id === id
      )
      setSelectedDate(findIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const element = (
    <div className='opd-container-column'>
      {show && (
        <div style={{ marginTop: '0.5rem' }}>
          <div>
            <OPDVistsDeatils />
          </div>
          <div className='opd-visittable'>
            <OPDPatientTable patients={patientData} isOpenDocsPage={true} />
          </div>
        </div>
      )}

      <div className='opd-menudates'>
        <div className='opdmenu'>
          <OpdMenu />
        </div>
        <Box width={'100%'}>
          {show && (
            <div>
              <DateofAppointments
                dateHandler={visitDateHandler}
                activeDate={selectedDate}
                visitDates={visitsDateList}
              />
            </div>
          )}
          <div className={show ? 'opd-content-visit' : 'opd-content'}>
            <Outlet />
          </div>
        </Box>
      </div>
    </div>
  )
  return (
    <RenderOnRole
      roles={[
        HISTORY_READ,
        VITALS_READ,
        SYSTEMIC_READ,
        DIAGNOSIS_READ,
        MEDICATIONS_READ,
        LAB_ORDERS_READ,
      ]}
      element={element}
    />
  )
}

export default Opd
