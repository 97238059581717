import React, { forwardRef } from 'react'
import ResultLabel from './ResultLabel'
import { RLabel } from '../../models'

type Props = {
  data: RLabel
}

const PrintLabel = forwardRef<HTMLDivElement, Props>(function Label(
  props,
  ref
) {
  return (
    <div ref={ref}>
      <ResultLabel data={props.data} />
      <ResultLabel data={props.data} />
    </div>
  )
})

export default PrintLabel
