import React from 'react'

type Props = {
  title: string
  loading?: boolean
  compact?: boolean
  disable?: boolean
  onClick: () => void
}

const LoadingButton = ({
  title,
  loading,
  compact,
  disable,
  onClick,
}: Props) => {
  const handleClick = () => {
    if (!loading) {
      onClick()
    }
  }

  return (
    <button
      disabled={(loading || disable) ?? false}
      className={`base-button${compact ? ' compact-button' : ' create-button'}`}
      onClick={handleClick}
    >
      {title}
    </button>
  )
}

export default LoadingButton
