import React, { useEffect, useRef, useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import '../admin.scss'
import CreateButton from '../../../components/CreateButton'
import EditButton from '../../../components/EditButton'
import { useNavigate } from 'react-router-dom'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { departments } from '../../patientRegstration/constants'

export type AppDepartment = {
  code: string
  display: string
}

export type SpecialityData = {
  status: string
  specialities: AppDepartment[]
}

const Departments = () => {
  const [specialityData, setSpecialityData] = useState<SpecialityData[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'getSpecilities')
      .then((res) => {
        const data = res?.data?.entry?.map((entry: any) => {
          const status = entry?.resource?.status || 'NA'
          const specialities =
            entry?.resource?.compose?.include[0]?.concept || []

          return {
            status,
            specialities: specialities.map((concept: any) => ({
              code: concept?.code || 'NA',
              display: concept?.display || 'NA',
            })),
          }
        })
        setSpecialityData(data)
      })
      .catch((err) => console.log(err))
  }, [])

  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const handleCreate = () => {
    navigate('create')
  }

  const handleEdit = (id?: string) => {
    if (id) {
      navigate(`edit/${id}`)
    }
  }

  return (
    <div className='mtminus8'>
      <div className='membershipText'>{departments}</div>
      <div className='button-enclosure border-none mb9'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type='search'
            className='admin-search'
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            placeholder='Search by Name'
          />
          <CreateButton title={'Create'} onClick={handleCreate} />
        </div>
      </div>
      <div
        ref={patientTableContainerRef}
        className='data-table table-fixed admin-table-table-container'
        style={{ height: '440px', overflowY: 'auto' }}
      >
        <table className='data-table table-fixed admin-table relative'>
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              <th className='table-w-5'>Sl&nbsp;No</th>
              <th className='table-w-50'>Speciality</th>
              <th className='table-w-25'>Super Speciality</th>
              <th>Status</th>
              <th className='table-w-10'>Action</th>
            </tr>
          </thead>
          <tbody className='admin-table-body'>
            {specialityData.map((entry, index) => {
              const filteredSpecialities = entry.specialities.filter(
                (speciality) =>
                  speciality.display
                    .toLowerCase()
                    .startsWith(searchText.toLowerCase())
              )

              if (filteredSpecialities.length === 0) {
                return null
              }
              filteredSpecialities?.sort?.((a, b) =>
                a?.display?.toLowerCase() < b?.display?.toLowerCase() ? -1 : 1
              )

              return (
                <React.Fragment key={index}>
                  {filteredSpecialities.map((speciality, Index) => (
                    <tr key={Index}>
                      <td>{Index + 1}</td>
                      <td>{speciality.display}</td>
                      <td>-</td>
                      <td>{entry.status}</td>
                      <td>
                        <EditButton
                          title='Edit Departments'
                          onEdit={() => handleEdit(speciality?.code)}
                        />
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Departments
