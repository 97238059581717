import React from 'react'
import UnAuthorizedLogo from '../assets/images/block-user.png'

const UnAuthorized = () => {
  return (
    <div className='center-layout'>
      <img src={UnAuthorizedLogo} alt='Unauthorized' />
      <p className='unauthorized'>
        Sorry, You are not authorized to access this page. Please contact
        Administrator
      </p>
    </div>
  )
}

export default UnAuthorized
