import React, { useEffect, useState } from 'react'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import SxpForm from '../../../form/SxpForm'
import { useParams } from 'react-router-dom'
import RenderOnRole from '../../../components/RenderOnRole'
import { DIAGNOSIS_READ, DIAGNOSIS_WRITE } from '../../../utils/roles'
import KeycloakService from '../../../utils/keycloakService'
import { startSxpProxy } from '../../../utils/api'

const IpdVitals = () => {
  const { id } = useParams()
  const [key, setKey] = useState(Date.now())
  const [status, setStatus] = useState(false)
  useEffect(() => {
    startSxpProxy(IPD_PROJECT_ID, 'getEncounterById', { encounterId: id }).then(
      (data) => {
        const status =
          data?.data?.statusHistory?.[0]?.status === 'finished' ? true : false
        setStatus(status)
      }
    )
  }, [id])
  return (
    <RenderOnRole
      roles={[DIAGNOSIS_READ, DIAGNOSIS_WRITE]}
      element={
        <SxpForm
          key={key}
          project={IPD_PROJECT_ID}
          intent='vitalsForIPD'
          journeyState={{ encounterId: id }}
          isDynamic
          canSubmit={
            status === true ? false : KeycloakService.hasRole([DIAGNOSIS_WRITE])
          }
          onJourneyEnd={() => setKey(Date.now())}
        />
      }
    />
  )
}

export default IpdVitals
