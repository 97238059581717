import {
  ChangeEvent,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import './dialog.scss'

type Props = {
  open: boolean
  onClose: () => void
  style?: React.CSSProperties
}

const Dialog = ({
  open,
  onClose,
  style,
  children,
}: PropsWithChildren<Props>) => {
  const [show, setShow] = useState(false)
  const modalRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (open) {
      setShow(true)
      modalRef?.current?.showModal()
    }
  }, [open])

  const onCancel = useCallback(
    (e: ChangeEvent<HTMLDialogElement>) => {
      e.preventDefault()
      onClose()
    },
    [onClose]
  )

  const onClick = useCallback(
    ({ target }: MouseEvent<HTMLDialogElement>) => {
      const { current: el } = modalRef
      if (target === el) onClose()
    },
    [onClose]
  )

  const onAnimEnd = useCallback(() => {
    const { current: el } = modalRef
    if (!open) {
      el?.close()
      setShow(false)
    }
  }, [open])

  return (
    <dialog
      ref={modalRef}
      className={`modal${open ? '' : ' modal-closing'}`}
      onClose={() => onClose()}
      onCancel={onCancel}
      onClick={onClick}
      onAnimationEnd={onAnimEnd}
      style={style}
    >
      {show && (
        <>
          {/* <img
            src={Delete}
            alt='Delete'
            className='delete-image'
            onClick={handleDeleteClick}
          /> */}
          {children}
        </>
      )}
    </dialog>
  )
}

export default Dialog
