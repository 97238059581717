import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { LABS_PROJECT_ID } from '../../../utils/constants'
import { Organization } from '../models'

export const useOrganizations = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([])

  useEffect(() => {
    const intent = 'getOrganizations'
    startSxpProxy(LABS_PROJECT_ID, intent, {})
      .then((data) => {
        setOrganizations(data.data.organization ?? [])
      })
      .catch((err) => {
        console.log(err)
        setOrganizations([])
      })
  }, [])

  return organizations
}
