import { useRef, useEffect } from 'react'
import { AdminTableProps } from '../lms/models'
import { useAppSelector } from '../../app/hooks'
import { selectedLmsAdminSearch } from '../lms/lmsSlice'
import { getSearchResults } from '../lms/utils'
import LinkButton from '../lms/components/admin/LinkButton'
import { noRadiologySamplesFound, tableHeadText } from './constants'
import './radiology.scss'

const RadiologyTest = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = getSearchResults(data, searchParam)

  filtered?.sort?.((a: any, b: any) => {
    if (a.radiology_sample?.name < b.radiology_sample?.name) {
      return -1
    } else if (a.radiology_sample?.name > b.radiology_sample?.name) {
      return 1
    } else {
      return a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1
    }
  })
  const sampleTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = sampleTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={sampleTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead className='head-action'>
          <tr>
            <th className='table-w-5'>{tableHeadText.id}</th>
            <th>{tableHeadText.name}</th>
            <th>{tableHeadText.category}</th>
            <th> {tableHeadText.description}</th>
            <th>{tableHeadText.handledAt}</th>
            <th>{tableHeadText.interpretation}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d.id}>
                <td>{d.id}</td>
                <td>
                  <LinkButton id={d.id} name={d.name} onClick={onEdit} />
                </td>
                <td>{d?.radiology_sample?.name}</td>
                <td>{d.description}</td>
                <td>{d?.handled_at}</td>
                <td>{d?.Interpretation}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className='text-center'>
                {noRadiologySamplesFound}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default RadiologyTest
