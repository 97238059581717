import React, { ChangeEvent } from 'react'
import { ElementProps } from '../models'
import FieldLabel from './FieldLabel'
import ErrorMessage from './ErrorMessage'
import { uploadImage } from '../../utils/api'
import { FILE_SERVER_URL } from '../../utils/constants'

const AttachmentField = ({
  component,
  state,
  handleChange,
  disabled,
  errorMessage,
}: ElementProps) => {
  const key = Date.now()
  const urls = state[component.reference]

  const handleFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files?.length) {
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[0])
      }
      try {
        const response = await uploadImage(formData)
        const urls = []
        for (const name of response.data) {
          urls.push(`${FILE_SERVER_URL}file/${name}`)
        }
        handleChange(urls, component)
      } catch (err) {
        handleChange('', component)
        console.log('error uploading the file', err)
      }
    } else {
      handleChange('', component)
    }
  }

  const clearImage = () => {
    handleChange('', component)
  }

  return (
    <div className='fieldSet'>
      <FieldLabel label={component.label} required={component.required} />
      <div>
        <input
          type='file'
          key={key}
          multiple
          name={component.reference}
          onChange={handleFileInput}
          disabled={disabled}
        />
        <ErrorMessage errorMessage={errorMessage} />
        {urls?.length
          ? (urls as string[]).map((url) => (
              <div
                key={url}
                onClick={clearImage}
                style={{ display: 'inline', marginLeft: 10 }}
              >
                <img src={url} alt={component.reference} />
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

export default AttachmentField
