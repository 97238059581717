import { useNavigate } from 'react-router-dom'
import LabDepartmentsForm from './LabDepartmentsForm'
import { headers } from '../constants'

const CreateLabDepartments = () => {
  const navigate = useNavigate()
  return (
    <>
      <button
        className='base-button back-button'
        onClick={() => navigate('/administration/Labdepartments')}
      >
        {headers.backToLab}
      </button>
      <LabDepartmentsForm />
    </>
  )
}

export default CreateLabDepartments
