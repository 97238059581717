import { Medication } from '../../models'

type Props = {
  medications: Medication[]
  onItemDelete: (id: number) => void
}
export const itemMap: Record<string, string> = {
  immediately: 'Immediately',
  onceDay: 'Once a day',
  twiceDay: 'Twice a day',
  thriceDay: 'Thrice a day',
  fourDay: 'Four times a day',
  everyHour: 'Every Hour',
  twoHour: 'Every 2 hours',
  threeHour: 'Every 3 hours',
  fourHour: 'Every 4 hours',
  sixHour: 'Every 6 hours',
  eightHour: 'Every 8 hours',
  twelveHour: 'Every 12 hours',
  alternateDays: 'On alternate days',
  onceWeek: 'Once a week',
  double: 'Twice a week',
  triple: 'Thrice a week',
  doubleWeek: 'Every 2 weeks',
  tripleWeek: 'Every 3 weeks',
  onceMonth: 'Once a month',
  fiveDay: 'Five times a day',
  fourDayWeek: 'Four days a week',
  fiveDayWeek: 'Five days a week',
  sixDayWeek: 'Six days a week',
  day: 'Day(s)',
  week: 'Week(s)',
  month: 'Month(s)',
}

const MedicationList = ({ medications, onItemDelete }: Props) => {
  return (
    <div className='medication-list'>
      <p className='medication-heading'>Medicines</p>
      {medications.length > 0 ? (
        <table className='medications-table'>
          <thead className='medication-headings'>
            <tr>
              <th>Medicine</th>
              <th>Timing</th>
              <th>Duration</th>
              <th>Interval</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {medications.map((med) => (
              // <tr key={med.id} className='medication-detail'>
              //   {/* Medicine Column */}
              //   <td className='grid-cell medication-name'>{med.drug_name}</td>
              //   {/* Timing Column */}
              //   <td className='grid-cell medication-timing' style={{marginLeft:'20px',marginRight:'none'}}>
              //     {`${med.dose} ${med.dose_units} ${itemMap[med.frequency]}`}
              //   </td>
              //   {/* Duration Column */}
              //   <td>
              //     {med.next_visit_date
              //       ? `Until ${med.next_visit_date}`
              //       : `${med.duration} ${itemMap[med.duration_units]}`}
              //   </td>
              //   {/* Actions Column */}
              //   <td>{med.intervals}</td>
              //   {KeycloakService.hasRole([MEDICATIONS_WRITE]) && (
              //     <td className='grid-cell medicine-btn'>
              //       <button onClick={() => onItemDelete(med.id)}>Remove</button>
              //     </td>
              //   )}
              // </tr>
              <tr key={med.id}>
                <td>{med.drug_name}</td>
                <td>
                  {`${med.dose} ${med.dose_units} ${itemMap[med.frequency]}`}
                </td>
                <td> {med.duration}</td>
                <td>{med.intervals ?? '-'}</td>
                <td className='grid-cell medicine-btn'>
                  <button onClick={() => onItemDelete(med.id)}>Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        ''
      )}
    </div>
  )
}

export default MedicationList
