import React from 'react'
import UploadDocs from '../../patients/dashboard/UploadDocs'
import { selectId } from './IpdVisitSlice'
import { useAppSelector } from '../../../app/hooks'

const InitialAssessment = () => {
  const id = useAppSelector(selectId)
  return <div>{id && <UploadDocs patientId={id} />}</div>
}

export default InitialAssessment
