import React from 'react'

const AppointmentCard = (props: any) => {
  return (
    <div className='mainCard'>
      <h3>{props.title}</h3>
      <div className='cardContent'>
        <table className='data-table'>
          <thead>
            <tr>
              <th>Service</th>
              <th>Doc Name</th>
              <th>Date</th>
              <th>Time Slot</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {props.data.length ? (
              props.data.map((d: any, i: number) => SingleCard(d, i))
            ) : (
              <tr>
                <td className='text-center' colSpan={5}>
                  {' '}
                  ~ Upcoming Appointments ~
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AppointmentCard

const SingleCard = (d: any, i: number) => {
  return (
    <tr key={i}>
      <td>{d.service}</td>
      <td>{d.docName ? d.docName : '-'}</td>
      <td className='text-center'>{d.date}</td>
      <td className='text-center'>{d.time}</td>
      <td>{d.status ? d.status : '-'}</td>
    </tr>
  )
}
