import { useRef, useEffect } from 'react'
import { getAgeInYears } from '../../../utils/dateUtils'
import { MemberPatient, SearchPatient } from '../models'

type props = {
  patients: SearchPatient[]
  onMemberSelect: (val: string) => void
  members: MemberPatient[]
}

const SearchPatients = ({ patients, onMemberSelect, members }: props) => {
  const patientsTableContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const resizeHandler = () => {
      const container = patientsTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 25}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const isAdded = (id: string): boolean =>
    members.findIndex((m) => m.id === id) !== -1

  return (
    <>
      <div className='search-table'>
        <p className='admin-header'>Search Results:</p>
      </div>

      <div
        className='fixedTableHeads'
        ref={patientsTableContainerRef}
        style={{ marginBottom: 70 }}
      >
        <table className='data-table table-fixed admin-table'>
          <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
            <tr>
              <th className='table-w-4' style={{ padding: '6px' }}>
                Sl&nbsp;No
              </th>
              <th className='text-left table-w-15'>UHID</th>
              <th className='text-left table-w-30'>Name</th>
              <th className='text-center table-w-6'>Gender</th>
              <th className='text-center table-w-8'>Age</th>
              <th className='text-center table-w-6'>Action</th>
            </tr>
          </thead>
          <tbody>
            {patients.length > 0 ? (
              patients.map((d: SearchPatient, i) => (
                <tr className='memberId' key={d.id}>
                  <td>{i + 1}</td>
                  <td>{d.uhid || d.thopId}</td>
                  <td className='selected-name'>{d.name} </td>
                  <td className='text-center'>{d.gender}</td>
                  <td className='text-center'>{getAgeInYears(d.dob) ?? '-'}</td>
                  <td className='text-center'>
                    <button
                      className={
                        members.length >= 4 || isAdded(d.id)
                          ? 'disabled-button'
                          : 'typeButton'
                      }
                      onClick={() => onMemberSelect(d.id)}
                      disabled={members.length >= 4 || isAdded(d.id)}
                    >
                      Add
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className='text-center'>
                  No Patients found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SearchPatients
