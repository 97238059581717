import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { PractitionerRole } from 'fhir/r4'
import { forms, intent, messages } from '../constants'
import PathologistForm, { FormPathologist } from './PathologistForm'

export const formatPathologist = (e: any): FormPathologist => {
  const practitionerRole: PractitionerRole = e.find(
    (e: { resource: { resourceType: string } }) =>
      e?.resource?.resourceType === forms.practitionerRole
  )?.resource
  const code = practitionerRole.specialty?.[0]?.coding?.[0]?.code ?? ''
  const display = practitionerRole.specialty?.[0]?.coding?.[0]?.display ?? ''
  const practitioner = e.find(
    (e: { resource: { resourceType: string } }) =>
      e?.resource?.resourceType === forms.practitioner
  )
  const id = practitioner?.resource.id
  const gender = practitioner?.resource?.gender
  const salutation = practitioner?.resource?.name?.[0]?.prefix?.[0]
  const firstName = practitioner?.resource?.name?.[0]?.given?.[0] ?? '-'
  const middleName = practitioner?.resource?.name?.[0]?.given?.[0] ?? '-'
  const lastName = practitioner?.resource?.name?.[0]?.family ?? '-'
  const email =
    practitioner?.resource?.telecom?.find(
      (e: { system: string }) => e?.system === 'email'
    )?.value ?? ''
  const phone =
    practitioner?.resource?.telecom?.find(
      (e: { system: string }) => e?.system === 'phone'
    )?.value ?? '-'
  const qualificationValue =
    practitioner?.resource?.qualification?.[0]?.code?.text
  const regNumber =
    practitioner?.resource?.qualification?.[0]?.identifier?.[0]?.value ?? '-'
  const active = practitioner?.resource?.active ?? true
  const dob = practitioner?.resource?.birthDate
  const photo = practitioner?.resource?.photo?.[0]?.url
  const signature = practitioner?.resource?.photo?.[1]?.url
  const speciality = `${display}::${code}`
  return {
    id,
    gender,
    active,
    email,
    phone,
    qualificationValue,
    regNumber,
    salutation,
    firstName,
    lastName,
    middleName,
    dob,
    speciality,
    photo,
    signature,
  }
}

const EditPathologist = () => {
  const [pathologist, setPathologist] = useState<FormPathologist | null>(null)
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      startSxpProxy(ADMIN_PROJECT_ID, intent.getDoctorById, { id })
        .then((data) => {
          setPathologist(formatPathologist(data?.data?.entry))
        })
        .catch((err) => console.error(err))
    }
  }, [id])

  const navigate = useNavigate()
  return (
    <>
      <button
        className='base-button back-button'
        onClick={() => navigate('/administration/pathologist')}
      >
        {messages.backToPathology}
      </button>
      {pathologist ? (
        <PathologistForm pathologist={pathologist} />
      ) : (
        <p>{messages.notValidPathologist}</p>
      )}
    </>
  )
}

export default EditPathologist
