import React from 'react'
import './icons.scss'
import odoo1 from '../../assets/images/User_icon.png'
import odoo2 from '../../assets/images/Admin_icon.png'
import odoo3 from '../../assets/images/Innoment_icon.png'
import odoo4 from '../../assets/images/Lan_icon.png'
import odoo5 from '../../assets/images/Radiology.png'
import odoo6 from '../../assets/images/bed_icon.png'
import odoo7 from '../../assets/images/MemberShip_icon.png'
import odoo8 from '../../assets/images/Reports.png'
import odoo9 from '../../assets/images/RMS_icon.png'
import odoo10 from '../../assets/images/Appointments_icon.png'
import odoo11 from '../../assets/images/billing.png'
import odoo12 from '../../assets/images/Pharmacy.png'

const Myicons: React.FC = () => {
  return (
    <div id='container'>
      <div className='icons_All'>
        <div>
          <span className='myicons'>
            <img src={odoo1} alt='Odoo Icon 1' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>Registration</p>
        </div>
        <div>
          <span className='myicons'>
            <img src={odoo7} alt='Odoo Icon 2' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>Membership</p>
        </div>
        <div>
          <span className='myicons'>
            <img src={odoo10} alt='Odoo Icon 3' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>Appointment</p>
        </div>
        <div>
          <span className='myicons'>
            <img src={odoo4} alt='Odoo Icon 4' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>LMS</p>
        </div>
        <div>
          <span className='myicons'>
            <img src={odoo5} alt='Odoo Icon 5' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>Radiology</p>
        </div>
        <div>
          <span className='myicons'>
            <img src={odoo6} alt='Odoo Icon 6' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>IPD</p>
        </div>
        <div>
          <span className='myicons'>
            <img src={odoo11} alt='Odoo Icon 7' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>Billing</p>
        </div>
        <div>
          <span className='myicons'>
            <img src={odoo12} alt='Odoo Icon 8' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>Pharmacy</p>
        </div>
        <div>
          <span className='myicons'>
            <img src={odoo2} alt='Odoo Icon 9' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>Administration</p>
        </div>
        <div>
          <span className='myicons'>
            <img src={odoo8} alt='Odoo Icon 10' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>Reporting</p>
        </div>

        <div>
          <span className='myicons'>
            <img src={odoo3} alt='Odoo Icon 11' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>Innoment</p>
        </div>

        <div>
          <span className='myicons'>
            <img src={odoo9} alt='Odoo Icon 12' className='odoo-image' />
          </span>
          <p className='paragragh_icon'>RMS</p>
        </div>
      </div>
    </div>
  )
}

export default Myicons
