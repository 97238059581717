import React from 'react'
import { LabOrder, LabTest, Panel, Sample } from '../models'
import Delete from '../../../assets/images/close.png'
import { groupTests } from '../../lms/utils'
import KeycloakService from '../../../utils/keycloakService'
import { LAB_ORDERS_WRITE } from '../../../utils/roles'

type Props = {
  samples: Sample[]
  selectedPanels: Panel[]
  selectedLabTests: LabTest[]
  saved: LabOrder
  onUpdate: (id?: number) => void
  onSave: () => void
}

const SelectedLabTests = ({
  samples,
  selectedPanels,
  selectedLabTests,
  saved,
  onUpdate,
  onSave,
}: Props) => {
  const { filteredPanels, filteredLabTests } = groupTests(
    saved,
    selectedPanels,
    selectedLabTests
  )
  const allTests: Record<number, string[]> = {}
  for (const panel of filteredPanels) {
    const tests = panel.lab_tests.map((plt) => plt.name)
    const existing = allTests[panel.sampleId]
    if (existing?.length) {
      allTests[panel.sampleId] = [...existing, ...tests]
    } else {
      allTests[panel.sampleId] = tests
    }
  }
  for (const labTest of filteredLabTests) {
    const existing = allTests[labTest.sampleId]
    if (existing?.length) {
      allTests[labTest.sampleId] = [...existing, labTest.name]
    } else {
      allTests[labTest.sampleId] = [labTest.name]
    }
  }
  const testKeys = Object.keys(allTests)

  return (
    <div className='selected-container'>
      <span className='heading'>Selected Tests</span>
      {testKeys.length > 0 ? (
        <>
          {testKeys.map((tk) => {
            const sample = samples.find((s) => s.id === parseInt(tk))
            return (
              <div key={tk} className='selected-sample'>
                <div className='selected-header'>
                  <span>{sample?.name ?? tk}</span>
                  <img
                    className='delete'
                    onClick={() => onUpdate(sample?.id)}
                    src={Delete}
                    alt='Delete'
                  />
                </div>
                <div className='selected-content'>
                  {allTests[parseInt(tk)]?.join(', ') ?? '-'}
                </div>
              </div>
            )
          })}
          {saved.order_events &&
          saved?.order_events?.[0]?.type !== 'ORDERED' &&
          saved?.order_events?.[0]?.type !== 'ORDER_MODIFIED' ? (
            <p className='admin-header'>Lab Samples Collected</p>
          ) : (
            <button
              onClick={onSave}
              className={`save-btn ${
                KeycloakService.hasRole([LAB_ORDERS_WRITE])
                  ? ''
                  : 'auth-disabled'
              }`}
            >
              Save
            </button>
          )}
        </>
      ) : (
        <p className='admin-header'>No Tests selected</p>
      )}
    </div>
  )
}

export default SelectedLabTests
