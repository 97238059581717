import Edit from '../assets/icons/edit_icon.svg'

type Props = {
  title: string
  onEdit: () => void
}

const EditButton = ({ title, onEdit }: Props) => {
  return (
    <div onClick={onEdit} title={title} style={{ textAlign: 'center' }}>
      <img alt={title} src={Edit} style={{ height: '18px' }} />
    </div>
  )
}

export default EditButton
