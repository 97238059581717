import { useState } from 'react'
import { bedFilter } from '../constants'
import { getRemarks } from '../IpdUtils'
import { allocation } from '../modals'

type bed = {
  data: bedFilter
  id: number
  onChange: (data: bedFilter, remarks: string) => void
}
const EditRemarksText = ({ data, onChange }: bed) => {
  const [remarks, setRemarks] = useState(getRemarks(data?.assignedDoctor))
  const handleEdit = () => {
    onChange(data, remarks)
  }
  return (
    <>
      <input
        type='text'
        placeholder='Enter Remarks'
        className='doctor-input'
        value={remarks}
        onChange={(e) => setRemarks(e.target.value)}
      />
      <button className='edit-doctor' onClick={handleEdit}>
        {allocation.save}
      </button>
    </>
  )
}
export default EditRemarksText
