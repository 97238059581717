import React from 'react'
import Barcode from 'react-barcode'
import { RLabel } from '../../models'
import { readableDateFormat } from '../../../../utils/dateUtils'

type Props = {
  data: RLabel
}

const ResultLabel = ({ data }: Props) => {
  return (
    <div className='result-label'>
      <div className='name-row'>
        <span>{data.name}</span>
        <span>{data.gender}</span>
        <span>{data.age}</span>
      </div>
      <div className='id-row'>
        <span>{data.patientId}</span>
        <span>{readableDateFormat(new Date())}</span>
      </div>
      <div className='code-row'>
        <Barcode
          value={'' + data.code}
          height={30}
          width={1.3}
          margin={0}
          fontSize={12}
        />
      </div>
    </div>
  )
}

export default ResultLabel
