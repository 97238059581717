import React, { useRef, useEffect, useState } from 'react'
import { appointmentDateFormat } from '../../../utils/dateUtils'
import StatusChange from './StatusChange'
import { useAppSelector } from '../../../app/hooks'
import {
  AppointmentData,
  selectAppointments,
  selectAppointmentsStatus,
  selectProvenanceKey,
  selectSearchValue,
} from '../appointmentsSlice'
import VisitLink from './VisitLink'
import MeetingLink from './MeetingLink'
import MaskedNumber from '../../../components/MaskedNumber'
import KeycloakService from '../../../utils/keycloakService'
import { MEETING_LINK } from '../../../utils/roles'
import {
  selectLocations,
  selectSelectedLocationId,
} from '../../location/locationSlice'
import BillingLink from './BillingLink'
import { startSxpProxy } from '../../../utils/api'
import { CHAT_PROJECT_ID } from '../../../utils/constants'
import { Practitioner, Provenance } from 'fhir/r4'
import { getProvenanceData } from '../../patients/utils'
import { ProvenanceData } from '../../patients/patientsSlice'
import ProvenanceView from '../../../components/ProvenanceView'

type Props = {
  handleChange: (status: string, appointment: AppointmentData) => void
}

const SingleAppointment = ({
  d,
  provenances,
  index,
  handleChange,
  locations,
}: {
  d: AppointmentData
  provenances: Record<string, ProvenanceData>
  index: number
  handleChange: (e: string) => void
  locations: any
}) => {
  const shouldDisable = (d: AppointmentData): boolean => {
    return d.status === 'fulfilled' || d.status === 'cancelled'
  }
  const locationName = (selectedId: any) => {
    const selectedLocation = locations?.find(
      (e: any) => e.resource?.id === selectedId
    )
    return selectedLocation?.resource?.name ?? '-'
  }
  return (
    <tr>
      <td className='text-center'>{index + 1}</td>
      <td className='text-center'>{d.patientUhid || d.patientThopId}</td>
      <td>
        {d.patientName}
        {d.status !== 'cancelled' && <VisitLink appointment={d} />}
      </td>
      <td className='text-center'>
        <MaskedNumber mobile={d.patientNumber} />
      </td>
      <td className='text-center'>{locationName(d.locationId)}</td>
      <td className='text-center'>{d.speciality}</td>
      <td>{d.practitioner ? `Dr. ${d.practitioner}` : 'NA'}</td>
      <td className='text-center'>
        {appointmentDateFormat(d.slotTiming)}{' '}
        {d.meetingLink &&
        d.status === 'checked-in' &&
        KeycloakService.hasRole([MEETING_LINK]) ? (
          <MeetingLink appointment={d} />
        ) : null}
      </td>
      <td className='text-center'>
        <ProvenanceView
          date={provenances[d.id]?.createdOn}
          name={provenances[d.id]?.createdBy}
          mode={'CREATE'}
        />
      </td>
      <td className='text-center'>
        <ProvenanceView
          date={provenances[d.id]?.updatedOn ?? provenances[d.id]?.createdOn}
          name={provenances[d.id]?.updatedBy ?? provenances[d.id]?.createdBy}
          mode={'UPDATE'}
        />
      </td>
      <td title={d.reasonCode} className='text-center'>
        {d.consultationType}
      </td>
      <td className='text-center'>
        <div className='flex apart'>
          <StatusChange
            initialStatus={d.status}
            disabled={shouldDisable(d)}
            handleChange={handleChange}
            appointmentDate={d.slotTiming}
          />
        </div>
      </td>
      <td>
        {d.consultationType === 'Paid' && d.status !== 'cancelled' ? (
          <BillingLink status={d.status} thopId={d.thopId} />
        ) : (
          '-'
        )}
      </td>
    </tr>
  )
}

const startsWithString = (first: string, second: string) =>
  first?.toLowerCase()?.startsWith(second?.toLowerCase())

const searchByValue = (data: AppointmentData[], value: string) => {
  if (!value) {
    return data
  }
  return data.filter(
    (d) =>
      startsWithString(d.patientUhid, value) ||
      startsWithString(d.patientThopId, value) ||
      startsWithString(d.patientName, value) ||
      startsWithString(d.patientNumber, value) ||
      startsWithString(d.practitioner, value) ||
      startsWithString(d.speciality, value)
  )
}

const AppointmentsTable = ({ handleChange }: Props) => {
  const status = useAppSelector(selectAppointmentsStatus)
  const appointments = useAppSelector(selectAppointments)
  const searchValue = useAppSelector(selectSearchValue)
  const locationId = useAppSelector(selectSelectedLocationId)
  const provenanceKey = useAppSelector(selectProvenanceKey)
  const locations = useAppSelector(selectLocations)
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})

  let filtered = appointments.filter(
    (app) => !(app.locationId && app.locationId !== locationId)
  )
  filtered = searchByValue(filtered, searchValue)
  const ids = filtered.map((f) => f.id).join(',')

  useEffect(() => {
    if (ids) {
      startSxpProxy(CHAT_PROJECT_ID, 'fetchAppointments', {
        appointmentIds: ids,
      })
        .then((data) => {
          const entries =
            data.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde.resource
            ) ?? []
          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((err) => console.log(err))
    }
  }, [ids, provenanceKey])

  const appointmentsTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = appointmentsTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 35}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  return (
    <div
      className='appointments-table-container'
      ref={appointmentsTableContainerRef}
    >
      <table
        className='data-table admin-table'
        style={{ position: 'relative' }}
      >
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr style={{ textAlign: 'center' }}>
            <th className='width-minor'>Sl No</th>
            <th className='table-w-10'>UHID</th>
            <th className='text-left'>Name</th>
            <th>Mobile No.</th>
            <th className='table-w-9'>Operating Unit</th>
            <th>Speciality</th>
            <th className='text-left'>Consultant</th>
            <th>Slot Timing</th>
            <th>Created</th>
            <th>Updated</th>
            <th className='table-w-5'>Type</th>
            <th className='table-w-10'>Status</th>
            <th>Pay.Status</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length > 0 ? (
            filtered.map((d, i) => (
              <SingleAppointment
                key={d.id}
                d={d}
                provenances={provenances}
                locations={locations}
                index={i}
                handleChange={(e) => handleChange(e, d)}
              />
            ))
          ) : (
            <tr>
              <td colSpan={11} style={{ textAlign: 'center' }}>
                {status === 'loading'
                  ? 'Loading Appointments'
                  : 'No Appointments found'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default AppointmentsTable
