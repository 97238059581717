import React, { MouseEvent, useState } from 'react'
import Summary from './Summary'
import { LmsObservation, LmsValues, resultsSummary } from '../../models'
import Observation from './Observation'
import '../../lms.scss'

type ViewProps = {
  obs: LmsObservation
  selected?: LmsObservation
  disabled: boolean
  onSelect: (obs: LmsObservation) => void
  onDelete: (obs: LmsObservation) => void
}

const ObservationView = ({
  obs,
  disabled,
  selected,
  onSelect,
  onDelete,
}: ViewProps) => {
  const handleDelete = (
    ev: MouseEvent<HTMLButtonElement>,
    o: LmsObservation
  ) => {
    onDelete(o)
    ev.stopPropagation()
  }

  return (
    <div
      key={obs.id}
      className={`obs-flex${selected?.id === obs.id ? ' selected' : ''}`}
      onClick={() => onSelect(obs)}
    >
      <div className='w165px obs-container'>
        <span className='obs-span-title'>{obs.title}</span>
      </div>
      <div className='w380px obs-conatiner'>
        <span className='obs-span-content'>{obs.content}</span>
      </div>
      <button
        disabled={disabled}
        onClick={(ev) => handleDelete(ev, obs)}
        className='obs-delete'
      >
        -
      </button>
    </div>
  )
}

type ListProps = {
  values?: LmsValues
  disabled: boolean
  onSummaryChange: (summary: string) => void
  onObservationChange: (observations: LmsObservation[]) => void
}

const ObservationList = ({
  values,
  disabled,
  onSummaryChange,
  onObservationChange,
}: ListProps) => {
  const [selected, setSelected] = useState<LmsObservation | undefined>(
    undefined
  )

  const handleSummaryChange = (str: string) => {
    onSummaryChange(str)
  }

  const handleObservationSelect = (obs: LmsObservation) => {
    if (disabled) {
      return
    }
    if (selected?.id === obs.id) {
      setSelected(undefined)
    } else {
      setSelected(obs)
    }
  }

  const handleDelete = (obs: LmsObservation) => {
    if (obs.id === selected?.id) {
      setSelected(undefined)
    }
    const updated = values?.observations.filter((v) => v.id !== obs.id) ?? []
    onObservationChange(updated)
  }

  const handleObservationSave = (data: LmsObservation) => {
    onObservationChange([...(values?.observations ?? []), data])
  }

  const handleObservationUpdate = (data: LmsObservation) => {
    const updated =
      values?.observations.map((v) => {
        if (v.id === data.id) {
          return data
        }
        return v
      }) ?? []
    onObservationChange(updated)
    setSelected(undefined)
  }

  return (
    <div>
      {!disabled && (
        <Observation
          existing={selected}
          onSave={handleObservationSave}
          onUpdate={handleObservationUpdate}
        />
      )}
      <div>
        {!!values?.observations?.length &&
          values.observations.map((oo) => (
            <ObservationView
              key={oo.id}
              selected={selected}
              disabled={disabled}
              obs={oo}
              onSelect={handleObservationSelect}
              onDelete={handleDelete}
            />
          ))}
        <div className='summary-container'>
          <span className='summary-span-container'>{resultsSummary}</span>
          <Summary
            disabled={disabled}
            summary={values?.summary || ''}
            onChange={handleSummaryChange}
          />
        </div>
      </div>
    </div>
  )
}

export default ObservationList
