import { useState } from 'react'
import CreateButton from '../../../components/CreateButton'
import { useNavigate } from 'react-router-dom'
import EditButton from '../../../components/EditButton'

const Organization = () => {
  const [partnerData, setPartnerData] = useState<[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const navigate = useNavigate()
  const handleCreate = () => {
    navigate('create')
  }
  const mockData = [
    {
      id: '',
      type: 'B2BPartner',
      name: 'akhil',
      phone: '9900099009',
      email: 'ramaraju@gmail.com',
      status: 'Active',
    },
    {
      id: '',
      type: 'Employee',
      name: 'rama raju',
      phone: '9900099009',
      email: 'ramaraju@gmail.com',
      status: 'In Active',
    },
    {
      id: '',
      type: 'self',
      name: 'akhil',
      phone: '9900099009',
      email: 'ramaraju@gmail.com',
      status: 'Active',
    },
  ]
  const handleEdit = (id: any) => {
    return 'adsad'
  }
  return (
    <>
      <div>
        <div className='button-enclosure border-none mb9 flexible'>
          <div>
            <span className='membershipText'>Partner</span>
          </div>
          <div>
            <input
              type='search'
              className='admin-search'
              value={searchText}
              onChange={({ target: { value } }) => setSearchText(value)}
              placeholder='Search by Name'
            />
            <CreateButton title={'Create'} onClick={handleCreate} />
          </div>
        </div>
        <div>
          <table className='partner-table w100'>
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Partner Type</th>
                <th>Partner Name</th>
                <th>Phone Number</th>
                <th>Email Id</th>
                <th>Location</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {mockData?.map((e, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{e?.type}</td>
                  <td>{e?.name}</td>
                  <td>{e?.phone}</td>
                  <td>{e?.email}</td>
                  <td>-</td>
                  <td>{e?.status}</td>
                  <td>
                    <td className='edit-relation'>
                      <EditButton
                        title='Edit Doctor'
                        onEdit={() => handleEdit(i)}
                      />
                    </td>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default Organization
