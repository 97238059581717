import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectedLmsItem, setLmsFormMode, setLmsItemId } from '../../lmsSlice'
import { startSxpProxy } from '../../../../utils/api'
import SampleTable from './SampleTable'
import PanelTable from './PanelTable'
import LabTestTable from './LabTestTable'
import TestRangeTable from './TestRangeTable'
import PackagesTable from './PackagesTable'
import SequencesConfig from './SequencesConfig'
import DepartmentTable from './DepartmentTable'
import UnitTypeTable from './UnitTypeTable'
import MethodTable from './MethodTable'
import RenderOnRole from '../../../../components/RenderOnRole'
import { LMS_ADMIN_WRITE } from '../../../../utils/roles'
import ResultTypeTable from './ResultTypeTable'
import RadiologySampleTable from '../../../Radiology/RadiologyCategory'
import RadiologyTest from '../../../Radiology/RadiologyTest'
import Wards from './Wards'
import Rooms from './Rooms'
import Beds from './Beds'
type props = {
  projectId: string
}
const AdminTable = ({ projectId }: props) => {
  const [data, setData] = useState<any>({})
  const item = useAppSelector(selectedLmsItem)
  const dispatch = useAppDispatch()
  const handleEdit = (id: string) => {
    dispatch(setLmsItemId(id))
    dispatch(setLmsFormMode('edit'))
  }

  useEffect(() => {
    if (item && item !== 'sequence') {
      let intent: string
      const state = {}
      switch (item) {
        case 'sample':
          intent = 'getAllSamplesApi'
          break
        case 'panel':
          intent = 'getAllPanelsApi'
          break
        case 'labTest':
          intent = 'getAllLabTestsApi'
          break
        case 'department':
          intent = 'getAllDepartmentsApi'
          break
        case 'unitType':
          intent = 'getAllTestUnitTypesApi'
          break
        case 'resultType':
          intent = 'getAllTestResultTypes'
          break
        case 'method':
          intent = 'getAllTestMethodsApi'
          break
        case 'testRange':
          intent = 'getAllTestResultLimitsApi'
          break
        case 'packages':
          intent = 'getAllPackagesApi'
          break
        case 'radiologyCategory':
          intent = 'getRadiologySamplesAPI'
          break
        case 'radiologyTest':
          intent = 'getRadiologyTestsAPI'
          break
        case 'ward':
          intent = 'getWardDetails'
          break
        case 'rooms':
          intent = 'getRoomDetails'
          break
        case 'beds':
          intent = 'getAllBeds'
          break
        default:
          intent = ''
      }
      startSxpProxy(projectId, intent, state)
        .then((data) => {
          setData(data.data)
        })
        .catch(() => {
          setData(null)
        })
    }
  }, [item, projectId])

  let content
  switch (item) {
    case 'sample':
      content = <SampleTable data={data?.sample} onEdit={handleEdit} />
      break
    case 'panel':
      content = <PanelTable data={data?.panel} onEdit={handleEdit} />
      break
    case 'labTest':
      content = <LabTestTable data={data?.lab_test} onEdit={handleEdit} />
      break
    case 'department':
      content = <DepartmentTable data={data?.department} onEdit={handleEdit} />
      break
    case 'unitType':
      content = (
        <UnitTypeTable data={data?.test_unit_type} onEdit={handleEdit} />
      )
      break
    case 'resultType':
      content = (
        <ResultTypeTable data={data?.test_result_type} onEdit={handleEdit} />
      )
      break
    case 'method':
      content = <MethodTable data={data?.test_method} onEdit={handleEdit} />
      break
    case 'testRange':
      content = (
        <TestRangeTable data={data?.test_result_limits} onEdit={handleEdit} />
      )
      break
    case 'packages':
      content = <PackagesTable data={data?.packages} onEdit={handleEdit} />
      break
    case 'sequence':
      content = (
        <RenderOnRole roles={[LMS_ADMIN_WRITE]} element={<SequencesConfig />} />
      )
      break
    case 'radiologyCategory':
      content = (
        <RadiologySampleTable
          data={data?.radiology_sample}
          onEdit={handleEdit}
        />
      )
      break
    case 'radiologyTest':
      content = (
        <RadiologyTest data={data?.radiology_lab_test} onEdit={handleEdit} />
      )
      break
    case 'ward':
      content = <Wards data={data?.ward_details} onEdit={handleEdit} />
      break
    case 'rooms':
      content = <Rooms data={data?.room_details} onEdit={handleEdit} />
      break
    case 'beds':
      content = <Beds data={data?.bed} onEdit={handleEdit} />
      break

    default:
      content = <p>No Data</p>
  }

  return item ? content : null
}

export default AdminTable
