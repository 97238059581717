import React from 'react'
import SxpElement from '../../form/SxpElement'

const Patient = () => (
  <>
    <SxpElement reference='service' />
    <SxpElement reference='salutation' />
    <SxpElement reference='firstName' />
    <SxpElement reference='middleName' />
    <SxpElement reference='lastName' />
    <SxpElement reference='gender' />
    <SxpElement reference='dob' />
    <SxpElement reference='age' />
  </>
)

const Other = () => (
  <>
    <SxpElement reference='notes' />
    <SxpElement reference='patientSource' />
    <SxpElement reference='phoneNumber' />
    <SxpElement reference='whatsapp' />
    <SxpElement reference='alternateNumber' />
    <SxpElement reference='emailId' />
    <SxpElement reference='maritalStatus' />
    <SxpElement reference='socioEconomicStatus' />
    <SxpElement reference='governmentId' />
    <SxpElement reference='otherId' />
    <SxpElement reference='idNumber' />
    <SxpElement reference='aadharId' />
    <SxpElement reference='religion' />
    <SxpElement reference='otherReligion' />
    <SxpElement reference='occupation' />
    <SxpElement reference='otherOccupation' />
  </>
)

const Address = () => (
  <>
    <SxpElement reference='houseNo' />
    <SxpElement reference='area' />
    <SxpElement reference='town' />
    <SxpElement reference='state' />
    <SxpElement reference='district' />
    <SxpElement reference='postalCode' />
    <SxpElement reference='permanentAddressConfirmation' />
    <SxpElement reference='permanentHouseNo' />
    <SxpElement reference='permanentArea' />
    <SxpElement reference='permanentTown' />
    <SxpElement reference='permanentState' />
    <SxpElement reference='permanentDistrict' />
    <SxpElement reference='permanentPostalCode' />
  </>
)

const Emergency = () => (
  <>
    <SxpElement reference='emergencyContactName' />
    <SxpElement reference='emergencyPhoneNumber' />
    <SxpElement reference='emergencyRelationshipWithPatient' />
    <SxpElement reference='otherRelation' />
    <SxpElement reference='emergencyAddress' />
  </>
)

const Quick = () => (
  <>
    <SxpElement reference='service' />
    <SxpElement reference='salutation' />
    <SxpElement reference='firstName' />
    <SxpElement reference='middleName' />
    <SxpElement reference='lastName' />
    <SxpElement reference='gender' />
    <SxpElement reference='dob' />
    <SxpElement reference='age' />
    <SxpElement reference='phoneNumber' />
    <SxpElement reference='whatsapp' />
    <SxpElement reference='emailId' />
  </>
)

const ER = () => (
  <>
    <SxpElement reference='service' />
    <SxpElement reference='salutation' />
    <SxpElement reference='firstName' />
    <SxpElement reference='middleName' />
    <SxpElement reference='lastName' />
    <SxpElement reference='gender' />
    <SxpElement reference='dob' />
    <SxpElement reference='age' />
    <SxpElement reference='notes' />
    <SxpElement reference='phoneNumber' />
    <SxpElement reference='whatsapp' />
    <SxpElement reference='emailId' />
  </>
)
const CAMPAIGN = () => (
  <>
    <SxpElement reference='service' />
    <SxpElement reference='campName' />
    <SxpElement reference='salutation' />
    <SxpElement reference='firstName' />
    <SxpElement reference='middleName' />
    <SxpElement reference='lastName' />
    <SxpElement reference='gender' />
    <SxpElement reference='dob' />
    <SxpElement reference='age' />
    <SxpElement reference='notes' />
    <SxpElement reference='phoneNumber' />
    <SxpElement reference='whatsapp' />
    <SxpElement reference='emailId' />
  </>
)

const PACKAGE = () => (
  <>
    <SxpElement reference='service' />
    <SxpElement reference='salutation' />
    <SxpElement reference='firstName' />
    <SxpElement reference='middleName' />
    <SxpElement reference='lastName' />
    <SxpElement reference='gender' />
    <SxpElement reference='dob' />
    <SxpElement reference='age' />
  </>
)

const StepElements = ({ index, kind }: { index: number; kind: string }) => {
  switch (index) {
    case 0:
      if (kind === 'fr' || kind === 'membershipPrimary') {
        return <Patient />
      } else if (kind === 'er') {
        return <ER />
      } else if (kind === 'camp') {
        return <CAMPAIGN />
      } else if (kind === 'package') {
        return <PACKAGE />
      } else {
        return <Quick />
      }

    case 1:
      return <Other />
    case 2:
      return <Address />
    case 3:
      return <Emergency />
  }
  return null
}

export default StepElements
