import React from 'react'
import { RadiologyOrder, RadiologyTest } from '../models'
import Delete from '../../../assets/images/close.png'
import Refresh from '../../../assets/images/refresh.png'
import KeycloakService from '../../../utils/keycloakService'
import { LAB_ORDERS_WRITE } from '../../../utils/roles'

type Props = {
  selected: RadiologyTest[]
  saved: RadiologyOrder
  unselected: RadiologyTest[]
  onUpdate: (id: number) => void
  onSave: () => void
}

const SelectedLabTests = ({
  selected,
  saved,
  unselected,
  onUpdate,
  onSave,
}: Props) => {
  const labTests =
    saved?.lab_tests?.map((sp) => {
      if (unselected.findIndex((us) => us.id === sp.id) >= 0) {
        return { ...sp, removed: true }
      }
      return sp
    }) ?? []
  return (
    <div className='selected-container'>
      <span className='heading'>Selected Orders</span>
      {selected.length > 0 || labTests.length > 0 ? (
        <>
          {labTests.map((sample: any) => (
            <div className='selected' key={sample.id}>
              {sample.name}
              <img
                className='delete'
                onClick={() => onUpdate(sample.id)}
                src={sample.removed ? Refresh : Delete}
                alt='Delete'
              />
            </div>
          ))}
          {selected.map((sample) => (
            <div className='selected' key={sample.id}>
              {sample.name}
              <img
                className='delete'
                onClick={() => onUpdate(sample.id)}
                src={Delete}
                alt='Delete'
              />
            </div>
          ))}
          <button
            disabled={!selected.length && !unselected.length}
            onClick={onSave}
            style={{ alignSelf: 'self-start' }}
            className={`btn btn-primaryBtn mt10${
              !selected.length && !unselected.length ? ' disableBtn' : ''
            } ${
              KeycloakService.hasRole([LAB_ORDERS_WRITE]) ? '' : 'auth-disabled'
            }`}
          >
            Save
          </button>
        </>
      ) : (
        <span className='not-found'>No Tests Selected</span>
      )}
    </div>
  )
}

export default SelectedLabTests
