import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export type AppSliceData = {
  pageSize: number
}

const initialState: AppSliceData = {
  pageSize: 10,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
  },
})

export const { setPageSize } = appSlice.actions

export const selectPageSize = (state: RootState) => state.app.pageSize

export default appSlice.reducer
