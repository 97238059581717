import React, { ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectSlotsSelectedLocation,
  setSelectedSlotsLocation,
} from '../slotsSlice'
import { selectLocations } from '../../location/locationSlice'

const SelectLocation = () => {
  const locations = useAppSelector(selectLocations)
  const selectedLocation = useAppSelector(selectSlotsSelectedLocation)
  const dispatch = useAppDispatch()

  const onLocationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedSlotsLocation(e.target.value))
  }

  return (
    <select
      className='doctorSelect'
      value={selectedLocation}
      onChange={onLocationChange}
    >
      <option value=''>Select a Location</option>
      {locations
        .filter((l) => l.resource.status === 'active')
        .map(({ resource }) => (
          <option key={resource.id} value={resource.id}>
            {resource.name}
          </option>
        ))}
    </select>
  )
}

export default SelectLocation
