export function dateDifference(startDate: string, endDate: string): number {
  const start = new Date(startDate)
  const end = new Date(endDate)

  if (start > end) {
    return 0
  }

  const diffInMs = end.getTime() - start.getTime()

  const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

  return Math.floor(diffInDays)
}

export function formatReadableDate(datetimeStr: string): string {
  if (!datetimeStr) {
    return 'Invalid date'
  }

  const dateObj = new Date(datetimeStr)

  if (isNaN(dateObj.getTime())) {
    return 'Invalid date'
  }

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }

  const readableDate = dateObj.toLocaleDateString(undefined, dateOptions)
  const readableTime = dateObj.toLocaleTimeString(undefined, timeOptions)

  return `${readableDate} ${readableTime}`
}

type Encounter = {
  encounterId: string
  date: string
  practitioner_id: string
  practitioner_name: string
}

export function filterEncountersByDate(
  data: Encounter[],
  targetDate: Date | null
): Encounter[] {
  if (targetDate === null) {
    return data
  }
  targetDate.setHours(0, 0, 0, 0)

  return data.filter((encounter) => {
    const encounterDate = new Date(encounter.date)
    encounterDate.setHours(0, 0, 0, 0)
    return encounterDate.getTime() === targetDate.getTime()
  })
}

export function convertUTCToLocal(dateTime: string): string {
  const utcDate = new Date(dateTime)
  const year = utcDate.getUTCFullYear()
  const month = utcDate.getUTCMonth() + 1
  const day = utcDate.getUTCDate()
  const hour = utcDate.getUTCHours()
  const minute = utcDate.getUTCMinutes()
  const second = utcDate.getUTCSeconds()
  const localDate = new Date(
    Date.UTC(year, month - 1, day, hour, minute, second)
  )
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  }
  const formattedDate = localDate.toLocaleString(undefined, options)

  return formattedDate
}

export const medicationAdministrationintent = 'getMedicineAdministration'
export const medicationAdministrationPost = 'medicineAdministration'
export const medicationAdministrationId = 'getAdministerMedicineById'
export const medicationDiscontinue = 'editMedicationById'
