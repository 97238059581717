import { Outlet } from 'react-router-dom'
import './admin.scss'

const Administration = () => {
  return (
    <div className='admin-container'>
      <div className='admin-content'>
        <Outlet />
      </div>
    </div>
  )
}

export default Administration
