import { createPackages } from '../Radiology/constants'
import '../../App.scss'

type Props = {
  onCreate: () => void
}

const PackageSearch = ({ onCreate }: Props) => {
  return (
    <div className='patients-filters'>
      <button className='btn btn-primaryBtn' onClick={onCreate}>
        {createPackages}
      </button>
    </div>
  )
}

export default PackageSearch
