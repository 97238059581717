import { useRef, useEffect } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsAdminSearch,
  selectedLmsLocationSearch,
} from '../../lmsSlice'
import { getWardResults } from '../../utils'
import LinkButton from './LinkButton'
import { wardType } from '../../constants'
import { allocation } from '../../../bedManagement/modals'

const Wards = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const locationSearch = useAppSelector(selectedLmsLocationSearch)
  const filtered = getWardResults(data, searchParam, locationSearch)
  const sampleTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = sampleTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <>
      <div
        className='data-table table-fixed admin-table-table-container'
        ref={sampleTableContainerRef}
        style={{ overflowY: 'auto' }}
      >
        <table className='data-table table-fixed admin-table relative'>
          <thead className='sticky'>
            <tr>
              <th className='table-w-5'>{wardType.id}</th>
              <th>{wardType.ward}</th>
              <th>{wardType.floor}</th>
              <th>{wardType.location}</th>
              <th>{wardType.maximumBeds}</th>
            </tr>
          </thead>
          <tbody>
            {filtered?.length ? (
              filtered.map((d: any) => (
                <tr key={d.id}>
                  <td>{d.id}</td>
                  <td>
                    <LinkButton id={d.id} name={d.ward_name} onClick={onEdit} />
                  </td>
                  <td>{d.floor_number ?? allocation.hyphen}</td>
                  <td>{d?.location_id ?? allocation.hyphen}</td>
                  <td>{d?.maximum_beds}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className='text-center'>
                  {wardType.noWards}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Wards
