import KeycloakService from '../../utils/keycloakService'
import { startSxpProxy } from '../../utils/api'
import { LABS_PROJECT_ID, OPD_PROJECT_ID } from '../../utils/constants'
import { LabOrder, LabTest, Panel } from './models'
import { Package } from '../lms/models'

export const saveOrder = (
  mode: 'visit' | 'patient' | undefined,
  order: LabOrder,
  id: string,
  patientId: string,
  filteredPanels?: Panel[],
  filteredLabTests?: LabTest[],
  source?: string,
  packageId?: number,
  price?: number,
  requestedBy?: string,
  origin?: string
) => {
  const intent = order.id ? 'updateLabOrdersApi' : 'createLabOrder'
  const state = {
    labOrderId: order?.id,
    visitId: mode === 'visit' && id ? parseInt(id) : undefined,
    panels: filteredPanels,
    labTests: filteredLabTests,
    lrNumber: order?.lr_number ?? undefined,
    orderedByName: KeycloakService.getFullname(),
    orderedById: KeycloakService.getSub(),
    paymentStatus: order?.payment_status ?? 'unpaid',
    patientId: mode === 'patient' ? id : order?.patient_id ?? patientId,
    paymentCompletedOn: order?.payment_completed_on ?? undefined,
    collectionCompletedOn: order?.collection_completed_on ?? undefined,
    status: order?.status ?? 'ORDERED',
    source: order?.source ?? source ?? undefined,
    packageId: packageId,
    price: price,
    requestedBy: requestedBy,
    origin: origin,
  }
  return startSxpProxy(LABS_PROJECT_ID, intent, state)
}

export const testCount = (item: LabOrder | Package): number => {
  const panelTests = item?.panels?.flatMap((op) => op.lab_tests)?.length ?? 0
  const labTests = item?.lab_tests?.length ?? 0
  return panelTests + labTests
}

export const getTestNames = (item: LabOrder | Package): string[] => {
  const panelTests =
    item?.panels?.flatMap((op) => op.lab_tests.map((opl) => opl.name)) ?? []
  const labTests = item?.lab_tests?.map((ilt) => ilt.name) ?? []
  return [...panelTests, ...labTests]
}

export const getDoctorForVisit = (id: string): Promise<string> => {
  const intent = 'getDoctorByVisit'
  const state = { id: id }
  return startSxpProxy(OPD_PROJECT_ID, intent, state)
    .then((data) => {
      const { name, qualification } = data.data
      return `${name}${qualification ? ` [${qualification}]` : ''}`.trim()
    })
    .catch(() => '')
}
