import { useCallback, useEffect, useMemo } from 'react'
import TextField from './fields/TextField'
import ButtonField from './fields/ButtonField'
import RadioField from './fields/RadioField'
import ConfirmField from './fields/ConfirmField'
import AttachmentField from './fields/AttachmentField'
import NumberField from './fields/NumberField'
import {
  normalizeComponents,
  useSxpFormData,
  useSxpFormDispatch,
} from './formContext'
import { ElementProps, SxpComponent } from './models'
import debounce from 'lodash.debounce'
import { startSxpForm } from '../utils/api'
import TextAreaField from './fields/TextAreaField'
import DateField from './fields/DateField'
import DateAndTimeField from './fields/DateAndTimeField'

type Props = {
  reference: string
  disabled?: boolean
  imageURL?: string
}

const SxpElement = ({ reference, disabled = false, imageURL }: Props) => {
  const formData = useSxpFormData()
  const dispatch = useSxpFormDispatch()

  const onChangeCall = useCallback(
    (
      project: string,
      intent: string,
      state: Record<string, string | string[]>
    ) => {
      startSxpForm(project, intent, state, false).then((data) => {
        dispatch({ type: 'set_submitted', value: false })
        dispatch({
          type: 'store_data',
          data: normalizeComponents(data, state as Record<string, string>),
        })
      })
    },
    [dispatch]
  )

  const debouncedOnChangeCall = useMemo(
    () => debounce(onChangeCall, 800),
    [onChangeCall]
  )

  useEffect(() => {
    return () => {
      debouncedOnChangeCall.cancel()
    }
  }, [debouncedOnChangeCall])

  const handleChange = (value: string | string[], component: SxpComponent) => {
    dispatch({
      type: 'update_state',
      reference: component.reference,
      value: value,
    })
    if (component.onChange) {
      const state = {
        ...formData.state,
        [component.reference]: value,
        onChangeRef: component.reference,
      }
      if (
        component.type === 'TEXT' ||
        component.type === 'TEXT_AREA' ||
        component.type === 'DATE' ||
        component.type === 'DATE_AND_TIME'
      ) {
        debouncedOnChangeCall(formData.project, formData.intent, state)
      } else {
        onChangeCall(formData.project, formData.intent, state)
      }
    }
  }

  const component = formData.components?.[reference]
  const compType = component?.type
  const props: ElementProps = {
    component: component,
    state: formData.state,
    handleChange: handleChange,
    disabled: disabled,
    errorMessage: formData.submitted ? component?.errorMessage : '',
  }

  switch (compType) {
    case 'TEXT':
      return <TextField {...props} imageURL={imageURL} />
    case 'BUTTON':
      return <ButtonField {...props} />
    case 'RADIO':
      return <RadioField {...props} />
    case 'CONFIRM':
      return <ConfirmField {...props} />
    case 'ATTACHMENT':
      return <AttachmentField {...props} />
    case 'DATE':
      return <DateField {...props} />
    case 'DATE_AND_TIME':
      return <DateAndTimeField {...props} />
    case 'NUMBER':
      return <NumberField {...props} />
    case 'TEXT_AREA':
      return <TextAreaField {...props} />
    default:
      return null
  }
}

export default SxpElement
