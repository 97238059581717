import React from 'react'
import { ElementProps, SxpButtonOption } from '../models'
import FieldLabel from './FieldLabel'
import ErrorMessage from './ErrorMessage'

const ButtonField = ({
  component,
  state,
  handleChange,
  disabled,
  errorMessage,
}: ElementProps) => {
  const key = Date.now()
  return (
    <div className='fieldSet'>
      <FieldLabel label={component.label} required={component.required} />
      <div>
        <select
          key={key}
          onChange={(e) => handleChange(e.target.value, component)}
          value={state[component.reference]}
          name={component.reference}
          disabled={disabled}
          style={errorMessage ? { borderColor: 'red' } : {}}
        >
          <option key='' value=''>
            Select an option
          </option>
          {component.options.map((d: SxpButtonOption) => (
            <option key={d.value} value={d.value}>
              {d.label}
            </option>
          ))}
        </select>
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    </div>
  )
}

export default ButtonField
