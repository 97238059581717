import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { allocateApi } from '../modals'

export const GetWards = () => {
  const [wards, setWards] = useState<string[]>([])
  useEffect(() => {
    const intent = allocateApi.getWardDetails
    startSxpProxy(IPD_PROJECT_ID, intent, {})
      .then((data: any) => {
        const sampleData = data.data.ward_details.map(
          (e: any) => e?.location_id
        )
        const uniqueArray = sampleData.filter(
          (value: any, index: number, self: any) => {
            return self.indexOf(value) === index
          }
        )
        setWards(uniqueArray ?? [])
      })
      .catch(() => {
        setWards([])
      })
  }, [])

  return wards
}
