import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  LabMode,
  LabOrder,
  LabTest,
  OrderEvent,
  Panel,
  Row,
  LabTests,
} from './models'
import { startSxpProxy } from '../../utils/api'
import { LABS_PROJECT_ID, OPD_PROJECT_ID } from '../../utils/constants'
import PackageTests from './PackageTests'
import { toast } from 'react-toastify'
import ToastMessage from '../lms/components/ToastMessage'
import { toastOptions } from '../lms/models'
import { getDoctorForVisit, saveOrder } from './utils'
import KeycloakService from '../../utils/keycloakService'
import { LAB_ORDERS_WRITE } from '../../utils/roles'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  selectLocationById,
  selectSelectedLocationId,
} from '../location/locationSlice'
import { prepareOrderEventTests } from '../lms/utils'
import { readableDateTimeFormat } from '../../utils/dateUtils'
import Select from 'react-select'
import { Doctor } from '../scheduler/schedulerSlice'
import { useSamples } from '../lms/components/useSamples'
import { useOrganizations } from '../lms/components/useOrganizations'
import { fetchSlotsDoctorsAsync, selectSlotsDoctors } from '../slots/slotsSlice'
import { setQueryParam } from '../membership/membershipSlice'
import { Button, IconButton } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { setLmsActiveTab, setLmsDateFilter } from '../lms/lmsSlice'

const OpdOrder = () => {
  const { id }: any = useParams()
  const [newrowadded, setNewRowAdded] = useState(false)
  const [patientId, setPatientId] = useState('')
  const [order, setOrder] = useState<LabOrder>({})
  const [orders, setOrders] = useState<LabOrder[]>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [mode, setMode] = useState<LabMode>('')
  const [origin, setOrigin] = useState('OPD')
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const [selectedTests, setSelectedTests] = useState<any[]>([])
  const [requestedBy, setRequestedBy] = useState('')
  const [rows, setRows] = useState<Row[]>([])
  const [ordersEvent, setOrdersEvent] = useState<OrderEvent[]>([])
  const [isRowAdded, setIsRowAdded] = useState(false)
  const [testAdded, setTestAdded] = useState(false)
  const navigate = useNavigate()
  const [testNameOptions, setTestNameOptions] = useState<
    { label: string; value: string; panels: any }[]
  >([])
  const [visitStatus, SetVisitStatus] = useState('')
  const [newRow, setNewRow] = useState<Row>({
    mode: null,
    requestedBy: null,
    sampleName: null,
    testName: null,
    panel: null,
    origin: null,
  })

  const dispatch = useAppDispatch()

  const handleModeChange = (selectedOption: any) => {
    const selectedValue = selectedOption?.value || ''
    setMode(selectedValue)
    setNewRow((prev) => ({ ...prev, mode: selectedOption }))
  }

  const handlePackageSave = (
    packageId: number,
    price: number,
    panels: Panel[],
    labTests: LabTest[]
  ) => {
    if (!requestedBy) {
      alert('Requested By is mandatory for external tests...')
      return
    }
    saveOrder(
      'visit',
      order,
      id ?? '',
      id ?? '',
      panels,
      labTests,
      location?.resource.name ?? '',
      packageId,
      price,
      requestedBy,
      origin
    ).then(() => {
      const stateObj: Partial<OrderEvent> = {
        updated_by: KeycloakService.getUsername(),
        order_id: order.id,
        type: 'ORDER_MODIFIED',
        tests: prepareOrderEventTests(panels, labTests, 'ORDER_MODIFIED'),
      }
      startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
        event: stateObj,
      }).then(() => {
        toast(<ToastMessage message={'Lab Tests Saved'} />, {
          ...toastOptions,
          type: 'success',
        })
      })
    })
  }

  const handleSampleNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      sampleName: selectedOption,
      testName: null,
    }))
    if (!selectedOption?.value) return
    startSxpProxy(LABS_PROJECT_ID, 'getAllTestsBySampleIdApi', {
      sampleId: parseInt(selectedOption?.value),
    })
      .then((response) => {
        const testNames =
          response?.data?.sample[0]?.panels?.map((test: any) => ({
            label: test?.name,
            value: String(test?.id),
            panel: {
              ...test,
              sampleId: parseInt(selectedOption?.value),
              sampleName: selectedOption?.label,
            },
          })) || []
        const selectedTestLabels = rows.map((row) => row.testName?.label)
        const filteredTestNames = testNames.filter(
          (test: any) => !selectedTestLabels.includes(test.label)
        )
        setTestNameOptions(filteredTestNames)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
      })
  }

  const executeFunction = () => {
    const state = {}
    startSxpProxy(LABS_PROJECT_ID, 'getAllPanelsApi', state)
      .then((data) => {
        const panelData: any = data?.data?.panel ?? []
        const tests = panelData?.map((test: any) => ({
          label: test?.name,
          value: String(test?.id),
          panel: {
            name: test?.name,
            description: test?.description,
            id: test?.id,
            lab_tests: test?.lab_tests,
            sampleId: parseInt(test?.sample?.value),
            sampleName: test?.sample?.name,
          },
        }))
        const selectedTestLabels = rows.map((row) => row.testName?.label)
        const filteredTests = tests.filter(
          (test: any) => !selectedTestLabels.includes(test.label)
        )
        setTestNameOptions(filteredTests)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
      })
  }

  const handleTestNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      testName: { label: selectedOption?.label, value: selectedOption?.value },
      sampleName: {
        label: selectedOption?.panel?.sampleName,
        value: selectedOption?.panel?.sampleId,
      },
      panel: selectedOption?.panel,
    }))
  }

  const handleSave = async () => {
    if (!KeycloakService.hasRole([LAB_ORDERS_WRITE])) {
      return
    }
    if (rows[0]?.sampleName && rows[0]?.testName && rows[0]?.mode) {
      const filteredPanels: any = rows?.map((row: Row) => {
        return row?.panel
      })
      const filteredLabTests: any = []
      const mode: 'visit' | 'patient' = locations.pathname.startsWith('/visits')
        ? 'visit'
        : 'patient'
      const doctorName = await getDoctorForVisit(id ?? '')
      const locationName = location?.resource.name ?? ''
      const requestedBy = locations.pathname.startsWith('/visits')
        ? doctorName
        : rows[rows?.length - 1].requestedBy?.value
      saveOrder(
        mode,
        order,
        id,
        patientId || id,
        filteredPanels,
        filteredLabTests,
        locationName,
        0,
        0,
        requestedBy,
        origin
      )
        .then((data) => {
          setOrder(data?.data?.insert_lab_orders_one)
          toast(
            <ToastMessage
              message={
                order?.id
                  ? 'Lab Order Saved Successfully'
                  : 'Lab Order Created Successfully'
              }
            />,
            {
              ...toastOptions,
              type: 'success',
            }
          )
          setTimeout(() => {
            dispatch(setLmsDateFilter(new Date()?.toISOString()?.slice(0, 10)))
            dispatch(setLmsActiveTab('collection'))
            navigate('/lms/dashboard')
          }, 1500)
          getAllLabOrders()
          setNewRowAdded(false)
          let orderLab: LabOrder
          const stateObj: Partial<OrderEvent> = {
            updated_by: KeycloakService.getUsername(),
          }
          if (order.id) {
            orderLab = data.data?.update_lab_orders?.returning?.[0]
            stateObj.order_id = order.id
            stateObj.type = 'ORDER_MODIFIED'
            stateObj.tests = prepareOrderEventTests(
              filteredPanels,
              filteredLabTests,
              'ORDER_MODIFIED'
            )
          } else {
            orderLab = data.data?.insert_lab_orders_one
            stateObj.order_id = orderLab.id
            stateObj.type = 'ORDERED'
            stateObj.tests = prepareOrderEventTests(
              filteredPanels,
              filteredLabTests,
              'ORDERED'
            )
          }
          startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
            event: stateObj,
          }).then(() => {
            setOrder(order ?? {})
          })
        })
        .catch((err) => {
          console.error('Error saving order:', err)
        })
    } else {
      toast(
        <ToastMessage message={'Please add atleast one test to submit'} />,
        {
          ...toastOptions,
          type: 'error',
        }
      )
    }
  }

  const handleSaveRow = () => {
    if (!newRow.sampleName) {
      alert('Please select a Sample Name before adding.')
    } else if (!newRow.testName) {
      alert('Please select a Test Name before adding.')
    } else if (
      locations.pathname.startsWith('/lms/search/createorder') &&
      !newRow.requestedBy
    ) {
      alert('Please select Requested By before adding.')
    } else if (!newRow.mode) {
      alert('Please select a Mode before adding.')
    } else {
      setRows((prevRows) => {
        return [...prevRows, newRow]
      })

      const nextRow = {
        mode: newRow.mode,
        requestedBy: newRow.requestedBy,
        origin: newRow.origin,
        sampleName: null,
        testName: null,
        panel: null,
      }
      setNewRow(nextRow)
      setNewRowAdded(true)
      if (newRow.testName) {
        setSelectedTests([...selectedTests, newRow.testName])
      }
      setTestAdded((prev) => !prev)
    }
  }

  const handleRequestedByChange = (selectedOption: any) => {
    const selectedValue = selectedOption?.value || ''
    setRequestedBy(selectedValue)
    setNewRow((prev) => ({ ...prev, requestedBy: selectedOption }))
  }

  const handleOriginChange = (selectedOption: any) => {
    const selectedValue = selectedOption?.value || ''
    setOrigin(selectedValue)
    setNewRow((prev) => ({ ...prev, origin: selectedOption }))
  }
  const samples = useSamples()
  const sampleOptions = samples?.map((sample) => ({
    label: sample.name,
    value: sample.id.toString(),
  }))
  const organizations = useOrganizations()
  const doctors = useAppSelector(selectSlotsDoctors)

  const handleDeleteRow = (index: number) => {
    setRows((prevRows: Row[]) => {
      const updatedRows = [...prevRows]
      updatedRows.splice(index, 1)
      return updatedRows
    })
    setNewRowAdded(true)
  }

  useEffect(() => {
    dispatch(fetchSlotsDoctorsAsync())
  }, [dispatch])

  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  const fetchData = async () => {
    try {
      const labOrderData = await startSxpProxy(
        LABS_PROJECT_ID,
        'getLabOrdersByPatientIdForOpd',
        { patientId: id }
      )
      const orders: LabOrder[] = labOrderData?.data?.lab_orders ?? []
      setOrders(orders)
      handleActiveOrderChage(orders)
    } catch (err) {
      console.error(err)
    }
  }

  const getAllLabOrders = () => {
    const intent = 'getLabOrdersApi'
    startSxpProxy(LABS_PROJECT_ID, intent, { visitId: id })
      ?.then((data: any) => {
        const orders: LabOrder[] = data.data?.lab_orders ?? []
        setOrders(orders)
        handleActiveOrderChage(orders)
        const state = { encounterId: id }
        startSxpProxy(OPD_PROJECT_ID, 'getVisitApi', state)
          ?.then((data) => {
            setPatientId(data?.data?.subject?.reference?.split('/')?.[1])
            SetVisitStatus(data?.data?.statusHistory[0]?.status)
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (window.location.pathname === `/lms/search/createorder/${id}`) {
      fetchData()
    } else {
      getAllLabOrders()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  const handleActiveOrderChage = (orders: LabOrder[]) => {
    let activeOrder: any = {}
    if (window.location.pathname.startsWith('/lms/search/createorder')) {
      activeOrder = orders?.[orders?.length - 1]?.order_events?.every((event) =>
        event.tests?.every(
          (test) =>
            test.status === 'ORDER_MODIFIED' || test.status === 'ORDERED'
        )
      )
        ? orders?.[orders?.length - 1] ?? {}
        : orders?.[-1] ?? {}
    } else {
      activeOrder = orders[activeIndex] ?? {}
    }
    setOrder(activeOrder)
    const formattedRows: any = activeOrder?.panels?.map((panel: any) => {
      return {
        mode: {
          label: activeOrder?.package_id ? 'Package' : 'Test',
          value: activeOrder?.package_id ? 'package' : 'test',
        },
        requestedBy: {
          label: activeOrder?.requested_by,
          value: activeOrder?.requested_by,
        },
        origin: {
          label: activeOrder?.origin,
          value: activeOrder?.origin,
        },
        sampleName:
          { label: panel?.sampleName, value: panel?.sampleId } || null,
        testName: { label: panel?.name, value: panel?.id } || null,
        panel: panel || null,
      }
    })
    setRows(formattedRows || [])
    setOrdersEvent(activeOrder?.order_events ?? [])
    setOrder(activeOrder)
    if (
      activeOrder?.id &&
      activeOrder?.panels &&
      activeOrder?.panels.length >= 1
    ) {
      setNewRow({
        mode: {
          label: activeOrder?.package_id ? 'Package' : 'Test',
          value: activeOrder?.package_id ? 'package' : 'test',
        },
        requestedBy: null,
        sampleName: null,
        testName: null,
        panel: null,
        origin: null,
      })
    } else {
      setNewRow({
        mode: null,
        requestedBy: null,
        sampleName: null,
        testName: null,
        panel: null,
        origin: null,
      })
    }
  }

  const renderParameters = (parameters: LabTests[]) => {
    return (
      <div>
        {parameters?.map((item: LabTests) => {
          return <li key={item?.id}>{item?.name}</li>
        })}
      </div>
    )
  }

  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 90}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  useEffect(() => {
    executeFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testAdded])

  useEffect(() => {
    handleActiveOrderChage(orders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  const locations = useLocation()

  useEffect(() => {
    if (locations.pathname.startsWith('/lms/search/createorder')) {
      setNewRow((prevState) => ({
        ...prevState,
        origin: { value: 'OPD', label: 'OPD' },
      }))
    }
  }, [locations.pathname, setNewRow])
  const handleIndexChange = (index: any) => {
    setActiveIndex(index)
    setNewRowAdded(false)
  }
  const isPointerEventsDisabled = ordersEvent?.some((event) =>
    event.tests?.some(
      (test) => test.status !== 'ORDER_MODIFIED' && test.status !== 'ORDERED'
    )
  )

  return (
    <div className='orders-container'>
      {!window.location.pathname.startsWith('/lms/search/createorder') && (
        <div className='order-buttons'>
          {orders?.map((ord, idx) => (
            <button
              className={`order-btn${
                activeIndex === idx ? ' active-order-btn' : ''
              }`}
              onClick={() => handleIndexChange(idx)}
              key={ord?.id}
              title={`Order ${ord?.id} - ${readableDateTimeFormat(
                new Date(ord?.ordered_on ?? '')
              )}`}
            >
              Order {ord?.id}
            </button>
          ))}
          <button
            className={`order-btn${
              activeIndex === -1 ? ' active-order-btn' : ''
            }`}
            onClick={() => handleIndexChange(-1)}
            disabled={ordersEvent?.every((event) =>
              event.tests?.every(
                (test) =>
                  test.status === 'ORDER_MODIFIED' || test.status === 'ORDERED'
              )
            )}
          >
            New Order
          </button>
        </div>
      )}
      <div className='createlaborder-table'>
        <div className='laborder-sub-btn'>
          <Button
            onClick={handleSave}
            disabled={
              !newrowadded ||
              visitStatus === 'finished' ||
              ordersEvent?.some((event) =>
                event.tests?.some(
                  (test) =>
                    test.status !== 'ORDER_MODIFIED' &&
                    test.status !== 'ORDERED'
                )
              )
            }
            size='small'
            variant='contained'
          >
            Submit
          </Button>
        </div>
        <div
          className={
            isPointerEventsDisabled
              ? 'pointer-events-none'
              : 'pointer-events-auto'
          }
        >
          <table className='data-table admin-table'>
            <thead style={{ position: 'sticky', top: '0px' }}>
              <tr>
                <th className='th-mode'>Mode</th>
                {locations.pathname.startsWith('/lms/search/createorder') && (
                  <>
                    <th className='th-requested'>Requested By</th>
                    <th className='th-requested'>Origin</th>
                  </>
                )}
                <th className='th-sample'>Sample Name</th>
                <th className='th-test'>Test Name</th>
                <th className='th-parameters'>Parameters</th>
                <th className='th-action'>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Select
                    value={newRow?.mode || ''}
                    onChange={handleModeChange}
                    options={[
                      { value: '', label: 'Select Mode' },
                      { value: 'test', label: 'Test' },
                      { value: 'package', label: 'Package' },
                    ]}
                    isDisabled={isRowAdded}
                  />
                </td>
                {locations.pathname.startsWith('/lms/search/createorder') && (
                  <>
                    <td>
                      <Select
                        placeholder='Select Requested By'
                        className='requested-by'
                        isClearable
                        value={newRow?.requestedBy || ''}
                        onChange={handleRequestedByChange}
                        options={[
                          { value: 'Self', label: 'Self' },
                          ...organizations.map((org) => ({
                            value: org.name,
                            label: org.name,
                          })),
                          ...(doctors?.map((d: Doctor) => ({
                            value: `Dr. ${d.name}${
                              d.specialty ? ` [${d.specialty}]` : ''
                            }`.trim(),
                            label: `Dr. ${d.name}${
                              d.specialty ? ` [${d.specialty}]` : ''
                            }`.trim(),
                          })) || []),
                        ]}
                        isDisabled={isRowAdded}
                      />
                    </td>
                    <td>
                      <Select
                        className='origin-select'
                        isClearable
                        value={newRow?.origin}
                        onChange={handleOriginChange}
                        options={[
                          { value: 'OPD', label: 'OPD' },
                          { value: 'IPD', label: 'IPD' },
                        ]}
                      />
                    </td>
                  </>
                )}
                <td>
                  {newRow?.mode?.value === 'package' && (
                    <PackageTests
                      id={order.package_id ?? 0}
                      status={order.status ?? ''}
                      onSave={handlePackageSave}
                    />
                  )}
                  {newRow?.mode?.value === 'test' && (
                    <Select
                      options={sampleOptions?.map((option) => ({
                        ...option,
                        isDisabled: rows?.some(
                          (r) =>
                            r?.sampleName &&
                            r?.sampleName.value === option?.value
                        ),
                      }))}
                      value={newRow.sampleName}
                      onChange={handleSampleNameChange}
                      placeholder='Select Sample Name'
                    />
                  )}
                </td>
                <td>
                  {newRow?.mode?.value === 'test' && (
                    <Select
                      options={testNameOptions?.map((option) => ({
                        ...option,
                        isDisabled: rows?.some(
                          (row) =>
                            row?.testName &&
                            row?.testName?.value === option?.value
                        ),
                      }))}
                      value={newRow?.testName}
                      onChange={handleTestNameChange}
                      placeholder='Select Test Name'
                    />
                  )}
                </td>
                <td>{renderParameters(newRow?.panel?.lab_tests || [])}</td>

                <td>
                  <button onClick={handleSaveRow}>Add</button>
                </td>
              </tr>
              {rows?.map((row, index) => (
                <tr key={index}>
                  <td>{row?.mode?.label}</td>
                  {locations.pathname.startsWith('/lms/search/createorder') && (
                    <td>{row?.requestedBy?.label}</td>
                  )}
                  {locations.pathname.startsWith('/lms/search/createorder') && (
                    <td>{row?.origin?.label}</td>
                  )}
                  <td>{row?.sampleName?.label}</td>
                  <td>{row?.testName?.label}</td>
                  <td>{renderParameters(row?.panel?.lab_tests || [])}</td>
                  <td>
                    <IconButton
                      size='small'
                      onClick={() => handleDeleteRow(index)}
                    >
                      <DeleteOutlinedIcon fontSize='small' />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default OpdOrder
