import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LabTest, Panel, RadiologyOrder, RadiologyTest, Sample } from './models'
import { startSxpProxy } from '../../utils/api'
import { LABS_PROJECT_ID } from '../../utils/constants'
import LabSamples from './components/LabSamples'
import RadiologyTests from './components/RadiologyTests'
import SelectedRadiologyTests from './components/SelectedRadiologyTests'
import { toast } from 'react-toastify'
import KeycloakService from '../../utils/keycloakService'
import { LAB_ORDERS_WRITE } from '../../utils/roles'

const Radiology = () => {
  const { id } = useParams()
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  const [samples, setSamples] = useState<Sample[]>([])
  const [tests, setTests] = useState<RadiologyTest[]>([])
  const [saved, setSaved] = useState<RadiologyOrder>({})
  const [selected, setSelected] = useState<RadiologyTest[]>([])
  const [unselected, setUnselected] = useState<RadiologyTest[]>([])

  useEffect(() => {
    const intent = 'getRadiologySamplesAPI'
    startSxpProxy(LABS_PROJECT_ID, intent, {})
      .then((data: any) => {
        const sampleData = data.data?.radiology_sample ?? []
        setSamples(sampleData)
        setActiveIndex(0)
      })
      .catch(() => {
        setActiveIndex(-1)
      })
  }, [])

  useEffect(() => {
    const intent = 'getRadiologyOrders'
    startSxpProxy(LABS_PROJECT_ID, intent, { visitId: id })
      .then((data: any) => {
        const orders = data.data.radilogy_lab_orders?.[0] ?? {}
        setSaved(orders)
      })
      .catch((err) => {
        console.log(err)
        setSaved({})
      })
  }, [id])

  useEffect(() => {
    const sample = samples[activeIndex]
    if (sample) {
      const intent = 'getRadiologyTestsAPI'
      startSxpProxy(LABS_PROJECT_ID, intent, { sampleId: sample.id })
        .then((data: any) => {
          const testData =
            data.data?.radiology_sample_by_pk?.radiology_lab_tests ?? []
          setTests(testData)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [activeIndex, samples])

  const handleSampleSelect = (id: number) => {
    setActiveIndex(id)
  }

  const handleTestSelect = (test: RadiologyTest) => {
    if (unselected.findIndex((us) => us.id === test.id) >= 0) {
      return setUnselected((us) => us.filter((s) => s.id !== test.id))
    }
    const removedLabTest = saved?.lab_tests?.find((sl) => sl.id === test.id)
    if (removedLabTest) {
      return setUnselected((us) => [...us, removedLabTest])
    }
    const foundIndex = selected.findIndex((s) => s.id === test.id)
    if (foundIndex >= 0) {
      const first = selected.slice(0, foundIndex)
      const second = selected.slice(foundIndex + 1)
      return setSelected([...first, ...second])
    }
    setSelected([...selected, test])
  }

  const handleUpdate = (id: number) => {
    if (unselected.findIndex((us) => us.id === id) >= 0) {
      setUnselected((us) => us.filter((s) => s.id !== id))
      return
    }
    const removedLabTest = saved?.lab_tests?.find((sl) => sl.id === id)
    if (removedLabTest) {
      setUnselected((us) => [...us, removedLabTest])
      return
    }
    setSelected((sel) => sel.filter((s) => s.id !== id))
  }

  const handleSave = () => {
    if (!KeycloakService.hasRole([LAB_ORDERS_WRITE])) {
      return
    }
    const savedTests =
      saved?.lab_tests?.filter(
        (sl) => unselected.findIndex((us) => us.id === sl.id) === -1
      ) ?? []
    const intent = saved.id ? 'updateRadiologyOrder' : 'createRadiologyOrder'
    const state = {
      visitId: parseInt(id as string),
      labTests: [...savedTests, ...selected],
    }
    const toastId = toast.loading(
      saved.id ? 'Updating lab tests' : 'Saving lab tests'
    )
    startSxpProxy(LABS_PROJECT_ID, intent, state)
      .then((data: any) => {
        let orders
        if (saved.id) {
          orders = data.data?.update_radilogy_lab_orders?.returning?.[0]
        } else {
          orders = data.data?.insert_radilogy_lab_orders_one
        }
        setSaved(orders ?? {})
        setSelected([])
        setUnselected([])
        toast.update(toastId, {
          render: saved.id ? 'Updated lab tests' : 'Saved lab tests',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        })
      })
      .catch((err) => {
        toast.update(toastId, {
          render: 'Failed to save tests',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        })
        console.log(err)
      })
  }

  const savedTests =
    saved?.lab_tests?.filter(
      (sl) => unselected.findIndex((us) => us.id === sl.id) === -1
    ) ?? []
  const merged = [...savedTests, ...selected]

  const selectedObj: Record<number, Panel | LabTest> = merged.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: cur }),
    {}
  )

  const transformed: RadiologyTest[] = tests.map((t) => {
    const found = selectedObj[t.id]
    if (found) {
      return { ...t, active: true }
    }
    return t
  })

  return (
    <div className='lab-container'>
      <div className='lab-sidebar'>
        <LabSamples
          samples={samples}
          active={activeIndex}
          onSampleSelect={handleSampleSelect}
        />
        <SelectedRadiologyTests
          selected={selected}
          saved={saved}
          unselected={unselected}
          onUpdate={handleUpdate}
          onSave={handleSave}
        />
      </div>
      <div className='lab-content'>
        <RadiologyTests tests={transformed} onTestSelect={handleTestSelect} />
      </div>
    </div>
  )
}

export default Radiology
