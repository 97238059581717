import { useEffect, useState } from 'react'
import { Sample } from '../../labTests/models'
import { startSxpProxy } from '../../../utils/api'
import { LABS_PROJECT_ID } from '../../../utils/constants'

export const useSamples = () => {
  const [samples, setSamples] = useState<Sample[]>([])

  useEffect(() => {
    const intent = 'getAllSamplesApi'
    startSxpProxy(LABS_PROJECT_ID, intent, {})
      .then((data: any) => {
        const sampleData = data.data.sample
        setSamples(sampleData ?? [])
      })
      .catch(() => {
        setSamples([])
      })
  }, [])

  return samples
}
