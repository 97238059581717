export const modeType = {
  admin: 'admin',
  signature: 'signature',
}
export const forms = {
  filter: 'Filter:',
  active: 'Active',
  inactive: 'Inactive',
  practitioner: 'Practitioner',
  practitionerRole: 'PractitionerRole',
  loading: '...Loading',
  salutation: ' Salutation',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  gender: 'Gender',
  dob: 'DOB',
  speciality: ' Speciality',
  department: 'Department',
  service: 'Service',
  qualification: 'Qualification',
  registrationNumber: ' Registration Number',
  phoneNumber: 'Phone Number',
  submit: 'Submit',
}

export const formValues = {
  active: 'active',
  inActive: 'inactive',
  all: 'all',
  email: 'email',
  na: 'NA',
  phone: 'phone',
  mr: 'Mr',
  mrs: 'Mrs',
  miss: 'Miss',
  dr: 'DR.',
  master: 'Master',
  male: 'male',
  female: 'female',
  other: 'other',
  healthService: 'healthService',
  OperatingUnit: 'Operating Unit',
}
export const placeHolder = {
  doctorName: 'Search by Doctor Name',
  doctors: 'Enter Doctors Name',
  searchName: 'Search by Name',
  email: 'Enter Email',
  phone: 'Enter Phone Number',
  pathologyName: 'Search by Pathologist Name',
  pathologists: 'Enter Pathologists Name',
  firstName: 'Enter First Name',
  lastName: 'Enter Last Name',
}

export const tableText = {
  name: 'Name',
  speciality: 'Speciality',
  superSpecialty: 'Super Specialty',
  qualification: 'Qualification',
  regNo: 'Reg No',
  slNo: 'Sl No',
  email: 'Email-ID',
  phone: 'Mobile No.',
  status: 'Status',
  gender: 'Gender',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  action: 'Action',
  operatingUnit: 'Operating Unit',
  noDoctorsFound: 'No Doctors Found',
  noPathologistFound: 'No Pathologists Found',
}

export const intent = {
  fetchProvByDoctors: 'fetchProvByDoctors',
  getDoctorById: 'getDoctorById',
  getDoctors: 'getDoctors',
  doctorActive: 'doctorActive',
  createDoc: 'createDoc',
  editDoctor: 'editDoctor',
  getSpecilities: 'getSpecilities',
  getHealthCareService: 'getHealthCareService',
  getLabInchargeList: 'getLabInchargeList',
  getLabDepartments: 'getLabDepartments',
  editLabIncharge: 'editLabIncharge',
  createLabIncharge: 'createLabIncharge',
  labInchargeActive: 'labInchargeActive',
  editLabDepartments: 'editLabDepartments',
  createLabDepartments: 'createLabDepartments',
  getSpecilitiesDoctor: 'getSpecialtiesAW',
  getDoctorsAshaWorkers: 'getDoctorsAndAshaWorkers',
}
export const messages = {
  errorDoctorMessage: 'Failed to Update doctor status...',
  successDoctorMessage: 'Doctor edited successfully!',
  createdDoctorSuccess: 'Doctor created successfully!',
  errorPathologistMessage: 'Failed to Update pathologist status...',
  successPathologistMessage: 'Pathologist edited successfully!',
  createdPathologistSuccess: 'Pathologist created successfully!',
  validPhoneMessage: 'Please enter valid phone number',
  ValidEmailMessage: 'Please enter a valid email address.',
  validNameMessage: 'Please enter your name in characters',
  backToPathology: 'Back to Pathology',
  notValidPathologist: 'Not a Valid Pathologist',
}
export const headers = {
  backToLab: 'Back to LabDepartments',
  submit: ' Submit',
  notValidLabId: 'Lab Department Id is not valid',
}
