import React from 'react'
import { Sample } from '../../../labTests/models'
import {
  activeText,
  emptyString,
  noSamplesFound,
  samplesHeading,
} from '../../constants'

type Props = {
  samples: Sample[]
  active: number
  onSampleSelect: (id: number) => void
}

const RadiologyCategories = ({ samples, active, onSampleSelect }: Props) => {
  return (
    <div className='samples'>
      <span className='heading'>{samplesHeading}</span>
      {samples.length > 0 ? (
        <ul>
          {samples.map((sample, index) => (
            <li
              className={active === index ? activeText : emptyString}
              key={sample.id}
              onClick={() => onSampleSelect(index)}
              title={sample.description}
            >
              {sample.name}
            </li>
          ))}
        </ul>
      ) : (
        <span>{noSamplesFound}</span>
      )}
    </div>
  )
}

export default RadiologyCategories
