import React, { useState, useEffect, useCallback } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
} from '@mui/material'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'

interface RoleOption {
  id: string
  name: string
}

interface KeycloakRolesDialogProps {
  isOpen: boolean
  onClose: () => void
  roles: RoleOption[]
  userId: string
}

const KeycloakRolesDialog: React.FC<KeycloakRolesDialogProps> = ({
  isOpen,
  onClose,
  roles,
  userId,
}) => {
  const [availableKeycloakRoles, setAvailableKeycloakRoles] = useState<
    RoleOption[]
  >([])
  const [selectedRoles, setSelectedRoles] = useState<RoleOption[]>([])
  const keyclockId = userId
  const handleFetchAvailableRoles = useCallback(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'keyclockAvaiableRoles', { userId })
      .then((response) => {
        if (response?.data?.data) {
          console.log('Available roles:', response.data.data)
          setAvailableKeycloakRoles(response.data.data)
        }
      })
      .catch((err) => console.error('Error fetching available roles:', err))
  }, [userId])

  useEffect(() => {
    if (isOpen) {
      handleFetchAvailableRoles()
    }
  }, [isOpen, handleFetchAvailableRoles])

  const handleAddRoles = () => {
    const SelectedKeyclockRoles = selectedRoles.map((role) => ({
      id: role.id,
      name: role.name,
    }))
    startSxpProxy(ADMIN_PROJECT_ID, 'addKeyclockRoles', {
      keyclockId,
      SelectedKeyclockRoles,
    })
      .then((response) => {
        onClose()
      })
      .catch((err) => console.error('Error adding roles:', err))
  }

  const handleCancel = () => {
    setSelectedRoles([])
    onClose()
  }

  const handleCheckboxChange = (roleId: string) => {
    const role = availableKeycloakRoles.find((role) => role.id === roleId)
    if (!role) return
    const updatedSelectedRoles = selectedRoles.some(
      (selectedRole) => selectedRole.id === roleId
    )
      ? selectedRoles.filter((selectedRole) => selectedRole.id !== roleId)
      : [...selectedRoles, { id: roleId, name: role.name }]
    setSelectedRoles(updatedSelectedRoles)
  }

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>Keycloak Roles</DialogTitle>
      <DialogContent>
        <ul>
          {roles.map((role) => (
            <li key={role.id}>{role.name}</li>
          ))}
        </ul>
        {/* needed */}
        {/* <Button onClick={handleFetchAvailableRoles} color='primary'>
          Fetch Available Roles
        </Button>
        {availableKeycloakRoles.length > 0 && (
          <div>
            <h3>Available Roles</h3>
            <ul>
              {availableKeycloakRoles.map((role) => (
                <li key={role.id}>
                  <Checkbox
                    checked={selectedRoles.some(
                      (selectedRole) => selectedRole.id === role.id
                    )}
                    onChange={() => handleCheckboxChange(role.id)}
                  />
                  {role.name}
                </li>
              ))}
            </ul>
          </div>
        )} */}
        {/* <Button onClick={handleAddRoles} color='primary'>
          Add Roles
        </Button>
        <Button onClick={handleCancel} color='primary'>
          Cancel
        </Button> */}
      </DialogContent>
    </Dialog>
  )
}

export default KeycloakRolesDialog
