import { useEffect, useState } from 'react'
import { Sample } from '../../../labTests/models'
import { startSxpProxy } from '../../../../utils/api'
import { RADIOLOGY_PROJECT_ID } from '../../../../utils/constants'
import { intent } from '../../constants'

export const useCategories = () => {
  const [samples, setSamples] = useState<Sample[]>([])

  useEffect(() => {
    intent.getRadiologySamplesAPI
    startSxpProxy(RADIOLOGY_PROJECT_ID, intent.getRadiologySamplesAPI, {})
      .then((data: any) => {
        const sampleData = data.data.radiology_sample
        setSamples(sampleData ?? [])
      })
      .catch(() => {
        setSamples([])
      })
  }, [])

  return samples
}
