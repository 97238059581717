import React, { KeyboardEvent, useEffect, useRef, useState } from 'react'
import {
  decodeNewLineText,
  getApplicableRange,
  isAbnormal,
  isOutsideNormalRange,
} from '../utils'
import { LabResult, LmsObservation, LmsValues } from '../models'
import { Patient } from 'fhir/r4'
import { fullDateTimeFormat } from '../../../utils/dateUtils'
import ObservationList from './observations/ObservationList'

type Props = {
  activeId: number
  test: LabResult
  patient: Patient | null
  onValueChange: (val: string, index: number) => void
  onNotesChange: (val: string, index: number) => void
  onExtraValueChange: (val: string, index: number) => void
  onValuesChange: (values: LmsValues, index: number) => void
  onEnter: (id: number) => void
  disabled: boolean
  isReferred: boolean
  isEdit: boolean
}

const TestRow = ({
  activeId,
  test,
  patient,
  onValueChange,
  onNotesChange,
  onExtraValueChange,
  onValuesChange,
  onEnter,
  disabled,
  isReferred,
  isEdit,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [showNotes, setShowNotes] = useState(false)
  const applicableRange = getApplicableRange(
    test.lab_test.test_result_limits ?? [],
    patient
  )

  useEffect(() => {
    if (test.id === activeId) {
      inputRef.current?.focus()
    }
  }, [activeId, test.id])

  const handleKeyUp = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      onEnter(test.id)
    }
  }

  const handleSummaryChange = (str: string) => {
    const values = test.values
    if (values) {
      onValuesChange({ ...values, summary: str }, test.id)
    } else {
      onValuesChange({ summary: str, observations: [] }, test.id)
    }
  }

  const handleObservationChange = (obs: LmsObservation[]) => {
    const values = test.values
    if (values) {
      onValuesChange({ ...values, observations: obs }, test.id)
    } else {
      onValuesChange({ summary: '', observations: obs }, test.id)
    }
  }

  const isDisabled = isEdit
    ? false
    : disabled ||
      test.test_status === 'REJECTED' ||
      (!!test.referred_out && !isReferred)

  return (
    <div
      className={`test-row ${
        test.test_status === 'REJECTED' ? 'rejected-test' : ''
      }`}
    >
      <div className='test-details'>
        <span className='test-name'>{test.lab_test.name}</span>
        <div className='limit-details'>
          <span>
            {applicableRange?.min_value || applicableRange?.max_value
              ? `${applicableRange.min_value ?? 0} to ${
                  applicableRange.max_value
                }`
              : 'No Valid Range'}{' '}
          </span>
          <span>{applicableRange?.test_unit_type?.unit_type ?? 'N/A'}</span>
        </div>
        {test.test_status === 'REJECTED' && (
          <span className='rejected-info'>
            Test Rejected by {test.rejected_by_name} due to reason{' '}
            {test.rejection_reason}{' '}
            <span className='rejected-date'>
              {fullDateTimeFormat(new Date(test.rejected_on ?? ''))}
            </span>
          </span>
        )}
      </div>
      {test.lab_test.test_result_type.result_type === 'Observation' ? (
        <ObservationList
          disabled={isDisabled}
          values={test.values}
          onSummaryChange={handleSummaryChange}
          onObservationChange={handleObservationChange}
        />
      ) : (
        <>
          <div className='value-input'>
            {test.lab_test.test_result_type.result_type === 'Numeric' && (
              <>
                <input
                  ref={inputRef}
                  title={
                    isOutsideNormalRange(test.value, applicableRange)
                      ? 'Outside Normal Range'
                      : ''
                  }
                  type='number'
                  disabled={isDisabled}
                  value={test.value ?? ''}
                  onChange={({ target }) =>
                    onValueChange(target.value, test.id)
                  }
                  onKeyUp={handleKeyUp}
                  className={
                    isOutsideNormalRange(test.value, applicableRange)
                      ? 'abnormal'
                      : ''
                  }
                />
                <span>
                  {applicableRange?.test_unit_type?.unit_type ?? 'N/A'}
                </span>
              </>
            )}
            {test.lab_test.test_result_type.result_type ===
              'Numeric Outcome' && (
              <>
                <input
                  ref={inputRef}
                  title={
                    isOutsideNormalRange(test.value, applicableRange)
                      ? 'Outside Normal Range'
                      : ''
                  }
                  type='number'
                  disabled={isDisabled}
                  value={test.value ?? ''}
                  onChange={({ target }) =>
                    onValueChange(target.value, test.id)
                  }
                  onKeyUp={handleKeyUp}
                  className={
                    isOutsideNormalRange(test.value, applicableRange)
                      ? 'abnormal'
                      : ''
                  }
                />
                <span>
                  {applicableRange?.test_unit_type?.unit_type ?? 'N/A'}
                </span>
              </>
            )}
            {test.lab_test.test_result_type.result_type === 'Short Text' && (
              <>
                <input
                  ref={inputRef}
                  type='text'
                  disabled={isDisabled}
                  value={test.value ?? ''}
                  onChange={({ target }) =>
                    onValueChange(target.value, test.id)
                  }
                  onKeyUp={handleKeyUp}
                />
                <span>
                  {applicableRange?.test_unit_type?.unit_type ?? 'N/A'}
                </span>
              </>
            )}
            {test.lab_test.test_result_type.result_type !== 'Numeric' &&
              test.lab_test.test_result_type.result_type !==
                'Numeric Outcome' &&
              test.lab_test.test_result_type.result_type !== 'Short Text' && (
                <>
                  <select
                    name='Value'
                    className={
                      applicableRange?.abnormal &&
                      isAbnormal(applicableRange?.abnormal, test.value ?? '')
                        ? 'abnormal'
                        : ''
                    }
                    disabled={isDisabled}
                    value={test.value ?? ''}
                    onChange={({ target }) =>
                      onValueChange(target.value, test.id)
                    }
                  >
                    <option value=''>Choose an option</option>
                    {test?.lab_test?.test_result_type?.options?.length > 0 &&
                      test?.lab_test?.test_result_type?.options.map((tlto) => (
                        <option key={tlto} value={tlto}>
                          {tlto}
                        </option>
                      ))}
                  </select>
                  <input
                    type='text'
                    placeholder='Remarks'
                    className='extra-value'
                    disabled={isDisabled}
                    value={test.extra_value ?? ''}
                    onChange={({ target }) =>
                      onExtraValueChange(target.value, test.id)
                    }
                  />
                </>
              )}
          </div>
          <div className='notes'>
            {test.test_status !== 'REJECTED' && (
              <button
                disabled={isDisabled}
                onClick={() => setShowNotes((sh) => !sh)}
              >
                {showNotes ? 'Hide Notes' : 'Show Notes'}
              </button>
            )}
            {showNotes && (
              <textarea
                className='notes-area'
                name='notes'
                placeholder='Result Notes'
                value={decodeNewLineText(test.observation)}
                onChange={(ev) => onNotesChange(ev.target.value, test.id)}
                cols={45}
                rows={4}
                disabled={isDisabled}
              ></textarea>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default TestRow
