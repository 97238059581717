import React, { useEffect, useState } from 'react'
import { usePatientOrders } from '../usePatientOrders'
import SearchTable from './SearchTable'
import { useAppSelector } from '../../../../app/hooks'
import {
  selectLocationById,
  selectSelectedLocationId,
} from '../../../location/locationSlice'
import { startSxpProxy } from '../../../../utils/api'
import { OPD_PROJECT_ID } from '../../../../utils/constants'
import { DomainResource } from 'fhir/r4'
import { formatPatientVisits } from '../../utils'
import { PatientVisit } from '../../models'
import PatientVisitsTable from './PatientVisitsTable'

type Props = {
  id: string
  onView: (url: string) => void
  onCreate: (id: string) => void
}

const AllOrders = ({ id, onView, onCreate }: Props) => {
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const orders = usePatientOrders(id, location?.resource?.name ?? '')
  const [visits, setVisits] = useState<PatientVisit[]>([])

  useEffect(() => {
    const intent = 'getPatientVisits'
    const state = { id: id }
    startSxpProxy(OPD_PROJECT_ID, intent, state)
      .then((data) => {
        const visitResources: { resource: DomainResource }[] =
          data.data.entry ?? []
        const patientVisits = formatPatientVisits(
          visitResources.map((vr) => vr.resource)
        )
        setVisits(patientVisits)
      })
      .catch((err) => console.log(err))
  }, [id])

  const ordered = orders.filter((o) => o.status === 'ORDERED')
  const collected = orders.filter((o) => o.status === 'COLLECTED')
  const completed = orders.filter((o) => o.status === 'COMPLETED')

  const handleViewOrdered = (id: string) => {
    onView(`collect/${id}`)
  }

  const handleViewCollected = (id: string) => {
    onView(`results/${id}`)
  }

  const handleViewCompleted = (id: string) => {
    onView(`results/${id}`)
  }

  return (
    <div>
      <SearchTable
        mode='ordered'
        orders={ordered}
        onOrderView={handleViewOrdered}
        onOrderCreate={() => onCreate(id)}
      />
      <SearchTable
        mode='collected'
        orders={collected}
        onOrderView={handleViewCollected}
      />
      <SearchTable
        mode='completed'
        orders={completed}
        onOrderView={handleViewCompleted}
      />
      <PatientVisitsTable visits={visits} />
    </div>
  )
}

export default AllOrders
