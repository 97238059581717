import { useEffect, useCallback, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useNavigate, useLocation } from 'react-router-dom'
import { capitalizeFirstLetter } from '../patients/utils'
import { appointmentType, upComingAppointmentType } from '../OPDScreen/constant'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID } from '../../utils/constants'
import './opd.scss'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}
interface Appointment {
  appointmentId: string
}
const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout
  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const DateofAppointments = (props: any) => {
  const { visitDates, dateHandler } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [filterEncounterDates, setFilterEncounterDates] = useState<
    appointmentType[]
  >([])
  const [appointments, setAppointments] = useState<appointmentType[]>([])
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    upComingAppointmentType[]
  >([])
  const [encounterDetails, setEncounterDetails] = useState<any>(null)
  const [filterActive, setFilterActive] = useState(false)
  const [appointmentIds, setAppointmentIds] = useState<string[]>([])
  const [filteredDate, setFilteredDate] = useState<string | null>(
    location.state?.filteredDate || 'All'
  )
  const [activeTab, setActiveTab] = useState(null)
  const [componentToRender, setComponentToRender] =
    useState<JSX.Element | null>(null)

  const timeFormat = (time: any) => {
    const date = new Date(time)
    if (isNaN(date.getTime())) {
      return 'Invalid date'
    }
    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'long',
      timeZone: 'Asia/Calcutta',
    }).format(date)
  }

  const getVisitsListDates = async (visitedData: any) => {
    if (visitedData) {
      const visitedDates = visitedData.filter(
        (elm: any) => elm.resource?.resourceType === 'Encounter'
      )
      setFilterEncounterDates(visitedDates)

      const appointments = visitedData.filter(
        (elm: any) => elm.resource?.resourceType === 'Appointment'
      )
      setAppointments(appointments)
    }
  }

  useEffect(() => {
    getVisitsListDates(visitDates)
  }, [visitDates])

  const sortedVisitDates = filterEncounterDates
    ?.slice()
    .sort((a: any, b: any) => {
      const aStartDate = new Date(
        b.resource?.statusHistory?.[0]?.period?.start
      ).getTime()
      const bStartDate = new Date(
        a.resource?.statusHistory?.[0]?.period?.start
      ).getTime()
      return aStartDate - bStartDate
    })

  const uniqueVisitDates = [
    'All',
    ...new Set(
      sortedVisitDates.map((d: any) => {
        const statusHistory = d?.resource?.statusHistory
        const startDate = statusHistory?.[0]?.period?.start
        return startDate ? timeFormat(startDate) : 'Date not available'
      })
    ),
  ]

  const debouncedHandleDateClick = debounce((d, i, doctorId) => {
    dateHandler(d, d.resource.status === 'finished')
    const appointmentId = d.resource.id
    const visitType = d.resource.type?.[0]?.coding?.[0]?.display.toLowerCase()
    const basePath = `/visits/${appointmentId}/history`

    navigate(basePath, {
      state: {
        id: d.resource.patientId,
        show: true,
        filteredDate: d.filteredDate,
        visitType,
        doctorId,
      },
    })

    setActiveTab(d.resource.id)
  }, 300)

  const handleDateClick = useCallback(
    (d: any, i: number, doctorId: string) => {
      debouncedHandleDateClick(d, i, doctorId)
    },
    [debouncedHandleDateClick]
  )

  const handleFilteredDateClick = (date: string) => {
    setFilteredDate(date)
    setFilterActive(false)
  }

  const patientReference =
    filterEncounterDates?.[0]?.resource?.subject?.reference
  const patientId = patientReference ? patientReference.split('/')[1] : ''

  const fetchUpComingAppointments = useCallback(async () => {
    try {
      const intent = 'allAppointments'
      const state = { id: parseInt(patientId, 10) }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setUpcomingAppointments(resp.data)
      const ids = resp.data.map(
        (appointment: Appointment) => appointment.appointmentId
      )
      setAppointmentIds(ids)
    } catch (e) {
      console.log(e)
    }
  }, [patientId])

  useEffect(() => {
    if (patientId) {
      fetchUpComingAppointments()
    }
  }, [patientId, fetchUpComingAppointments])

  const fetchEncounterForAppointments = async (appointmentIds: string) => {
    try {
      const intent = 'getEncounterForAppointments'
      const state = { appointmentIds }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setEncounterDetails(resp?.data)
    } catch (e) {
      console.error('Error fetching encounter details:', e)
    }
  }

  useEffect(() => {
    if (appointmentIds?.length > 0) {
      const appointmentIdsString = appointmentIds?.join(',')
      fetchEncounterForAppointments(appointmentIdsString)
    }
  }, [appointmentIds])
  return (
    <div className='carousel-container' style={{ width: '88vw' }}>
      <div className='filter-container'>
        <div
          className='uid-container'
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginBottom: '0.7rem',
            marginRight: '1rem',
          }}
        >
          <select
            value={filteredDate || ''}
            onChange={(e) => handleFilteredDateClick(e.target.value)}
            className='date-appointment-filter'
          >
            {uniqueVisitDates.map((date, i) => (
              <option key={i} value={date}>
                {date}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Carousel responsive={responsive}>
        {(filteredDate === 'All'
          ? sortedVisitDates
          : sortedVisitDates.filter((d: any) => {
              const statusHistory = d?.resource?.statusHistory
              const startDate = statusHistory?.[0]?.period?.start
              return startDate ? timeFormat(startDate) === filteredDate : false
            })
        ).map((d: any, i: any) => {
          const statusHistory = d.resource?.statusHistory
          const startDate = statusHistory?.[0]?.period?.start
          const reference = d?.resource?.participant?.[0]?.individual?.reference
          const afterSlash = reference?.split('/')[1]
          const visitType = capitalizeFirstLetter(
            d?.resource?.type?.[0]?.coding?.[0]?.display
          )
          const visitLabel = visitType ? ` - ${visitType}` : 'General'

          return (
            <div
              onClick={() => handleDateClick(d, i, afterSlash)}
              key={i}
              className='dateSelections'
            >
              <span
                className={`${
                  activeTab === d.resource.id ? 'activeDateTab' : ''
                }`}
              >
                {startDate ? timeFormat(startDate) : 'Date not available'}
                {visitLabel}
                <div>
                  <ul className='doc-name'>
                    {encounterDetails?.map((encounterDetail: any) => {
                      const matchingUpcoming = upcomingAppointments.find(
                        (upcoming) =>
                          encounterDetail.encounterId === d?.resource?.id &&
                          encounterDetail.appointmentId ===
                            upcoming.appointmentId
                      )

                      if (matchingUpcoming) {
                        return (
                          <li
                            key={encounterDetail.appointmentId}
                            style={{
                              display: 'inline-block',
                              marginRight: '10px',
                            }}
                          >
                            {matchingUpcoming.docName}
                          </li>
                        )
                      }
                      return null
                    })}
                  </ul>
                </div>
              </span>
            </div>
          )
        })}
      </Carousel>
      {componentToRender}
    </div>
  )
}

export default DateofAppointments
