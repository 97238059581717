import React from 'react'
import {
  StepDTO,
  StepStyleDTO,
} from 'react-form-stepper/dist/components/Step/StepTypes'
import { Stepper } from 'react-form-stepper'

type Props = {
  steps: StepDTO[]
  activeIndex: number
}

const styleProps: StepStyleDTO = {
  activeBgColor: '#087cb1',
  activeTextColor: '#ffffff',
  borderRadius: '50%',
  circleFontSize: '1rem',
  completedBgColor: '#0d3f6d',
  completedTextColor: '#ffffff',
  fontWeight: '500',
  inactiveBgColor: '#e0e0e0',
  inactiveTextColor: '#ffffff',
  labelFontSize: '0.875rem',
  size: '2em',
}

const FormStepper = ({ steps, activeIndex }: Props) => {
  return (
    <Stepper styleConfig={styleProps} steps={steps} activeStep={activeIndex} />
  )
}

export default FormStepper
